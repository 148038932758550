.banner-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;

    @include breakpoint-up('md') {
        display: block;
    }
}

.banner {

    &.--image {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &.--media {
        @include utils(flex, flex-column, flex-jc-end);
        @include spacing(py-0);
        background: rgb(0,0,0);

        @include breakpoint-up('md+') {
            min-height: rem(550px);
        }

        $banner__main--overlap: 10rem;

        .banner-content {
            @include utils(flex-order-2, w-100);
            @include spacing(pb-4);
            background: rgb(0,0,0);
            background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1) $banner__main--overlap, rgba(0, 0, 0, 1));
            margin-top: -10rem;
            z-index: 2;

            @include breakpoint-up('sm') {
                margin-top: -14rem;
            }

            @include breakpoint-up('sm+') {
                margin-top: -16rem;
            }

            @include breakpoint-up('md') {
                margin-top: -20rem;
            }

            @include breakpoint-up('md+') {
                @include spacing(mt-0, mb-8, pt-0, pb-0);
                background: none;
            }
        }

        .banner-media {
            @include utils(flex-order-1);
            max-height: 50vh;

            img,
            video {
                object-fit: cover;
                @include utils(w-100, h-100);
                // @include spacing(pb-24);
            }

            @include breakpoint-up('md+') {
                @include utils(absolute, h-100, w-100, top-0);
                max-height: none;

                img,
                video {
                    @include spacing(pb-0);
                }
            }
        }
    }
}
