.collapse {
    display: none;

    &.collapsible-show {
        display: block;
        animation: fadeIn 0.2s $easing-in;
    }
}

// soften the focus state of open items
.collapse-control--open {}


// MIKE
.collapse-control--open {
    .triangle-down {
        transform: rotate(180deg);
        @include utils(relative);
        top: 4px;
    }
}
