.header {
    background-color: color('white');
    z-index: 100;
    @include utils(sticky, top-0);
}

#lsq-logo {
    width: rem(140px);

    @include breakpoint-up('md') {
        width: rem(220px);
    }
}

#burger {
    height: 22px;
    padding: rem(7px) rem(17px);
    margin-top: 2px;
    position: relative;
    transition: .5s ease-in-out;
    cursor: pointer;
    transform: rotate(0deg);

    span {
        @include utils(block, absolute, w-100, opacity-100);
        background: color('grey-70');
        height: rem(4px);
        display: block;
        opacity: 1;
        left: 0;
        transition: .25s ease-in-out;
        transform: rotate(0deg);

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: rem(9px);
        }

        &:nth-child(3) {
            top: rem(9px);
        }

        &:nth-child(4) {
            top: rem(18px);
        }
    }
}

#mobile-menu {
    transform: translateY(-100%);
    z-index: 99;
}

.mobile-menu--visible {
    .header {
        background-color: color('black');
    }

    #mobile-menu {
        transform: translateY(0);
    }

    .mobile-nav-wrapper {
        $mobile-header-height: 77px; // TODO: Magic number! Related to height of header element

        @include utils(absolute);
        top: $mobile-header-height;
        height: calc(100vh - #{$mobile-header-height});
        left: 0;
        right: 0;
        bottom: 0;
    }

    #burger {
        span {
            background-color: color('white');
        }

        span:nth-child(1) {
            top: 16px;
            width: 0;
            left: 50%;
        }

        span:nth-child(2) {
            transform: rotate(45deg);
        }

        span:nth-child(3) {
            transform: rotate(-45deg);
        }

        span:nth-child(4) {
            top: 16px;
            width: 0;
            left: 50%;
        }
    }
}

// Prevents mobile menu background scrolling when menu open
.overlay-open {
    overflow: hidden;
    height: 100%;
}

.nav-item {
    position: relative;
    text-decoration: none;

    &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: color('black');
        width: 0;
    }

    &.active {
        font-weight: 600;
    }

    &:hover,
    &:focus,
    &.active {
        text-decoration: none;

        &:after {
            width: 100%;
            transition: width .3s;
        }
    }

    &:focus {
        outline: none;
        color: color('gold');
    }
}

.sub-nav {
    @include utils(absolute, bg-white, shadow-4);
    width: rem(280px);
    top: 150%;

    &:after {
        content: "";
        @include utils(block, absolute, w-0, h-0);
        top: 0;
        left: rem(30px);
        transform: translate(-50%, -100%);
        border-left: rem(8px) solid transparent;
        border-right: rem(8px) solid transparent;
        border-bottom: rem(8px) solid color('white');
        z-index: 1;
    }
}

.shortlist-count {
    $counter-dimension: 18px;
    $counter-line-height: 20px;

    @include utils(absolute, rounded-circle, font-medium, color-white, bg-red, inline-block, text-center);
    font-size: rem(13px);
    width: rem($counter-dimension);
    height: rem($counter-dimension);
    line-height: rem($counter-line-height);
    top: rem(0px);
    right: rem(-6px);

    &.--update {
        animation: pulse 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2s;
    }
}