.default-margins {
    :first-child {
        @include spacing(mt-0);
    }

    h1,
    h2,
    h3 {
        @include spacing(mt-5);

        @include breakpoint-up(md) {
            @include spacing(mt-8);
        }
    }

    h4,
    h5,
    h6,
    p,
    ul,
    ol {
        @include spacing(mt-4);

        @include breakpoint-up(md) {
            @include spacing(mt-8);
        }
    }

    table {
        @include spacing(mt-2);
    }

    li {
        @include spacing(mt-1);
    }

    ul,
    ol {
        /* stylelint-disable */
        & ol,
        & ul {
            @include spacing(mt-0);
        }
        /* stylelint-enable */
    }

    hr + & {
        margin-top: 0 !important;
    }



    // Specific relationship spacing


    // H3
    p + h3   { @include spacing(mt-24); }
    h6 + h3  { @include spacing(mt-4); }

    // H4
    p + h4   { @include spacing(mt-12); }

    // P
    h4 + p   { @include spacing(mt-4); }
    .small p + p,
    p.small + p.small { @include spacing(mt-4); }
}
