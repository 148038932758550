.btn {
    @include spacing(py-4, px-10);
    @include transition((border-color, color, background-color));
    @include utils(antialiased, uppercase);
    font-size: rem(15px);
    font-weight: $font-weight-bold;
    font-family: $font-family-headings;
    // border: none;

    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    white-space: nowrap;
    text-decoration: none !important;
    // border-width: 1px;
    // border-style: solid;
    cursor: pointer;

    // & + &:not(.btn--full) {
    //     @include spacing(ml-2, mt-2);
    // }

    &.disabled,
    &:disabled {
        box-shadow: none;
        pointer-events: none;
        border: none;
        background-color: #CCCCCC;
        color: #A3A3A3;

        i {
            opacity: 0.3;
        }
    }

    &:hover, &:focus {
        @include transition((none)); // creates a snappier hover effect
    }
}

.btn--default {
    color: color('white');
    background-color: color('gold');

    &:hover, &:focus {
        color: color('white');
        background-color: color('grey-70');
    }
}

.btn--secondary {
    color: color('white');
    background-color: color('red');

    &:hover, &:focus {
        color: color('white');
        background-color: color('grey-70');
    }
}

.btn--hollow, .btn--white-hollow {
    position: relative;
    background-color: transparent;
    line-height: 1;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform .3s;
        z-index: -1;
    }

    &:hover {
        &:before {
            transform: scaleX(1);
        }
    }
}

.btn--hollow {
    color: color('livid');
    border: 1px solid color('livid');

    &:before {
        background: color('livid');
    }

    &:hover {
        color: color('white');
    }
}

.btn--white-hollow {
    color: color('white');
    border: 1px solid color('white');

    &:before {
        background: color('white');
    }

    &:hover {
        color: color('black');
    }
}
