/*
* Config Functions.
* These functions are required for generating config maps
*/
/*
* NOTE
*  This font config needs to be rendered manually using the "render-font-style" @mixin
*  i.e. .thing { @include render-font-style(default) }
**/
/* * * * * * * * * * * * * * * * * * * * * * * *
* Public
* The following files actually output css
* They should be added accordingly in your entry files
* Utils should most likely come at the bottom of your scss include stack
* * * * * * * * * * * * * * * * * * * * * * */
@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes slideInUp {
  from {
    transform: translateY(15%); }
  to {
    transform: translateY(0); } }

@keyframes slideOutDown {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(-10%); } }

@keyframes slideOut {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateX(-100%); } }

@keyframes slideIn {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateY(0); } }

@keyframes opacity-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.6; }
  100% {
    opacity: 1; } }

@font-face {
  font-family: 'TTNorms';
  src: url("/static/fonts//TTNorms-Regular.eot");
  src: url("/static/fonts//TTNorms-Regular.eot?#iefix") format("embedded-opentype"), url("/static/fonts//TTNorms-Regular.woff2") format("woff2"), url("/static/fonts//TTNorms-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'TTNorms';
  src: url("/static/fonts//TTNorms-Regular-Italic.eot");
  src: url("/static/fonts//TTNorms-Regular-Italic.eot?#iefix") format("embedded-opentype"), url("/static/fonts//TTNorms-Regular-Italic.woff2") format("woff2"), url("/static/fonts//TTNorms-Regular-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: 'TTNorms';
  src: url("/static/fonts//TTNorms-Medium.eot");
  src: url("/static/fonts//TTNorms-Medium.eot?#iefix") format("embedded-opentype"), url("/static/fonts//TTNorms-Medium.woff2") format("woff2"), url("/static/fonts//TTNorms-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'TTNorms';
  src: url("/static/fonts//TTNorms-Medium-Italic.eot");
  src: url("/static/fonts//TTNorms-Medium-Italic.eot?#iefix") format("embedded-opentype"), url("/static/fonts//TTNorms-Medium-Italic.woff2") format("woff2"), url("/static/fonts//TTNorms-Medium-Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: 'TTNorms';
  src: url("/static/fonts//TTNorms-Bold.eot");
  src: url("/static/fonts//TTNorms-Bold.eot?#iefix") format("embedded-opentype"), url("/static/fonts//TTNorms-Bold.woff2") format("woff2"), url("/static/fonts//TTNorms-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'TTNorms';
  src: url("/static/fonts//TTNorms-Bold-Italic.eot");
  src: url("/static/fonts//TTNorms-Bold-Italic.eot?#iefix") format("embedded-opentype"), url("/static/fonts//TTNorms-Bold-Italic.woff2") format("woff2"), url("/static/fonts//TTNorms-Bold-Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: 'Rational';
  src: url("/static/fonts//Rational-TW-Display-Semibold.eot");
  src: url("/static/fonts//Rational-TW-Display-Semibold.eot?#iefix") format("embedded-opentype"), url("/static/fonts//Rational-TW-Display-Semibold.woff2") format("woff2"), url("/static/fonts//Rational-TW-Display-Semibold.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  text-align: left;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

main {
  display: block; }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0; }

h1 {
  font-size: 2em; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 66%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none;
  vertical-align: middle; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  appearance: none; }

::-webkit-file-upload-button {
  appearance: button;
  font: inherit; }

::-ms-expand {
  background-color: transparent;
  border: 0; }

::placeholder {
  opacity: 1; }

:disabled,
[readonly] {
  opacity: 1; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

table {
  border-collapse: collapse; }

caption {
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  font-weight: normal; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

label {
  display: inline-block; }

textarea {
  overflow: auto;
  resize: vertical; }

dt {
  font-weight: bold; }

* {
  margin-top: 0;
  margin-bottom: 0; }

body,
html {
  min-height: 100%;
  font-feature-settings: "liga" off;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smooth: always; }

::selection {
  background-color: #A18455;
  color: #FFFFFF; }

::-moz-selection {
  background-color: #A18455;
  color: #FFFFFF; }

html {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "eb-garamond", serif;
  font-weight: 400;
  color: #000000; }

strong {
  font-weight: 400; }

body,
.font-default {
  font-family: "eb-garamond", serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.5rem; }

.super {
  line-height: 1.63;
  font-size: 1.5rem; }
  @media (min-width: 410px) {
    .super {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    .super {
      font-size: 4.25rem; } }
  @media (min-width: 1024px) {
    .super {
      font-size: 5rem; } }
  @media (min-width: 1440px) {
    .super {
      font-size: 7.375rem; } }

.xlarge {
  line-height: 1.3;
  font-size: 2.25rem; }
  @media (min-width: 768px) {
    .xlarge {
      font-size: 3.125rem; } }

.large {
  line-height: 1.5;
  font-size: 1.75rem; }

.small {
  line-height: 1.65;
  font-size: 1.25rem; }

.tiny {
  line-height: 1.3;
  font-size: 0.75rem; }
  @media (min-width: 768px) {
    .tiny {
      font-size: 0.875rem; } }
  @media (min-width: 1440px) {
    .tiny {
      font-size: 0.875rem; } }

.h1,
h1 {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  font-size: 2.25rem; }
  @media (min-width: 768px) {
    .h1,
    h1 {
      font-size: 2.375rem; } }
  @media (min-width: 1440px) {
    .h1,
    h1 {
      font-size: 2.5rem; } }
  @media (max-width: 599.98px) {
    .h1.too-big,
    h1.too-big {
      font-size: 1.375rem;
      letter-spacing: .3rem; } }

.h1.small,
h1.small {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 2.25rem; }
  @media (min-width: 768px) {
    .h1.small,
    h1.small {
      font-weight: 500;
      line-height: 1.15; } }

.h2,
h2 {
  font-family: "eb-garamond", serif;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.025em;
  font-size: 2.25rem; }
  @media (min-width: 768px) {
    .h2,
    h2 {
      font-size: 3.125rem; } }

.default-margins .h2,
.default-margins h2 {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 2.25rem; }
  @media (min-width: 768px) {
    .default-margins .h2,
    .default-margins h2 {
      font-weight: 500;
      line-height: 1.15; } }

.h3,
h3 {
  font-family: "eb-garamond", serif;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.025em;
  color: #8A8A8A;
  font-size: 2.25rem; }
  @media (min-width: 768px) {
    .h3,
    h3 {
      font-size: 2.375rem; } }

.h4,
h4 {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 500;
  line-height: 1.15;
  font-size: 2.25rem; }

.h4.small,
h4.small {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 500;
  line-height: 1.15;
  font-size: 1.75rem; }

.h4.tiny,
h4.tiny {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 500;
  line-height: 1.15;
  font-size: 1.5rem; }
  @media (min-width: 768px) {
    .h4.tiny,
    h4.tiny {
      font-size: 1.25rem; } }

.h5,
h5 {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  color: #A18455;
  letter-spacing: 0.5em;
  font-size: 1.25rem; }
  @media (min-width: 768px) {
    .h5,
    h5 {
      font-size: 1.5625rem; } }

.h5.small,
h5.small {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  font-size: 1.25rem; }
  @media (min-width: 768px) {
    .h5.small,
    h5.small {
      font-size: 1.375rem; } }

.h6,
h6 {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  font-size: 1rem; }

.h6.small,
h6.small {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 0.8125rem; }
  @media (min-width: 1440px) {
    .h6.small,
    h6.small {
      font-size: 0.9375rem; } }

.h6.tiny,
h6.tiny {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 400;
  line-height: 2;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.8125rem; }

select,
textarea,
input[type=text],
input[type=password],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
.form-control {
  font-family: "gill-sans-nova", sans-serif;
  width: 100%;
  padding: 0.9375rem;
  background-color: #E7E7E7;
  background-clip: padding-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
  select::placeholder,
  textarea::placeholder,
  input[type=text]::placeholder,
  input[type=password]::placeholder,
  input[type=number]::placeholder,
  input[type=email]::placeholder,
  input[type=url]::placeholder,
  input[type=search]::placeholder,
  input[type=tel]::placeholder,
  .form-control::placeholder {
    color: #AFB0B0;
    font-weight: 300; }
  select:disabled, select[readonly],
  textarea:disabled,
  textarea[readonly],
  input[type=text]:disabled,
  input[type=text][readonly],
  input[type=password]:disabled,
  input[type=password][readonly],
  input[type=number]:disabled,
  input[type=number][readonly],
  input[type=email]:disabled,
  input[type=email][readonly],
  input[type=url]:disabled,
  input[type=url][readonly],
  input[type=search]:disabled,
  input[type=search][readonly],
  input[type=tel]:disabled,
  input[type=tel][readonly],
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #C9C9C9; }

.bg-grey-5 select,
.bg-grey-5 textarea,
.bg-grey-5 input[type=text],
.bg-grey-5 input[type=password],
.bg-grey-5 input[type=number],
.bg-grey-5 input[type=email],
.bg-grey-5 input[type=url],
.bg-grey-5 input[type=search],
.bg-grey-5 input[type=tel],
.bg-grey-5 .form-control,
.bg-grey-10 select,
.bg-grey-10 textarea,
.bg-grey-10 input[type=text],
.bg-grey-10 input[type=password],
.bg-grey-10 input[type=number],
.bg-grey-10 input[type=email],
.bg-grey-10 input[type=url],
.bg-grey-10 input[type=search],
.bg-grey-10 input[type=tel],
.bg-grey-10 .form-control {
  background-color: #FFFFFF; }

label {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  color: #A18455;
  letter-spacing: 0.5em;
  font-size: 1.25rem;
  margin-bottom: 0.5rem; }
  @media (min-width: 768px) {
    label {
      font-size: 1.5625rem; } }

input[type=file],
input[type=range],
.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

select[size], select[multiple],
select.form-control[size],
select.form-control[multiple] {
  height: auto; }

textarea,
textarea.form-control {
  height: auto; }

.default-margins .form-group {
  margin-top: 1.5rem; }
  .default-margins .form-group + * {
    margin-top: 1.5rem; }

.default-margins label + select,
.default-margins label + textarea,
.default-margins label + input[type=text],
.default-margins label + input[type=password],
.default-margins label + input[type=number],
.default-margins label + input[type=email],
.default-margins label + input[type=url],
.default-margins label + input[type=search],
.default-margins label + input[type=tel],
.default-margins label + .form-control {
  margin-top: 0.25rem; }

.form--fieldset-mb0 fieldset {
  margin-bottom: 0.125rem !important; }

.radio {
  position: relative; }
  .radio > input[type="radio"] {
    appearance: none;
    background: transparent;
    opacity: .00001;
    z-index: 2; }
  .radio > label {
    display: inline-block;
    padding: .75em .5em .75em 2em; }
  .radio > input[type="radio"],
  .radio > label:before,
  .radio > label:after {
    border: 2px solid;
    height: 1.125em;
    left: .125em;
    position: absolute;
    top: .825em;
    width: 1.125em;
    border-radius: 100%;
    content: " ";
    transition: border-color .2s ease-in-out, box-shadow .2s ease-in-out; }
  .radio > label:after {
    border-color: #565656; }
  .radio > label:before {
    border-color: transparent;
    background: #FFFFFF;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0); }
  .radio > input:checked ~ label:before {
    border-color: transparent;
    box-shadow: 0 0 0 2px #0d5192; }
  .radio > input:focus ~ label:before {
    border-color: transparent;
    box-shadow: 0 0 0 4px #228BEC; }
  .radio > input:checked ~ label:after {
    border-color: #fff;
    border-width: 4px;
    box-shadow: inset 0 0 0 8px #0d5192; }
  .culture-club .radio > input:checked ~ label:before {
    box-shadow: 0 0 0 2px var(--theme-color); }
  .culture-club .radio > input:checked ~ label:after {
    box-shadow: inset 0 0 0 8px var(--theme-color); }

.radio-lockup {
  margin-top: -2rem;
  margin-left: -2rem; }
  .radio-lockup .radio {
    margin-top: 2rem;
    margin-left: 2rem; }

.checkbox {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center; }
  .checkbox input {
    flex-shrink: 0;
    -webkit-appearance: none;
    background: #FFFFFF;
    border-radius: 4px;
    border: 2px solid #6E6E6E;
    font-size: inherit;
    height: 2em;
    line-height: 1;
    margin: 0;
    position: relative;
    vertical-align: bottom;
    width: 2em; }
    .checkbox input:before, .checkbox input:after {
      content: "";
      position: absolute;
      transition: transform .2s ease-in-out;
      border-bottom: 3px solid #0d5192;
      border-right: 3px solid #0d5192;
      bottom: 0;
      height: 1em;
      margin: auto;
      right: .5em;
      top: -.5em;
      transform: rotate(45deg) scale(0);
      width: .625em; }
    .checkbox input:checked {
      border-color: #0d5192;
      box-shadow: inset 0 0 20px 3px rgba(34, 139, 236, 0.05); }
    .checkbox input:checked:before {
      transform: rotate(45deg) scale(1); }
    .checkbox input:focus {
      box-shadow: 0 0 0 1px #0d5192;
      border: 2px solid #0d5192;
      outline: 1px dotted #0d5192;
      outline-offset: 3px; }
  .checkbox label {
    position: relative;
    left: -.25em;
    padding-left: .75em;
    margin-bottom: 0; }
  .culture-club .checkbox input:before, .culture-club .checkbox input:after {
    border-bottom: 3px solid var(--theme-color);
    border-right: 3px solid var(--theme-color); }
  .culture-club .checkbox input:checked {
    border-color: var(--theme-color); }
  .culture-club .checkbox input:focus {
    box-shadow: 0 0 0 1px var(--theme-color);
    border: 2px solid var(--theme-color);
    outline: 1px dotted var(--theme-color); }
  .checkbox + .checkbox {
    margin-top: 1rem; }

hr {
  color: #AFB0B0;
  background-color: currentColor;
  border: 0;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  @media (min-width: 1440px) {
    hr {
      margin-top: 2rem;
      margin-bottom: 2rem; } }

hr:not([size]) {
  height: 1px; }

hr + * {
  margin-top: 0; }

code {
  background: #E7E7E7;
  color: #F1BA9D;
  border: 1px solid #C9C9C9;
  display: inline-block;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 4px; }

iframe {
  height: 100%;
  width: 100%;
  border: 0 none; }

.btn--link,
a {
  color: #8A2C2C;
  text-decoration: none;
  cursor: pointer; }
  .btn--link:hover, .btn--link:focus,
  a:hover,
  a:focus {
    text-decoration: underline; }
  .btn--link.link--reverse,
  .reverse-links .btn--link,
  a.link--reverse,
  .reverse-links
  a {
    text-decoration: none; }
    .btn--link.link--reverse:hover,
    .reverse-links .btn--link:hover,
    a.link--reverse:hover,
    .reverse-links
    a:hover {
      text-decoration: underline; }
  .btn--link.underline-link,
  a.underline-link {
    text-decoration: underline; }
    .btn--link.underline-link:hover, .btn--link.underline-link:focus,
    a.underline-link:hover,
    a.underline-link:focus {
      text-decoration: none; }

.link--no-hover:hover {
  text-decoration: none; }

.link--stubby {
  font-family: "gill-sans-nova", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 0.8125rem;
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #000000;
  border-color: #000000; }
  @media (min-width: 1440px) {
    .link--stubby {
      font-size: 0.9375rem; } }
  @media (min-width: 768px) {
    .link--stubby {
      padding-left: 0.75rem;
      padding-right: 0.75rem; } }
  .link--stubby span {
    display: inline-block;
    border-bottom-width: 2px;
    padding-bottom: 0.25rem; }
  .link--stubby:hover {
    color: #000000;
    cursor: pointer;
    text-decoration: none; }
    .link--stubby:hover span {
      border-color: #000000; }
  .color-white .link--stubby, .link--stubby.color-white,
  .--shade .link--stubby {
    color: #FFFFFF;
    border-color: #FFFFFF; }
    .color-white .link--stubby span, .link--stubby.color-white span,
    .--shade .link--stubby span {
      border-color: #FFFFFF; }
    .color-white .link--stubby:hover span, .link--stubby.color-white:hover span,
    .--shade .link--stubby:hover span {
      border-color: transparent; }

.color-white-link a {
  color: #FFFFFF; }

.link--stubby-lockup {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }
  @media (min-width: 768px) {
    .link--stubby-lockup {
      margin-left: -0.75rem;
      margin-right: -0.75rem; } }

.link--alt {
  display: inline-block;
  color: #000000;
  text-decoration: none;
  border-bottom-width: 2px;
  border-color: rgba(0, 0, 0, 0); }
  .link--alt:hover {
    border-color: #CADFC0;
    border-color: var(--theme-color); }

.sponsor:hover {
  position: relative;
  opacity: 0.5;
  top: -0.125rem; }

ul,
.default-margins .list-zero {
  list-style: none;
  padding: 0;
  margin: 0; }

ol,
ul.bullet-list {
  padding-left: 2rem; }

ul.bullet-list {
  list-style: initial; }

dl {
  font-weight: bold; }

.list-reset {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.default-margins ul {
  list-style-image: url("data:image/svg+xml;charset=UTF-8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath d='M8 8H0V0h8v8zM1 7h6V1H1v6z'/%3E%3C/svg%3E%0A");
  padding-left: 1rem;
  margin-bottom: 2rem; }
  .default-margins ul li {
    padding-left: 1.25rem; }

img {
  max-width: 100%;
  height: auto; }
  img.fill {
    width: 100%; }
  img.fill-portrait {
    max-height: 90vh;
    width: auto; }

.aspect-ratio {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .aspect-ratio > :first-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
  .aspect-ratio::before {
    content: "";
    display: block; }

.aspect-ratio--16by9::before {
  padding-top: 56.25%; }

.aspect-ratio-square::before {
  padding-top: 100%; }

.aspect-ratio--portrait::before {
  padding-top: 139.5%; }

.aspect-ratio--tile::before {
  padding-top: 114.667%; }

.responsive-object,
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  background-color: rgba(255, 255, 255, 0.1);
  clear: both; }

.responsive-object iframe,
.responsive-object object,
.responsive-object embed,
.video-wrapper iframe,
.video-wrapper object,
.video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.default-margins :first-child {
  margin-top: 0; }

.default-margins h1,
.default-margins h2,
.default-margins h3 {
  margin-top: 1.25rem; }
  @media (min-width: 768px) {
    .default-margins h1,
    .default-margins h2,
    .default-margins h3 {
      margin-top: 2rem; } }

.default-margins h4,
.default-margins h5,
.default-margins h6,
.default-margins p,
.default-margins ul,
.default-margins ol {
  margin-top: 1rem; }
  @media (min-width: 768px) {
    .default-margins h4,
    .default-margins h5,
    .default-margins h6,
    .default-margins p,
    .default-margins ul,
    .default-margins ol {
      margin-top: 2rem; } }

.default-margins table {
  margin-top: 0.5rem; }

.default-margins li {
  margin-top: 0.25rem; }

.default-margins ul,
.default-margins ol {
  /* stylelint-disable */
  /* stylelint-enable */ }
  .default-margins ul ol,
  .default-margins ul ul,
  .default-margins ol ol,
  .default-margins ol ul {
    margin-top: 0; }

hr + .default-margins {
  margin-top: 0 !important; }

.default-margins p + h3 {
  margin-top: 6rem; }

.default-margins h6 + h3 {
  margin-top: 1rem; }

.default-margins p + h4 {
  margin-top: 3rem; }

.default-margins h4 + p {
  margin-top: 1rem; }

.default-margins .small p + p,
.default-margins p.small + p.small {
  margin-top: 1rem; }

svg {
  overflow: hidden;
  vertical-align: middle;
  fill: currentColor;
  pointer-events: none; }

.svg-max-width svg {
  max-width: 100%; }

table {
  width: 100%;
  color: #000000; }
  table th,
  table td {
    padding: 0.5rem;
    vertical-align: top; }
  table thead th {
    vertical-align: bottom;
    background-color: #E7E7E7; }

th {
  font-weight: 400; }

.table--lg th,
.table--lg td {
  padding: 0.75rem; }

.table--border-rows th,
.table--border-rows td {
  border-top: 1px solid #D0D0D1; }

.table--border-rows thead th {
  border-bottom: 1px solid #D0D0D1; }

.table--border-rows tbody + tbody {
  border-top: 1px solid #D0D0D1; }

.table--bordered {
  border: 1px solid #D0D0D1; }
  .table--bordered th,
  .table--bordered td {
    border: 1px solid #D0D0D1; }
  .table--bordered thead th,
  .table--bordered thead td {
    border-bottom-width: 1px; }

.table--borderless th,
.table--borderless td,
.table--borderless thead th,
.table--borderless tbody + tbody {
  border: 0; }

.table--borderless tr :first-child {
  padding-left: 0; }

.table--borderless tr :last-child {
  padding-right: 0; }

.table--striped tbody tr:nth-of-type(odd) {
  background-color: #E7E7E7; }

.table--hover tbody tr {
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .table--hover tbody tr:hover {
    background-color: #000000;
    color: #FFFFFF; }

.responsive-table {
  position: relative;
  width: calc(100vw);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 3px; }
  .responsive-table::-webkit-scrollbar {
    width: 3px;
    height: 3px; }
  .responsive-table::-webkit-scrollbar-track {
    background-color: #C9C9C9; }
  .responsive-table::-webkit-scrollbar-thumb {
    background-color: #0F4DBC; }
  .responsive-table th {
    white-space: nowrap; }

/* * * * * * * * * * * * * * * * * * * * * * * *
* Grid Class output
* * * * * * * * * * * * * * * * * * * * * * */
.grid {
  display: flex;
  flex-wrap: wrap; }
  .grid > * {
    position: relative;
    width: 100%; }

.grid .gutters,
.grid--gutters {
  margin-right: -15px;
  margin-left: -15px; }
  .grid .gutters > *,
  .grid--gutters > * {
    padding-right: 15px;
    padding-left: 15px; }

.grid .gutters-sm,
.grid--gutters-sm {
  margin-right: -6px;
  margin-left: -6px; }
  .grid .gutters-sm > *,
  .grid--gutters-sm > * {
    padding-right: 6px;
    padding-left: 6px; }

.col {
  flex-basis: 0 ;
  flex-grow: 1 ;
  max-width: 100% ; }

.c-auto {
  flex: 0 0 auto ;
  width: auto ;
  max-width: 100% ; }

.c1 {
  flex: 0 0 8.33333% ;
  max-width: 8.33333% ; }

.c2 {
  flex: 0 0 16.66667% ;
  max-width: 16.66667% ; }

.c3 {
  flex: 0 0 25% ;
  max-width: 25% ; }

.c4 {
  flex: 0 0 33.33333% ;
  max-width: 33.33333% ; }

.c5 {
  flex: 0 0 41.66667% ;
  max-width: 41.66667% ; }

.c6 {
  flex: 0 0 50% ;
  max-width: 50% ; }

.c7 {
  flex: 0 0 58.33333% ;
  max-width: 58.33333% ; }

.c8 {
  flex: 0 0 66.66667% ;
  max-width: 66.66667% ; }

.c9 {
  flex: 0 0 75% ;
  max-width: 75% ; }

.c10 {
  flex: 0 0 83.33333% ;
  max-width: 83.33333% ; }

.c11 {
  flex: 0 0 91.66667% ;
  max-width: 91.66667% ; }

.c12 {
  flex: 0 0 100% ;
  max-width: 100% ; }

.o0 {
  margin-left: 0% ; }

.o1 {
  margin-left: 8.33333% ; }

.o2 {
  margin-left: 16.66667% ; }

.o3 {
  margin-left: 25% ; }

.o4 {
  margin-left: 33.33333% ; }

.o5 {
  margin-left: 41.66667% ; }

.o6 {
  margin-left: 50% ; }

.o7 {
  margin-left: 58.33333% ; }

.o8 {
  margin-left: 66.66667% ; }

.o9 {
  margin-left: 75% ; }

.o10 {
  margin-left: 83.33333% ; }

.o11 {
  margin-left: 91.66667% ; }

@media (min-width: 410px) {
  .sm\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .sm\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .sm\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .sm\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .sm\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .sm\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .sm\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .sm\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .sm\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .sm\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .sm\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .sm\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .sm\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .sm\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .sm\:o0 {
    margin-left: 0% ; }
  .sm\:o1 {
    margin-left: 8.33333% ; }
  .sm\:o2 {
    margin-left: 16.66667% ; }
  .sm\:o3 {
    margin-left: 25% ; }
  .sm\:o4 {
    margin-left: 33.33333% ; }
  .sm\:o5 {
    margin-left: 41.66667% ; }
  .sm\:o6 {
    margin-left: 50% ; }
  .sm\:o7 {
    margin-left: 58.33333% ; }
  .sm\:o8 {
    margin-left: 66.66667% ; }
  .sm\:o9 {
    margin-left: 75% ; }
  .sm\:o10 {
    margin-left: 83.33333% ; }
  .sm\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 600px) {
  .sm\+\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .sm\+\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .sm\+\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .sm\+\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .sm\+\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .sm\+\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .sm\+\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .sm\+\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .sm\+\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .sm\+\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .sm\+\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .sm\+\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .sm\+\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .sm\+\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .sm\+\:o0 {
    margin-left: 0% ; }
  .sm\+\:o1 {
    margin-left: 8.33333% ; }
  .sm\+\:o2 {
    margin-left: 16.66667% ; }
  .sm\+\:o3 {
    margin-left: 25% ; }
  .sm\+\:o4 {
    margin-left: 33.33333% ; }
  .sm\+\:o5 {
    margin-left: 41.66667% ; }
  .sm\+\:o6 {
    margin-left: 50% ; }
  .sm\+\:o7 {
    margin-left: 58.33333% ; }
  .sm\+\:o8 {
    margin-left: 66.66667% ; }
  .sm\+\:o9 {
    margin-left: 75% ; }
  .sm\+\:o10 {
    margin-left: 83.33333% ; }
  .sm\+\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 768px) {
  .md\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .md\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .md\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .md\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .md\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .md\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .md\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .md\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .md\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .md\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .md\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .md\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .md\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .md\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .md\:o0 {
    margin-left: 0% ; }
  .md\:o1 {
    margin-left: 8.33333% ; }
  .md\:o2 {
    margin-left: 16.66667% ; }
  .md\:o3 {
    margin-left: 25% ; }
  .md\:o4 {
    margin-left: 33.33333% ; }
  .md\:o5 {
    margin-left: 41.66667% ; }
  .md\:o6 {
    margin-left: 50% ; }
  .md\:o7 {
    margin-left: 58.33333% ; }
  .md\:o8 {
    margin-left: 66.66667% ; }
  .md\:o9 {
    margin-left: 75% ; }
  .md\:o10 {
    margin-left: 83.33333% ; }
  .md\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 1024px) {
  .md\+\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .md\+\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .md\+\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .md\+\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .md\+\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .md\+\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .md\+\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .md\+\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .md\+\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .md\+\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .md\+\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .md\+\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .md\+\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .md\+\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .md\+\:o0 {
    margin-left: 0% ; }
  .md\+\:o1 {
    margin-left: 8.33333% ; }
  .md\+\:o2 {
    margin-left: 16.66667% ; }
  .md\+\:o3 {
    margin-left: 25% ; }
  .md\+\:o4 {
    margin-left: 33.33333% ; }
  .md\+\:o5 {
    margin-left: 41.66667% ; }
  .md\+\:o6 {
    margin-left: 50% ; }
  .md\+\:o7 {
    margin-left: 58.33333% ; }
  .md\+\:o8 {
    margin-left: 66.66667% ; }
  .md\+\:o9 {
    margin-left: 75% ; }
  .md\+\:o10 {
    margin-left: 83.33333% ; }
  .md\+\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 1440px) {
  .lg\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .lg\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .lg\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .lg\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .lg\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .lg\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .lg\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .lg\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .lg\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .lg\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .lg\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .lg\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .lg\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .lg\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .lg\:o0 {
    margin-left: 0% ; }
  .lg\:o1 {
    margin-left: 8.33333% ; }
  .lg\:o2 {
    margin-left: 16.66667% ; }
  .lg\:o3 {
    margin-left: 25% ; }
  .lg\:o4 {
    margin-left: 33.33333% ; }
  .lg\:o5 {
    margin-left: 41.66667% ; }
  .lg\:o6 {
    margin-left: 50% ; }
  .lg\:o7 {
    margin-left: 58.33333% ; }
  .lg\:o8 {
    margin-left: 66.66667% ; }
  .lg\:o9 {
    margin-left: 75% ; }
  .lg\:o10 {
    margin-left: 83.33333% ; }
  .lg\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 1800px) {
  .xl\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .xl\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .xl\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .xl\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .xl\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .xl\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .xl\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .xl\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .xl\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .xl\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .xl\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .xl\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .xl\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .xl\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .xl\:o0 {
    margin-left: 0% ; }
  .xl\:o1 {
    margin-left: 8.33333% ; }
  .xl\:o2 {
    margin-left: 16.66667% ; }
  .xl\:o3 {
    margin-left: 25% ; }
  .xl\:o4 {
    margin-left: 33.33333% ; }
  .xl\:o5 {
    margin-left: 41.66667% ; }
  .xl\:o6 {
    margin-left: 50% ; }
  .xl\:o7 {
    margin-left: 58.33333% ; }
  .xl\:o8 {
    margin-left: 66.66667% ; }
  .xl\:o9 {
    margin-left: 75% ; }
  .xl\:o10 {
    margin-left: 83.33333% ; }
  .xl\:o11 {
    margin-left: 91.66667% ; } }

.theme-color {
  color: #CADFC0;
  color: var(--theme-color); }

.theme-color-dark {
  color: #A9CA99;
  color: var(--theme-color-dark); }

.theme-bg-color {
  background-color: #CADFC0;
  background-color: var(--theme-color); }

.theme-bg-color-tint {
  background: #CADFC0;
  /* The Fallback */
  background: rgba(202, 223, 192, 0.3);
  background: var(--theme-color);
  /* The Fallback */
  background: rgba(var(--theme-color), 0.3); }

.theme-dark {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF; }
  .theme-dark a {
    color: #FFFFFF; }

.site-wrapper {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto; }

.container, .container--responsive, .container--full {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.1875rem;
  padding-right: 2.1875rem; }
  @media (min-width: 768px) {
    .container, .container--responsive, .container--full {
      padding-left: 3rem;
      padding-right: 3rem; } }

.container {
  max-width: 1300px; }

.container--responsive {
  /*
    * matches wrapper-medium from main site
    */
  max-width: 900px; }
  @media (min-width: 768px) {
    .container--responsive {
      max-width: 1000px; } }
  @media (min-width: 1440px) {
    .container--responsive {
      max-width: 1100px; } }
  @media (min-width: 1800px) {
    .container--responsive {
      max-width: 1300px; } }

.--default-y-padding {
  padding-top: 3rem;
  padding-bottom: 6rem; }
  @media (min-width: 768px) {
    .--default-y-padding {
      padding-top: 6rem;
      padding-bottom: 12rem; } }

.grid {
  display: flex;
  flex-wrap: wrap; }
  .grid > * {
    position: relative;
    width: 100%; }

.grid--gutters {
  margin-right: -8px;
  margin-left: -8px; }
  .grid--gutters > * {
    padding-right: 8px;
    padding-left: 8px; }
  @media (min-width: 768px) {
    .grid--gutters {
      margin-right: -16px;
      margin-left: -16px; }
      .grid--gutters > * {
        padding-right: 16px;
        padding-left: 16px; } }

.grid--gutters-sm {
  margin-right: -6px;
  margin-left: -6px; }
  .grid--gutters-sm > * {
    padding-right: 6px;
    padding-left: 6px; }

.col {
  flex-basis: 0 ;
  flex-grow: 1 ;
  max-width: 100% ; }

.c-auto {
  flex: 0 0 auto ;
  width: auto ;
  max-width: 100% ; }

.c1 {
  flex: 0 0 8.33333% ;
  max-width: 8.33333% ; }

.c2 {
  flex: 0 0 16.66667% ;
  max-width: 16.66667% ; }

.c3 {
  flex: 0 0 25% ;
  max-width: 25% ; }

.c4 {
  flex: 0 0 33.33333% ;
  max-width: 33.33333% ; }

.c5 {
  flex: 0 0 41.66667% ;
  max-width: 41.66667% ; }

.c6 {
  flex: 0 0 50% ;
  max-width: 50% ; }

.c7 {
  flex: 0 0 58.33333% ;
  max-width: 58.33333% ; }

.c8 {
  flex: 0 0 66.66667% ;
  max-width: 66.66667% ; }

.c9 {
  flex: 0 0 75% ;
  max-width: 75% ; }

.c10 {
  flex: 0 0 83.33333% ;
  max-width: 83.33333% ; }

.c11 {
  flex: 0 0 91.66667% ;
  max-width: 91.66667% ; }

.c12 {
  flex: 0 0 100% ;
  max-width: 100% ; }

.o0 {
  margin-left: 0% ; }

.o1 {
  margin-left: 8.33333% ; }

.o2 {
  margin-left: 16.66667% ; }

.o3 {
  margin-left: 25% ; }

.o4 {
  margin-left: 33.33333% ; }

.o5 {
  margin-left: 41.66667% ; }

.o6 {
  margin-left: 50% ; }

.o7 {
  margin-left: 58.33333% ; }

.o8 {
  margin-left: 66.66667% ; }

.o9 {
  margin-left: 75% ; }

.o10 {
  margin-left: 83.33333% ; }

.o11 {
  margin-left: 91.66667% ; }

@media (min-width: 410px) {
  .sm\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .sm\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .sm\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .sm\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .sm\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .sm\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .sm\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .sm\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .sm\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .sm\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .sm\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .sm\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .sm\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .sm\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .sm\:o0 {
    margin-left: 0% ; }
  .sm\:o1 {
    margin-left: 8.33333% ; }
  .sm\:o2 {
    margin-left: 16.66667% ; }
  .sm\:o3 {
    margin-left: 25% ; }
  .sm\:o4 {
    margin-left: 33.33333% ; }
  .sm\:o5 {
    margin-left: 41.66667% ; }
  .sm\:o6 {
    margin-left: 50% ; }
  .sm\:o7 {
    margin-left: 58.33333% ; }
  .sm\:o8 {
    margin-left: 66.66667% ; }
  .sm\:o9 {
    margin-left: 75% ; }
  .sm\:o10 {
    margin-left: 83.33333% ; }
  .sm\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 600px) {
  .sm\+\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .sm\+\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .sm\+\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .sm\+\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .sm\+\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .sm\+\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .sm\+\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .sm\+\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .sm\+\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .sm\+\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .sm\+\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .sm\+\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .sm\+\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .sm\+\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .sm\+\:o0 {
    margin-left: 0% ; }
  .sm\+\:o1 {
    margin-left: 8.33333% ; }
  .sm\+\:o2 {
    margin-left: 16.66667% ; }
  .sm\+\:o3 {
    margin-left: 25% ; }
  .sm\+\:o4 {
    margin-left: 33.33333% ; }
  .sm\+\:o5 {
    margin-left: 41.66667% ; }
  .sm\+\:o6 {
    margin-left: 50% ; }
  .sm\+\:o7 {
    margin-left: 58.33333% ; }
  .sm\+\:o8 {
    margin-left: 66.66667% ; }
  .sm\+\:o9 {
    margin-left: 75% ; }
  .sm\+\:o10 {
    margin-left: 83.33333% ; }
  .sm\+\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 768px) {
  .md\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .md\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .md\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .md\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .md\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .md\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .md\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .md\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .md\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .md\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .md\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .md\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .md\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .md\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .md\:o0 {
    margin-left: 0% ; }
  .md\:o1 {
    margin-left: 8.33333% ; }
  .md\:o2 {
    margin-left: 16.66667% ; }
  .md\:o3 {
    margin-left: 25% ; }
  .md\:o4 {
    margin-left: 33.33333% ; }
  .md\:o5 {
    margin-left: 41.66667% ; }
  .md\:o6 {
    margin-left: 50% ; }
  .md\:o7 {
    margin-left: 58.33333% ; }
  .md\:o8 {
    margin-left: 66.66667% ; }
  .md\:o9 {
    margin-left: 75% ; }
  .md\:o10 {
    margin-left: 83.33333% ; }
  .md\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 1024px) {
  .md\+\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .md\+\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .md\+\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .md\+\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .md\+\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .md\+\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .md\+\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .md\+\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .md\+\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .md\+\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .md\+\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .md\+\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .md\+\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .md\+\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .md\+\:o0 {
    margin-left: 0% ; }
  .md\+\:o1 {
    margin-left: 8.33333% ; }
  .md\+\:o2 {
    margin-left: 16.66667% ; }
  .md\+\:o3 {
    margin-left: 25% ; }
  .md\+\:o4 {
    margin-left: 33.33333% ; }
  .md\+\:o5 {
    margin-left: 41.66667% ; }
  .md\+\:o6 {
    margin-left: 50% ; }
  .md\+\:o7 {
    margin-left: 58.33333% ; }
  .md\+\:o8 {
    margin-left: 66.66667% ; }
  .md\+\:o9 {
    margin-left: 75% ; }
  .md\+\:o10 {
    margin-left: 83.33333% ; }
  .md\+\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 1440px) {
  .lg\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .lg\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .lg\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .lg\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .lg\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .lg\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .lg\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .lg\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .lg\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .lg\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .lg\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .lg\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .lg\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .lg\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .lg\:o0 {
    margin-left: 0% ; }
  .lg\:o1 {
    margin-left: 8.33333% ; }
  .lg\:o2 {
    margin-left: 16.66667% ; }
  .lg\:o3 {
    margin-left: 25% ; }
  .lg\:o4 {
    margin-left: 33.33333% ; }
  .lg\:o5 {
    margin-left: 41.66667% ; }
  .lg\:o6 {
    margin-left: 50% ; }
  .lg\:o7 {
    margin-left: 58.33333% ; }
  .lg\:o8 {
    margin-left: 66.66667% ; }
  .lg\:o9 {
    margin-left: 75% ; }
  .lg\:o10 {
    margin-left: 83.33333% ; }
  .lg\:o11 {
    margin-left: 91.66667% ; } }

@media (min-width: 1800px) {
  .xl\:col {
    flex-basis: 0 ;
    flex-grow: 1 ;
    max-width: 100% ; }
  .xl\:c-auto {
    flex: 0 0 auto ;
    width: auto ;
    max-width: 100% ; }
  .xl\:c1 {
    flex: 0 0 8.33333% ;
    max-width: 8.33333% ; }
  .xl\:c2 {
    flex: 0 0 16.66667% ;
    max-width: 16.66667% ; }
  .xl\:c3 {
    flex: 0 0 25% ;
    max-width: 25% ; }
  .xl\:c4 {
    flex: 0 0 33.33333% ;
    max-width: 33.33333% ; }
  .xl\:c5 {
    flex: 0 0 41.66667% ;
    max-width: 41.66667% ; }
  .xl\:c6 {
    flex: 0 0 50% ;
    max-width: 50% ; }
  .xl\:c7 {
    flex: 0 0 58.33333% ;
    max-width: 58.33333% ; }
  .xl\:c8 {
    flex: 0 0 66.66667% ;
    max-width: 66.66667% ; }
  .xl\:c9 {
    flex: 0 0 75% ;
    max-width: 75% ; }
  .xl\:c10 {
    flex: 0 0 83.33333% ;
    max-width: 83.33333% ; }
  .xl\:c11 {
    flex: 0 0 91.66667% ;
    max-width: 91.66667% ; }
  .xl\:c12 {
    flex: 0 0 100% ;
    max-width: 100% ; }
  .xl\:o0 {
    margin-left: 0% ; }
  .xl\:o1 {
    margin-left: 8.33333% ; }
  .xl\:o2 {
    margin-left: 16.66667% ; }
  .xl\:o3 {
    margin-left: 25% ; }
  .xl\:o4 {
    margin-left: 33.33333% ; }
  .xl\:o5 {
    margin-left: 41.66667% ; }
  .xl\:o6 {
    margin-left: 50% ; }
  .xl\:o7 {
    margin-left: 58.33333% ; }
  .xl\:o8 {
    margin-left: 66.66667% ; }
  .xl\:o9 {
    margin-left: 75% ; }
  .xl\:o10 {
    margin-left: 83.33333% ; }
  .xl\:o11 {
    margin-left: 91.66667% ; } }

body .grid--debug {
  padding: 4px !important;
  border: 1px dashed #8A2C2C; }
  body .grid--debug > * {
    border: 1px dashed #00AE65; }

.debug * {
  background: rgba(249, 202, 36, 0.05);
  box-shadow: 0 0 0 1px #F9CA24; }
  .debug *::after, .debug *::before {
    background: rgba(255, 121, 121, 0.05);
    box-shadow: 0 0 0 1px #FF7979; }

body.develop::before {
  content: '';
  display: inline-block;
  padding: 2px 8px;
  font-size: 0.75rem;
  font-weight: 500;
  color: #FFFFFF;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  content: "xs";
  background: #f368e0; }
  @media (min-width: 410px) {
    body.develop::before {
      content: "sm";
      background: #f368e0; } }
  @media (min-width: 600px) {
    body.develop::before {
      content: "sm+";
      background: #f368e0; } }
  @media (min-width: 768px) {
    body.develop::before {
      content: "md";
      background: #f368e0; } }
  @media (min-width: 1024px) {
    body.develop::before {
      content: "md+";
      background: #f368e0; } }
  @media (min-width: 1440px) {
    body.develop::before {
      content: "lg";
      background: #f368e0; } }
  @media (min-width: 1800px) {
    body.develop::before {
      content: "xl";
      background: #f368e0; } }
  @media print {
    body.develop::before {
      display: none !important; } }

body.develop.iframe-container::before {
  background: #8A2C2C;
  bottom: 50px; }

.collapse {
  display: none; }
  .collapse.collapsible-show {
    display: block;
    animation: fadeIn 0.2s cubic-bezier(0, 0, 0.2, 1); }

.collapse-control--open .triangle-down {
  transform: rotate(180deg);
  position: relative;
  top: 4px; }

.banner-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative; }
  @media (min-width: 768px) {
    .banner-wrapper {
      display: block; } }

.banner.--image {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; }

.banner.--media {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 0;
  padding-bottom: 0;
  background: black; }
  @media (min-width: 1024px) {
    .banner.--media {
      min-height: 34.375rem; } }
  .banner.--media .banner-content {
    order: 2;
    width: 100%;
    padding-bottom: 1rem;
    background: black;
    background: linear-gradient(180deg, transparent, black 10rem, black);
    margin-top: -10rem;
    z-index: 2; }
    @media (min-width: 410px) {
      .banner.--media .banner-content {
        margin-top: -14rem; } }
    @media (min-width: 600px) {
      .banner.--media .banner-content {
        margin-top: -16rem; } }
    @media (min-width: 768px) {
      .banner.--media .banner-content {
        margin-top: -20rem; } }
    @media (min-width: 1024px) {
      .banner.--media .banner-content {
        margin-top: 0;
        margin-bottom: 2rem;
        padding-top: 0;
        padding-bottom: 0;
        background: none; } }
  .banner.--media .banner-media {
    order: 1;
    max-height: 50vh; }
    .banner.--media .banner-media img,
    .banner.--media .banner-media video {
      object-fit: cover;
      width: 100%;
      height: 100%; }
    @media (min-width: 1024px) {
      .banner.--media .banner-media {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        max-height: none; }
        .banner.--media .banner-media img,
        .banner.--media .banner-media video {
          padding-bottom: 0; } }

.btn {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1) , color 0.3s cubic-bezier(0.4, 0, 0.2, 1) , background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: 600;
  font-family: "gill-sans-nova", sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  white-space: nowrap;
  text-decoration: none !important;
  cursor: pointer; }
  .btn.disabled, .btn:disabled {
    box-shadow: none;
    pointer-events: none;
    border: none;
    background-color: #CCCCCC;
    color: #A3A3A3; }
    .btn.disabled i, .btn:disabled i {
      opacity: 0.3; }
  .btn:hover, .btn:focus {
    transition: none 0.3s cubic-bezier(0.4, 0, 0.2, 1); }

.btn--default {
  color: #FFFFFF;
  background-color: #A18455; }
  .btn--default:hover, .btn--default:focus {
    color: #FFFFFF;
    background-color: #626363; }

.btn--secondary {
  color: #FFFFFF;
  background-color: #8A2C2C; }
  .btn--secondary:hover, .btn--secondary:focus {
    color: #FFFFFF;
    background-color: #626363; }

.btn--hollow, .btn--white-hollow {
  position: relative;
  background-color: transparent;
  line-height: 1;
  z-index: 1; }
  .btn--hollow:before, .btn--white-hollow:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform .3s;
    z-index: -1; }
  .btn--hollow:hover:before, .btn--white-hollow:hover:before {
    transform: scaleX(1); }

.btn--hollow {
  color: #658389;
  border: 1px solid #658389; }
  .btn--hollow:before {
    background: #658389; }
  .btn--hollow:hover {
    color: #FFFFFF; }

.btn--white-hollow {
  color: #FFFFFF;
  border: 1px solid #FFFFFF; }
  .btn--white-hollow:before {
    background: #FFFFFF; }
  .btn--white-hollow:hover {
    color: #000000; }

@media (min-width: 768px) {
  .session-list-item {
    width: 600px; } }

.tickets-list-item.tickets-list-item-sold-out {
  background-color: #FFFFFF;
  color: #FFFFFF;
  position: relative; }
  .tickets-list-item.tickets-list-item-sold-out:after {
    position: absolute;
    content: 'Sold out';
    color: #8A2C2C;
    top: 2px;
    right: 2px;
    font-size: 0.8rem;
    text-decoration: line-through; }
    @media (min-width: 600px) {
      .tickets-list-item.tickets-list-item-sold-out:after {
        right: 8px;
        font-size: 0.875rem; } }

.sold-out-animation:after {
  text-decoration: none !important;
  animation: pulse 1s infinite ease-in-out; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1); }
  50% {
    transform: scale3d(1.5, 1.5, 1.5); }
  to {
    transform: scale3d(1, 1, 1); } }

.ticket-price-arrow::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-color: transparent transparent #F7D4D9 transparent;
  border-style: solid;
  border-width: 0 13px 13px;
  bottom: -16px; }
  @media (min-width: 600px) {
    .ticket-price-arrow::after {
      border-width: 0 20px 20px;
      bottom: -22px; } }

.ticket-input-quantity {
  background-color: #FFFFFF !important;
  border: 2px solid #C9C9C9;
  outline: none;
  text-align: center;
  padding: 1rem 1.5rem !important;
  max-width: 120px; }
  .ticket-input-quantity.error {
    background-color: #F7D4D9 !important;
    border: 2px solid #8A2C2C; }

.ticket-input-quantity-error-msg {
  color: #8A2C2C; }

.ticket-input-tickets-price {
  padding-top: 1.6rem; }

.ticket-width-wrapper {
  width: 120px; }

.recommended-price-flag__triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9.5px 9.5px 0 9.5px;
  border-color: #e7e7e7 transparent transparent transparent; }

.event-page__sessions input {
  vertical-align: middle;
  accent-color: #1f1f1f; }

.event-page__sessions label {
  font-family: "Suisse", sans-serif;
  text-transform: none;
  letter-spacing: normal; }

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  flex-direction: column; }

.cyp-modal-inner {
  overflow-y: scroll; }

g:focus {
  outline: none; }

path:focus {
  outline: none; }

[data-choose-your-price-sessions] h5 {
  font-family: "eb-garamond", serif;
  color: currentColor; }

[data-choose-your-price-sessions] h4 {
  font-size: large;
  font-weight: bold;
  margin-bottom: 5px !important; }

[data-choose-your-price-sessions] time {
  font-family: "eb-garamond", serif;
  font-size: 18px;
  color: #000000; }

/* LEAFLET */
/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0; }

.leaflet-container {
  overflow: hidden; }

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none; }

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent; }

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast; }

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0; }

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block; }

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important; }

.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
  width: auto;
  padding: 0; }

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y; }

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom; }

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none; }

.leaflet-container {
  -webkit-tap-highlight-color: transparent; }

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4); }

.leaflet-tile {
  filter: inherit;
  visibility: hidden; }

.leaflet-tile-loaded {
  visibility: inherit; }

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800; }

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none; }

.leaflet-pane {
  z-index: 400; }

.leaflet-tile-pane {
  z-index: 200; }

.leaflet-overlay-pane {
  z-index: 400; }

.leaflet-shadow-pane {
  z-index: 500; }

.leaflet-marker-pane {
  z-index: 600; }

.leaflet-tooltip-pane {
  z-index: 650; }

.leaflet-popup-pane {
  z-index: 700; }

.leaflet-map-pane canvas {
  z-index: 100; }

.leaflet-map-pane svg {
  z-index: 200; }

.leaflet-vml-shape {
  width: 1px;
  height: 1px; }

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute; }

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none; }

.leaflet-top {
  top: 0; }

.leaflet-right {
  right: 0; }

.leaflet-bottom {
  bottom: 0; }

.leaflet-left {
  left: 0; }

.leaflet-control {
  float: left;
  clear: both; }

.leaflet-right .leaflet-control {
  float: right; }

.leaflet-top .leaflet-control {
  margin-top: 10px; }

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px; }

.leaflet-left .leaflet-control {
  margin-left: 10px; }

.leaflet-right .leaflet-control {
  margin-right: 10px; }

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1; }

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

svg.leaflet-zoom-animated {
  will-change: transform; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1); }

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none; }

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden; }

/* cursors */
.leaflet-interactive {
  cursor: pointer; }

.leaflet-grab {
  cursor: default; }

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair; }

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto; }

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing; }

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none; }

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline-offset: 1px; }

.leaflet-container a {
  color: #0078A8; }

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5); }

/* general typography */
.leaflet-container {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5; }

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px; }

.leaflet-bar a {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black; }

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block; }

.leaflet-bar a:hover,
.leaflet-bar a:focus {
  background-color: #f4f4f4; }

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none; }

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb; }

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px; }

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px; }

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px; }

.leaflet-control-layers-toggle {
  background-image: url(../../../images/leaflet/layers.png);
  width: 36px;
  height: 36px; }

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(../../../images/leaflet/layers-2x.png);
  background-size: 26px 26px; }

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px; }

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none; }

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative; }

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff; }

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px; }

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px; }

.leaflet-control-layers label {
  display: block;
  font-size: 13px;
  font-size: 1.08333em; }

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px; }

/* Default icon URLs */
.leaflet-default-icon-path {
  /* used only in path-guessing heuristic, see L.Icon.Default */
  background-image: url(../../../images/leaflet/marker-icon.png); }

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  margin: 0; }

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
  line-height: 1.4; }

.leaflet-control-attribution a {
  text-decoration: none; }

.leaflet-control-attribution a:hover,
.leaflet-control-attribution a:focus {
  text-decoration: underline; }

.leaflet-attribution-flag {
  display: inline !important;
  vertical-align: baseline !important;
  width: 1em;
  height: 0.6669em; }

.leaflet-left .leaflet-control-scale {
  margin-left: 5px; }

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px; }

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  white-space: nowrap;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px #fff; }

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px; }

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777; }

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none; }

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box; }

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px; }

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px; }

.leaflet-popup-content {
  margin: 13px 24px 13px 20px;
  line-height: 1.3;
  font-size: 13px;
  font-size: 1.08333em;
  min-height: 1px; }

.leaflet-popup-content p {
  margin: 17px 0;
  margin: 1.3em 0; }

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-top: -1px;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none; }

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  pointer-events: auto;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4); }

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  font: 16px/24px Tahoma, Verdana, sans-serif;
  color: #757575;
  text-decoration: none;
  background: transparent; }

.leaflet-container a.leaflet-popup-close-button:hover,
.leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858; }

.leaflet-popup-scrolled {
  overflow: auto; }

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1; }

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678); }

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999; }

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666; }

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); }

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto; }

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: ""; }

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px; }

.leaflet-tooltip-top {
  margin-top: -6px; }

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px; }

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff; }

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff; }

.leaflet-tooltip-left {
  margin-left: -6px; }

.leaflet-tooltip-right {
  margin-left: 6px; }

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px; }

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff; }

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff; }

/* Printing */
@media print {
  /* Prevent printers from removing background-images of controls. */
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact; } }

.event-tile {
  position: relative;
  flex: 1 1 auto;
  overflow: hidden; }
  .--compact-tiles .event-tile {
    background-color: #000000; }
  .event-tile .event-tile__media {
    position: relative;
    overflow: hidden; }
  .event-tile img,
  .event-tile video {
    width: 100%; }
  .event-tile .event-tile__video {
    left: 0; }
  .event-tile .event-tile__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 10; }
  .event-tile .event-tile__text {
    background: black;
    background: linear-gradient(0deg, #000, rgba(0, 0, 0, 0.9) 2%, rgba(0, 0, 0, 0.5) 50%, transparent);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 1rem;
    padding-bottom: 2rem;
    padding-top: 6rem;
    padding-right: 4rem;
    color: #FFFFFF; }
    .event-tile .event-tile__text a {
      text-decoration: none;
      color: #FFFFFF; }
  .event-tile .event-tile__date {
    order: 1; }
  .event-tile .event-tile__headline {
    order: 2;
    font-weight: 600;
    width: 100%; }
    @media (max-width: 767.98px) {
      .event-tile .event-tile__headline {
        font-size: 2.375rem; } }
  .event-tile .event-tile__region {
    order: 3; }
  .event-tile .event-tile__tagline {
    order: 1;
    opacity: 0.8;
    width: 100%;
    margin-bottom: 0.5rem; }
  .--compact-tiles .event-tile .event-tile__headline, .--compact-tiles
  .event-tile .event-tile__tagline {
    text-shadow: 0.0625rem 0rem 0.1875rem rgba(0, 0, 0, 0.4); }
  .event-tile .event-tile__blurb {
    order: 3; }
    .event-tile .event-tile__blurb p {
      font-weight: 400;
      line-height: 1.65;
      font-size: 1.25rem;
      margin-top: 0.5rem; }
  .event-tile .event-tile__shortlist {
    position: absolute;
    padding: 0.5rem;
    z-index: 20;
    right: 0.5rem;
    bottom: 0.75rem;
    color: #FFFFFF; }
    .event-tile .event-tile__shortlist:hover {
      color: #8A2C2C; }
    .--exploded-tiles .event-tile .event-tile__shortlist {
      padding-top: 0;
      top: 1rem;
      bottom: auto;
      color: #000000; }
  .event-tile:hover {
    cursor: pointer; }
    .event-tile:hover a {
      text-decoration: none; }
  .--compact-tiles .event-tile.--blurb .event-tile__text {
    display: flex;
    justify-content: flex-end;
    flex-direction: column; }
  .--compact-tiles .event-tile.--blurb .event-tile__blurb {
    flex: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: flex 0.2s cubic-bezier(0.4, 0, 1, 1) , opacity 0.2s cubic-bezier(0.4, 0, 1, 1); }
  .--compact-tiles .event-tile.--blurb:hover .event-tile__blurb {
    flex: 1;
    height: auto;
    opacity: 1;
    transition: flex 0.15s cubic-bezier(0, 0, 0.2, 1) , opacity 0.15s cubic-bezier(0, 0, 0.2, 1); }
  .event-tile.--reveal .event-tile__image {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1); }
  .event-tile.--reveal:hover .event-tile__image {
    opacity: 0;
    transition: opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
    transition-delay: 0.1s; }
  .event-tile.--zoom .event-tile__image {
    transition: transform 0.1s cubic-bezier(0.4, 0, 1, 1); }
  .event-tile.--zoom:hover .event-tile__image {
    transform: scale(1.1);
    transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1); }
  .event-tile.--loading {
    background-color: #E7E7E7;
    height: 43rem; }
  .--exploded-tiles .event-tile .event-tile__inner {
    position: static; }
  .--exploded-tiles .event-tile .event-tile__text {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 6rem;
    background: none;
    color: inherit; }
    .--exploded-tiles .event-tile .event-tile__text a {
      color: inherit; }

.header {
  background-color: #FFFFFF;
  z-index: 100;
  position: sticky;
  top: 0; }

#lsq-logo {
  width: 8.75rem; }
  @media (min-width: 768px) {
    #lsq-logo {
      width: 13.75rem; } }

#burger {
  height: 22px;
  padding: 0.4375rem 1.0625rem;
  margin-top: 2px;
  position: relative;
  transition: .5s ease-in-out;
  cursor: pointer;
  transform: rotate(0deg); }
  #burger span {
    display: block;
    position: absolute;
    width: 100%;
    opacity: 1;
    background: #626363;
    height: 0.25rem;
    display: block;
    opacity: 1;
    left: 0;
    transition: .25s ease-in-out;
    transform: rotate(0deg); }
    #burger span:nth-child(1) {
      top: 0; }
    #burger span:nth-child(2) {
      top: 0.5625rem; }
    #burger span:nth-child(3) {
      top: 0.5625rem; }
    #burger span:nth-child(4) {
      top: 1.125rem; }

#mobile-menu {
  transform: translateY(-100%);
  z-index: 99; }

.mobile-menu--visible .header {
  background-color: #000000; }

.mobile-menu--visible #mobile-menu {
  transform: translateY(0); }

.mobile-menu--visible .mobile-nav-wrapper {
  position: absolute;
  top: 77px;
  height: calc(100vh - 77px);
  left: 0;
  right: 0;
  bottom: 0; }

.mobile-menu--visible #burger span {
  background-color: #FFFFFF; }

.mobile-menu--visible #burger span:nth-child(1) {
  top: 16px;
  width: 0;
  left: 50%; }

.mobile-menu--visible #burger span:nth-child(2) {
  transform: rotate(45deg); }

.mobile-menu--visible #burger span:nth-child(3) {
  transform: rotate(-45deg); }

.mobile-menu--visible #burger span:nth-child(4) {
  top: 16px;
  width: 0;
  left: 50%; }

.overlay-open {
  overflow: hidden;
  height: 100%; }

.nav-item {
  position: relative;
  text-decoration: none; }
  .nav-item:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #000000;
    width: 0; }
  .nav-item.active {
    font-weight: 600; }
  .nav-item:hover, .nav-item:focus, .nav-item.active {
    text-decoration: none; }
    .nav-item:hover:after, .nav-item:focus:after, .nav-item.active:after {
      width: 100%;
      transition: width .3s; }
  .nav-item:focus {
    outline: none;
    color: #A18455; }

.sub-nav {
  position: absolute;
  background-color: #FFFFFF;
  box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.2);
  width: 17.5rem;
  top: 150%; }
  .sub-nav:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 1.875rem;
    transform: translate(-50%, -100%);
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #FFFFFF;
    z-index: 1; }

.shortlist-count {
  position: absolute;
  border-radius: 50%;
  font-weight: 400;
  color: #FFFFFF;
  background-color: #8A2C2C;
  display: inline-block;
  text-align: center;
  font-size: 0.8125rem;
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.25rem;
  top: 0rem;
  right: -0.375rem; }
  .shortlist-count.--update {
    animation: pulse 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2s; }

.intro {
  border-right: 2px solid #A18455;
  padding-right: 1rem; }
  @media (min-width: 768px) {
    .intro {
      padding-right: 8rem; } }

@keyframes spin {
  to {
    transform: rotate(360deg); } }

.loader {
  text-align: center; }
  .loader::before {
    content: '';
    display: inline-block;
    border: 3px solid rgba(138, 138, 138, 0.2);
    border-top-color: #8A8A8A;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    animation: spin 1s linear infinite; }

.theme-dark .loader::before {
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-top-color: #FFFFFF; }

.loading-pulse {
  animation: opacity-pulse 2s linear infinite; }

.spinner {
  width: 4rem;
  height: 4rem;
  border: 0.5rem solid;
  position: relative;
  color: #D0D0D1;
  display: inline-block;
  border-radius: 50%;
  border-right-color: #CADFC0;
  border-right-color: var(--theme-color);
  animation: rotate 1s linear infinite; }
  @media (min-width: 768px) {
    .spinner {
      height: 9.375rem;
      width: 9.375rem;
      border-width: 0.9375rem; } }
  @media (min-width: 1440px) {
    .spinner {
      height: 12.5rem;
      width: 12.5rem;
      border-width: 1.25rem; } }

@keyframes rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

/**************************\
  Basic Modal Styles
\**************************/
.modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 400;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity; }

.modal__container {
  display: flex;
  border-radius: 6px;
  background: #FFFFFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  transform: translateY(10%);
  max-width: 770px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform; }
  .modal__container.--small {
    max-width: 410px; }
  @media (min-width: 1024px) {
    .modal__container.--large {
      max-width: 56.25rem; } }
  @media (min-width: 1440px) {
    .modal__container.--large {
      max-width: 75rem; } }

.modal__container--sm {
  max-width: 440px; }

.modal__inner {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 5rem;
  margin-top: 0.25rem;
  padding-bottom: 1rem; }

.modal__footer {
  bottom: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: 1px;
  border-top-width: 1px;
  width: 100%;
  background-color: #FFFFFF;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 768px) {
    .modal__footer {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  .modal__footer::before {
    content: '';
    display: block;
    height: 20px;
    position: absolute;
    top: -20px;
    width: 100%;
    left: 0;
    background-repeat: no-repeat;
    background-position: 0 -1px;
    background-size: 95% 100%;
    background-image: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }

@media (max-width: 767.98px) {
  .modal--mobile-full-screen .modal__container {
    max-height: none;
    max-width: none;
    height: 100%;
    border-radius: 0; }
  .modal--mobile-full-screen .modal__inner {
    margin-bottom: 0;
    margin-top: 4rem;
    padding-bottom: 1rem;
    padding-top: 0; }
  .modal--mobile-full-screen .modal__footer {
    top: 0;
    bottom: auto;
    border-bottom: 1px solid #AFB0B0;
    border-top: 0;
    border-radius: 0; }
    .modal--mobile-full-screen .modal__footer::before {
      display: none; } }

.modal[aria-hidden='false'] .modal__overlay {
  opacity: 1; }

.modal[aria-hidden='false'] .modal__container {
  animation: slideInUp 0.3s cubic-bezier(0, 0, 0.2, 1); }

.modal[aria-hidden='true'] .modal__overlay {
  opacity: 0; }

.modal[aria-hidden='true'] .modal__container {
  animation: slideOutDown 0.3s cubic-bezier(0.4, 0, 1, 1); }

.modal--close {
  position: absolute;
  cursor: pointer;
  right: 34px;
  top: 34px; }

html.modal-open,
html.modal-open body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch; }

.overlay-open {
  overflow: hidden;
  height: 100%; }

.modal__container > div {
  min-height: 300px !important;
  padding: 1rem 0rem !important; }
  .modal__container > div button, .modal__container > div h3 {
    margin-top: 0 !important; }
  .modal__container > div p {
    margin-top: 1rem !important; }

/* --
@ Reoako Scss
- variables
- utils
- resets
- Dialog Container
- Dialog container tail
- Macro Layout
- Content Layout
- Actions Layout
- trigger
- overrides
*/
/* -- variables --------------- */
/* -- utils --------------- */
.reoako-dialog__translation-audio button:focus, .reoako-dialog__actions button:focus, .reoako-dialog__actions a:focus {
  box-shadow: 0 0 0 1px #146DFF;
  outline: none; }

/* -- resets --------------- */
.reoako-dialog,
.reoako-dialog * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: left; }

.reoako-dialog button {
  background: none;
  border: none; }

.reoako-dialog svg {
  width: 100%;
  height: 100%;
  pointer-events: none; }

/* -- Dialog Container ------ */
.reoako-dialog {
  visibility: hidden;
  position: absolute;
  min-width: 200px;
  max-width: 280px;
  width: max-content;
  background: #FFFFFF;
  color: #000000;
  border-radius: 10px;
  padding: 11px 11px 11px 18px;
  box-shadow: 0 5px 24px -6px rgba(0, 0, 0, 0.35);
  opacity: 0;
  transform: scale(0.3);
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.4s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform, opacity;
  transform-origin: center bottom;
  pointer-events: none; }
  @media only screen and (min-width: 700px) {
    .reoako-dialog {
      max-width: 400px; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .reoako-dialog {
      width: 300px; } }

.reoako-dialog.reoako-dialog--open {
  visibility: visible;
  transform: scale(1);
  pointer-events: auto;
  opacity: 1;
  z-index: 1111; }

.reoako-dialog.reoako-dialog--closing {
  transition: transform 0.1s cubic-bezier(0.4, 0, 1, 1), opacity 0.2s cubic-bezier(0.4, 0, 1, 1);
  visibility: visible;
  transform: scale(0.3);
  opacity: 0; }

/* -- Dialog container tail --- */
.reoako-dialog__tail {
  display: block;
  position: absolute;
  width: 17px;
  height: 8px;
  font-size: 0;
  pointer-events: none;
  background: no-repeat 0 0 url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='8'%3E%3Cpath fill='%23FFF' d='M9.87 6.71a2 2 0 01-2.74 0L0 0h17L9.87 6.71z' /%3E%3C/svg%3E"); }

.reoako-dialog--above .reoako-dialog__tail {
  bottom: 0;
  transform: translate(-50%, 100%); }

.reoako-dialog--below .reoako-dialog__tail {
  top: 0;
  transform: translate(-50%, -100%) rotate(180deg); }

/* -- Macro Layout --- */
.reoako-dialog__inner {
  display: flex; }

.reoako-dialog__content {
  flex-grow: 1;
  padding-right: 16px; }

.reoako-dialog__actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

/* -- Content Layout --- */
.reoako-dialog__translation {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 1px; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .reoako-dialog__translation {
      width: 200px; } }

.reoako-dialog__translation-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;
  margin-right: 8px;
  min-height: 29px;
  padding-top: 4px; }

.reoako-dialog__function {
  line-height: 1;
  color: #767676;
  flex-basis: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px; }

.reoako-dialog__translation-audio {
  line-height: 1;
  font-size: 0; }
  .reoako-dialog__translation-audio button {
    width: 29px;
    height: 29px;
    border-radius: 6px; }
    .reoako-dialog__translation-audio button:hover svg path {
      fill: #146DFF; }

.reoako-dialog__entry {
  margin-top: 28px;
  padding-bottom: 3px; }

.reoako-dialog__headword {
  display: block;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.2;
  letter-spacing: -0.01em;
  color: #000000; }

/* -- Actions Layout --- */
.reoako-dialog__actions > span {
  line-height: 1;
  font-size: 0; }

.reoako-dialog__actions button, .reoako-dialog__actions a {
  width: 31px;
  height: 31px;
  display: inline-block;
  border-radius: 6px; }

.reoako-dialog__dismiss:hover svg path {
  fill: #146DFF; }

.reoako-dialog__reoako_link:hover svg path {
  fill: #BB000F; }

/* -- trigger --------------- */
.reoako-trigger {
  position: relative;
  display: inline-block;
  border-radius: 9999px;
  cursor: pointer;
  line-height: 1;
  color: inherit;
  border: 0.085em solid currentColor;
  padding: 0.05em 0.5em 0.15em;
  white-space: nowrap;
  transition: color 0.3s cubic-bezier(0, 0, 0.2, 1), border-color 0.3s cubic-bezier(0, 0, 0.2, 1), background-color 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .reoako-trigger .reoako-dialog {
    white-space: normal; }
  .reoako-trigger.is-active, .reoako-trigger:hover {
    color: #146DFF;
    border: 0.085em solid #146DFF; }
  .reoako-trigger:focus, .reoako-trigger:active {
    outline: none;
    background-color: #146DFF;
    color: #FFFFFF;
    border: 0.085em solid #146DFF; }

/* -- overrides --------------- */
@media (prefers-reduced-motion) {
  .reoako-dialog {
    transition: none !important; } }

.theme--dark .reoako-trigger {
  box-shadow: inset 0 0 0 0.1em #e5e5e5;
  color: #e5e5e5; }
  .theme--dark .reoako-trigger:hover {
    background: #3A3A3A; }
  .theme--dark .reoako-trigger:focus, .theme--dark .reoako-trigger:active {
    background: #e5e5e5;
    color: #3A3A3A; }

.loading-spinner {
  color: #D0D0D1;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }
  .loading-spinner div {
    transform-origin: 40px 40px;
    animation: spinner 1.2s linear infinite; }
    .loading-spinner div:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: #D0D0D1; }
    .loading-spinner div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s; }
    .loading-spinner div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s; }
    .loading-spinner div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s; }
    .loading-spinner div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s; }
    .loading-spinner div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s; }
    .loading-spinner div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s; }
    .loading-spinner div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s; }
    .loading-spinner div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s; }
    .loading-spinner div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s; }
    .loading-spinner div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s; }
    .loading-spinner div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s; }
    .loading-spinner div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s; }

@keyframes spinner {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.sponsor-image-wrapper {
  flex: 1 1 8.75rem;
  max-width: 8.75rem; }

.tag {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1rem;
  font-size: 0.9375rem;
  font-weight: 600;
  font-family: "gill-sans-nova", sans-serif;
  color: #FFFFFF;
  background-color: #A18455; }

/* * * * * * * * * * * * * * * * * * * * * * * *
* Border Class output
*   set default border rules for all elements
*   this allows us to specify only e.g `.border` or `.border-top` to an element
*   and sensible defaults are applied
* * * * * * * * * * * * * * * * * * * * * * */
* {
  border-width: 0;
  border-style: solid;
  border-color: #C9C9C9; }

.border-solid {
  border-style: solid !important; }

.border-dashed {
  border-style: dashed !important; }

.border-dotted {
  border-style: dotted !important; }

.border-none, .tickets-list-item:last-of-type {
  border-style: none !important;
  border-width: 0 !important; }

.border {
  border-width: 1px !important; }

.border-x {
  border-left-width: 1px !important;
  border-right-width: 1px !important; }

.border-y {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important; }

.border-top {
  border-top-width: 1px !important; }

.border-right {
  border-right-width: 1px !important; }

.border-bottom {
  border-bottom-width: 1px !important; }

.border-left {
  border-left-width: 1px !important; }

.border2 {
  border-width: 2px !important; }

.border2-x {
  border-left-width: 2px !important;
  border-right-width: 2px !important; }

.border2-y {
  border-top-width: 2px !important;
  border-bottom-width: 2px !important; }

.border2-top {
  border-top-width: 2px !important; }

.border2-right, .tickets-list-item {
  border-right-width: 2px !important; }

.border2-bottom {
  border-bottom-width: 2px !important; }

.border2-left {
  border-left-width: 2px !important; }

@media (min-width: 410px) {
  .sm\:border-solid {
    border-style: solid !important; }
  .sm\:border-dashed {
    border-style: dashed !important; }
  .sm\:border-dotted {
    border-style: dotted !important; }
  .sm\:border-none {
    border-style: none !important;
    border-width: 0 !important; }
  .sm\:border {
    border-width: 1px !important; }
  .sm\:border-x {
    border-left-width: 1px !important;
    border-right-width: 1px !important; }
  .sm\:border-y {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important; }
  .sm\:border-top {
    border-top-width: 1px !important; }
  .sm\:border-right {
    border-right-width: 1px !important; }
  .sm\:border-bottom {
    border-bottom-width: 1px !important; }
  .sm\:border-left {
    border-left-width: 1px !important; }
  .sm\:border2 {
    border-width: 2px !important; }
  .sm\:border2-x {
    border-left-width: 2px !important;
    border-right-width: 2px !important; }
  .sm\:border2-y {
    border-top-width: 2px !important;
    border-bottom-width: 2px !important; }
  .sm\:border2-top {
    border-top-width: 2px !important; }
  .sm\:border2-right {
    border-right-width: 2px !important; }
  .sm\:border2-bottom {
    border-bottom-width: 2px !important; }
  .sm\:border2-left {
    border-left-width: 2px !important; } }

@media (min-width: 600px) {
  .sm\+\:border-solid {
    border-style: solid !important; }
  .sm\+\:border-dashed {
    border-style: dashed !important; }
  .sm\+\:border-dotted {
    border-style: dotted !important; }
  .sm\+\:border-none {
    border-style: none !important;
    border-width: 0 !important; }
  .sm\+\:border {
    border-width: 1px !important; }
  .sm\+\:border-x {
    border-left-width: 1px !important;
    border-right-width: 1px !important; }
  .sm\+\:border-y {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important; }
  .sm\+\:border-top {
    border-top-width: 1px !important; }
  .sm\+\:border-right {
    border-right-width: 1px !important; }
  .sm\+\:border-bottom {
    border-bottom-width: 1px !important; }
  .sm\+\:border-left {
    border-left-width: 1px !important; }
  .sm\+\:border2 {
    border-width: 2px !important; }
  .sm\+\:border2-x {
    border-left-width: 2px !important;
    border-right-width: 2px !important; }
  .sm\+\:border2-y {
    border-top-width: 2px !important;
    border-bottom-width: 2px !important; }
  .sm\+\:border2-top {
    border-top-width: 2px !important; }
  .sm\+\:border2-right {
    border-right-width: 2px !important; }
  .sm\+\:border2-bottom {
    border-bottom-width: 2px !important; }
  .sm\+\:border2-left {
    border-left-width: 2px !important; } }

@media (min-width: 768px) {
  .md\:border-solid {
    border-style: solid !important; }
  .md\:border-dashed {
    border-style: dashed !important; }
  .md\:border-dotted {
    border-style: dotted !important; }
  .md\:border-none {
    border-style: none !important;
    border-width: 0 !important; }
  .md\:border {
    border-width: 1px !important; }
  .md\:border-x {
    border-left-width: 1px !important;
    border-right-width: 1px !important; }
  .md\:border-y {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important; }
  .md\:border-top {
    border-top-width: 1px !important; }
  .md\:border-right {
    border-right-width: 1px !important; }
  .md\:border-bottom {
    border-bottom-width: 1px !important; }
  .md\:border-left {
    border-left-width: 1px !important; }
  .md\:border2 {
    border-width: 2px !important; }
  .md\:border2-x {
    border-left-width: 2px !important;
    border-right-width: 2px !important; }
  .md\:border2-y {
    border-top-width: 2px !important;
    border-bottom-width: 2px !important; }
  .md\:border2-top {
    border-top-width: 2px !important; }
  .md\:border2-right {
    border-right-width: 2px !important; }
  .md\:border2-bottom {
    border-bottom-width: 2px !important; }
  .md\:border2-left {
    border-left-width: 2px !important; } }

@media (min-width: 1024px) {
  .md\+\:border-solid {
    border-style: solid !important; }
  .md\+\:border-dashed {
    border-style: dashed !important; }
  .md\+\:border-dotted {
    border-style: dotted !important; }
  .md\+\:border-none {
    border-style: none !important;
    border-width: 0 !important; }
  .md\+\:border {
    border-width: 1px !important; }
  .md\+\:border-x {
    border-left-width: 1px !important;
    border-right-width: 1px !important; }
  .md\+\:border-y {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important; }
  .md\+\:border-top {
    border-top-width: 1px !important; }
  .md\+\:border-right {
    border-right-width: 1px !important; }
  .md\+\:border-bottom {
    border-bottom-width: 1px !important; }
  .md\+\:border-left {
    border-left-width: 1px !important; }
  .md\+\:border2 {
    border-width: 2px !important; }
  .md\+\:border2-x {
    border-left-width: 2px !important;
    border-right-width: 2px !important; }
  .md\+\:border2-y {
    border-top-width: 2px !important;
    border-bottom-width: 2px !important; }
  .md\+\:border2-top {
    border-top-width: 2px !important; }
  .md\+\:border2-right {
    border-right-width: 2px !important; }
  .md\+\:border2-bottom {
    border-bottom-width: 2px !important; }
  .md\+\:border2-left {
    border-left-width: 2px !important; } }

@media (min-width: 1440px) {
  .lg\:border-solid {
    border-style: solid !important; }
  .lg\:border-dashed {
    border-style: dashed !important; }
  .lg\:border-dotted {
    border-style: dotted !important; }
  .lg\:border-none {
    border-style: none !important;
    border-width: 0 !important; }
  .lg\:border {
    border-width: 1px !important; }
  .lg\:border-x {
    border-left-width: 1px !important;
    border-right-width: 1px !important; }
  .lg\:border-y {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important; }
  .lg\:border-top {
    border-top-width: 1px !important; }
  .lg\:border-right {
    border-right-width: 1px !important; }
  .lg\:border-bottom {
    border-bottom-width: 1px !important; }
  .lg\:border-left {
    border-left-width: 1px !important; }
  .lg\:border2 {
    border-width: 2px !important; }
  .lg\:border2-x {
    border-left-width: 2px !important;
    border-right-width: 2px !important; }
  .lg\:border2-y {
    border-top-width: 2px !important;
    border-bottom-width: 2px !important; }
  .lg\:border2-top {
    border-top-width: 2px !important; }
  .lg\:border2-right {
    border-right-width: 2px !important; }
  .lg\:border2-bottom {
    border-bottom-width: 2px !important; }
  .lg\:border2-left {
    border-left-width: 2px !important; } }

@media (min-width: 1800px) {
  .xl\:border-solid {
    border-style: solid !important; }
  .xl\:border-dashed {
    border-style: dashed !important; }
  .xl\:border-dotted {
    border-style: dotted !important; }
  .xl\:border-none {
    border-style: none !important;
    border-width: 0 !important; }
  .xl\:border {
    border-width: 1px !important; }
  .xl\:border-x {
    border-left-width: 1px !important;
    border-right-width: 1px !important; }
  .xl\:border-y {
    border-top-width: 1px !important;
    border-bottom-width: 1px !important; }
  .xl\:border-top {
    border-top-width: 1px !important; }
  .xl\:border-right {
    border-right-width: 1px !important; }
  .xl\:border-bottom {
    border-bottom-width: 1px !important; }
  .xl\:border-left {
    border-left-width: 1px !important; }
  .xl\:border2 {
    border-width: 2px !important; }
  .xl\:border2-x {
    border-left-width: 2px !important;
    border-right-width: 2px !important; }
  .xl\:border2-y {
    border-top-width: 2px !important;
    border-bottom-width: 2px !important; }
  .xl\:border2-top {
    border-top-width: 2px !important; }
  .xl\:border2-right {
    border-right-width: 2px !important; }
  .xl\:border2-bottom {
    border-bottom-width: 2px !important; }
  .xl\:border2-left {
    border-left-width: 2px !important; } }

/* * * * * * * * * * * * * * * * * * * * * * * *
* All other util class outputs
* * * * * * * * * * * * * * * * * * * * * * */
.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-none {
  border-radius: 0 !important !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-1 {
  border-radius: 4px !important; }

.rounded-top-1 {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.rounded-right-1 {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.rounded-bottom-1 {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.rounded-left-1 {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded {
  border-radius: 4px !important; }

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.rounded-bottom {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-2 {
  border-radius: 6px !important; }

.rounded-top-2 {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important; }

.rounded-right-2 {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important; }

.rounded-bottom-2 {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important; }

.rounded-left-2 {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important; }

.rounded-3 {
  border-radius: 10px !important; }

.rounded-top-3 {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important; }

.rounded-right-3 {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important; }

.rounded-bottom-3 {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important; }

.rounded-left-3 {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important; }

@media (min-width: 410px) {
  .sm\:rounded-circle {
    border-radius: 50% !important; }
  .sm\:rounded-pill {
    border-radius: 50rem !important; }
  .sm\:rounded-none {
    border-radius: 0 !important !important; }
  .sm\:rounded-0 {
    border-radius: 0 !important; }
  .sm\:rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .sm\:rounded-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .sm\:rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .sm\:rounded-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .sm\:rounded-1 {
    border-radius: 4px !important; }
  .sm\:rounded-top-1 {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .sm\:rounded-right-1 {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .sm\:rounded-bottom-1 {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .sm\:rounded-left-1 {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .sm\:rounded {
    border-radius: 4px !important; }
  .sm\:rounded-top {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .sm\:rounded-right {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .sm\:rounded-bottom {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .sm\:rounded-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .sm\:rounded-2 {
    border-radius: 6px !important; }
  .sm\:rounded-top-2 {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important; }
  .sm\:rounded-right-2 {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .sm\:rounded-bottom-2 {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .sm\:rounded-left-2 {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important; }
  .sm\:rounded-3 {
    border-radius: 10px !important; }
  .sm\:rounded-top-3 {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; }
  .sm\:rounded-right-3 {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .sm\:rounded-bottom-3 {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .sm\:rounded-left-3 {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important; } }

@media (min-width: 600px) {
  .sm\+\:rounded-circle {
    border-radius: 50% !important; }
  .sm\+\:rounded-pill {
    border-radius: 50rem !important; }
  .sm\+\:rounded-none {
    border-radius: 0 !important !important; }
  .sm\+\:rounded-0 {
    border-radius: 0 !important; }
  .sm\+\:rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .sm\+\:rounded-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .sm\+\:rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .sm\+\:rounded-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .sm\+\:rounded-1 {
    border-radius: 4px !important; }
  .sm\+\:rounded-top-1 {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .sm\+\:rounded-right-1 {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .sm\+\:rounded-bottom-1 {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .sm\+\:rounded-left-1 {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .sm\+\:rounded {
    border-radius: 4px !important; }
  .sm\+\:rounded-top {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .sm\+\:rounded-right {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .sm\+\:rounded-bottom {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .sm\+\:rounded-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .sm\+\:rounded-2 {
    border-radius: 6px !important; }
  .sm\+\:rounded-top-2 {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important; }
  .sm\+\:rounded-right-2 {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .sm\+\:rounded-bottom-2 {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .sm\+\:rounded-left-2 {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important; }
  .sm\+\:rounded-3 {
    border-radius: 10px !important; }
  .sm\+\:rounded-top-3 {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; }
  .sm\+\:rounded-right-3 {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .sm\+\:rounded-bottom-3 {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .sm\+\:rounded-left-3 {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important; } }

@media (min-width: 768px) {
  .md\:rounded-circle {
    border-radius: 50% !important; }
  .md\:rounded-pill {
    border-radius: 50rem !important; }
  .md\:rounded-none {
    border-radius: 0 !important !important; }
  .md\:rounded-0 {
    border-radius: 0 !important; }
  .md\:rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .md\:rounded-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .md\:rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .md\:rounded-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .md\:rounded-1 {
    border-radius: 4px !important; }
  .md\:rounded-top-1 {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .md\:rounded-right-1 {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .md\:rounded-bottom-1 {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .md\:rounded-left-1 {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .md\:rounded {
    border-radius: 4px !important; }
  .md\:rounded-top {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .md\:rounded-right {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .md\:rounded-bottom {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .md\:rounded-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .md\:rounded-2 {
    border-radius: 6px !important; }
  .md\:rounded-top-2 {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important; }
  .md\:rounded-right-2 {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .md\:rounded-bottom-2 {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .md\:rounded-left-2 {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important; }
  .md\:rounded-3 {
    border-radius: 10px !important; }
  .md\:rounded-top-3 {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; }
  .md\:rounded-right-3 {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .md\:rounded-bottom-3 {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .md\:rounded-left-3 {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important; } }

@media (min-width: 1024px) {
  .md\+\:rounded-circle {
    border-radius: 50% !important; }
  .md\+\:rounded-pill {
    border-radius: 50rem !important; }
  .md\+\:rounded-none {
    border-radius: 0 !important !important; }
  .md\+\:rounded-0 {
    border-radius: 0 !important; }
  .md\+\:rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .md\+\:rounded-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .md\+\:rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .md\+\:rounded-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .md\+\:rounded-1 {
    border-radius: 4px !important; }
  .md\+\:rounded-top-1 {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .md\+\:rounded-right-1 {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .md\+\:rounded-bottom-1 {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .md\+\:rounded-left-1 {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .md\+\:rounded {
    border-radius: 4px !important; }
  .md\+\:rounded-top {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .md\+\:rounded-right {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .md\+\:rounded-bottom {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .md\+\:rounded-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .md\+\:rounded-2 {
    border-radius: 6px !important; }
  .md\+\:rounded-top-2 {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important; }
  .md\+\:rounded-right-2 {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .md\+\:rounded-bottom-2 {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .md\+\:rounded-left-2 {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important; }
  .md\+\:rounded-3 {
    border-radius: 10px !important; }
  .md\+\:rounded-top-3 {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; }
  .md\+\:rounded-right-3 {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .md\+\:rounded-bottom-3 {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .md\+\:rounded-left-3 {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important; } }

@media (min-width: 1440px) {
  .lg\:rounded-circle {
    border-radius: 50% !important; }
  .lg\:rounded-pill {
    border-radius: 50rem !important; }
  .lg\:rounded-none {
    border-radius: 0 !important !important; }
  .lg\:rounded-0 {
    border-radius: 0 !important; }
  .lg\:rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .lg\:rounded-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .lg\:rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .lg\:rounded-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .lg\:rounded-1 {
    border-radius: 4px !important; }
  .lg\:rounded-top-1 {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .lg\:rounded-right-1 {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .lg\:rounded-bottom-1 {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .lg\:rounded-left-1 {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .lg\:rounded {
    border-radius: 4px !important; }
  .lg\:rounded-top {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .lg\:rounded-right {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .lg\:rounded-bottom {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .lg\:rounded-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .lg\:rounded-2 {
    border-radius: 6px !important; }
  .lg\:rounded-top-2 {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important; }
  .lg\:rounded-right-2 {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .lg\:rounded-bottom-2 {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .lg\:rounded-left-2 {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important; }
  .lg\:rounded-3 {
    border-radius: 10px !important; }
  .lg\:rounded-top-3 {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; }
  .lg\:rounded-right-3 {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .lg\:rounded-bottom-3 {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .lg\:rounded-left-3 {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important; } }

@media (min-width: 1800px) {
  .xl\:rounded-circle {
    border-radius: 50% !important; }
  .xl\:rounded-pill {
    border-radius: 50rem !important; }
  .xl\:rounded-none {
    border-radius: 0 !important !important; }
  .xl\:rounded-0 {
    border-radius: 0 !important; }
  .xl\:rounded-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .xl\:rounded-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .xl\:rounded-bottom-0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }
  .xl\:rounded-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .xl\:rounded-1 {
    border-radius: 4px !important; }
  .xl\:rounded-top-1 {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .xl\:rounded-right-1 {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .xl\:rounded-bottom-1 {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .xl\:rounded-left-1 {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .xl\:rounded {
    border-radius: 4px !important; }
  .xl\:rounded-top {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important; }
  .xl\:rounded-right {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .xl\:rounded-bottom {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important; }
  .xl\:rounded-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .xl\:rounded-2 {
    border-radius: 6px !important; }
  .xl\:rounded-top-2 {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important; }
  .xl\:rounded-right-2 {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .xl\:rounded-bottom-2 {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .xl\:rounded-left-2 {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important; }
  .xl\:rounded-3 {
    border-radius: 10px !important; }
  .xl\:rounded-top-3 {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; }
  .xl\:rounded-right-3 {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .xl\:rounded-bottom-3 {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }
  .xl\:rounded-left-3 {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important; } }

.shadow-0 {
  box-shadow: none !important; }

.shadow-none {
  box-shadow: none !important; }

.shadow-1 {
  box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.2) !important; }

.shadow-2 {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.38) !important; }

.shadow-3 {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-4 {
  box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.2) !important; }

.shadow-form {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; }

.shadow-field-error {
  box-shadow: 0 0 2px 1px rgba(138, 44, 44, 0.5) !important; }

@media (min-width: 410px) {
  .sm\:shadow-0 {
    box-shadow: none !important; }
  .sm\:shadow-none {
    box-shadow: none !important; }
  .sm\:shadow-1 {
    box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.2) !important; }
  .sm\:shadow-2 {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.38) !important; }
  .sm\:shadow-3 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .sm\:shadow-4 {
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.2) !important; }
  .sm\:shadow-form {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; }
  .sm\:shadow-field-error {
    box-shadow: 0 0 2px 1px rgba(138, 44, 44, 0.5) !important; } }

@media (min-width: 600px) {
  .sm\+\:shadow-0 {
    box-shadow: none !important; }
  .sm\+\:shadow-none {
    box-shadow: none !important; }
  .sm\+\:shadow-1 {
    box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.2) !important; }
  .sm\+\:shadow-2 {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.38) !important; }
  .sm\+\:shadow-3 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .sm\+\:shadow-4 {
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.2) !important; }
  .sm\+\:shadow-form {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; }
  .sm\+\:shadow-field-error {
    box-shadow: 0 0 2px 1px rgba(138, 44, 44, 0.5) !important; } }

@media (min-width: 768px) {
  .md\:shadow-0 {
    box-shadow: none !important; }
  .md\:shadow-none {
    box-shadow: none !important; }
  .md\:shadow-1 {
    box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.2) !important; }
  .md\:shadow-2 {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.38) !important; }
  .md\:shadow-3 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .md\:shadow-4 {
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.2) !important; }
  .md\:shadow-form {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; }
  .md\:shadow-field-error {
    box-shadow: 0 0 2px 1px rgba(138, 44, 44, 0.5) !important; } }

@media (min-width: 1024px) {
  .md\+\:shadow-0 {
    box-shadow: none !important; }
  .md\+\:shadow-none {
    box-shadow: none !important; }
  .md\+\:shadow-1 {
    box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.2) !important; }
  .md\+\:shadow-2 {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.38) !important; }
  .md\+\:shadow-3 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .md\+\:shadow-4 {
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.2) !important; }
  .md\+\:shadow-form {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; }
  .md\+\:shadow-field-error {
    box-shadow: 0 0 2px 1px rgba(138, 44, 44, 0.5) !important; } }

@media (min-width: 1440px) {
  .lg\:shadow-0 {
    box-shadow: none !important; }
  .lg\:shadow-none {
    box-shadow: none !important; }
  .lg\:shadow-1 {
    box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.2) !important; }
  .lg\:shadow-2 {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.38) !important; }
  .lg\:shadow-3 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .lg\:shadow-4 {
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.2) !important; }
  .lg\:shadow-form {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; }
  .lg\:shadow-field-error {
    box-shadow: 0 0 2px 1px rgba(138, 44, 44, 0.5) !important; } }

@media (min-width: 1800px) {
  .xl\:shadow-0 {
    box-shadow: none !important; }
  .xl\:shadow-none {
    box-shadow: none !important; }
  .xl\:shadow-1 {
    box-shadow: 0 1.5px 5px 0 rgba(0, 0, 0, 0.2) !important; }
  .xl\:shadow-2 {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.38) !important; }
  .xl\:shadow-3 {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .xl\:shadow-4 {
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.2) !important; }
  .xl\:shadow-form {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; }
  .xl\:shadow-field-error {
    box-shadow: 0 0 2px 1px rgba(138, 44, 44, 0.5) !important; } }

.w-0 {
  width: 0 !important; }

.w-1 {
  width: 0.25rem !important; }

.w-2 {
  width: 0.5rem !important; }

.w-3 {
  width: 0.75rem !important; }

.w-4 {
  width: 1rem !important; }

.w-5 {
  width: 1.25rem !important; }

.w-6 {
  width: 1.5rem !important; }

.w-8 {
  width: 2rem !important; }

.w-10 {
  width: 2.5rem !important; }

.w-12 {
  width: 3rem !important; }

.w-16 {
  width: 4rem !important; }

.w-20 {
  width: 5rem !important; }

.w-24 {
  width: 6rem !important; }

.w-32 {
  width: 8rem !important; }

.w-40 {
  width: 10rem !important; }

.w-48 {
  width: 12rem !important; }

.w-56 {
  width: 14rem !important; }

.w-64 {
  width: 16rem !important; }

.w-85 {
  width: 21.25rem !important; }

.w-auto {
  width: auto !important; }

.w-1\/2 {
  width: 50% !important; }

.w-1\/3 {
  width: 33.33333% !important; }

.w-2\/3 {
  width: 66.66667% !important; }

.w-1\/4 {
  width: 25% !important; }

.w-2\/4 {
  width: 50% !important; }

.w-3\/4 {
  width: 75% !important; }

.w-1\/5 {
  width: 20% !important; }

.w-2\/5 {
  width: 40% !important; }

.w-3\/5 {
  width: 60% !important; }

.w-4\/5 {
  width: 80% !important; }

.w-1\/12 {
  width: 8.33333% !important; }

.w-2\/12 {
  width: 16.66667% !important; }

.w-3\/12 {
  width: 25% !important; }

.w-4\/12 {
  width: 33.33333% !important; }

.w-5\/12 {
  width: 41.66667% !important; }

.w-6\/12 {
  width: 50% !important; }

.w-7\/12 {
  width: 58.33333% !important; }

.w-8\/12 {
  width: 66.66667% !important; }

.w-9\/12 {
  width: 75% !important; }

.w-10\/12 {
  width: 83.33333% !important; }

.w-11\/12 {
  width: 91.66667% !important; }

.w-12\/12 {
  width: 100% !important; }

.w-full {
  width: 100% !important; }

.w-100 {
  width: 100% !important; }

.w-xs {
  width: 320px !important; }

.w-sm {
  width: 410px !important; }

.w-sm\+ {
  width: 600px !important; }

.w-md {
  width: 768px !important; }

.w-md\+ {
  width: 1024px !important; }

.w-lg {
  width: 1440px !important; }

.w-xl {
  width: 1800px !important; }

.w-vw {
  width: 100vw !important; }

.w-px {
  width: 1px !important; }

.h-0 {
  height: 0 !important; }

.h-1 {
  height: 0.25rem !important; }

.h-2 {
  height: 0.5rem !important; }

.h-3 {
  height: 0.75rem !important; }

.h-4 {
  height: 1rem !important; }

.h-5 {
  height: 1.25rem !important; }

.h-6 {
  height: 1.5rem !important; }

.h-8 {
  height: 2rem !important; }

.h-10 {
  height: 2.5rem !important; }

.h-12 {
  height: 3rem !important; }

.h-16 {
  height: 4rem !important; }

.h-20 {
  height: 5rem !important; }

.h-24 {
  height: 6rem !important; }

.h-32 {
  height: 8rem !important; }

.h-40 {
  height: 10rem !important; }

.h-48 {
  height: 12rem !important; }

.h-56 {
  height: 14rem !important; }

.h-64 {
  height: 16rem !important; }

.h-85 {
  height: 21.25rem !important; }

.h-auto {
  height: auto !important; }

.h-1\/2 {
  height: 50% !important; }

.h-1\/3 {
  height: 33.33333% !important; }

.h-2\/3 {
  height: 66.66667% !important; }

.h-1\/4 {
  height: 25% !important; }

.h-2\/4 {
  height: 50% !important; }

.h-3\/4 {
  height: 75% !important; }

.h-1\/5 {
  height: 20% !important; }

.h-2\/5 {
  height: 40% !important; }

.h-3\/5 {
  height: 60% !important; }

.h-4\/5 {
  height: 80% !important; }

.h-1\/12 {
  height: 8.33333% !important; }

.h-2\/12 {
  height: 16.66667% !important; }

.h-3\/12 {
  height: 25% !important; }

.h-4\/12 {
  height: 33.33333% !important; }

.h-5\/12 {
  height: 41.66667% !important; }

.h-6\/12 {
  height: 50% !important; }

.h-7\/12 {
  height: 58.33333% !important; }

.h-8\/12 {
  height: 66.66667% !important; }

.h-9\/12 {
  height: 75% !important; }

.h-10\/12 {
  height: 83.33333% !important; }

.h-11\/12 {
  height: 91.66667% !important; }

.h-12\/12 {
  height: 100% !important; }

.h-full {
  height: 100% !important; }

.h-100 {
  height: 100% !important; }

.h-vh {
  height: 100vh !important; }

.h-px {
  height: 1px !important; }

.min-w-xs {
  min-width: 320px !important; }

.min-w-sm {
  min-width: 410px !important; }

.min-w-sm\+ {
  min-width: 600px !important; }

.min-w-md {
  min-width: 768px !important; }

.min-w-md\+ {
  min-width: 1024px !important; }

.min-w-lg {
  min-width: 1440px !important; }

.min-w-xl {
  min-width: 1800px !important; }

.min-w-0 {
  min-width: 0 !important; }

.min-w-1 {
  min-width: 0.25rem !important; }

.min-w-2 {
  min-width: 0.5rem !important; }

.min-w-3 {
  min-width: 0.75rem !important; }

.min-w-4 {
  min-width: 1rem !important; }

.min-w-5 {
  min-width: 1.25rem !important; }

.min-w-6 {
  min-width: 1.5rem !important; }

.min-w-8 {
  min-width: 2rem !important; }

.min-w-10 {
  min-width: 2.5rem !important; }

.min-w-12 {
  min-width: 3rem !important; }

.min-w-16 {
  min-width: 4rem !important; }

.min-w-20 {
  min-width: 5rem !important; }

.min-w-24 {
  min-width: 6rem !important; }

.min-w-32 {
  min-width: 8rem !important; }

.min-w-40 {
  min-width: 10rem !important; }

.min-w-48 {
  min-width: 12rem !important; }

.min-w-56 {
  min-width: 14rem !important; }

.min-w-64 {
  min-width: 16rem !important; }

.min-w-85 {
  min-width: 21.25rem !important; }

.min-w-auto {
  min-width: auto !important; }

.min-w-full {
  min-width: 100% !important; }

.max-w-xs {
  max-width: 320px !important; }

.max-w-sm {
  max-width: 410px !important; }

.max-w-sm\+ {
  max-width: 600px !important; }

.max-w-md {
  max-width: 768px !important; }

.max-w-md\+ {
  max-width: 1024px !important; }

.max-w-lg {
  max-width: 1440px !important; }

.max-w-xl {
  max-width: 1800px !important; }

.max-w-1\/2 {
  max-width: 50% !important; }

.max-w-1\/3 {
  max-width: 33.33333% !important; }

.max-w-2\/3 {
  max-width: 66.66667% !important; }

.max-w-1\/4 {
  max-width: 25% !important; }

.max-w-2\/4 {
  max-width: 50% !important; }

.max-w-3\/4 {
  max-width: 75% !important; }

.max-w-1\/5 {
  max-width: 20% !important; }

.max-w-2\/5 {
  max-width: 40% !important; }

.max-w-3\/5 {
  max-width: 60% !important; }

.max-w-4\/5 {
  max-width: 80% !important; }

.max-w-1\/12 {
  max-width: 8.33333% !important; }

.max-w-2\/12 {
  max-width: 16.66667% !important; }

.max-w-3\/12 {
  max-width: 25% !important; }

.max-w-4\/12 {
  max-width: 33.33333% !important; }

.max-w-5\/12 {
  max-width: 41.66667% !important; }

.max-w-6\/12 {
  max-width: 50% !important; }

.max-w-7\/12 {
  max-width: 58.33333% !important; }

.max-w-8\/12 {
  max-width: 66.66667% !important; }

.max-w-9\/12 {
  max-width: 75% !important; }

.max-w-10\/12 {
  max-width: 83.33333% !important; }

.max-w-11\/12 {
  max-width: 91.66667% !important; }

.max-w-12\/12 {
  max-width: 100% !important; }

.max-w-full {
  max-width: 100% !important; }

.max-w-100 {
  max-width: 100% !important; }

.max-w-0 {
  max-width: 0 !important; }

.max-w-1 {
  max-width: 0.25rem !important; }

.max-w-2 {
  max-width: 0.5rem !important; }

.max-w-3 {
  max-width: 0.75rem !important; }

.max-w-4 {
  max-width: 1rem !important; }

.max-w-5 {
  max-width: 1.25rem !important; }

.max-w-6 {
  max-width: 1.5rem !important; }

.max-w-8 {
  max-width: 2rem !important; }

.max-w-10 {
  max-width: 2.5rem !important; }

.max-w-12 {
  max-width: 3rem !important; }

.max-w-16 {
  max-width: 4rem !important; }

.max-w-20 {
  max-width: 5rem !important; }

.max-w-24 {
  max-width: 6rem !important; }

.max-w-32 {
  max-width: 8rem !important; }

.max-w-40 {
  max-width: 10rem !important; }

.max-w-48 {
  max-width: 12rem !important; }

.max-w-56 {
  max-width: 14rem !important; }

.max-w-64 {
  max-width: 16rem !important; }

.max-w-85 {
  max-width: 21.25rem !important; }

.max-w-auto {
  max-width: auto !important; }

.min-h-xs {
  min-height: 320px !important; }

.min-h-sm {
  min-height: 410px !important; }

.min-h-sm\+ {
  min-height: 600px !important; }

.min-h-md {
  min-height: 768px !important; }

.min-h-md\+ {
  min-height: 1024px !important; }

.min-h-lg {
  min-height: 1440px !important; }

.min-h-xl {
  min-height: 1800px !important; }

.min-h-0 {
  min-height: 0 !important; }

.min-h-full {
  min-height: 100% !important; }

.min-h-screen {
  min-height: 100vh !important; }

@media (min-width: 410px) {
  .sm\:w-0 {
    width: 0 !important; }
  .sm\:w-1 {
    width: 0.25rem !important; }
  .sm\:w-2 {
    width: 0.5rem !important; }
  .sm\:w-3 {
    width: 0.75rem !important; }
  .sm\:w-4 {
    width: 1rem !important; }
  .sm\:w-5 {
    width: 1.25rem !important; }
  .sm\:w-6 {
    width: 1.5rem !important; }
  .sm\:w-8 {
    width: 2rem !important; }
  .sm\:w-10 {
    width: 2.5rem !important; }
  .sm\:w-12 {
    width: 3rem !important; }
  .sm\:w-16 {
    width: 4rem !important; }
  .sm\:w-20 {
    width: 5rem !important; }
  .sm\:w-24 {
    width: 6rem !important; }
  .sm\:w-32 {
    width: 8rem !important; }
  .sm\:w-40 {
    width: 10rem !important; }
  .sm\:w-48 {
    width: 12rem !important; }
  .sm\:w-56 {
    width: 14rem !important; }
  .sm\:w-64 {
    width: 16rem !important; }
  .sm\:w-85 {
    width: 21.25rem !important; }
  .sm\:w-auto {
    width: auto !important; }
  .sm\:w-1\/2 {
    width: 50% !important; }
  .sm\:w-1\/3 {
    width: 33.33333% !important; }
  .sm\:w-2\/3 {
    width: 66.66667% !important; }
  .sm\:w-1\/4 {
    width: 25% !important; }
  .sm\:w-2\/4 {
    width: 50% !important; }
  .sm\:w-3\/4 {
    width: 75% !important; }
  .sm\:w-1\/5 {
    width: 20% !important; }
  .sm\:w-2\/5 {
    width: 40% !important; }
  .sm\:w-3\/5 {
    width: 60% !important; }
  .sm\:w-4\/5 {
    width: 80% !important; }
  .sm\:w-1\/12 {
    width: 8.33333% !important; }
  .sm\:w-2\/12 {
    width: 16.66667% !important; }
  .sm\:w-3\/12 {
    width: 25% !important; }
  .sm\:w-4\/12 {
    width: 33.33333% !important; }
  .sm\:w-5\/12 {
    width: 41.66667% !important; }
  .sm\:w-6\/12 {
    width: 50% !important; }
  .sm\:w-7\/12 {
    width: 58.33333% !important; }
  .sm\:w-8\/12 {
    width: 66.66667% !important; }
  .sm\:w-9\/12 {
    width: 75% !important; }
  .sm\:w-10\/12 {
    width: 83.33333% !important; }
  .sm\:w-11\/12 {
    width: 91.66667% !important; }
  .sm\:w-12\/12 {
    width: 100% !important; }
  .sm\:w-full {
    width: 100% !important; }
  .sm\:w-100 {
    width: 100% !important; }
  .sm\:w-xs {
    width: 320px !important; }
  .sm\:w-sm {
    width: 410px !important; }
  .sm\:w-sm\+ {
    width: 600px !important; }
  .sm\:w-md {
    width: 768px !important; }
  .sm\:w-md\+ {
    width: 1024px !important; }
  .sm\:w-lg {
    width: 1440px !important; }
  .sm\:w-xl {
    width: 1800px !important; }
  .sm\:w-vw {
    width: 100vw !important; }
  .sm\:w-px {
    width: 1px !important; }
  .sm\:h-0 {
    height: 0 !important; }
  .sm\:h-1 {
    height: 0.25rem !important; }
  .sm\:h-2 {
    height: 0.5rem !important; }
  .sm\:h-3 {
    height: 0.75rem !important; }
  .sm\:h-4 {
    height: 1rem !important; }
  .sm\:h-5 {
    height: 1.25rem !important; }
  .sm\:h-6 {
    height: 1.5rem !important; }
  .sm\:h-8 {
    height: 2rem !important; }
  .sm\:h-10 {
    height: 2.5rem !important; }
  .sm\:h-12 {
    height: 3rem !important; }
  .sm\:h-16 {
    height: 4rem !important; }
  .sm\:h-20 {
    height: 5rem !important; }
  .sm\:h-24 {
    height: 6rem !important; }
  .sm\:h-32 {
    height: 8rem !important; }
  .sm\:h-40 {
    height: 10rem !important; }
  .sm\:h-48 {
    height: 12rem !important; }
  .sm\:h-56 {
    height: 14rem !important; }
  .sm\:h-64 {
    height: 16rem !important; }
  .sm\:h-85 {
    height: 21.25rem !important; }
  .sm\:h-auto {
    height: auto !important; }
  .sm\:h-1\/2 {
    height: 50% !important; }
  .sm\:h-1\/3 {
    height: 33.33333% !important; }
  .sm\:h-2\/3 {
    height: 66.66667% !important; }
  .sm\:h-1\/4 {
    height: 25% !important; }
  .sm\:h-2\/4 {
    height: 50% !important; }
  .sm\:h-3\/4 {
    height: 75% !important; }
  .sm\:h-1\/5 {
    height: 20% !important; }
  .sm\:h-2\/5 {
    height: 40% !important; }
  .sm\:h-3\/5 {
    height: 60% !important; }
  .sm\:h-4\/5 {
    height: 80% !important; }
  .sm\:h-1\/12 {
    height: 8.33333% !important; }
  .sm\:h-2\/12 {
    height: 16.66667% !important; }
  .sm\:h-3\/12 {
    height: 25% !important; }
  .sm\:h-4\/12 {
    height: 33.33333% !important; }
  .sm\:h-5\/12 {
    height: 41.66667% !important; }
  .sm\:h-6\/12 {
    height: 50% !important; }
  .sm\:h-7\/12 {
    height: 58.33333% !important; }
  .sm\:h-8\/12 {
    height: 66.66667% !important; }
  .sm\:h-9\/12 {
    height: 75% !important; }
  .sm\:h-10\/12 {
    height: 83.33333% !important; }
  .sm\:h-11\/12 {
    height: 91.66667% !important; }
  .sm\:h-12\/12 {
    height: 100% !important; }
  .sm\:h-full {
    height: 100% !important; }
  .sm\:h-100 {
    height: 100% !important; }
  .sm\:h-vh {
    height: 100vh !important; }
  .sm\:h-px {
    height: 1px !important; }
  .sm\:min-w-xs {
    min-width: 320px !important; }
  .sm\:min-w-sm {
    min-width: 410px !important; }
  .sm\:min-w-sm\+ {
    min-width: 600px !important; }
  .sm\:min-w-md {
    min-width: 768px !important; }
  .sm\:min-w-md\+ {
    min-width: 1024px !important; }
  .sm\:min-w-lg {
    min-width: 1440px !important; }
  .sm\:min-w-xl {
    min-width: 1800px !important; }
  .sm\:min-w-0 {
    min-width: 0 !important; }
  .sm\:min-w-1 {
    min-width: 0.25rem !important; }
  .sm\:min-w-2 {
    min-width: 0.5rem !important; }
  .sm\:min-w-3 {
    min-width: 0.75rem !important; }
  .sm\:min-w-4 {
    min-width: 1rem !important; }
  .sm\:min-w-5 {
    min-width: 1.25rem !important; }
  .sm\:min-w-6 {
    min-width: 1.5rem !important; }
  .sm\:min-w-8 {
    min-width: 2rem !important; }
  .sm\:min-w-10 {
    min-width: 2.5rem !important; }
  .sm\:min-w-12 {
    min-width: 3rem !important; }
  .sm\:min-w-16 {
    min-width: 4rem !important; }
  .sm\:min-w-20 {
    min-width: 5rem !important; }
  .sm\:min-w-24 {
    min-width: 6rem !important; }
  .sm\:min-w-32 {
    min-width: 8rem !important; }
  .sm\:min-w-40 {
    min-width: 10rem !important; }
  .sm\:min-w-48 {
    min-width: 12rem !important; }
  .sm\:min-w-56 {
    min-width: 14rem !important; }
  .sm\:min-w-64 {
    min-width: 16rem !important; }
  .sm\:min-w-85 {
    min-width: 21.25rem !important; }
  .sm\:min-w-auto {
    min-width: auto !important; }
  .sm\:min-w-full {
    min-width: 100% !important; }
  .sm\:max-w-xs {
    max-width: 320px !important; }
  .sm\:max-w-sm {
    max-width: 410px !important; }
  .sm\:max-w-sm\+ {
    max-width: 600px !important; }
  .sm\:max-w-md {
    max-width: 768px !important; }
  .sm\:max-w-md\+ {
    max-width: 1024px !important; }
  .sm\:max-w-lg {
    max-width: 1440px !important; }
  .sm\:max-w-xl {
    max-width: 1800px !important; }
  .sm\:max-w-1\/2 {
    max-width: 50% !important; }
  .sm\:max-w-1\/3 {
    max-width: 33.33333% !important; }
  .sm\:max-w-2\/3 {
    max-width: 66.66667% !important; }
  .sm\:max-w-1\/4 {
    max-width: 25% !important; }
  .sm\:max-w-2\/4 {
    max-width: 50% !important; }
  .sm\:max-w-3\/4 {
    max-width: 75% !important; }
  .sm\:max-w-1\/5 {
    max-width: 20% !important; }
  .sm\:max-w-2\/5 {
    max-width: 40% !important; }
  .sm\:max-w-3\/5 {
    max-width: 60% !important; }
  .sm\:max-w-4\/5 {
    max-width: 80% !important; }
  .sm\:max-w-1\/12 {
    max-width: 8.33333% !important; }
  .sm\:max-w-2\/12 {
    max-width: 16.66667% !important; }
  .sm\:max-w-3\/12 {
    max-width: 25% !important; }
  .sm\:max-w-4\/12 {
    max-width: 33.33333% !important; }
  .sm\:max-w-5\/12 {
    max-width: 41.66667% !important; }
  .sm\:max-w-6\/12 {
    max-width: 50% !important; }
  .sm\:max-w-7\/12 {
    max-width: 58.33333% !important; }
  .sm\:max-w-8\/12 {
    max-width: 66.66667% !important; }
  .sm\:max-w-9\/12 {
    max-width: 75% !important; }
  .sm\:max-w-10\/12 {
    max-width: 83.33333% !important; }
  .sm\:max-w-11\/12 {
    max-width: 91.66667% !important; }
  .sm\:max-w-12\/12 {
    max-width: 100% !important; }
  .sm\:max-w-full {
    max-width: 100% !important; }
  .sm\:max-w-100 {
    max-width: 100% !important; }
  .sm\:max-w-0 {
    max-width: 0 !important; }
  .sm\:max-w-1 {
    max-width: 0.25rem !important; }
  .sm\:max-w-2 {
    max-width: 0.5rem !important; }
  .sm\:max-w-3 {
    max-width: 0.75rem !important; }
  .sm\:max-w-4 {
    max-width: 1rem !important; }
  .sm\:max-w-5 {
    max-width: 1.25rem !important; }
  .sm\:max-w-6 {
    max-width: 1.5rem !important; }
  .sm\:max-w-8 {
    max-width: 2rem !important; }
  .sm\:max-w-10 {
    max-width: 2.5rem !important; }
  .sm\:max-w-12 {
    max-width: 3rem !important; }
  .sm\:max-w-16 {
    max-width: 4rem !important; }
  .sm\:max-w-20 {
    max-width: 5rem !important; }
  .sm\:max-w-24 {
    max-width: 6rem !important; }
  .sm\:max-w-32 {
    max-width: 8rem !important; }
  .sm\:max-w-40 {
    max-width: 10rem !important; }
  .sm\:max-w-48 {
    max-width: 12rem !important; }
  .sm\:max-w-56 {
    max-width: 14rem !important; }
  .sm\:max-w-64 {
    max-width: 16rem !important; }
  .sm\:max-w-85 {
    max-width: 21.25rem !important; }
  .sm\:max-w-auto {
    max-width: auto !important; }
  .sm\:min-h-xs {
    min-height: 320px !important; }
  .sm\:min-h-sm {
    min-height: 410px !important; }
  .sm\:min-h-sm\+ {
    min-height: 600px !important; }
  .sm\:min-h-md {
    min-height: 768px !important; }
  .sm\:min-h-md\+ {
    min-height: 1024px !important; }
  .sm\:min-h-lg {
    min-height: 1440px !important; }
  .sm\:min-h-xl {
    min-height: 1800px !important; }
  .sm\:min-h-0 {
    min-height: 0 !important; }
  .sm\:min-h-full {
    min-height: 100% !important; }
  .sm\:min-h-screen {
    min-height: 100vh !important; } }

@media (min-width: 600px) {
  .sm\+\:w-0 {
    width: 0 !important; }
  .sm\+\:w-1 {
    width: 0.25rem !important; }
  .sm\+\:w-2 {
    width: 0.5rem !important; }
  .sm\+\:w-3 {
    width: 0.75rem !important; }
  .sm\+\:w-4 {
    width: 1rem !important; }
  .sm\+\:w-5 {
    width: 1.25rem !important; }
  .sm\+\:w-6 {
    width: 1.5rem !important; }
  .sm\+\:w-8 {
    width: 2rem !important; }
  .sm\+\:w-10 {
    width: 2.5rem !important; }
  .sm\+\:w-12 {
    width: 3rem !important; }
  .sm\+\:w-16 {
    width: 4rem !important; }
  .sm\+\:w-20 {
    width: 5rem !important; }
  .sm\+\:w-24 {
    width: 6rem !important; }
  .sm\+\:w-32 {
    width: 8rem !important; }
  .sm\+\:w-40 {
    width: 10rem !important; }
  .sm\+\:w-48 {
    width: 12rem !important; }
  .sm\+\:w-56 {
    width: 14rem !important; }
  .sm\+\:w-64 {
    width: 16rem !important; }
  .sm\+\:w-85 {
    width: 21.25rem !important; }
  .sm\+\:w-auto {
    width: auto !important; }
  .sm\+\:w-1\/2 {
    width: 50% !important; }
  .sm\+\:w-1\/3 {
    width: 33.33333% !important; }
  .sm\+\:w-2\/3 {
    width: 66.66667% !important; }
  .sm\+\:w-1\/4 {
    width: 25% !important; }
  .sm\+\:w-2\/4 {
    width: 50% !important; }
  .sm\+\:w-3\/4 {
    width: 75% !important; }
  .sm\+\:w-1\/5 {
    width: 20% !important; }
  .sm\+\:w-2\/5 {
    width: 40% !important; }
  .sm\+\:w-3\/5 {
    width: 60% !important; }
  .sm\+\:w-4\/5 {
    width: 80% !important; }
  .sm\+\:w-1\/12 {
    width: 8.33333% !important; }
  .sm\+\:w-2\/12 {
    width: 16.66667% !important; }
  .sm\+\:w-3\/12 {
    width: 25% !important; }
  .sm\+\:w-4\/12 {
    width: 33.33333% !important; }
  .sm\+\:w-5\/12 {
    width: 41.66667% !important; }
  .sm\+\:w-6\/12 {
    width: 50% !important; }
  .sm\+\:w-7\/12 {
    width: 58.33333% !important; }
  .sm\+\:w-8\/12 {
    width: 66.66667% !important; }
  .sm\+\:w-9\/12 {
    width: 75% !important; }
  .sm\+\:w-10\/12 {
    width: 83.33333% !important; }
  .sm\+\:w-11\/12 {
    width: 91.66667% !important; }
  .sm\+\:w-12\/12 {
    width: 100% !important; }
  .sm\+\:w-full {
    width: 100% !important; }
  .sm\+\:w-100 {
    width: 100% !important; }
  .sm\+\:w-xs {
    width: 320px !important; }
  .sm\+\:w-sm {
    width: 410px !important; }
  .sm\+\:w-sm\+ {
    width: 600px !important; }
  .sm\+\:w-md {
    width: 768px !important; }
  .sm\+\:w-md\+ {
    width: 1024px !important; }
  .sm\+\:w-lg {
    width: 1440px !important; }
  .sm\+\:w-xl {
    width: 1800px !important; }
  .sm\+\:w-vw {
    width: 100vw !important; }
  .sm\+\:w-px {
    width: 1px !important; }
  .sm\+\:h-0 {
    height: 0 !important; }
  .sm\+\:h-1 {
    height: 0.25rem !important; }
  .sm\+\:h-2 {
    height: 0.5rem !important; }
  .sm\+\:h-3 {
    height: 0.75rem !important; }
  .sm\+\:h-4 {
    height: 1rem !important; }
  .sm\+\:h-5 {
    height: 1.25rem !important; }
  .sm\+\:h-6 {
    height: 1.5rem !important; }
  .sm\+\:h-8 {
    height: 2rem !important; }
  .sm\+\:h-10 {
    height: 2.5rem !important; }
  .sm\+\:h-12 {
    height: 3rem !important; }
  .sm\+\:h-16 {
    height: 4rem !important; }
  .sm\+\:h-20 {
    height: 5rem !important; }
  .sm\+\:h-24 {
    height: 6rem !important; }
  .sm\+\:h-32 {
    height: 8rem !important; }
  .sm\+\:h-40 {
    height: 10rem !important; }
  .sm\+\:h-48 {
    height: 12rem !important; }
  .sm\+\:h-56 {
    height: 14rem !important; }
  .sm\+\:h-64 {
    height: 16rem !important; }
  .sm\+\:h-85 {
    height: 21.25rem !important; }
  .sm\+\:h-auto {
    height: auto !important; }
  .sm\+\:h-1\/2 {
    height: 50% !important; }
  .sm\+\:h-1\/3 {
    height: 33.33333% !important; }
  .sm\+\:h-2\/3 {
    height: 66.66667% !important; }
  .sm\+\:h-1\/4 {
    height: 25% !important; }
  .sm\+\:h-2\/4 {
    height: 50% !important; }
  .sm\+\:h-3\/4 {
    height: 75% !important; }
  .sm\+\:h-1\/5 {
    height: 20% !important; }
  .sm\+\:h-2\/5 {
    height: 40% !important; }
  .sm\+\:h-3\/5 {
    height: 60% !important; }
  .sm\+\:h-4\/5 {
    height: 80% !important; }
  .sm\+\:h-1\/12 {
    height: 8.33333% !important; }
  .sm\+\:h-2\/12 {
    height: 16.66667% !important; }
  .sm\+\:h-3\/12 {
    height: 25% !important; }
  .sm\+\:h-4\/12 {
    height: 33.33333% !important; }
  .sm\+\:h-5\/12 {
    height: 41.66667% !important; }
  .sm\+\:h-6\/12 {
    height: 50% !important; }
  .sm\+\:h-7\/12 {
    height: 58.33333% !important; }
  .sm\+\:h-8\/12 {
    height: 66.66667% !important; }
  .sm\+\:h-9\/12 {
    height: 75% !important; }
  .sm\+\:h-10\/12 {
    height: 83.33333% !important; }
  .sm\+\:h-11\/12 {
    height: 91.66667% !important; }
  .sm\+\:h-12\/12 {
    height: 100% !important; }
  .sm\+\:h-full {
    height: 100% !important; }
  .sm\+\:h-100 {
    height: 100% !important; }
  .sm\+\:h-vh {
    height: 100vh !important; }
  .sm\+\:h-px {
    height: 1px !important; }
  .sm\+\:min-w-xs {
    min-width: 320px !important; }
  .sm\+\:min-w-sm {
    min-width: 410px !important; }
  .sm\+\:min-w-sm\+ {
    min-width: 600px !important; }
  .sm\+\:min-w-md {
    min-width: 768px !important; }
  .sm\+\:min-w-md\+ {
    min-width: 1024px !important; }
  .sm\+\:min-w-lg {
    min-width: 1440px !important; }
  .sm\+\:min-w-xl {
    min-width: 1800px !important; }
  .sm\+\:min-w-0 {
    min-width: 0 !important; }
  .sm\+\:min-w-1 {
    min-width: 0.25rem !important; }
  .sm\+\:min-w-2 {
    min-width: 0.5rem !important; }
  .sm\+\:min-w-3 {
    min-width: 0.75rem !important; }
  .sm\+\:min-w-4 {
    min-width: 1rem !important; }
  .sm\+\:min-w-5 {
    min-width: 1.25rem !important; }
  .sm\+\:min-w-6 {
    min-width: 1.5rem !important; }
  .sm\+\:min-w-8 {
    min-width: 2rem !important; }
  .sm\+\:min-w-10 {
    min-width: 2.5rem !important; }
  .sm\+\:min-w-12 {
    min-width: 3rem !important; }
  .sm\+\:min-w-16 {
    min-width: 4rem !important; }
  .sm\+\:min-w-20 {
    min-width: 5rem !important; }
  .sm\+\:min-w-24 {
    min-width: 6rem !important; }
  .sm\+\:min-w-32 {
    min-width: 8rem !important; }
  .sm\+\:min-w-40 {
    min-width: 10rem !important; }
  .sm\+\:min-w-48 {
    min-width: 12rem !important; }
  .sm\+\:min-w-56 {
    min-width: 14rem !important; }
  .sm\+\:min-w-64 {
    min-width: 16rem !important; }
  .sm\+\:min-w-85 {
    min-width: 21.25rem !important; }
  .sm\+\:min-w-auto {
    min-width: auto !important; }
  .sm\+\:min-w-full {
    min-width: 100% !important; }
  .sm\+\:max-w-xs {
    max-width: 320px !important; }
  .sm\+\:max-w-sm {
    max-width: 410px !important; }
  .sm\+\:max-w-sm\+ {
    max-width: 600px !important; }
  .sm\+\:max-w-md {
    max-width: 768px !important; }
  .sm\+\:max-w-md\+ {
    max-width: 1024px !important; }
  .sm\+\:max-w-lg {
    max-width: 1440px !important; }
  .sm\+\:max-w-xl {
    max-width: 1800px !important; }
  .sm\+\:max-w-1\/2 {
    max-width: 50% !important; }
  .sm\+\:max-w-1\/3 {
    max-width: 33.33333% !important; }
  .sm\+\:max-w-2\/3 {
    max-width: 66.66667% !important; }
  .sm\+\:max-w-1\/4 {
    max-width: 25% !important; }
  .sm\+\:max-w-2\/4 {
    max-width: 50% !important; }
  .sm\+\:max-w-3\/4 {
    max-width: 75% !important; }
  .sm\+\:max-w-1\/5 {
    max-width: 20% !important; }
  .sm\+\:max-w-2\/5 {
    max-width: 40% !important; }
  .sm\+\:max-w-3\/5 {
    max-width: 60% !important; }
  .sm\+\:max-w-4\/5 {
    max-width: 80% !important; }
  .sm\+\:max-w-1\/12 {
    max-width: 8.33333% !important; }
  .sm\+\:max-w-2\/12 {
    max-width: 16.66667% !important; }
  .sm\+\:max-w-3\/12 {
    max-width: 25% !important; }
  .sm\+\:max-w-4\/12 {
    max-width: 33.33333% !important; }
  .sm\+\:max-w-5\/12 {
    max-width: 41.66667% !important; }
  .sm\+\:max-w-6\/12 {
    max-width: 50% !important; }
  .sm\+\:max-w-7\/12 {
    max-width: 58.33333% !important; }
  .sm\+\:max-w-8\/12 {
    max-width: 66.66667% !important; }
  .sm\+\:max-w-9\/12 {
    max-width: 75% !important; }
  .sm\+\:max-w-10\/12 {
    max-width: 83.33333% !important; }
  .sm\+\:max-w-11\/12 {
    max-width: 91.66667% !important; }
  .sm\+\:max-w-12\/12 {
    max-width: 100% !important; }
  .sm\+\:max-w-full {
    max-width: 100% !important; }
  .sm\+\:max-w-100 {
    max-width: 100% !important; }
  .sm\+\:max-w-0 {
    max-width: 0 !important; }
  .sm\+\:max-w-1 {
    max-width: 0.25rem !important; }
  .sm\+\:max-w-2 {
    max-width: 0.5rem !important; }
  .sm\+\:max-w-3 {
    max-width: 0.75rem !important; }
  .sm\+\:max-w-4 {
    max-width: 1rem !important; }
  .sm\+\:max-w-5 {
    max-width: 1.25rem !important; }
  .sm\+\:max-w-6 {
    max-width: 1.5rem !important; }
  .sm\+\:max-w-8 {
    max-width: 2rem !important; }
  .sm\+\:max-w-10 {
    max-width: 2.5rem !important; }
  .sm\+\:max-w-12 {
    max-width: 3rem !important; }
  .sm\+\:max-w-16 {
    max-width: 4rem !important; }
  .sm\+\:max-w-20 {
    max-width: 5rem !important; }
  .sm\+\:max-w-24 {
    max-width: 6rem !important; }
  .sm\+\:max-w-32 {
    max-width: 8rem !important; }
  .sm\+\:max-w-40 {
    max-width: 10rem !important; }
  .sm\+\:max-w-48 {
    max-width: 12rem !important; }
  .sm\+\:max-w-56 {
    max-width: 14rem !important; }
  .sm\+\:max-w-64 {
    max-width: 16rem !important; }
  .sm\+\:max-w-85 {
    max-width: 21.25rem !important; }
  .sm\+\:max-w-auto {
    max-width: auto !important; }
  .sm\+\:min-h-xs {
    min-height: 320px !important; }
  .sm\+\:min-h-sm {
    min-height: 410px !important; }
  .sm\+\:min-h-sm\+ {
    min-height: 600px !important; }
  .sm\+\:min-h-md {
    min-height: 768px !important; }
  .sm\+\:min-h-md\+ {
    min-height: 1024px !important; }
  .sm\+\:min-h-lg {
    min-height: 1440px !important; }
  .sm\+\:min-h-xl {
    min-height: 1800px !important; }
  .sm\+\:min-h-0 {
    min-height: 0 !important; }
  .sm\+\:min-h-full {
    min-height: 100% !important; }
  .sm\+\:min-h-screen {
    min-height: 100vh !important; } }

@media (min-width: 768px) {
  .md\:w-0 {
    width: 0 !important; }
  .md\:w-1 {
    width: 0.25rem !important; }
  .md\:w-2 {
    width: 0.5rem !important; }
  .md\:w-3 {
    width: 0.75rem !important; }
  .md\:w-4 {
    width: 1rem !important; }
  .md\:w-5 {
    width: 1.25rem !important; }
  .md\:w-6 {
    width: 1.5rem !important; }
  .md\:w-8 {
    width: 2rem !important; }
  .md\:w-10 {
    width: 2.5rem !important; }
  .md\:w-12 {
    width: 3rem !important; }
  .md\:w-16 {
    width: 4rem !important; }
  .md\:w-20 {
    width: 5rem !important; }
  .md\:w-24 {
    width: 6rem !important; }
  .md\:w-32 {
    width: 8rem !important; }
  .md\:w-40 {
    width: 10rem !important; }
  .md\:w-48 {
    width: 12rem !important; }
  .md\:w-56 {
    width: 14rem !important; }
  .md\:w-64 {
    width: 16rem !important; }
  .md\:w-85 {
    width: 21.25rem !important; }
  .md\:w-auto {
    width: auto !important; }
  .md\:w-1\/2 {
    width: 50% !important; }
  .md\:w-1\/3 {
    width: 33.33333% !important; }
  .md\:w-2\/3 {
    width: 66.66667% !important; }
  .md\:w-1\/4 {
    width: 25% !important; }
  .md\:w-2\/4 {
    width: 50% !important; }
  .md\:w-3\/4 {
    width: 75% !important; }
  .md\:w-1\/5 {
    width: 20% !important; }
  .md\:w-2\/5 {
    width: 40% !important; }
  .md\:w-3\/5 {
    width: 60% !important; }
  .md\:w-4\/5 {
    width: 80% !important; }
  .md\:w-1\/12 {
    width: 8.33333% !important; }
  .md\:w-2\/12 {
    width: 16.66667% !important; }
  .md\:w-3\/12 {
    width: 25% !important; }
  .md\:w-4\/12 {
    width: 33.33333% !important; }
  .md\:w-5\/12 {
    width: 41.66667% !important; }
  .md\:w-6\/12 {
    width: 50% !important; }
  .md\:w-7\/12 {
    width: 58.33333% !important; }
  .md\:w-8\/12 {
    width: 66.66667% !important; }
  .md\:w-9\/12 {
    width: 75% !important; }
  .md\:w-10\/12 {
    width: 83.33333% !important; }
  .md\:w-11\/12 {
    width: 91.66667% !important; }
  .md\:w-12\/12 {
    width: 100% !important; }
  .md\:w-full {
    width: 100% !important; }
  .md\:w-100 {
    width: 100% !important; }
  .md\:w-xs {
    width: 320px !important; }
  .md\:w-sm {
    width: 410px !important; }
  .md\:w-sm\+ {
    width: 600px !important; }
  .md\:w-md {
    width: 768px !important; }
  .md\:w-md\+ {
    width: 1024px !important; }
  .md\:w-lg {
    width: 1440px !important; }
  .md\:w-xl {
    width: 1800px !important; }
  .md\:w-vw {
    width: 100vw !important; }
  .md\:w-px {
    width: 1px !important; }
  .md\:h-0 {
    height: 0 !important; }
  .md\:h-1 {
    height: 0.25rem !important; }
  .md\:h-2 {
    height: 0.5rem !important; }
  .md\:h-3 {
    height: 0.75rem !important; }
  .md\:h-4 {
    height: 1rem !important; }
  .md\:h-5 {
    height: 1.25rem !important; }
  .md\:h-6 {
    height: 1.5rem !important; }
  .md\:h-8 {
    height: 2rem !important; }
  .md\:h-10 {
    height: 2.5rem !important; }
  .md\:h-12 {
    height: 3rem !important; }
  .md\:h-16 {
    height: 4rem !important; }
  .md\:h-20 {
    height: 5rem !important; }
  .md\:h-24 {
    height: 6rem !important; }
  .md\:h-32 {
    height: 8rem !important; }
  .md\:h-40 {
    height: 10rem !important; }
  .md\:h-48 {
    height: 12rem !important; }
  .md\:h-56 {
    height: 14rem !important; }
  .md\:h-64 {
    height: 16rem !important; }
  .md\:h-85 {
    height: 21.25rem !important; }
  .md\:h-auto {
    height: auto !important; }
  .md\:h-1\/2 {
    height: 50% !important; }
  .md\:h-1\/3 {
    height: 33.33333% !important; }
  .md\:h-2\/3 {
    height: 66.66667% !important; }
  .md\:h-1\/4 {
    height: 25% !important; }
  .md\:h-2\/4 {
    height: 50% !important; }
  .md\:h-3\/4 {
    height: 75% !important; }
  .md\:h-1\/5 {
    height: 20% !important; }
  .md\:h-2\/5 {
    height: 40% !important; }
  .md\:h-3\/5 {
    height: 60% !important; }
  .md\:h-4\/5 {
    height: 80% !important; }
  .md\:h-1\/12 {
    height: 8.33333% !important; }
  .md\:h-2\/12 {
    height: 16.66667% !important; }
  .md\:h-3\/12 {
    height: 25% !important; }
  .md\:h-4\/12 {
    height: 33.33333% !important; }
  .md\:h-5\/12 {
    height: 41.66667% !important; }
  .md\:h-6\/12 {
    height: 50% !important; }
  .md\:h-7\/12 {
    height: 58.33333% !important; }
  .md\:h-8\/12 {
    height: 66.66667% !important; }
  .md\:h-9\/12 {
    height: 75% !important; }
  .md\:h-10\/12 {
    height: 83.33333% !important; }
  .md\:h-11\/12 {
    height: 91.66667% !important; }
  .md\:h-12\/12 {
    height: 100% !important; }
  .md\:h-full {
    height: 100% !important; }
  .md\:h-100 {
    height: 100% !important; }
  .md\:h-vh {
    height: 100vh !important; }
  .md\:h-px {
    height: 1px !important; }
  .md\:min-w-xs {
    min-width: 320px !important; }
  .md\:min-w-sm {
    min-width: 410px !important; }
  .md\:min-w-sm\+ {
    min-width: 600px !important; }
  .md\:min-w-md {
    min-width: 768px !important; }
  .md\:min-w-md\+ {
    min-width: 1024px !important; }
  .md\:min-w-lg {
    min-width: 1440px !important; }
  .md\:min-w-xl {
    min-width: 1800px !important; }
  .md\:min-w-0 {
    min-width: 0 !important; }
  .md\:min-w-1 {
    min-width: 0.25rem !important; }
  .md\:min-w-2 {
    min-width: 0.5rem !important; }
  .md\:min-w-3 {
    min-width: 0.75rem !important; }
  .md\:min-w-4 {
    min-width: 1rem !important; }
  .md\:min-w-5 {
    min-width: 1.25rem !important; }
  .md\:min-w-6 {
    min-width: 1.5rem !important; }
  .md\:min-w-8 {
    min-width: 2rem !important; }
  .md\:min-w-10 {
    min-width: 2.5rem !important; }
  .md\:min-w-12 {
    min-width: 3rem !important; }
  .md\:min-w-16 {
    min-width: 4rem !important; }
  .md\:min-w-20 {
    min-width: 5rem !important; }
  .md\:min-w-24 {
    min-width: 6rem !important; }
  .md\:min-w-32 {
    min-width: 8rem !important; }
  .md\:min-w-40 {
    min-width: 10rem !important; }
  .md\:min-w-48 {
    min-width: 12rem !important; }
  .md\:min-w-56 {
    min-width: 14rem !important; }
  .md\:min-w-64 {
    min-width: 16rem !important; }
  .md\:min-w-85 {
    min-width: 21.25rem !important; }
  .md\:min-w-auto {
    min-width: auto !important; }
  .md\:min-w-full {
    min-width: 100% !important; }
  .md\:max-w-xs {
    max-width: 320px !important; }
  .md\:max-w-sm {
    max-width: 410px !important; }
  .md\:max-w-sm\+ {
    max-width: 600px !important; }
  .md\:max-w-md {
    max-width: 768px !important; }
  .md\:max-w-md\+ {
    max-width: 1024px !important; }
  .md\:max-w-lg {
    max-width: 1440px !important; }
  .md\:max-w-xl {
    max-width: 1800px !important; }
  .md\:max-w-1\/2 {
    max-width: 50% !important; }
  .md\:max-w-1\/3 {
    max-width: 33.33333% !important; }
  .md\:max-w-2\/3 {
    max-width: 66.66667% !important; }
  .md\:max-w-1\/4 {
    max-width: 25% !important; }
  .md\:max-w-2\/4 {
    max-width: 50% !important; }
  .md\:max-w-3\/4 {
    max-width: 75% !important; }
  .md\:max-w-1\/5 {
    max-width: 20% !important; }
  .md\:max-w-2\/5 {
    max-width: 40% !important; }
  .md\:max-w-3\/5 {
    max-width: 60% !important; }
  .md\:max-w-4\/5 {
    max-width: 80% !important; }
  .md\:max-w-1\/12 {
    max-width: 8.33333% !important; }
  .md\:max-w-2\/12 {
    max-width: 16.66667% !important; }
  .md\:max-w-3\/12 {
    max-width: 25% !important; }
  .md\:max-w-4\/12 {
    max-width: 33.33333% !important; }
  .md\:max-w-5\/12 {
    max-width: 41.66667% !important; }
  .md\:max-w-6\/12 {
    max-width: 50% !important; }
  .md\:max-w-7\/12 {
    max-width: 58.33333% !important; }
  .md\:max-w-8\/12 {
    max-width: 66.66667% !important; }
  .md\:max-w-9\/12 {
    max-width: 75% !important; }
  .md\:max-w-10\/12 {
    max-width: 83.33333% !important; }
  .md\:max-w-11\/12 {
    max-width: 91.66667% !important; }
  .md\:max-w-12\/12 {
    max-width: 100% !important; }
  .md\:max-w-full {
    max-width: 100% !important; }
  .md\:max-w-100 {
    max-width: 100% !important; }
  .md\:max-w-0 {
    max-width: 0 !important; }
  .md\:max-w-1 {
    max-width: 0.25rem !important; }
  .md\:max-w-2 {
    max-width: 0.5rem !important; }
  .md\:max-w-3 {
    max-width: 0.75rem !important; }
  .md\:max-w-4 {
    max-width: 1rem !important; }
  .md\:max-w-5 {
    max-width: 1.25rem !important; }
  .md\:max-w-6 {
    max-width: 1.5rem !important; }
  .md\:max-w-8 {
    max-width: 2rem !important; }
  .md\:max-w-10 {
    max-width: 2.5rem !important; }
  .md\:max-w-12 {
    max-width: 3rem !important; }
  .md\:max-w-16 {
    max-width: 4rem !important; }
  .md\:max-w-20 {
    max-width: 5rem !important; }
  .md\:max-w-24 {
    max-width: 6rem !important; }
  .md\:max-w-32 {
    max-width: 8rem !important; }
  .md\:max-w-40 {
    max-width: 10rem !important; }
  .md\:max-w-48 {
    max-width: 12rem !important; }
  .md\:max-w-56 {
    max-width: 14rem !important; }
  .md\:max-w-64 {
    max-width: 16rem !important; }
  .md\:max-w-85 {
    max-width: 21.25rem !important; }
  .md\:max-w-auto {
    max-width: auto !important; }
  .md\:min-h-xs {
    min-height: 320px !important; }
  .md\:min-h-sm {
    min-height: 410px !important; }
  .md\:min-h-sm\+ {
    min-height: 600px !important; }
  .md\:min-h-md {
    min-height: 768px !important; }
  .md\:min-h-md\+ {
    min-height: 1024px !important; }
  .md\:min-h-lg {
    min-height: 1440px !important; }
  .md\:min-h-xl {
    min-height: 1800px !important; }
  .md\:min-h-0 {
    min-height: 0 !important; }
  .md\:min-h-full {
    min-height: 100% !important; }
  .md\:min-h-screen {
    min-height: 100vh !important; } }

@media (min-width: 1024px) {
  .md\+\:w-0 {
    width: 0 !important; }
  .md\+\:w-1 {
    width: 0.25rem !important; }
  .md\+\:w-2 {
    width: 0.5rem !important; }
  .md\+\:w-3 {
    width: 0.75rem !important; }
  .md\+\:w-4 {
    width: 1rem !important; }
  .md\+\:w-5 {
    width: 1.25rem !important; }
  .md\+\:w-6 {
    width: 1.5rem !important; }
  .md\+\:w-8 {
    width: 2rem !important; }
  .md\+\:w-10 {
    width: 2.5rem !important; }
  .md\+\:w-12 {
    width: 3rem !important; }
  .md\+\:w-16 {
    width: 4rem !important; }
  .md\+\:w-20 {
    width: 5rem !important; }
  .md\+\:w-24 {
    width: 6rem !important; }
  .md\+\:w-32 {
    width: 8rem !important; }
  .md\+\:w-40 {
    width: 10rem !important; }
  .md\+\:w-48 {
    width: 12rem !important; }
  .md\+\:w-56 {
    width: 14rem !important; }
  .md\+\:w-64 {
    width: 16rem !important; }
  .md\+\:w-85 {
    width: 21.25rem !important; }
  .md\+\:w-auto {
    width: auto !important; }
  .md\+\:w-1\/2 {
    width: 50% !important; }
  .md\+\:w-1\/3 {
    width: 33.33333% !important; }
  .md\+\:w-2\/3 {
    width: 66.66667% !important; }
  .md\+\:w-1\/4 {
    width: 25% !important; }
  .md\+\:w-2\/4 {
    width: 50% !important; }
  .md\+\:w-3\/4 {
    width: 75% !important; }
  .md\+\:w-1\/5 {
    width: 20% !important; }
  .md\+\:w-2\/5 {
    width: 40% !important; }
  .md\+\:w-3\/5 {
    width: 60% !important; }
  .md\+\:w-4\/5 {
    width: 80% !important; }
  .md\+\:w-1\/12 {
    width: 8.33333% !important; }
  .md\+\:w-2\/12 {
    width: 16.66667% !important; }
  .md\+\:w-3\/12 {
    width: 25% !important; }
  .md\+\:w-4\/12 {
    width: 33.33333% !important; }
  .md\+\:w-5\/12 {
    width: 41.66667% !important; }
  .md\+\:w-6\/12 {
    width: 50% !important; }
  .md\+\:w-7\/12 {
    width: 58.33333% !important; }
  .md\+\:w-8\/12 {
    width: 66.66667% !important; }
  .md\+\:w-9\/12 {
    width: 75% !important; }
  .md\+\:w-10\/12 {
    width: 83.33333% !important; }
  .md\+\:w-11\/12 {
    width: 91.66667% !important; }
  .md\+\:w-12\/12 {
    width: 100% !important; }
  .md\+\:w-full {
    width: 100% !important; }
  .md\+\:w-100 {
    width: 100% !important; }
  .md\+\:w-xs {
    width: 320px !important; }
  .md\+\:w-sm {
    width: 410px !important; }
  .md\+\:w-sm\+ {
    width: 600px !important; }
  .md\+\:w-md {
    width: 768px !important; }
  .md\+\:w-md\+ {
    width: 1024px !important; }
  .md\+\:w-lg {
    width: 1440px !important; }
  .md\+\:w-xl {
    width: 1800px !important; }
  .md\+\:w-vw {
    width: 100vw !important; }
  .md\+\:w-px {
    width: 1px !important; }
  .md\+\:h-0 {
    height: 0 !important; }
  .md\+\:h-1 {
    height: 0.25rem !important; }
  .md\+\:h-2 {
    height: 0.5rem !important; }
  .md\+\:h-3 {
    height: 0.75rem !important; }
  .md\+\:h-4 {
    height: 1rem !important; }
  .md\+\:h-5 {
    height: 1.25rem !important; }
  .md\+\:h-6 {
    height: 1.5rem !important; }
  .md\+\:h-8 {
    height: 2rem !important; }
  .md\+\:h-10 {
    height: 2.5rem !important; }
  .md\+\:h-12 {
    height: 3rem !important; }
  .md\+\:h-16 {
    height: 4rem !important; }
  .md\+\:h-20 {
    height: 5rem !important; }
  .md\+\:h-24 {
    height: 6rem !important; }
  .md\+\:h-32 {
    height: 8rem !important; }
  .md\+\:h-40 {
    height: 10rem !important; }
  .md\+\:h-48 {
    height: 12rem !important; }
  .md\+\:h-56 {
    height: 14rem !important; }
  .md\+\:h-64 {
    height: 16rem !important; }
  .md\+\:h-85 {
    height: 21.25rem !important; }
  .md\+\:h-auto {
    height: auto !important; }
  .md\+\:h-1\/2 {
    height: 50% !important; }
  .md\+\:h-1\/3 {
    height: 33.33333% !important; }
  .md\+\:h-2\/3 {
    height: 66.66667% !important; }
  .md\+\:h-1\/4 {
    height: 25% !important; }
  .md\+\:h-2\/4 {
    height: 50% !important; }
  .md\+\:h-3\/4 {
    height: 75% !important; }
  .md\+\:h-1\/5 {
    height: 20% !important; }
  .md\+\:h-2\/5 {
    height: 40% !important; }
  .md\+\:h-3\/5 {
    height: 60% !important; }
  .md\+\:h-4\/5 {
    height: 80% !important; }
  .md\+\:h-1\/12 {
    height: 8.33333% !important; }
  .md\+\:h-2\/12 {
    height: 16.66667% !important; }
  .md\+\:h-3\/12 {
    height: 25% !important; }
  .md\+\:h-4\/12 {
    height: 33.33333% !important; }
  .md\+\:h-5\/12 {
    height: 41.66667% !important; }
  .md\+\:h-6\/12 {
    height: 50% !important; }
  .md\+\:h-7\/12 {
    height: 58.33333% !important; }
  .md\+\:h-8\/12 {
    height: 66.66667% !important; }
  .md\+\:h-9\/12 {
    height: 75% !important; }
  .md\+\:h-10\/12 {
    height: 83.33333% !important; }
  .md\+\:h-11\/12 {
    height: 91.66667% !important; }
  .md\+\:h-12\/12 {
    height: 100% !important; }
  .md\+\:h-full {
    height: 100% !important; }
  .md\+\:h-100 {
    height: 100% !important; }
  .md\+\:h-vh {
    height: 100vh !important; }
  .md\+\:h-px {
    height: 1px !important; }
  .md\+\:min-w-xs {
    min-width: 320px !important; }
  .md\+\:min-w-sm {
    min-width: 410px !important; }
  .md\+\:min-w-sm\+ {
    min-width: 600px !important; }
  .md\+\:min-w-md {
    min-width: 768px !important; }
  .md\+\:min-w-md\+ {
    min-width: 1024px !important; }
  .md\+\:min-w-lg {
    min-width: 1440px !important; }
  .md\+\:min-w-xl {
    min-width: 1800px !important; }
  .md\+\:min-w-0 {
    min-width: 0 !important; }
  .md\+\:min-w-1 {
    min-width: 0.25rem !important; }
  .md\+\:min-w-2 {
    min-width: 0.5rem !important; }
  .md\+\:min-w-3 {
    min-width: 0.75rem !important; }
  .md\+\:min-w-4 {
    min-width: 1rem !important; }
  .md\+\:min-w-5 {
    min-width: 1.25rem !important; }
  .md\+\:min-w-6 {
    min-width: 1.5rem !important; }
  .md\+\:min-w-8 {
    min-width: 2rem !important; }
  .md\+\:min-w-10 {
    min-width: 2.5rem !important; }
  .md\+\:min-w-12 {
    min-width: 3rem !important; }
  .md\+\:min-w-16 {
    min-width: 4rem !important; }
  .md\+\:min-w-20 {
    min-width: 5rem !important; }
  .md\+\:min-w-24 {
    min-width: 6rem !important; }
  .md\+\:min-w-32 {
    min-width: 8rem !important; }
  .md\+\:min-w-40 {
    min-width: 10rem !important; }
  .md\+\:min-w-48 {
    min-width: 12rem !important; }
  .md\+\:min-w-56 {
    min-width: 14rem !important; }
  .md\+\:min-w-64 {
    min-width: 16rem !important; }
  .md\+\:min-w-85 {
    min-width: 21.25rem !important; }
  .md\+\:min-w-auto {
    min-width: auto !important; }
  .md\+\:min-w-full {
    min-width: 100% !important; }
  .md\+\:max-w-xs {
    max-width: 320px !important; }
  .md\+\:max-w-sm {
    max-width: 410px !important; }
  .md\+\:max-w-sm\+ {
    max-width: 600px !important; }
  .md\+\:max-w-md {
    max-width: 768px !important; }
  .md\+\:max-w-md\+ {
    max-width: 1024px !important; }
  .md\+\:max-w-lg {
    max-width: 1440px !important; }
  .md\+\:max-w-xl {
    max-width: 1800px !important; }
  .md\+\:max-w-1\/2 {
    max-width: 50% !important; }
  .md\+\:max-w-1\/3 {
    max-width: 33.33333% !important; }
  .md\+\:max-w-2\/3 {
    max-width: 66.66667% !important; }
  .md\+\:max-w-1\/4 {
    max-width: 25% !important; }
  .md\+\:max-w-2\/4 {
    max-width: 50% !important; }
  .md\+\:max-w-3\/4 {
    max-width: 75% !important; }
  .md\+\:max-w-1\/5 {
    max-width: 20% !important; }
  .md\+\:max-w-2\/5 {
    max-width: 40% !important; }
  .md\+\:max-w-3\/5 {
    max-width: 60% !important; }
  .md\+\:max-w-4\/5 {
    max-width: 80% !important; }
  .md\+\:max-w-1\/12 {
    max-width: 8.33333% !important; }
  .md\+\:max-w-2\/12 {
    max-width: 16.66667% !important; }
  .md\+\:max-w-3\/12 {
    max-width: 25% !important; }
  .md\+\:max-w-4\/12 {
    max-width: 33.33333% !important; }
  .md\+\:max-w-5\/12 {
    max-width: 41.66667% !important; }
  .md\+\:max-w-6\/12 {
    max-width: 50% !important; }
  .md\+\:max-w-7\/12 {
    max-width: 58.33333% !important; }
  .md\+\:max-w-8\/12 {
    max-width: 66.66667% !important; }
  .md\+\:max-w-9\/12 {
    max-width: 75% !important; }
  .md\+\:max-w-10\/12 {
    max-width: 83.33333% !important; }
  .md\+\:max-w-11\/12 {
    max-width: 91.66667% !important; }
  .md\+\:max-w-12\/12 {
    max-width: 100% !important; }
  .md\+\:max-w-full {
    max-width: 100% !important; }
  .md\+\:max-w-100 {
    max-width: 100% !important; }
  .md\+\:max-w-0 {
    max-width: 0 !important; }
  .md\+\:max-w-1 {
    max-width: 0.25rem !important; }
  .md\+\:max-w-2 {
    max-width: 0.5rem !important; }
  .md\+\:max-w-3 {
    max-width: 0.75rem !important; }
  .md\+\:max-w-4 {
    max-width: 1rem !important; }
  .md\+\:max-w-5 {
    max-width: 1.25rem !important; }
  .md\+\:max-w-6 {
    max-width: 1.5rem !important; }
  .md\+\:max-w-8 {
    max-width: 2rem !important; }
  .md\+\:max-w-10 {
    max-width: 2.5rem !important; }
  .md\+\:max-w-12 {
    max-width: 3rem !important; }
  .md\+\:max-w-16 {
    max-width: 4rem !important; }
  .md\+\:max-w-20 {
    max-width: 5rem !important; }
  .md\+\:max-w-24 {
    max-width: 6rem !important; }
  .md\+\:max-w-32 {
    max-width: 8rem !important; }
  .md\+\:max-w-40 {
    max-width: 10rem !important; }
  .md\+\:max-w-48 {
    max-width: 12rem !important; }
  .md\+\:max-w-56 {
    max-width: 14rem !important; }
  .md\+\:max-w-64 {
    max-width: 16rem !important; }
  .md\+\:max-w-85 {
    max-width: 21.25rem !important; }
  .md\+\:max-w-auto {
    max-width: auto !important; }
  .md\+\:min-h-xs {
    min-height: 320px !important; }
  .md\+\:min-h-sm {
    min-height: 410px !important; }
  .md\+\:min-h-sm\+ {
    min-height: 600px !important; }
  .md\+\:min-h-md {
    min-height: 768px !important; }
  .md\+\:min-h-md\+ {
    min-height: 1024px !important; }
  .md\+\:min-h-lg {
    min-height: 1440px !important; }
  .md\+\:min-h-xl {
    min-height: 1800px !important; }
  .md\+\:min-h-0 {
    min-height: 0 !important; }
  .md\+\:min-h-full {
    min-height: 100% !important; }
  .md\+\:min-h-screen {
    min-height: 100vh !important; } }

@media (min-width: 1440px) {
  .lg\:w-0 {
    width: 0 !important; }
  .lg\:w-1 {
    width: 0.25rem !important; }
  .lg\:w-2 {
    width: 0.5rem !important; }
  .lg\:w-3 {
    width: 0.75rem !important; }
  .lg\:w-4 {
    width: 1rem !important; }
  .lg\:w-5 {
    width: 1.25rem !important; }
  .lg\:w-6 {
    width: 1.5rem !important; }
  .lg\:w-8 {
    width: 2rem !important; }
  .lg\:w-10 {
    width: 2.5rem !important; }
  .lg\:w-12 {
    width: 3rem !important; }
  .lg\:w-16 {
    width: 4rem !important; }
  .lg\:w-20 {
    width: 5rem !important; }
  .lg\:w-24 {
    width: 6rem !important; }
  .lg\:w-32 {
    width: 8rem !important; }
  .lg\:w-40 {
    width: 10rem !important; }
  .lg\:w-48 {
    width: 12rem !important; }
  .lg\:w-56 {
    width: 14rem !important; }
  .lg\:w-64 {
    width: 16rem !important; }
  .lg\:w-85 {
    width: 21.25rem !important; }
  .lg\:w-auto {
    width: auto !important; }
  .lg\:w-1\/2 {
    width: 50% !important; }
  .lg\:w-1\/3 {
    width: 33.33333% !important; }
  .lg\:w-2\/3 {
    width: 66.66667% !important; }
  .lg\:w-1\/4 {
    width: 25% !important; }
  .lg\:w-2\/4 {
    width: 50% !important; }
  .lg\:w-3\/4 {
    width: 75% !important; }
  .lg\:w-1\/5 {
    width: 20% !important; }
  .lg\:w-2\/5 {
    width: 40% !important; }
  .lg\:w-3\/5 {
    width: 60% !important; }
  .lg\:w-4\/5 {
    width: 80% !important; }
  .lg\:w-1\/12 {
    width: 8.33333% !important; }
  .lg\:w-2\/12 {
    width: 16.66667% !important; }
  .lg\:w-3\/12 {
    width: 25% !important; }
  .lg\:w-4\/12 {
    width: 33.33333% !important; }
  .lg\:w-5\/12 {
    width: 41.66667% !important; }
  .lg\:w-6\/12 {
    width: 50% !important; }
  .lg\:w-7\/12 {
    width: 58.33333% !important; }
  .lg\:w-8\/12 {
    width: 66.66667% !important; }
  .lg\:w-9\/12 {
    width: 75% !important; }
  .lg\:w-10\/12 {
    width: 83.33333% !important; }
  .lg\:w-11\/12 {
    width: 91.66667% !important; }
  .lg\:w-12\/12 {
    width: 100% !important; }
  .lg\:w-full {
    width: 100% !important; }
  .lg\:w-100 {
    width: 100% !important; }
  .lg\:w-xs {
    width: 320px !important; }
  .lg\:w-sm {
    width: 410px !important; }
  .lg\:w-sm\+ {
    width: 600px !important; }
  .lg\:w-md {
    width: 768px !important; }
  .lg\:w-md\+ {
    width: 1024px !important; }
  .lg\:w-lg {
    width: 1440px !important; }
  .lg\:w-xl {
    width: 1800px !important; }
  .lg\:w-vw {
    width: 100vw !important; }
  .lg\:w-px {
    width: 1px !important; }
  .lg\:h-0 {
    height: 0 !important; }
  .lg\:h-1 {
    height: 0.25rem !important; }
  .lg\:h-2 {
    height: 0.5rem !important; }
  .lg\:h-3 {
    height: 0.75rem !important; }
  .lg\:h-4 {
    height: 1rem !important; }
  .lg\:h-5 {
    height: 1.25rem !important; }
  .lg\:h-6 {
    height: 1.5rem !important; }
  .lg\:h-8 {
    height: 2rem !important; }
  .lg\:h-10 {
    height: 2.5rem !important; }
  .lg\:h-12 {
    height: 3rem !important; }
  .lg\:h-16 {
    height: 4rem !important; }
  .lg\:h-20 {
    height: 5rem !important; }
  .lg\:h-24 {
    height: 6rem !important; }
  .lg\:h-32 {
    height: 8rem !important; }
  .lg\:h-40 {
    height: 10rem !important; }
  .lg\:h-48 {
    height: 12rem !important; }
  .lg\:h-56 {
    height: 14rem !important; }
  .lg\:h-64 {
    height: 16rem !important; }
  .lg\:h-85 {
    height: 21.25rem !important; }
  .lg\:h-auto {
    height: auto !important; }
  .lg\:h-1\/2 {
    height: 50% !important; }
  .lg\:h-1\/3 {
    height: 33.33333% !important; }
  .lg\:h-2\/3 {
    height: 66.66667% !important; }
  .lg\:h-1\/4 {
    height: 25% !important; }
  .lg\:h-2\/4 {
    height: 50% !important; }
  .lg\:h-3\/4 {
    height: 75% !important; }
  .lg\:h-1\/5 {
    height: 20% !important; }
  .lg\:h-2\/5 {
    height: 40% !important; }
  .lg\:h-3\/5 {
    height: 60% !important; }
  .lg\:h-4\/5 {
    height: 80% !important; }
  .lg\:h-1\/12 {
    height: 8.33333% !important; }
  .lg\:h-2\/12 {
    height: 16.66667% !important; }
  .lg\:h-3\/12 {
    height: 25% !important; }
  .lg\:h-4\/12 {
    height: 33.33333% !important; }
  .lg\:h-5\/12 {
    height: 41.66667% !important; }
  .lg\:h-6\/12 {
    height: 50% !important; }
  .lg\:h-7\/12 {
    height: 58.33333% !important; }
  .lg\:h-8\/12 {
    height: 66.66667% !important; }
  .lg\:h-9\/12 {
    height: 75% !important; }
  .lg\:h-10\/12 {
    height: 83.33333% !important; }
  .lg\:h-11\/12 {
    height: 91.66667% !important; }
  .lg\:h-12\/12 {
    height: 100% !important; }
  .lg\:h-full {
    height: 100% !important; }
  .lg\:h-100 {
    height: 100% !important; }
  .lg\:h-vh {
    height: 100vh !important; }
  .lg\:h-px {
    height: 1px !important; }
  .lg\:min-w-xs {
    min-width: 320px !important; }
  .lg\:min-w-sm {
    min-width: 410px !important; }
  .lg\:min-w-sm\+ {
    min-width: 600px !important; }
  .lg\:min-w-md {
    min-width: 768px !important; }
  .lg\:min-w-md\+ {
    min-width: 1024px !important; }
  .lg\:min-w-lg {
    min-width: 1440px !important; }
  .lg\:min-w-xl {
    min-width: 1800px !important; }
  .lg\:min-w-0 {
    min-width: 0 !important; }
  .lg\:min-w-1 {
    min-width: 0.25rem !important; }
  .lg\:min-w-2 {
    min-width: 0.5rem !important; }
  .lg\:min-w-3 {
    min-width: 0.75rem !important; }
  .lg\:min-w-4 {
    min-width: 1rem !important; }
  .lg\:min-w-5 {
    min-width: 1.25rem !important; }
  .lg\:min-w-6 {
    min-width: 1.5rem !important; }
  .lg\:min-w-8 {
    min-width: 2rem !important; }
  .lg\:min-w-10 {
    min-width: 2.5rem !important; }
  .lg\:min-w-12 {
    min-width: 3rem !important; }
  .lg\:min-w-16 {
    min-width: 4rem !important; }
  .lg\:min-w-20 {
    min-width: 5rem !important; }
  .lg\:min-w-24 {
    min-width: 6rem !important; }
  .lg\:min-w-32 {
    min-width: 8rem !important; }
  .lg\:min-w-40 {
    min-width: 10rem !important; }
  .lg\:min-w-48 {
    min-width: 12rem !important; }
  .lg\:min-w-56 {
    min-width: 14rem !important; }
  .lg\:min-w-64 {
    min-width: 16rem !important; }
  .lg\:min-w-85 {
    min-width: 21.25rem !important; }
  .lg\:min-w-auto {
    min-width: auto !important; }
  .lg\:min-w-full {
    min-width: 100% !important; }
  .lg\:max-w-xs {
    max-width: 320px !important; }
  .lg\:max-w-sm {
    max-width: 410px !important; }
  .lg\:max-w-sm\+ {
    max-width: 600px !important; }
  .lg\:max-w-md {
    max-width: 768px !important; }
  .lg\:max-w-md\+ {
    max-width: 1024px !important; }
  .lg\:max-w-lg {
    max-width: 1440px !important; }
  .lg\:max-w-xl {
    max-width: 1800px !important; }
  .lg\:max-w-1\/2 {
    max-width: 50% !important; }
  .lg\:max-w-1\/3 {
    max-width: 33.33333% !important; }
  .lg\:max-w-2\/3 {
    max-width: 66.66667% !important; }
  .lg\:max-w-1\/4 {
    max-width: 25% !important; }
  .lg\:max-w-2\/4 {
    max-width: 50% !important; }
  .lg\:max-w-3\/4 {
    max-width: 75% !important; }
  .lg\:max-w-1\/5 {
    max-width: 20% !important; }
  .lg\:max-w-2\/5 {
    max-width: 40% !important; }
  .lg\:max-w-3\/5 {
    max-width: 60% !important; }
  .lg\:max-w-4\/5 {
    max-width: 80% !important; }
  .lg\:max-w-1\/12 {
    max-width: 8.33333% !important; }
  .lg\:max-w-2\/12 {
    max-width: 16.66667% !important; }
  .lg\:max-w-3\/12 {
    max-width: 25% !important; }
  .lg\:max-w-4\/12 {
    max-width: 33.33333% !important; }
  .lg\:max-w-5\/12 {
    max-width: 41.66667% !important; }
  .lg\:max-w-6\/12 {
    max-width: 50% !important; }
  .lg\:max-w-7\/12 {
    max-width: 58.33333% !important; }
  .lg\:max-w-8\/12 {
    max-width: 66.66667% !important; }
  .lg\:max-w-9\/12 {
    max-width: 75% !important; }
  .lg\:max-w-10\/12 {
    max-width: 83.33333% !important; }
  .lg\:max-w-11\/12 {
    max-width: 91.66667% !important; }
  .lg\:max-w-12\/12 {
    max-width: 100% !important; }
  .lg\:max-w-full {
    max-width: 100% !important; }
  .lg\:max-w-100 {
    max-width: 100% !important; }
  .lg\:max-w-0 {
    max-width: 0 !important; }
  .lg\:max-w-1 {
    max-width: 0.25rem !important; }
  .lg\:max-w-2 {
    max-width: 0.5rem !important; }
  .lg\:max-w-3 {
    max-width: 0.75rem !important; }
  .lg\:max-w-4 {
    max-width: 1rem !important; }
  .lg\:max-w-5 {
    max-width: 1.25rem !important; }
  .lg\:max-w-6 {
    max-width: 1.5rem !important; }
  .lg\:max-w-8 {
    max-width: 2rem !important; }
  .lg\:max-w-10 {
    max-width: 2.5rem !important; }
  .lg\:max-w-12 {
    max-width: 3rem !important; }
  .lg\:max-w-16 {
    max-width: 4rem !important; }
  .lg\:max-w-20 {
    max-width: 5rem !important; }
  .lg\:max-w-24 {
    max-width: 6rem !important; }
  .lg\:max-w-32 {
    max-width: 8rem !important; }
  .lg\:max-w-40 {
    max-width: 10rem !important; }
  .lg\:max-w-48 {
    max-width: 12rem !important; }
  .lg\:max-w-56 {
    max-width: 14rem !important; }
  .lg\:max-w-64 {
    max-width: 16rem !important; }
  .lg\:max-w-85 {
    max-width: 21.25rem !important; }
  .lg\:max-w-auto {
    max-width: auto !important; }
  .lg\:min-h-xs {
    min-height: 320px !important; }
  .lg\:min-h-sm {
    min-height: 410px !important; }
  .lg\:min-h-sm\+ {
    min-height: 600px !important; }
  .lg\:min-h-md {
    min-height: 768px !important; }
  .lg\:min-h-md\+ {
    min-height: 1024px !important; }
  .lg\:min-h-lg {
    min-height: 1440px !important; }
  .lg\:min-h-xl {
    min-height: 1800px !important; }
  .lg\:min-h-0 {
    min-height: 0 !important; }
  .lg\:min-h-full {
    min-height: 100% !important; }
  .lg\:min-h-screen {
    min-height: 100vh !important; } }

@media (min-width: 1800px) {
  .xl\:w-0 {
    width: 0 !important; }
  .xl\:w-1 {
    width: 0.25rem !important; }
  .xl\:w-2 {
    width: 0.5rem !important; }
  .xl\:w-3 {
    width: 0.75rem !important; }
  .xl\:w-4 {
    width: 1rem !important; }
  .xl\:w-5 {
    width: 1.25rem !important; }
  .xl\:w-6 {
    width: 1.5rem !important; }
  .xl\:w-8 {
    width: 2rem !important; }
  .xl\:w-10 {
    width: 2.5rem !important; }
  .xl\:w-12 {
    width: 3rem !important; }
  .xl\:w-16 {
    width: 4rem !important; }
  .xl\:w-20 {
    width: 5rem !important; }
  .xl\:w-24 {
    width: 6rem !important; }
  .xl\:w-32 {
    width: 8rem !important; }
  .xl\:w-40 {
    width: 10rem !important; }
  .xl\:w-48 {
    width: 12rem !important; }
  .xl\:w-56 {
    width: 14rem !important; }
  .xl\:w-64 {
    width: 16rem !important; }
  .xl\:w-85 {
    width: 21.25rem !important; }
  .xl\:w-auto {
    width: auto !important; }
  .xl\:w-1\/2 {
    width: 50% !important; }
  .xl\:w-1\/3 {
    width: 33.33333% !important; }
  .xl\:w-2\/3 {
    width: 66.66667% !important; }
  .xl\:w-1\/4 {
    width: 25% !important; }
  .xl\:w-2\/4 {
    width: 50% !important; }
  .xl\:w-3\/4 {
    width: 75% !important; }
  .xl\:w-1\/5 {
    width: 20% !important; }
  .xl\:w-2\/5 {
    width: 40% !important; }
  .xl\:w-3\/5 {
    width: 60% !important; }
  .xl\:w-4\/5 {
    width: 80% !important; }
  .xl\:w-1\/12 {
    width: 8.33333% !important; }
  .xl\:w-2\/12 {
    width: 16.66667% !important; }
  .xl\:w-3\/12 {
    width: 25% !important; }
  .xl\:w-4\/12 {
    width: 33.33333% !important; }
  .xl\:w-5\/12 {
    width: 41.66667% !important; }
  .xl\:w-6\/12 {
    width: 50% !important; }
  .xl\:w-7\/12 {
    width: 58.33333% !important; }
  .xl\:w-8\/12 {
    width: 66.66667% !important; }
  .xl\:w-9\/12 {
    width: 75% !important; }
  .xl\:w-10\/12 {
    width: 83.33333% !important; }
  .xl\:w-11\/12 {
    width: 91.66667% !important; }
  .xl\:w-12\/12 {
    width: 100% !important; }
  .xl\:w-full {
    width: 100% !important; }
  .xl\:w-100 {
    width: 100% !important; }
  .xl\:w-xs {
    width: 320px !important; }
  .xl\:w-sm {
    width: 410px !important; }
  .xl\:w-sm\+ {
    width: 600px !important; }
  .xl\:w-md {
    width: 768px !important; }
  .xl\:w-md\+ {
    width: 1024px !important; }
  .xl\:w-lg {
    width: 1440px !important; }
  .xl\:w-xl {
    width: 1800px !important; }
  .xl\:w-vw {
    width: 100vw !important; }
  .xl\:w-px {
    width: 1px !important; }
  .xl\:h-0 {
    height: 0 !important; }
  .xl\:h-1 {
    height: 0.25rem !important; }
  .xl\:h-2 {
    height: 0.5rem !important; }
  .xl\:h-3 {
    height: 0.75rem !important; }
  .xl\:h-4 {
    height: 1rem !important; }
  .xl\:h-5 {
    height: 1.25rem !important; }
  .xl\:h-6 {
    height: 1.5rem !important; }
  .xl\:h-8 {
    height: 2rem !important; }
  .xl\:h-10 {
    height: 2.5rem !important; }
  .xl\:h-12 {
    height: 3rem !important; }
  .xl\:h-16 {
    height: 4rem !important; }
  .xl\:h-20 {
    height: 5rem !important; }
  .xl\:h-24 {
    height: 6rem !important; }
  .xl\:h-32 {
    height: 8rem !important; }
  .xl\:h-40 {
    height: 10rem !important; }
  .xl\:h-48 {
    height: 12rem !important; }
  .xl\:h-56 {
    height: 14rem !important; }
  .xl\:h-64 {
    height: 16rem !important; }
  .xl\:h-85 {
    height: 21.25rem !important; }
  .xl\:h-auto {
    height: auto !important; }
  .xl\:h-1\/2 {
    height: 50% !important; }
  .xl\:h-1\/3 {
    height: 33.33333% !important; }
  .xl\:h-2\/3 {
    height: 66.66667% !important; }
  .xl\:h-1\/4 {
    height: 25% !important; }
  .xl\:h-2\/4 {
    height: 50% !important; }
  .xl\:h-3\/4 {
    height: 75% !important; }
  .xl\:h-1\/5 {
    height: 20% !important; }
  .xl\:h-2\/5 {
    height: 40% !important; }
  .xl\:h-3\/5 {
    height: 60% !important; }
  .xl\:h-4\/5 {
    height: 80% !important; }
  .xl\:h-1\/12 {
    height: 8.33333% !important; }
  .xl\:h-2\/12 {
    height: 16.66667% !important; }
  .xl\:h-3\/12 {
    height: 25% !important; }
  .xl\:h-4\/12 {
    height: 33.33333% !important; }
  .xl\:h-5\/12 {
    height: 41.66667% !important; }
  .xl\:h-6\/12 {
    height: 50% !important; }
  .xl\:h-7\/12 {
    height: 58.33333% !important; }
  .xl\:h-8\/12 {
    height: 66.66667% !important; }
  .xl\:h-9\/12 {
    height: 75% !important; }
  .xl\:h-10\/12 {
    height: 83.33333% !important; }
  .xl\:h-11\/12 {
    height: 91.66667% !important; }
  .xl\:h-12\/12 {
    height: 100% !important; }
  .xl\:h-full {
    height: 100% !important; }
  .xl\:h-100 {
    height: 100% !important; }
  .xl\:h-vh {
    height: 100vh !important; }
  .xl\:h-px {
    height: 1px !important; }
  .xl\:min-w-xs {
    min-width: 320px !important; }
  .xl\:min-w-sm {
    min-width: 410px !important; }
  .xl\:min-w-sm\+ {
    min-width: 600px !important; }
  .xl\:min-w-md {
    min-width: 768px !important; }
  .xl\:min-w-md\+ {
    min-width: 1024px !important; }
  .xl\:min-w-lg {
    min-width: 1440px !important; }
  .xl\:min-w-xl {
    min-width: 1800px !important; }
  .xl\:min-w-0 {
    min-width: 0 !important; }
  .xl\:min-w-1 {
    min-width: 0.25rem !important; }
  .xl\:min-w-2 {
    min-width: 0.5rem !important; }
  .xl\:min-w-3 {
    min-width: 0.75rem !important; }
  .xl\:min-w-4 {
    min-width: 1rem !important; }
  .xl\:min-w-5 {
    min-width: 1.25rem !important; }
  .xl\:min-w-6 {
    min-width: 1.5rem !important; }
  .xl\:min-w-8 {
    min-width: 2rem !important; }
  .xl\:min-w-10 {
    min-width: 2.5rem !important; }
  .xl\:min-w-12 {
    min-width: 3rem !important; }
  .xl\:min-w-16 {
    min-width: 4rem !important; }
  .xl\:min-w-20 {
    min-width: 5rem !important; }
  .xl\:min-w-24 {
    min-width: 6rem !important; }
  .xl\:min-w-32 {
    min-width: 8rem !important; }
  .xl\:min-w-40 {
    min-width: 10rem !important; }
  .xl\:min-w-48 {
    min-width: 12rem !important; }
  .xl\:min-w-56 {
    min-width: 14rem !important; }
  .xl\:min-w-64 {
    min-width: 16rem !important; }
  .xl\:min-w-85 {
    min-width: 21.25rem !important; }
  .xl\:min-w-auto {
    min-width: auto !important; }
  .xl\:min-w-full {
    min-width: 100% !important; }
  .xl\:max-w-xs {
    max-width: 320px !important; }
  .xl\:max-w-sm {
    max-width: 410px !important; }
  .xl\:max-w-sm\+ {
    max-width: 600px !important; }
  .xl\:max-w-md {
    max-width: 768px !important; }
  .xl\:max-w-md\+ {
    max-width: 1024px !important; }
  .xl\:max-w-lg {
    max-width: 1440px !important; }
  .xl\:max-w-xl {
    max-width: 1800px !important; }
  .xl\:max-w-1\/2 {
    max-width: 50% !important; }
  .xl\:max-w-1\/3 {
    max-width: 33.33333% !important; }
  .xl\:max-w-2\/3 {
    max-width: 66.66667% !important; }
  .xl\:max-w-1\/4 {
    max-width: 25% !important; }
  .xl\:max-w-2\/4 {
    max-width: 50% !important; }
  .xl\:max-w-3\/4 {
    max-width: 75% !important; }
  .xl\:max-w-1\/5 {
    max-width: 20% !important; }
  .xl\:max-w-2\/5 {
    max-width: 40% !important; }
  .xl\:max-w-3\/5 {
    max-width: 60% !important; }
  .xl\:max-w-4\/5 {
    max-width: 80% !important; }
  .xl\:max-w-1\/12 {
    max-width: 8.33333% !important; }
  .xl\:max-w-2\/12 {
    max-width: 16.66667% !important; }
  .xl\:max-w-3\/12 {
    max-width: 25% !important; }
  .xl\:max-w-4\/12 {
    max-width: 33.33333% !important; }
  .xl\:max-w-5\/12 {
    max-width: 41.66667% !important; }
  .xl\:max-w-6\/12 {
    max-width: 50% !important; }
  .xl\:max-w-7\/12 {
    max-width: 58.33333% !important; }
  .xl\:max-w-8\/12 {
    max-width: 66.66667% !important; }
  .xl\:max-w-9\/12 {
    max-width: 75% !important; }
  .xl\:max-w-10\/12 {
    max-width: 83.33333% !important; }
  .xl\:max-w-11\/12 {
    max-width: 91.66667% !important; }
  .xl\:max-w-12\/12 {
    max-width: 100% !important; }
  .xl\:max-w-full {
    max-width: 100% !important; }
  .xl\:max-w-100 {
    max-width: 100% !important; }
  .xl\:max-w-0 {
    max-width: 0 !important; }
  .xl\:max-w-1 {
    max-width: 0.25rem !important; }
  .xl\:max-w-2 {
    max-width: 0.5rem !important; }
  .xl\:max-w-3 {
    max-width: 0.75rem !important; }
  .xl\:max-w-4 {
    max-width: 1rem !important; }
  .xl\:max-w-5 {
    max-width: 1.25rem !important; }
  .xl\:max-w-6 {
    max-width: 1.5rem !important; }
  .xl\:max-w-8 {
    max-width: 2rem !important; }
  .xl\:max-w-10 {
    max-width: 2.5rem !important; }
  .xl\:max-w-12 {
    max-width: 3rem !important; }
  .xl\:max-w-16 {
    max-width: 4rem !important; }
  .xl\:max-w-20 {
    max-width: 5rem !important; }
  .xl\:max-w-24 {
    max-width: 6rem !important; }
  .xl\:max-w-32 {
    max-width: 8rem !important; }
  .xl\:max-w-40 {
    max-width: 10rem !important; }
  .xl\:max-w-48 {
    max-width: 12rem !important; }
  .xl\:max-w-56 {
    max-width: 14rem !important; }
  .xl\:max-w-64 {
    max-width: 16rem !important; }
  .xl\:max-w-85 {
    max-width: 21.25rem !important; }
  .xl\:max-w-auto {
    max-width: auto !important; }
  .xl\:min-h-xs {
    min-height: 320px !important; }
  .xl\:min-h-sm {
    min-height: 410px !important; }
  .xl\:min-h-sm\+ {
    min-height: 600px !important; }
  .xl\:min-h-md {
    min-height: 768px !important; }
  .xl\:min-h-md\+ {
    min-height: 1024px !important; }
  .xl\:min-h-lg {
    min-height: 1440px !important; }
  .xl\:min-h-xl {
    min-height: 1800px !important; }
  .xl\:min-h-0 {
    min-height: 0 !important; }
  .xl\:min-h-full {
    min-height: 100% !important; }
  .xl\:min-h-screen {
    min-height: 100vh !important; } }

.block {
  display: block !important; }

.inline-block {
  display: inline-block !important; }

.inline {
  display: inline !important; }

.flex {
  display: flex !important; }

.inline-flex {
  display: inline-flex !important; }

.hidden {
  display: none !important; }

@media (min-width: 410px) {
  .sm\:block {
    display: block !important; }
  .sm\:inline-block {
    display: inline-block !important; }
  .sm\:inline {
    display: inline !important; }
  .sm\:flex {
    display: flex !important; }
  .sm\:inline-flex {
    display: inline-flex !important; }
  .sm\:hidden {
    display: none !important; } }

@media (min-width: 600px) {
  .sm\+\:block {
    display: block !important; }
  .sm\+\:inline-block {
    display: inline-block !important; }
  .sm\+\:inline {
    display: inline !important; }
  .sm\+\:flex {
    display: flex !important; }
  .sm\+\:inline-flex {
    display: inline-flex !important; }
  .sm\+\:hidden {
    display: none !important; } }

@media (min-width: 768px) {
  .md\:block {
    display: block !important; }
  .md\:inline-block {
    display: inline-block !important; }
  .md\:inline {
    display: inline !important; }
  .md\:flex {
    display: flex !important; }
  .md\:inline-flex {
    display: inline-flex !important; }
  .md\:hidden {
    display: none !important; } }

@media (min-width: 1024px) {
  .md\+\:block {
    display: block !important; }
  .md\+\:inline-block {
    display: inline-block !important; }
  .md\+\:inline {
    display: inline !important; }
  .md\+\:flex {
    display: flex !important; }
  .md\+\:inline-flex {
    display: inline-flex !important; }
  .md\+\:hidden {
    display: none !important; } }

@media (min-width: 1440px) {
  .lg\:block {
    display: block !important; }
  .lg\:inline-block {
    display: inline-block !important; }
  .lg\:inline {
    display: inline !important; }
  .lg\:flex {
    display: flex !important; }
  .lg\:inline-flex {
    display: inline-flex !important; }
  .lg\:hidden {
    display: none !important; } }

@media (min-width: 1800px) {
  .xl\:block {
    display: block !important; }
  .xl\:inline-block {
    display: inline-block !important; }
  .xl\:inline {
    display: inline !important; }
  .xl\:flex {
    display: flex !important; }
  .xl\:inline-flex {
    display: inline-flex !important; }
  .xl\:hidden {
    display: none !important; } }

@media print {
  .print\:block {
    display: block !important; }
  .print\:inline-block {
    display: inline-block !important; }
  .print\:inline {
    display: inline !important; }
  .print\:flex {
    display: flex !important; }
  .print\:inline-flex {
    display: inline-flex !important; }
  .print\:hidden {
    display: none !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-jc-start {
  justify-content: flex-start !important; }

.flex-jc-end {
  justify-content: flex-end !important; }

.flex-jc-center {
  justify-content: center !important; }

.flex-jc-between {
  justify-content: space-between !important; }

.flex-jc-around {
  justify-content: space-around !important; }

.flex-jc-evenly {
  justify-content: space-evenly !important; }

.flex-ai-start {
  align-items: flex-start !important; }

.flex-ai-end {
  align-items: flex-end !important; }

.flex-ai-center {
  align-items: center !important; }

.flex-ai-baseline {
  align-items: baseline !important; }

.flex-ai-stretch {
  align-items: stretch !important; }

.flex-ac-start {
  align-content: flex-start !important; }

.flex-ac-end {
  align-content: flex-end !important; }

.flex-ac-center {
  align-content: center !important; }

.flex-ac-between {
  align-content: space-between !important; }

.flex-ac-around {
  align-content: space-around !important; }

.flex-ac-stretch {
  align-content: stretch !important; }

.flex-as-auto {
  align-self: auto !important; }

.flex-as-start {
  align-self: flex-start !important; }

.flex-as-end {
  align-self: flex-end !important; }

.flex-as-center {
  align-self: center !important; }

.flex-as-baseline {
  align-self: baseline !important; }

.flex-as-stretch {
  align-self: stretch !important; }

.flex-order-first {
  order: -9999 !important; }

.flex-order-last {
  order: 9999 !important; }

.flex-order-none {
  order: 0 !important; }

.flex-order-1 {
  order: 1 !important; }

.flex-order-2 {
  order: 2 !important; }

.flex-order-3 {
  order: 3 !important; }

.flex-order-4 {
  order: 4 !important; }

.flex-order-5 {
  order: 5 !important; }

.flex-order-6 {
  order: 6 !important; }

.flex-order-7 {
  order: 7 !important; }

.flex-order-8 {
  order: 8 !important; }

.flex-order-9 {
  order: 9 !important; }

.flex-order-10 {
  order: 10 !important; }

.flex-order-11 {
  order: 11 !important; }

.flex-order-12 {
  order: 12 !important; }

@media (min-width: 410px) {
  .sm\:flex-row {
    flex-direction: row !important; }
  .sm\:flex-column {
    flex-direction: column !important; }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important; }
  .sm\:flex-column-reverse {
    flex-direction: column-reverse !important; }
  .sm\:flex-wrap {
    flex-wrap: wrap !important; }
  .sm\:flex-nowrap {
    flex-wrap: nowrap !important; }
  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .sm\:flex-fill {
    flex: 1 1 auto !important; }
  .sm\:flex-grow-0 {
    flex-grow: 0 !important; }
  .sm\:flex-grow-1 {
    flex-grow: 1 !important; }
  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important; }
  .sm\:flex-shrink-1 {
    flex-shrink: 1 !important; }
  .sm\:flex-jc-start {
    justify-content: flex-start !important; }
  .sm\:flex-jc-end {
    justify-content: flex-end !important; }
  .sm\:flex-jc-center {
    justify-content: center !important; }
  .sm\:flex-jc-between {
    justify-content: space-between !important; }
  .sm\:flex-jc-around {
    justify-content: space-around !important; }
  .sm\:flex-jc-evenly {
    justify-content: space-evenly !important; }
  .sm\:flex-ai-start {
    align-items: flex-start !important; }
  .sm\:flex-ai-end {
    align-items: flex-end !important; }
  .sm\:flex-ai-center {
    align-items: center !important; }
  .sm\:flex-ai-baseline {
    align-items: baseline !important; }
  .sm\:flex-ai-stretch {
    align-items: stretch !important; }
  .sm\:flex-ac-start {
    align-content: flex-start !important; }
  .sm\:flex-ac-end {
    align-content: flex-end !important; }
  .sm\:flex-ac-center {
    align-content: center !important; }
  .sm\:flex-ac-between {
    align-content: space-between !important; }
  .sm\:flex-ac-around {
    align-content: space-around !important; }
  .sm\:flex-ac-stretch {
    align-content: stretch !important; }
  .sm\:flex-as-auto {
    align-self: auto !important; }
  .sm\:flex-as-start {
    align-self: flex-start !important; }
  .sm\:flex-as-end {
    align-self: flex-end !important; }
  .sm\:flex-as-center {
    align-self: center !important; }
  .sm\:flex-as-baseline {
    align-self: baseline !important; }
  .sm\:flex-as-stretch {
    align-self: stretch !important; }
  .sm\:flex-order-first {
    order: -9999 !important; }
  .sm\:flex-order-last {
    order: 9999 !important; }
  .sm\:flex-order-none {
    order: 0 !important; }
  .sm\:flex-order-1 {
    order: 1 !important; }
  .sm\:flex-order-2 {
    order: 2 !important; }
  .sm\:flex-order-3 {
    order: 3 !important; }
  .sm\:flex-order-4 {
    order: 4 !important; }
  .sm\:flex-order-5 {
    order: 5 !important; }
  .sm\:flex-order-6 {
    order: 6 !important; }
  .sm\:flex-order-7 {
    order: 7 !important; }
  .sm\:flex-order-8 {
    order: 8 !important; }
  .sm\:flex-order-9 {
    order: 9 !important; }
  .sm\:flex-order-10 {
    order: 10 !important; }
  .sm\:flex-order-11 {
    order: 11 !important; }
  .sm\:flex-order-12 {
    order: 12 !important; } }

@media (min-width: 600px) {
  .sm\+\:flex-row {
    flex-direction: row !important; }
  .sm\+\:flex-column {
    flex-direction: column !important; }
  .sm\+\:flex-row-reverse {
    flex-direction: row-reverse !important; }
  .sm\+\:flex-column-reverse {
    flex-direction: column-reverse !important; }
  .sm\+\:flex-wrap {
    flex-wrap: wrap !important; }
  .sm\+\:flex-nowrap {
    flex-wrap: nowrap !important; }
  .sm\+\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .sm\+\:flex-fill {
    flex: 1 1 auto !important; }
  .sm\+\:flex-grow-0 {
    flex-grow: 0 !important; }
  .sm\+\:flex-grow-1 {
    flex-grow: 1 !important; }
  .sm\+\:flex-shrink-0 {
    flex-shrink: 0 !important; }
  .sm\+\:flex-shrink-1 {
    flex-shrink: 1 !important; }
  .sm\+\:flex-jc-start {
    justify-content: flex-start !important; }
  .sm\+\:flex-jc-end {
    justify-content: flex-end !important; }
  .sm\+\:flex-jc-center {
    justify-content: center !important; }
  .sm\+\:flex-jc-between {
    justify-content: space-between !important; }
  .sm\+\:flex-jc-around {
    justify-content: space-around !important; }
  .sm\+\:flex-jc-evenly {
    justify-content: space-evenly !important; }
  .sm\+\:flex-ai-start {
    align-items: flex-start !important; }
  .sm\+\:flex-ai-end {
    align-items: flex-end !important; }
  .sm\+\:flex-ai-center {
    align-items: center !important; }
  .sm\+\:flex-ai-baseline {
    align-items: baseline !important; }
  .sm\+\:flex-ai-stretch {
    align-items: stretch !important; }
  .sm\+\:flex-ac-start {
    align-content: flex-start !important; }
  .sm\+\:flex-ac-end {
    align-content: flex-end !important; }
  .sm\+\:flex-ac-center {
    align-content: center !important; }
  .sm\+\:flex-ac-between {
    align-content: space-between !important; }
  .sm\+\:flex-ac-around {
    align-content: space-around !important; }
  .sm\+\:flex-ac-stretch {
    align-content: stretch !important; }
  .sm\+\:flex-as-auto {
    align-self: auto !important; }
  .sm\+\:flex-as-start {
    align-self: flex-start !important; }
  .sm\+\:flex-as-end {
    align-self: flex-end !important; }
  .sm\+\:flex-as-center {
    align-self: center !important; }
  .sm\+\:flex-as-baseline {
    align-self: baseline !important; }
  .sm\+\:flex-as-stretch {
    align-self: stretch !important; }
  .sm\+\:flex-order-first {
    order: -9999 !important; }
  .sm\+\:flex-order-last {
    order: 9999 !important; }
  .sm\+\:flex-order-none {
    order: 0 !important; }
  .sm\+\:flex-order-1 {
    order: 1 !important; }
  .sm\+\:flex-order-2 {
    order: 2 !important; }
  .sm\+\:flex-order-3 {
    order: 3 !important; }
  .sm\+\:flex-order-4 {
    order: 4 !important; }
  .sm\+\:flex-order-5 {
    order: 5 !important; }
  .sm\+\:flex-order-6 {
    order: 6 !important; }
  .sm\+\:flex-order-7 {
    order: 7 !important; }
  .sm\+\:flex-order-8 {
    order: 8 !important; }
  .sm\+\:flex-order-9 {
    order: 9 !important; }
  .sm\+\:flex-order-10 {
    order: 10 !important; }
  .sm\+\:flex-order-11 {
    order: 11 !important; }
  .sm\+\:flex-order-12 {
    order: 12 !important; } }

@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row !important; }
  .md\:flex-column {
    flex-direction: column !important; }
  .md\:flex-row-reverse {
    flex-direction: row-reverse !important; }
  .md\:flex-column-reverse {
    flex-direction: column-reverse !important; }
  .md\:flex-wrap {
    flex-wrap: wrap !important; }
  .md\:flex-nowrap {
    flex-wrap: nowrap !important; }
  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .md\:flex-fill {
    flex: 1 1 auto !important; }
  .md\:flex-grow-0 {
    flex-grow: 0 !important; }
  .md\:flex-grow-1 {
    flex-grow: 1 !important; }
  .md\:flex-shrink-0 {
    flex-shrink: 0 !important; }
  .md\:flex-shrink-1 {
    flex-shrink: 1 !important; }
  .md\:flex-jc-start {
    justify-content: flex-start !important; }
  .md\:flex-jc-end {
    justify-content: flex-end !important; }
  .md\:flex-jc-center {
    justify-content: center !important; }
  .md\:flex-jc-between {
    justify-content: space-between !important; }
  .md\:flex-jc-around {
    justify-content: space-around !important; }
  .md\:flex-jc-evenly {
    justify-content: space-evenly !important; }
  .md\:flex-ai-start {
    align-items: flex-start !important; }
  .md\:flex-ai-end {
    align-items: flex-end !important; }
  .md\:flex-ai-center {
    align-items: center !important; }
  .md\:flex-ai-baseline {
    align-items: baseline !important; }
  .md\:flex-ai-stretch {
    align-items: stretch !important; }
  .md\:flex-ac-start {
    align-content: flex-start !important; }
  .md\:flex-ac-end {
    align-content: flex-end !important; }
  .md\:flex-ac-center {
    align-content: center !important; }
  .md\:flex-ac-between {
    align-content: space-between !important; }
  .md\:flex-ac-around {
    align-content: space-around !important; }
  .md\:flex-ac-stretch {
    align-content: stretch !important; }
  .md\:flex-as-auto {
    align-self: auto !important; }
  .md\:flex-as-start {
    align-self: flex-start !important; }
  .md\:flex-as-end {
    align-self: flex-end !important; }
  .md\:flex-as-center {
    align-self: center !important; }
  .md\:flex-as-baseline {
    align-self: baseline !important; }
  .md\:flex-as-stretch {
    align-self: stretch !important; }
  .md\:flex-order-first {
    order: -9999 !important; }
  .md\:flex-order-last {
    order: 9999 !important; }
  .md\:flex-order-none {
    order: 0 !important; }
  .md\:flex-order-1 {
    order: 1 !important; }
  .md\:flex-order-2 {
    order: 2 !important; }
  .md\:flex-order-3 {
    order: 3 !important; }
  .md\:flex-order-4 {
    order: 4 !important; }
  .md\:flex-order-5 {
    order: 5 !important; }
  .md\:flex-order-6 {
    order: 6 !important; }
  .md\:flex-order-7 {
    order: 7 !important; }
  .md\:flex-order-8 {
    order: 8 !important; }
  .md\:flex-order-9 {
    order: 9 !important; }
  .md\:flex-order-10 {
    order: 10 !important; }
  .md\:flex-order-11 {
    order: 11 !important; }
  .md\:flex-order-12 {
    order: 12 !important; } }

@media (min-width: 1024px) {
  .md\+\:flex-row {
    flex-direction: row !important; }
  .md\+\:flex-column {
    flex-direction: column !important; }
  .md\+\:flex-row-reverse {
    flex-direction: row-reverse !important; }
  .md\+\:flex-column-reverse {
    flex-direction: column-reverse !important; }
  .md\+\:flex-wrap {
    flex-wrap: wrap !important; }
  .md\+\:flex-nowrap {
    flex-wrap: nowrap !important; }
  .md\+\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .md\+\:flex-fill {
    flex: 1 1 auto !important; }
  .md\+\:flex-grow-0 {
    flex-grow: 0 !important; }
  .md\+\:flex-grow-1 {
    flex-grow: 1 !important; }
  .md\+\:flex-shrink-0 {
    flex-shrink: 0 !important; }
  .md\+\:flex-shrink-1 {
    flex-shrink: 1 !important; }
  .md\+\:flex-jc-start {
    justify-content: flex-start !important; }
  .md\+\:flex-jc-end {
    justify-content: flex-end !important; }
  .md\+\:flex-jc-center {
    justify-content: center !important; }
  .md\+\:flex-jc-between {
    justify-content: space-between !important; }
  .md\+\:flex-jc-around {
    justify-content: space-around !important; }
  .md\+\:flex-jc-evenly {
    justify-content: space-evenly !important; }
  .md\+\:flex-ai-start {
    align-items: flex-start !important; }
  .md\+\:flex-ai-end {
    align-items: flex-end !important; }
  .md\+\:flex-ai-center {
    align-items: center !important; }
  .md\+\:flex-ai-baseline {
    align-items: baseline !important; }
  .md\+\:flex-ai-stretch {
    align-items: stretch !important; }
  .md\+\:flex-ac-start {
    align-content: flex-start !important; }
  .md\+\:flex-ac-end {
    align-content: flex-end !important; }
  .md\+\:flex-ac-center {
    align-content: center !important; }
  .md\+\:flex-ac-between {
    align-content: space-between !important; }
  .md\+\:flex-ac-around {
    align-content: space-around !important; }
  .md\+\:flex-ac-stretch {
    align-content: stretch !important; }
  .md\+\:flex-as-auto {
    align-self: auto !important; }
  .md\+\:flex-as-start {
    align-self: flex-start !important; }
  .md\+\:flex-as-end {
    align-self: flex-end !important; }
  .md\+\:flex-as-center {
    align-self: center !important; }
  .md\+\:flex-as-baseline {
    align-self: baseline !important; }
  .md\+\:flex-as-stretch {
    align-self: stretch !important; }
  .md\+\:flex-order-first {
    order: -9999 !important; }
  .md\+\:flex-order-last {
    order: 9999 !important; }
  .md\+\:flex-order-none {
    order: 0 !important; }
  .md\+\:flex-order-1 {
    order: 1 !important; }
  .md\+\:flex-order-2 {
    order: 2 !important; }
  .md\+\:flex-order-3 {
    order: 3 !important; }
  .md\+\:flex-order-4 {
    order: 4 !important; }
  .md\+\:flex-order-5 {
    order: 5 !important; }
  .md\+\:flex-order-6 {
    order: 6 !important; }
  .md\+\:flex-order-7 {
    order: 7 !important; }
  .md\+\:flex-order-8 {
    order: 8 !important; }
  .md\+\:flex-order-9 {
    order: 9 !important; }
  .md\+\:flex-order-10 {
    order: 10 !important; }
  .md\+\:flex-order-11 {
    order: 11 !important; }
  .md\+\:flex-order-12 {
    order: 12 !important; } }

@media (min-width: 1440px) {
  .lg\:flex-row {
    flex-direction: row !important; }
  .lg\:flex-column {
    flex-direction: column !important; }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important; }
  .lg\:flex-column-reverse {
    flex-direction: column-reverse !important; }
  .lg\:flex-wrap {
    flex-wrap: wrap !important; }
  .lg\:flex-nowrap {
    flex-wrap: nowrap !important; }
  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .lg\:flex-fill {
    flex: 1 1 auto !important; }
  .lg\:flex-grow-0 {
    flex-grow: 0 !important; }
  .lg\:flex-grow-1 {
    flex-grow: 1 !important; }
  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important; }
  .lg\:flex-shrink-1 {
    flex-shrink: 1 !important; }
  .lg\:flex-jc-start {
    justify-content: flex-start !important; }
  .lg\:flex-jc-end {
    justify-content: flex-end !important; }
  .lg\:flex-jc-center {
    justify-content: center !important; }
  .lg\:flex-jc-between {
    justify-content: space-between !important; }
  .lg\:flex-jc-around {
    justify-content: space-around !important; }
  .lg\:flex-jc-evenly {
    justify-content: space-evenly !important; }
  .lg\:flex-ai-start {
    align-items: flex-start !important; }
  .lg\:flex-ai-end {
    align-items: flex-end !important; }
  .lg\:flex-ai-center {
    align-items: center !important; }
  .lg\:flex-ai-baseline {
    align-items: baseline !important; }
  .lg\:flex-ai-stretch {
    align-items: stretch !important; }
  .lg\:flex-ac-start {
    align-content: flex-start !important; }
  .lg\:flex-ac-end {
    align-content: flex-end !important; }
  .lg\:flex-ac-center {
    align-content: center !important; }
  .lg\:flex-ac-between {
    align-content: space-between !important; }
  .lg\:flex-ac-around {
    align-content: space-around !important; }
  .lg\:flex-ac-stretch {
    align-content: stretch !important; }
  .lg\:flex-as-auto {
    align-self: auto !important; }
  .lg\:flex-as-start {
    align-self: flex-start !important; }
  .lg\:flex-as-end {
    align-self: flex-end !important; }
  .lg\:flex-as-center {
    align-self: center !important; }
  .lg\:flex-as-baseline {
    align-self: baseline !important; }
  .lg\:flex-as-stretch {
    align-self: stretch !important; }
  .lg\:flex-order-first {
    order: -9999 !important; }
  .lg\:flex-order-last {
    order: 9999 !important; }
  .lg\:flex-order-none {
    order: 0 !important; }
  .lg\:flex-order-1 {
    order: 1 !important; }
  .lg\:flex-order-2 {
    order: 2 !important; }
  .lg\:flex-order-3 {
    order: 3 !important; }
  .lg\:flex-order-4 {
    order: 4 !important; }
  .lg\:flex-order-5 {
    order: 5 !important; }
  .lg\:flex-order-6 {
    order: 6 !important; }
  .lg\:flex-order-7 {
    order: 7 !important; }
  .lg\:flex-order-8 {
    order: 8 !important; }
  .lg\:flex-order-9 {
    order: 9 !important; }
  .lg\:flex-order-10 {
    order: 10 !important; }
  .lg\:flex-order-11 {
    order: 11 !important; }
  .lg\:flex-order-12 {
    order: 12 !important; } }

@media (min-width: 1800px) {
  .xl\:flex-row {
    flex-direction: row !important; }
  .xl\:flex-column {
    flex-direction: column !important; }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important; }
  .xl\:flex-column-reverse {
    flex-direction: column-reverse !important; }
  .xl\:flex-wrap {
    flex-wrap: wrap !important; }
  .xl\:flex-nowrap {
    flex-wrap: nowrap !important; }
  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .xl\:flex-fill {
    flex: 1 1 auto !important; }
  .xl\:flex-grow-0 {
    flex-grow: 0 !important; }
  .xl\:flex-grow-1 {
    flex-grow: 1 !important; }
  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important; }
  .xl\:flex-shrink-1 {
    flex-shrink: 1 !important; }
  .xl\:flex-jc-start {
    justify-content: flex-start !important; }
  .xl\:flex-jc-end {
    justify-content: flex-end !important; }
  .xl\:flex-jc-center {
    justify-content: center !important; }
  .xl\:flex-jc-between {
    justify-content: space-between !important; }
  .xl\:flex-jc-around {
    justify-content: space-around !important; }
  .xl\:flex-jc-evenly {
    justify-content: space-evenly !important; }
  .xl\:flex-ai-start {
    align-items: flex-start !important; }
  .xl\:flex-ai-end {
    align-items: flex-end !important; }
  .xl\:flex-ai-center {
    align-items: center !important; }
  .xl\:flex-ai-baseline {
    align-items: baseline !important; }
  .xl\:flex-ai-stretch {
    align-items: stretch !important; }
  .xl\:flex-ac-start {
    align-content: flex-start !important; }
  .xl\:flex-ac-end {
    align-content: flex-end !important; }
  .xl\:flex-ac-center {
    align-content: center !important; }
  .xl\:flex-ac-between {
    align-content: space-between !important; }
  .xl\:flex-ac-around {
    align-content: space-around !important; }
  .xl\:flex-ac-stretch {
    align-content: stretch !important; }
  .xl\:flex-as-auto {
    align-self: auto !important; }
  .xl\:flex-as-start {
    align-self: flex-start !important; }
  .xl\:flex-as-end {
    align-self: flex-end !important; }
  .xl\:flex-as-center {
    align-self: center !important; }
  .xl\:flex-as-baseline {
    align-self: baseline !important; }
  .xl\:flex-as-stretch {
    align-self: stretch !important; }
  .xl\:flex-order-first {
    order: -9999 !important; }
  .xl\:flex-order-last {
    order: 9999 !important; }
  .xl\:flex-order-none {
    order: 0 !important; }
  .xl\:flex-order-1 {
    order: 1 !important; }
  .xl\:flex-order-2 {
    order: 2 !important; }
  .xl\:flex-order-3 {
    order: 3 !important; }
  .xl\:flex-order-4 {
    order: 4 !important; }
  .xl\:flex-order-5 {
    order: 5 !important; }
  .xl\:flex-order-6 {
    order: 6 !important; }
  .xl\:flex-order-7 {
    order: 7 !important; }
  .xl\:flex-order-8 {
    order: 8 !important; }
  .xl\:flex-order-9 {
    order: 9 !important; }
  .xl\:flex-order-10 {
    order: 10 !important; }
  .xl\:flex-order-11 {
    order: 11 !important; }
  .xl\:flex-order-12 {
    order: 12 !important; } }

.float-right {
  float: right !important; }

.float-left {
  float: left !important; }

.float-none {
  float: none !important; }

@media (min-width: 410px) {
  .sm\:float-right {
    float: right !important; }
  .sm\:float-left {
    float: left !important; }
  .sm\:float-none {
    float: none !important; } }

@media (min-width: 600px) {
  .sm\+\:float-right {
    float: right !important; }
  .sm\+\:float-left {
    float: left !important; }
  .sm\+\:float-none {
    float: none !important; } }

@media (min-width: 768px) {
  .md\:float-right {
    float: right !important; }
  .md\:float-left {
    float: left !important; }
  .md\:float-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .md\+\:float-right {
    float: right !important; }
  .md\+\:float-left {
    float: left !important; }
  .md\+\:float-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .lg\:float-right {
    float: right !important; }
  .lg\:float-left {
    float: left !important; }
  .lg\:float-none {
    float: none !important; } }

@media (min-width: 1800px) {
  .xl\:float-right {
    float: right !important; }
  .xl\:float-left {
    float: left !important; }
  .xl\:float-none {
    float: none !important; } }

.cursor-auto {
  cursor: auto !important; }

.cursor-default {
  cursor: default !important; }

.cursor-pointer {
  cursor: pointer !important; }

.cursor-wait {
  cursor: wait !important; }

.cursor-text {
  cursor: text !important; }

.cursor-move {
  cursor: move !important; }

.cursor-not-allowed {
  cursor: not-allowed !important; }

.pointer-events-none {
  pointer-events: none !important; }

.pointer-events-auto {
  pointer-events: auto !important; }

.select-none {
  user-select: none !important; }

.select-text {
  user-select: text !important; }

.select-all {
  user-select: all !important; }

.select-auto {
  user-select: auto !important; }

@media (min-width: 410px) {
  .sm\:cursor-auto {
    cursor: auto !important; }
  .sm\:cursor-default {
    cursor: default !important; }
  .sm\:cursor-pointer {
    cursor: pointer !important; }
  .sm\:cursor-wait {
    cursor: wait !important; }
  .sm\:cursor-text {
    cursor: text !important; }
  .sm\:cursor-move {
    cursor: move !important; }
  .sm\:cursor-not-allowed {
    cursor: not-allowed !important; }
  .sm\:pointer-events-none {
    pointer-events: none !important; }
  .sm\:pointer-events-auto {
    pointer-events: auto !important; }
  .sm\:select-none {
    user-select: none !important; }
  .sm\:select-text {
    user-select: text !important; }
  .sm\:select-all {
    user-select: all !important; }
  .sm\:select-auto {
    user-select: auto !important; } }

@media (min-width: 600px) {
  .sm\+\:cursor-auto {
    cursor: auto !important; }
  .sm\+\:cursor-default {
    cursor: default !important; }
  .sm\+\:cursor-pointer {
    cursor: pointer !important; }
  .sm\+\:cursor-wait {
    cursor: wait !important; }
  .sm\+\:cursor-text {
    cursor: text !important; }
  .sm\+\:cursor-move {
    cursor: move !important; }
  .sm\+\:cursor-not-allowed {
    cursor: not-allowed !important; }
  .sm\+\:pointer-events-none {
    pointer-events: none !important; }
  .sm\+\:pointer-events-auto {
    pointer-events: auto !important; }
  .sm\+\:select-none {
    user-select: none !important; }
  .sm\+\:select-text {
    user-select: text !important; }
  .sm\+\:select-all {
    user-select: all !important; }
  .sm\+\:select-auto {
    user-select: auto !important; } }

@media (min-width: 768px) {
  .md\:cursor-auto {
    cursor: auto !important; }
  .md\:cursor-default {
    cursor: default !important; }
  .md\:cursor-pointer {
    cursor: pointer !important; }
  .md\:cursor-wait {
    cursor: wait !important; }
  .md\:cursor-text {
    cursor: text !important; }
  .md\:cursor-move {
    cursor: move !important; }
  .md\:cursor-not-allowed {
    cursor: not-allowed !important; }
  .md\:pointer-events-none {
    pointer-events: none !important; }
  .md\:pointer-events-auto {
    pointer-events: auto !important; }
  .md\:select-none {
    user-select: none !important; }
  .md\:select-text {
    user-select: text !important; }
  .md\:select-all {
    user-select: all !important; }
  .md\:select-auto {
    user-select: auto !important; } }

@media (min-width: 1024px) {
  .md\+\:cursor-auto {
    cursor: auto !important; }
  .md\+\:cursor-default {
    cursor: default !important; }
  .md\+\:cursor-pointer {
    cursor: pointer !important; }
  .md\+\:cursor-wait {
    cursor: wait !important; }
  .md\+\:cursor-text {
    cursor: text !important; }
  .md\+\:cursor-move {
    cursor: move !important; }
  .md\+\:cursor-not-allowed {
    cursor: not-allowed !important; }
  .md\+\:pointer-events-none {
    pointer-events: none !important; }
  .md\+\:pointer-events-auto {
    pointer-events: auto !important; }
  .md\+\:select-none {
    user-select: none !important; }
  .md\+\:select-text {
    user-select: text !important; }
  .md\+\:select-all {
    user-select: all !important; }
  .md\+\:select-auto {
    user-select: auto !important; } }

@media (min-width: 1440px) {
  .lg\:cursor-auto {
    cursor: auto !important; }
  .lg\:cursor-default {
    cursor: default !important; }
  .lg\:cursor-pointer {
    cursor: pointer !important; }
  .lg\:cursor-wait {
    cursor: wait !important; }
  .lg\:cursor-text {
    cursor: text !important; }
  .lg\:cursor-move {
    cursor: move !important; }
  .lg\:cursor-not-allowed {
    cursor: not-allowed !important; }
  .lg\:pointer-events-none {
    pointer-events: none !important; }
  .lg\:pointer-events-auto {
    pointer-events: auto !important; }
  .lg\:select-none {
    user-select: none !important; }
  .lg\:select-text {
    user-select: text !important; }
  .lg\:select-all {
    user-select: all !important; }
  .lg\:select-auto {
    user-select: auto !important; } }

@media (min-width: 1800px) {
  .xl\:cursor-auto {
    cursor: auto !important; }
  .xl\:cursor-default {
    cursor: default !important; }
  .xl\:cursor-pointer {
    cursor: pointer !important; }
  .xl\:cursor-wait {
    cursor: wait !important; }
  .xl\:cursor-text {
    cursor: text !important; }
  .xl\:cursor-move {
    cursor: move !important; }
  .xl\:cursor-not-allowed {
    cursor: not-allowed !important; }
  .xl\:pointer-events-none {
    pointer-events: none !important; }
  .xl\:pointer-events-auto {
    pointer-events: auto !important; }
  .xl\:select-none {
    user-select: none !important; }
  .xl\:select-text {
    user-select: text !important; }
  .xl\:select-all {
    user-select: all !important; }
  .xl\:select-auto {
    user-select: auto !important; } }

.opacity-0 {
  opacity: 0 !important; }

.opacity-10 {
  opacity: 0.1 !important; }

.opacity-20 {
  opacity: 0.2 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-30 {
  opacity: 0.3 !important; }

.opacity-40 {
  opacity: 0.4 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-60 {
  opacity: 0.6 !important; }

.opacity-70 {
  opacity: 0.7 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-80 {
  opacity: 0.8 !important; }

.opacity-90 {
  opacity: 0.9 !important; }

.opacity-100 {
  opacity: 1 !important; }

@media (min-width: 410px) {
  .sm\:opacity-0 {
    opacity: 0 !important; }
  .sm\:opacity-10 {
    opacity: 0.1 !important; }
  .sm\:opacity-20 {
    opacity: 0.2 !important; }
  .sm\:opacity-25 {
    opacity: 0.25 !important; }
  .sm\:opacity-30 {
    opacity: 0.3 !important; }
  .sm\:opacity-40 {
    opacity: 0.4 !important; }
  .sm\:opacity-50 {
    opacity: 0.5 !important; }
  .sm\:opacity-60 {
    opacity: 0.6 !important; }
  .sm\:opacity-70 {
    opacity: 0.7 !important; }
  .sm\:opacity-75 {
    opacity: 0.75 !important; }
  .sm\:opacity-80 {
    opacity: 0.8 !important; }
  .sm\:opacity-90 {
    opacity: 0.9 !important; }
  .sm\:opacity-100 {
    opacity: 1 !important; } }

@media (min-width: 600px) {
  .sm\+\:opacity-0 {
    opacity: 0 !important; }
  .sm\+\:opacity-10 {
    opacity: 0.1 !important; }
  .sm\+\:opacity-20 {
    opacity: 0.2 !important; }
  .sm\+\:opacity-25 {
    opacity: 0.25 !important; }
  .sm\+\:opacity-30 {
    opacity: 0.3 !important; }
  .sm\+\:opacity-40 {
    opacity: 0.4 !important; }
  .sm\+\:opacity-50 {
    opacity: 0.5 !important; }
  .sm\+\:opacity-60 {
    opacity: 0.6 !important; }
  .sm\+\:opacity-70 {
    opacity: 0.7 !important; }
  .sm\+\:opacity-75 {
    opacity: 0.75 !important; }
  .sm\+\:opacity-80 {
    opacity: 0.8 !important; }
  .sm\+\:opacity-90 {
    opacity: 0.9 !important; }
  .sm\+\:opacity-100 {
    opacity: 1 !important; } }

@media (min-width: 768px) {
  .md\:opacity-0 {
    opacity: 0 !important; }
  .md\:opacity-10 {
    opacity: 0.1 !important; }
  .md\:opacity-20 {
    opacity: 0.2 !important; }
  .md\:opacity-25 {
    opacity: 0.25 !important; }
  .md\:opacity-30 {
    opacity: 0.3 !important; }
  .md\:opacity-40 {
    opacity: 0.4 !important; }
  .md\:opacity-50 {
    opacity: 0.5 !important; }
  .md\:opacity-60 {
    opacity: 0.6 !important; }
  .md\:opacity-70 {
    opacity: 0.7 !important; }
  .md\:opacity-75 {
    opacity: 0.75 !important; }
  .md\:opacity-80 {
    opacity: 0.8 !important; }
  .md\:opacity-90 {
    opacity: 0.9 !important; }
  .md\:opacity-100 {
    opacity: 1 !important; } }

@media (min-width: 1024px) {
  .md\+\:opacity-0 {
    opacity: 0 !important; }
  .md\+\:opacity-10 {
    opacity: 0.1 !important; }
  .md\+\:opacity-20 {
    opacity: 0.2 !important; }
  .md\+\:opacity-25 {
    opacity: 0.25 !important; }
  .md\+\:opacity-30 {
    opacity: 0.3 !important; }
  .md\+\:opacity-40 {
    opacity: 0.4 !important; }
  .md\+\:opacity-50 {
    opacity: 0.5 !important; }
  .md\+\:opacity-60 {
    opacity: 0.6 !important; }
  .md\+\:opacity-70 {
    opacity: 0.7 !important; }
  .md\+\:opacity-75 {
    opacity: 0.75 !important; }
  .md\+\:opacity-80 {
    opacity: 0.8 !important; }
  .md\+\:opacity-90 {
    opacity: 0.9 !important; }
  .md\+\:opacity-100 {
    opacity: 1 !important; } }

@media (min-width: 1440px) {
  .lg\:opacity-0 {
    opacity: 0 !important; }
  .lg\:opacity-10 {
    opacity: 0.1 !important; }
  .lg\:opacity-20 {
    opacity: 0.2 !important; }
  .lg\:opacity-25 {
    opacity: 0.25 !important; }
  .lg\:opacity-30 {
    opacity: 0.3 !important; }
  .lg\:opacity-40 {
    opacity: 0.4 !important; }
  .lg\:opacity-50 {
    opacity: 0.5 !important; }
  .lg\:opacity-60 {
    opacity: 0.6 !important; }
  .lg\:opacity-70 {
    opacity: 0.7 !important; }
  .lg\:opacity-75 {
    opacity: 0.75 !important; }
  .lg\:opacity-80 {
    opacity: 0.8 !important; }
  .lg\:opacity-90 {
    opacity: 0.9 !important; }
  .lg\:opacity-100 {
    opacity: 1 !important; } }

@media (min-width: 1800px) {
  .xl\:opacity-0 {
    opacity: 0 !important; }
  .xl\:opacity-10 {
    opacity: 0.1 !important; }
  .xl\:opacity-20 {
    opacity: 0.2 !important; }
  .xl\:opacity-25 {
    opacity: 0.25 !important; }
  .xl\:opacity-30 {
    opacity: 0.3 !important; }
  .xl\:opacity-40 {
    opacity: 0.4 !important; }
  .xl\:opacity-50 {
    opacity: 0.5 !important; }
  .xl\:opacity-60 {
    opacity: 0.6 !important; }
  .xl\:opacity-70 {
    opacity: 0.7 !important; }
  .xl\:opacity-75 {
    opacity: 0.75 !important; }
  .xl\:opacity-80 {
    opacity: 0.8 !important; }
  .xl\:opacity-90 {
    opacity: 0.9 !important; }
  .xl\:opacity-100 {
    opacity: 1 !important; } }

.static {
  position: static !important; }

.fixed {
  position: fixed !important; }

.absolute {
  position: absolute !important; }

.relative {
  position: relative !important; }

.sticky {
  position: sticky !important; }

@media (min-width: 410px) {
  .sm\:static {
    position: static !important; }
  .sm\:fixed {
    position: fixed !important; }
  .sm\:absolute {
    position: absolute !important; }
  .sm\:relative {
    position: relative !important; }
  .sm\:sticky {
    position: sticky !important; } }

@media (min-width: 600px) {
  .sm\+\:static {
    position: static !important; }
  .sm\+\:fixed {
    position: fixed !important; }
  .sm\+\:absolute {
    position: absolute !important; }
  .sm\+\:relative {
    position: relative !important; }
  .sm\+\:sticky {
    position: sticky !important; } }

@media (min-width: 768px) {
  .md\:static {
    position: static !important; }
  .md\:fixed {
    position: fixed !important; }
  .md\:absolute {
    position: absolute !important; }
  .md\:relative {
    position: relative !important; }
  .md\:sticky {
    position: sticky !important; } }

@media (min-width: 1024px) {
  .md\+\:static {
    position: static !important; }
  .md\+\:fixed {
    position: fixed !important; }
  .md\+\:absolute {
    position: absolute !important; }
  .md\+\:relative {
    position: relative !important; }
  .md\+\:sticky {
    position: sticky !important; } }

@media (min-width: 1440px) {
  .lg\:static {
    position: static !important; }
  .lg\:fixed {
    position: fixed !important; }
  .lg\:absolute {
    position: absolute !important; }
  .lg\:relative {
    position: relative !important; }
  .lg\:sticky {
    position: sticky !important; } }

@media (min-width: 1800px) {
  .xl\:static {
    position: static !important; }
  .xl\:fixed {
    position: fixed !important; }
  .xl\:absolute {
    position: absolute !important; }
  .xl\:relative {
    position: relative !important; }
  .xl\:sticky {
    position: sticky !important; } }

.z-n1 {
  z-index: -1 !important; }

.z-0 {
  z-index: 0 !important; }

.z-1 {
  z-index: 1 !important; }

.z-10 {
  z-index: 10 !important; }

.z-20 {
  z-index: 20 !important; }

.z-30 {
  z-index: 30 !important; }

.z-40 {
  z-index: 40 !important; }

.z-50 {
  z-index: 50 !important; }

.z-60 {
  z-index: 60 !important; }

.z-70 {
  z-index: 70 !important; }

.z-80 {
  z-index: 80 !important; }

.z-90 {
  z-index: 90 !important; }

.z-100 {
  z-index: 100 !important; }

.z-1k {
  z-index: 1000 !important; }

.z-2k {
  z-index: 2000 !important; }

.z-3k {
  z-index: 3000 !important; }

.z-4k {
  z-index: 4000 !important; }

.z-5k {
  z-index: 5000 !important; }

.z-auto {
  z-index: auto !important; }

@media (min-width: 410px) {
  .sm\:z-n1 {
    z-index: -1 !important; }
  .sm\:z-0 {
    z-index: 0 !important; }
  .sm\:z-1 {
    z-index: 1 !important; }
  .sm\:z-10 {
    z-index: 10 !important; }
  .sm\:z-20 {
    z-index: 20 !important; }
  .sm\:z-30 {
    z-index: 30 !important; }
  .sm\:z-40 {
    z-index: 40 !important; }
  .sm\:z-50 {
    z-index: 50 !important; }
  .sm\:z-60 {
    z-index: 60 !important; }
  .sm\:z-70 {
    z-index: 70 !important; }
  .sm\:z-80 {
    z-index: 80 !important; }
  .sm\:z-90 {
    z-index: 90 !important; }
  .sm\:z-100 {
    z-index: 100 !important; }
  .sm\:z-1k {
    z-index: 1000 !important; }
  .sm\:z-2k {
    z-index: 2000 !important; }
  .sm\:z-3k {
    z-index: 3000 !important; }
  .sm\:z-4k {
    z-index: 4000 !important; }
  .sm\:z-5k {
    z-index: 5000 !important; }
  .sm\:z-auto {
    z-index: auto !important; } }

@media (min-width: 600px) {
  .sm\+\:z-n1 {
    z-index: -1 !important; }
  .sm\+\:z-0 {
    z-index: 0 !important; }
  .sm\+\:z-1 {
    z-index: 1 !important; }
  .sm\+\:z-10 {
    z-index: 10 !important; }
  .sm\+\:z-20 {
    z-index: 20 !important; }
  .sm\+\:z-30 {
    z-index: 30 !important; }
  .sm\+\:z-40 {
    z-index: 40 !important; }
  .sm\+\:z-50 {
    z-index: 50 !important; }
  .sm\+\:z-60 {
    z-index: 60 !important; }
  .sm\+\:z-70 {
    z-index: 70 !important; }
  .sm\+\:z-80 {
    z-index: 80 !important; }
  .sm\+\:z-90 {
    z-index: 90 !important; }
  .sm\+\:z-100 {
    z-index: 100 !important; }
  .sm\+\:z-1k {
    z-index: 1000 !important; }
  .sm\+\:z-2k {
    z-index: 2000 !important; }
  .sm\+\:z-3k {
    z-index: 3000 !important; }
  .sm\+\:z-4k {
    z-index: 4000 !important; }
  .sm\+\:z-5k {
    z-index: 5000 !important; }
  .sm\+\:z-auto {
    z-index: auto !important; } }

@media (min-width: 768px) {
  .md\:z-n1 {
    z-index: -1 !important; }
  .md\:z-0 {
    z-index: 0 !important; }
  .md\:z-1 {
    z-index: 1 !important; }
  .md\:z-10 {
    z-index: 10 !important; }
  .md\:z-20 {
    z-index: 20 !important; }
  .md\:z-30 {
    z-index: 30 !important; }
  .md\:z-40 {
    z-index: 40 !important; }
  .md\:z-50 {
    z-index: 50 !important; }
  .md\:z-60 {
    z-index: 60 !important; }
  .md\:z-70 {
    z-index: 70 !important; }
  .md\:z-80 {
    z-index: 80 !important; }
  .md\:z-90 {
    z-index: 90 !important; }
  .md\:z-100 {
    z-index: 100 !important; }
  .md\:z-1k {
    z-index: 1000 !important; }
  .md\:z-2k {
    z-index: 2000 !important; }
  .md\:z-3k {
    z-index: 3000 !important; }
  .md\:z-4k {
    z-index: 4000 !important; }
  .md\:z-5k {
    z-index: 5000 !important; }
  .md\:z-auto {
    z-index: auto !important; } }

@media (min-width: 1024px) {
  .md\+\:z-n1 {
    z-index: -1 !important; }
  .md\+\:z-0 {
    z-index: 0 !important; }
  .md\+\:z-1 {
    z-index: 1 !important; }
  .md\+\:z-10 {
    z-index: 10 !important; }
  .md\+\:z-20 {
    z-index: 20 !important; }
  .md\+\:z-30 {
    z-index: 30 !important; }
  .md\+\:z-40 {
    z-index: 40 !important; }
  .md\+\:z-50 {
    z-index: 50 !important; }
  .md\+\:z-60 {
    z-index: 60 !important; }
  .md\+\:z-70 {
    z-index: 70 !important; }
  .md\+\:z-80 {
    z-index: 80 !important; }
  .md\+\:z-90 {
    z-index: 90 !important; }
  .md\+\:z-100 {
    z-index: 100 !important; }
  .md\+\:z-1k {
    z-index: 1000 !important; }
  .md\+\:z-2k {
    z-index: 2000 !important; }
  .md\+\:z-3k {
    z-index: 3000 !important; }
  .md\+\:z-4k {
    z-index: 4000 !important; }
  .md\+\:z-5k {
    z-index: 5000 !important; }
  .md\+\:z-auto {
    z-index: auto !important; } }

@media (min-width: 1440px) {
  .lg\:z-n1 {
    z-index: -1 !important; }
  .lg\:z-0 {
    z-index: 0 !important; }
  .lg\:z-1 {
    z-index: 1 !important; }
  .lg\:z-10 {
    z-index: 10 !important; }
  .lg\:z-20 {
    z-index: 20 !important; }
  .lg\:z-30 {
    z-index: 30 !important; }
  .lg\:z-40 {
    z-index: 40 !important; }
  .lg\:z-50 {
    z-index: 50 !important; }
  .lg\:z-60 {
    z-index: 60 !important; }
  .lg\:z-70 {
    z-index: 70 !important; }
  .lg\:z-80 {
    z-index: 80 !important; }
  .lg\:z-90 {
    z-index: 90 !important; }
  .lg\:z-100 {
    z-index: 100 !important; }
  .lg\:z-1k {
    z-index: 1000 !important; }
  .lg\:z-2k {
    z-index: 2000 !important; }
  .lg\:z-3k {
    z-index: 3000 !important; }
  .lg\:z-4k {
    z-index: 4000 !important; }
  .lg\:z-5k {
    z-index: 5000 !important; }
  .lg\:z-auto {
    z-index: auto !important; } }

@media (min-width: 1800px) {
  .xl\:z-n1 {
    z-index: -1 !important; }
  .xl\:z-0 {
    z-index: 0 !important; }
  .xl\:z-1 {
    z-index: 1 !important; }
  .xl\:z-10 {
    z-index: 10 !important; }
  .xl\:z-20 {
    z-index: 20 !important; }
  .xl\:z-30 {
    z-index: 30 !important; }
  .xl\:z-40 {
    z-index: 40 !important; }
  .xl\:z-50 {
    z-index: 50 !important; }
  .xl\:z-60 {
    z-index: 60 !important; }
  .xl\:z-70 {
    z-index: 70 !important; }
  .xl\:z-80 {
    z-index: 80 !important; }
  .xl\:z-90 {
    z-index: 90 !important; }
  .xl\:z-100 {
    z-index: 100 !important; }
  .xl\:z-1k {
    z-index: 1000 !important; }
  .xl\:z-2k {
    z-index: 2000 !important; }
  .xl\:z-3k {
    z-index: 3000 !important; }
  .xl\:z-4k {
    z-index: 4000 !important; }
  .xl\:z-5k {
    z-index: 5000 !important; }
  .xl\:z-auto {
    z-index: auto !important; } }

.inset-0 {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important; }

.inset-y-0 {
  top: 0 !important;
  bottom: 0 !important; }

.inset-x-0 {
  right: 0 !important;
  left: 0 !important; }

.top-0 {
  top: 0 !important; }

.right-0 {
  right: 0 !important; }

.bottom-0 {
  bottom: 0 !important; }

.left-0 {
  left: 0 !important; }

.inset-auto {
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important; }

.inset-y-auto {
  top: auto !important;
  bottom: auto !important; }

.inset-x-auto {
  left: auto !important;
  right: auto !important; }

.top-auto {
  top: auto !important; }

.bottom-auto {
  bottom: auto !important; }

.left-auto {
  left: auto !important; }

.right-auto {
  right: auto !important; }

@media (min-width: 410px) {
  .sm\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important; }
  .sm\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important; }
  .sm\:inset-x-0 {
    right: 0 !important;
    left: 0 !important; }
  .sm\:top-0 {
    top: 0 !important; }
  .sm\:right-0 {
    right: 0 !important; }
  .sm\:bottom-0 {
    bottom: 0 !important; }
  .sm\:left-0 {
    left: 0 !important; }
  .sm\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important; }
  .sm\:inset-y-auto {
    top: auto !important;
    bottom: auto !important; }
  .sm\:inset-x-auto {
    left: auto !important;
    right: auto !important; }
  .sm\:top-auto {
    top: auto !important; }
  .sm\:bottom-auto {
    bottom: auto !important; }
  .sm\:left-auto {
    left: auto !important; }
  .sm\:right-auto {
    right: auto !important; } }

@media (min-width: 600px) {
  .sm\+\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important; }
  .sm\+\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important; }
  .sm\+\:inset-x-0 {
    right: 0 !important;
    left: 0 !important; }
  .sm\+\:top-0 {
    top: 0 !important; }
  .sm\+\:right-0 {
    right: 0 !important; }
  .sm\+\:bottom-0 {
    bottom: 0 !important; }
  .sm\+\:left-0 {
    left: 0 !important; }
  .sm\+\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important; }
  .sm\+\:inset-y-auto {
    top: auto !important;
    bottom: auto !important; }
  .sm\+\:inset-x-auto {
    left: auto !important;
    right: auto !important; }
  .sm\+\:top-auto {
    top: auto !important; }
  .sm\+\:bottom-auto {
    bottom: auto !important; }
  .sm\+\:left-auto {
    left: auto !important; }
  .sm\+\:right-auto {
    right: auto !important; } }

@media (min-width: 768px) {
  .md\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important; }
  .md\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important; }
  .md\:inset-x-0 {
    right: 0 !important;
    left: 0 !important; }
  .md\:top-0 {
    top: 0 !important; }
  .md\:right-0 {
    right: 0 !important; }
  .md\:bottom-0 {
    bottom: 0 !important; }
  .md\:left-0 {
    left: 0 !important; }
  .md\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important; }
  .md\:inset-y-auto {
    top: auto !important;
    bottom: auto !important; }
  .md\:inset-x-auto {
    left: auto !important;
    right: auto !important; }
  .md\:top-auto {
    top: auto !important; }
  .md\:bottom-auto {
    bottom: auto !important; }
  .md\:left-auto {
    left: auto !important; }
  .md\:right-auto {
    right: auto !important; } }

@media (min-width: 1024px) {
  .md\+\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important; }
  .md\+\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important; }
  .md\+\:inset-x-0 {
    right: 0 !important;
    left: 0 !important; }
  .md\+\:top-0 {
    top: 0 !important; }
  .md\+\:right-0 {
    right: 0 !important; }
  .md\+\:bottom-0 {
    bottom: 0 !important; }
  .md\+\:left-0 {
    left: 0 !important; }
  .md\+\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important; }
  .md\+\:inset-y-auto {
    top: auto !important;
    bottom: auto !important; }
  .md\+\:inset-x-auto {
    left: auto !important;
    right: auto !important; }
  .md\+\:top-auto {
    top: auto !important; }
  .md\+\:bottom-auto {
    bottom: auto !important; }
  .md\+\:left-auto {
    left: auto !important; }
  .md\+\:right-auto {
    right: auto !important; } }

@media (min-width: 1440px) {
  .lg\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important; }
  .lg\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important; }
  .lg\:inset-x-0 {
    right: 0 !important;
    left: 0 !important; }
  .lg\:top-0 {
    top: 0 !important; }
  .lg\:right-0 {
    right: 0 !important; }
  .lg\:bottom-0 {
    bottom: 0 !important; }
  .lg\:left-0 {
    left: 0 !important; }
  .lg\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important; }
  .lg\:inset-y-auto {
    top: auto !important;
    bottom: auto !important; }
  .lg\:inset-x-auto {
    left: auto !important;
    right: auto !important; }
  .lg\:top-auto {
    top: auto !important; }
  .lg\:bottom-auto {
    bottom: auto !important; }
  .lg\:left-auto {
    left: auto !important; }
  .lg\:right-auto {
    right: auto !important; } }

@media (min-width: 1800px) {
  .xl\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important; }
  .xl\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important; }
  .xl\:inset-x-0 {
    right: 0 !important;
    left: 0 !important; }
  .xl\:top-0 {
    top: 0 !important; }
  .xl\:right-0 {
    right: 0 !important; }
  .xl\:bottom-0 {
    bottom: 0 !important; }
  .xl\:left-0 {
    left: 0 !important; }
  .xl\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important; }
  .xl\:inset-y-auto {
    top: auto !important;
    bottom: auto !important; }
  .xl\:inset-x-auto {
    left: auto !important;
    right: auto !important; }
  .xl\:top-auto {
    top: auto !important; }
  .xl\:bottom-auto {
    bottom: auto !important; }
  .xl\:left-auto {
    left: auto !important; }
  .xl\:right-auto {
    right: auto !important; } }

.m0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.mt-n0 {
  margin-top: 0 !important; }

.mr-n0 {
  margin-right: 0 !important; }

.mb-n0 {
  margin-bottom: 0 !important; }

.ml-n0 {
  margin-left: 0 !important; }

.mx-n0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.my-n0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.p0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.m1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mr-n1 {
  margin-right: -0.25rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1 {
  margin-left: -0.25rem !important; }

.mx-n1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.p1 {
  padding: 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.m2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mr-n2 {
  margin-right: -0.5rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2 {
  margin-left: -0.5rem !important; }

.mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.p2 {
  padding: 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.m3 {
  margin: 0.75rem !important; }

.mt-3 {
  margin-top: 0.75rem !important; }

.mr-3 {
  margin-right: 0.75rem !important; }

.mb-3 {
  margin-bottom: 0.75rem !important; }

.ml-3 {
  margin-left: 0.75rem !important; }

.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important; }

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important; }

.mt-n3 {
  margin-top: -0.75rem !important; }

.mr-n3 {
  margin-right: -0.75rem !important; }

.mb-n3 {
  margin-bottom: -0.75rem !important; }

.ml-n3 {
  margin-left: -0.75rem !important; }

.mx-n3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important; }

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important; }

.p3 {
  padding: 0.75rem !important; }

.pt-3 {
  padding-top: 0.75rem !important; }

.pr-3 {
  padding-right: 0.75rem !important; }

.pb-3 {
  padding-bottom: 0.75rem !important; }

.pl-3 {
  padding-left: 0.75rem !important; }

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important; }

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important; }

.m4 {
  margin: 1rem !important; }

.mt-4 {
  margin-top: 1rem !important; }

.mr-4 {
  margin-right: 1rem !important; }

.mb-4 {
  margin-bottom: 1rem !important; }

.ml-4 {
  margin-left: 1rem !important; }

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important; }

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.mt-n4 {
  margin-top: -1rem !important; }

.mr-n4 {
  margin-right: -1rem !important; }

.mb-n4 {
  margin-bottom: -1rem !important; }

.ml-n4 {
  margin-left: -1rem !important; }

.mx-n4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important; }

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.p4 {
  padding: 1rem !important; }

.pt-4 {
  padding-top: 1rem !important; }

.pr-4 {
  padding-right: 1rem !important; }

.pb-4 {
  padding-bottom: 1rem !important; }

.pl-4 {
  padding-left: 1rem !important; }

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important; }

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.m5 {
  margin: 1.25rem !important; }

.mt-5 {
  margin-top: 1.25rem !important; }

.mr-5 {
  margin-right: 1.25rem !important; }

.mb-5 {
  margin-bottom: 1.25rem !important; }

.ml-5 {
  margin-left: 1.25rem !important; }

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important; }

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important; }

.mt-n5 {
  margin-top: -1.25rem !important; }

.mr-n5 {
  margin-right: -1.25rem !important; }

.mb-n5 {
  margin-bottom: -1.25rem !important; }

.ml-n5 {
  margin-left: -1.25rem !important; }

.mx-n5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important; }

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important; }

.p5 {
  padding: 1.25rem !important; }

.pt-5 {
  padding-top: 1.25rem !important; }

.pr-5 {
  padding-right: 1.25rem !important; }

.pb-5 {
  padding-bottom: 1.25rem !important; }

.pl-5 {
  padding-left: 1.25rem !important; }

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important; }

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important; }

.m6 {
  margin: 1.5rem !important; }

.mt-6 {
  margin-top: 1.5rem !important; }

.mr-6 {
  margin-right: 1.5rem !important; }

.mb-6 {
  margin-bottom: 1.5rem !important; }

.ml-6 {
  margin-left: 1.5rem !important; }

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important; }

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.mt-n6 {
  margin-top: -1.5rem !important; }

.mr-n6 {
  margin-right: -1.5rem !important; }

.mb-n6 {
  margin-bottom: -1.5rem !important; }

.ml-n6 {
  margin-left: -1.5rem !important; }

.mx-n6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important; }

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.p6 {
  padding: 1.5rem !important; }

.pt-6 {
  padding-top: 1.5rem !important; }

.pr-6 {
  padding-right: 1.5rem !important; }

.pb-6 {
  padding-bottom: 1.5rem !important; }

.pl-6 {
  padding-left: 1.5rem !important; }

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important; }

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.m8 {
  margin: 2rem !important; }

.mt-8 {
  margin-top: 2rem !important; }

.mr-8 {
  margin-right: 2rem !important; }

.mb-8 {
  margin-bottom: 2rem !important; }

.ml-8 {
  margin-left: 2rem !important; }

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important; }

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.mt-n8 {
  margin-top: -2rem !important; }

.mr-n8 {
  margin-right: -2rem !important; }

.mb-n8 {
  margin-bottom: -2rem !important; }

.ml-n8 {
  margin-left: -2rem !important; }

.mx-n8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important; }

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important; }

.p8 {
  padding: 2rem !important; }

.pt-8 {
  padding-top: 2rem !important; }

.pr-8 {
  padding-right: 2rem !important; }

.pb-8 {
  padding-bottom: 2rem !important; }

.pl-8 {
  padding-left: 2rem !important; }

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important; }

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.m10 {
  margin: 2.5rem !important; }

.mt-10 {
  margin-top: 2.5rem !important; }

.mr-10 {
  margin-right: 2.5rem !important; }

.mb-10 {
  margin-bottom: 2.5rem !important; }

.ml-10 {
  margin-left: 2.5rem !important; }

.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important; }

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.mt-n10 {
  margin-top: -2.5rem !important; }

.mr-n10 {
  margin-right: -2.5rem !important; }

.mb-n10 {
  margin-bottom: -2.5rem !important; }

.ml-n10 {
  margin-left: -2.5rem !important; }

.mx-n10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important; }

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important; }

.p10 {
  padding: 2.5rem !important; }

.pt-10 {
  padding-top: 2.5rem !important; }

.pr-10 {
  padding-right: 2.5rem !important; }

.pb-10 {
  padding-bottom: 2.5rem !important; }

.pl-10 {
  padding-left: 2.5rem !important; }

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important; }

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.m12 {
  margin: 3rem !important; }

.mt-12 {
  margin-top: 3rem !important; }

.mr-12 {
  margin-right: 3rem !important; }

.mb-12 {
  margin-bottom: 3rem !important; }

.ml-12 {
  margin-left: 3rem !important; }

.mx-12 {
  margin-left: 3rem !important;
  margin-right: 3rem !important; }

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.mt-n12 {
  margin-top: -3rem !important; }

.mr-n12 {
  margin-right: -3rem !important; }

.mb-n12 {
  margin-bottom: -3rem !important; }

.ml-n12 {
  margin-left: -3rem !important; }

.mx-n12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important; }

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.p12 {
  padding: 3rem !important; }

.pt-12 {
  padding-top: 3rem !important; }

.pr-12 {
  padding-right: 3rem !important; }

.pb-12 {
  padding-bottom: 3rem !important; }

.pl-12 {
  padding-left: 3rem !important; }

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important; }

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.m16 {
  margin: 4rem !important; }

.mt-16 {
  margin-top: 4rem !important; }

.mr-16 {
  margin-right: 4rem !important; }

.mb-16 {
  margin-bottom: 4rem !important; }

.ml-16 {
  margin-left: 4rem !important; }

.mx-16 {
  margin-left: 4rem !important;
  margin-right: 4rem !important; }

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.mt-n16 {
  margin-top: -4rem !important; }

.mr-n16 {
  margin-right: -4rem !important; }

.mb-n16 {
  margin-bottom: -4rem !important; }

.ml-n16 {
  margin-left: -4rem !important; }

.mx-n16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important; }

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important; }

.p16 {
  padding: 4rem !important; }

.pt-16 {
  padding-top: 4rem !important; }

.pr-16 {
  padding-right: 4rem !important; }

.pb-16 {
  padding-bottom: 4rem !important; }

.pl-16 {
  padding-left: 4rem !important; }

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important; }

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.m20 {
  margin: 5rem !important; }

.mt-20 {
  margin-top: 5rem !important; }

.mr-20 {
  margin-right: 5rem !important; }

.mb-20 {
  margin-bottom: 5rem !important; }

.ml-20 {
  margin-left: 5rem !important; }

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important; }

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.mt-n20 {
  margin-top: -5rem !important; }

.mr-n20 {
  margin-right: -5rem !important; }

.mb-n20 {
  margin-bottom: -5rem !important; }

.ml-n20 {
  margin-left: -5rem !important; }

.mx-n20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important; }

.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important; }

.p20 {
  padding: 5rem !important; }

.pt-20 {
  padding-top: 5rem !important; }

.pr-20 {
  padding-right: 5rem !important; }

.pb-20 {
  padding-bottom: 5rem !important; }

.pl-20 {
  padding-left: 5rem !important; }

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important; }

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.m24 {
  margin: 6rem !important; }

.mt-24 {
  margin-top: 6rem !important; }

.mr-24 {
  margin-right: 6rem !important; }

.mb-24 {
  margin-bottom: 6rem !important; }

.ml-24 {
  margin-left: 6rem !important; }

.mx-24 {
  margin-left: 6rem !important;
  margin-right: 6rem !important; }

.my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.mt-n24 {
  margin-top: -6rem !important; }

.mr-n24 {
  margin-right: -6rem !important; }

.mb-n24 {
  margin-bottom: -6rem !important; }

.ml-n24 {
  margin-left: -6rem !important; }

.mx-n24 {
  margin-left: -6rem !important;
  margin-right: -6rem !important; }

.my-n24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important; }

.p24 {
  padding: 6rem !important; }

.pt-24 {
  padding-top: 6rem !important; }

.pr-24 {
  padding-right: 6rem !important; }

.pb-24 {
  padding-bottom: 6rem !important; }

.pl-24 {
  padding-left: 6rem !important; }

.px-24 {
  padding-left: 6rem !important;
  padding-right: 6rem !important; }

.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.m32 {
  margin: 8rem !important; }

.mt-32 {
  margin-top: 8rem !important; }

.mr-32 {
  margin-right: 8rem !important; }

.mb-32 {
  margin-bottom: 8rem !important; }

.ml-32 {
  margin-left: 8rem !important; }

.mx-32 {
  margin-left: 8rem !important;
  margin-right: 8rem !important; }

.my-32 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important; }

.mt-n32 {
  margin-top: -8rem !important; }

.mr-n32 {
  margin-right: -8rem !important; }

.mb-n32 {
  margin-bottom: -8rem !important; }

.ml-n32 {
  margin-left: -8rem !important; }

.mx-n32 {
  margin-left: -8rem !important;
  margin-right: -8rem !important; }

.my-n32 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important; }

.p32 {
  padding: 8rem !important; }

.pt-32 {
  padding-top: 8rem !important; }

.pr-32 {
  padding-right: 8rem !important; }

.pb-32 {
  padding-bottom: 8rem !important; }

.pl-32 {
  padding-left: 8rem !important; }

.px-32 {
  padding-left: 8rem !important;
  padding-right: 8rem !important; }

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important; }

.m40 {
  margin: 10rem !important; }

.mt-40 {
  margin-top: 10rem !important; }

.mr-40 {
  margin-right: 10rem !important; }

.mb-40 {
  margin-bottom: 10rem !important; }

.ml-40 {
  margin-left: 10rem !important; }

.mx-40 {
  margin-left: 10rem !important;
  margin-right: 10rem !important; }

.my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important; }

.mt-n40 {
  margin-top: -10rem !important; }

.mr-n40 {
  margin-right: -10rem !important; }

.mb-n40 {
  margin-bottom: -10rem !important; }

.ml-n40 {
  margin-left: -10rem !important; }

.mx-n40 {
  margin-left: -10rem !important;
  margin-right: -10rem !important; }

.my-n40 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important; }

.p40 {
  padding: 10rem !important; }

.pt-40 {
  padding-top: 10rem !important; }

.pr-40 {
  padding-right: 10rem !important; }

.pb-40 {
  padding-bottom: 10rem !important; }

.pl-40 {
  padding-left: 10rem !important; }

.px-40 {
  padding-left: 10rem !important;
  padding-right: 10rem !important; }

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important; }

.m48 {
  margin: 12rem !important; }

.mt-48 {
  margin-top: 12rem !important; }

.mr-48 {
  margin-right: 12rem !important; }

.mb-48 {
  margin-bottom: 12rem !important; }

.ml-48 {
  margin-left: 12rem !important; }

.mx-48 {
  margin-left: 12rem !important;
  margin-right: 12rem !important; }

.my-48 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important; }

.mt-n48 {
  margin-top: -12rem !important; }

.mr-n48 {
  margin-right: -12rem !important; }

.mb-n48 {
  margin-bottom: -12rem !important; }

.ml-n48 {
  margin-left: -12rem !important; }

.mx-n48 {
  margin-left: -12rem !important;
  margin-right: -12rem !important; }

.my-n48 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important; }

.p48 {
  padding: 12rem !important; }

.pt-48 {
  padding-top: 12rem !important; }

.pr-48 {
  padding-right: 12rem !important; }

.pb-48 {
  padding-bottom: 12rem !important; }

.pl-48 {
  padding-left: 12rem !important; }

.px-48 {
  padding-left: 12rem !important;
  padding-right: 12rem !important; }

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important; }

.m56 {
  margin: 14rem !important; }

.mt-56 {
  margin-top: 14rem !important; }

.mr-56 {
  margin-right: 14rem !important; }

.mb-56 {
  margin-bottom: 14rem !important; }

.ml-56 {
  margin-left: 14rem !important; }

.mx-56 {
  margin-left: 14rem !important;
  margin-right: 14rem !important; }

.my-56 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important; }

.mt-n56 {
  margin-top: -14rem !important; }

.mr-n56 {
  margin-right: -14rem !important; }

.mb-n56 {
  margin-bottom: -14rem !important; }

.ml-n56 {
  margin-left: -14rem !important; }

.mx-n56 {
  margin-left: -14rem !important;
  margin-right: -14rem !important; }

.my-n56 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important; }

.p56 {
  padding: 14rem !important; }

.pt-56 {
  padding-top: 14rem !important; }

.pr-56 {
  padding-right: 14rem !important; }

.pb-56 {
  padding-bottom: 14rem !important; }

.pl-56 {
  padding-left: 14rem !important; }

.px-56 {
  padding-left: 14rem !important;
  padding-right: 14rem !important; }

.py-56 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important; }

.m64 {
  margin: 16rem !important; }

.mt-64 {
  margin-top: 16rem !important; }

.mr-64 {
  margin-right: 16rem !important; }

.mb-64 {
  margin-bottom: 16rem !important; }

.ml-64 {
  margin-left: 16rem !important; }

.mx-64 {
  margin-left: 16rem !important;
  margin-right: 16rem !important; }

.my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important; }

.mt-n64 {
  margin-top: -16rem !important; }

.mr-n64 {
  margin-right: -16rem !important; }

.mb-n64 {
  margin-bottom: -16rem !important; }

.ml-n64 {
  margin-left: -16rem !important; }

.mx-n64 {
  margin-left: -16rem !important;
  margin-right: -16rem !important; }

.my-n64 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important; }

.p64 {
  padding: 16rem !important; }

.pt-64 {
  padding-top: 16rem !important; }

.pr-64 {
  padding-right: 16rem !important; }

.pb-64 {
  padding-bottom: 16rem !important; }

.pl-64 {
  padding-left: 16rem !important; }

.px-64 {
  padding-left: 16rem !important;
  padding-right: 16rem !important; }

.py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important; }

.m85 {
  margin: 21.25rem !important; }

.mt-85 {
  margin-top: 21.25rem !important; }

.mr-85 {
  margin-right: 21.25rem !important; }

.mb-85 {
  margin-bottom: 21.25rem !important; }

.ml-85 {
  margin-left: 21.25rem !important; }

.mx-85 {
  margin-left: 21.25rem !important;
  margin-right: 21.25rem !important; }

.my-85 {
  margin-top: 21.25rem !important;
  margin-bottom: 21.25rem !important; }

.mt-n85 {
  margin-top: -21.25rem !important; }

.mr-n85 {
  margin-right: -21.25rem !important; }

.mb-n85 {
  margin-bottom: -21.25rem !important; }

.ml-n85 {
  margin-left: -21.25rem !important; }

.mx-n85 {
  margin-left: -21.25rem !important;
  margin-right: -21.25rem !important; }

.my-n85 {
  margin-top: -21.25rem !important;
  margin-bottom: -21.25rem !important; }

.p85 {
  padding: 21.25rem !important; }

.pt-85 {
  padding-top: 21.25rem !important; }

.pr-85 {
  padding-right: 21.25rem !important; }

.pb-85 {
  padding-bottom: 21.25rem !important; }

.pl-85 {
  padding-left: 21.25rem !important; }

.px-85 {
  padding-left: 21.25rem !important;
  padding-right: 21.25rem !important; }

.py-85 {
  padding-top: 21.25rem !important;
  padding-bottom: 21.25rem !important; }

.mauto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-nauto {
  margin-top: -auto !important; }

.mr-nauto {
  margin-right: -auto !important; }

.mb-nauto {
  margin-bottom: -auto !important; }

.ml-nauto {
  margin-left: -auto !important; }

.mx-nauto {
  margin-left: -auto !important;
  margin-right: -auto !important; }

.my-nauto {
  margin-top: -auto !important;
  margin-bottom: -auto !important; }

.pauto {
  padding: auto !important; }

.pt-auto {
  padding-top: auto !important; }

.pr-auto {
  padding-right: auto !important; }

.pb-auto {
  padding-bottom: auto !important; }

.pl-auto {
  padding-left: auto !important; }

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important; }

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important; }

@media (min-width: 410px) {
  .sm\:m0 {
    margin: 0 !important; }
  .sm\:mt-0 {
    margin-top: 0 !important; }
  .sm\:mr-0 {
    margin-right: 0 !important; }
  .sm\:mb-0 {
    margin-bottom: 0 !important; }
  .sm\:ml-0 {
    margin-left: 0 !important; }
  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm\:mt-n0 {
    margin-top: 0 !important; }
  .sm\:mr-n0 {
    margin-right: 0 !important; }
  .sm\:mb-n0 {
    margin-bottom: 0 !important; }
  .sm\:ml-n0 {
    margin-left: 0 !important; }
  .sm\:mx-n0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm\:my-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm\:p0 {
    padding: 0 !important; }
  .sm\:pt-0 {
    padding-top: 0 !important; }
  .sm\:pr-0 {
    padding-right: 0 !important; }
  .sm\:pb-0 {
    padding-bottom: 0 !important; }
  .sm\:pl-0 {
    padding-left: 0 !important; }
  .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm\:m1 {
    margin: 0.25rem !important; }
  .sm\:mt-1 {
    margin-top: 0.25rem !important; }
  .sm\:mr-1 {
    margin-right: 0.25rem !important; }
  .sm\:mb-1 {
    margin-bottom: 0.25rem !important; }
  .sm\:ml-1 {
    margin-left: 0.25rem !important; }
  .sm\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
  .sm\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .sm\:mt-n1 {
    margin-top: -0.25rem !important; }
  .sm\:mr-n1 {
    margin-right: -0.25rem !important; }
  .sm\:mb-n1 {
    margin-bottom: -0.25rem !important; }
  .sm\:ml-n1 {
    margin-left: -0.25rem !important; }
  .sm\:mx-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important; }
  .sm\:my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .sm\:p1 {
    padding: 0.25rem !important; }
  .sm\:pt-1 {
    padding-top: 0.25rem !important; }
  .sm\:pr-1 {
    padding-right: 0.25rem !important; }
  .sm\:pb-1 {
    padding-bottom: 0.25rem !important; }
  .sm\:pl-1 {
    padding-left: 0.25rem !important; }
  .sm\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
  .sm\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .sm\:m2 {
    margin: 0.5rem !important; }
  .sm\:mt-2 {
    margin-top: 0.5rem !important; }
  .sm\:mr-2 {
    margin-right: 0.5rem !important; }
  .sm\:mb-2 {
    margin-bottom: 0.5rem !important; }
  .sm\:ml-2 {
    margin-left: 0.5rem !important; }
  .sm\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
  .sm\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .sm\:mt-n2 {
    margin-top: -0.5rem !important; }
  .sm\:mr-n2 {
    margin-right: -0.5rem !important; }
  .sm\:mb-n2 {
    margin-bottom: -0.5rem !important; }
  .sm\:ml-n2 {
    margin-left: -0.5rem !important; }
  .sm\:mx-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important; }
  .sm\:my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .sm\:p2 {
    padding: 0.5rem !important; }
  .sm\:pt-2 {
    padding-top: 0.5rem !important; }
  .sm\:pr-2 {
    padding-right: 0.5rem !important; }
  .sm\:pb-2 {
    padding-bottom: 0.5rem !important; }
  .sm\:pl-2 {
    padding-left: 0.5rem !important; }
  .sm\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
  .sm\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .sm\:m3 {
    margin: 0.75rem !important; }
  .sm\:mt-3 {
    margin-top: 0.75rem !important; }
  .sm\:mr-3 {
    margin-right: 0.75rem !important; }
  .sm\:mb-3 {
    margin-bottom: 0.75rem !important; }
  .sm\:ml-3 {
    margin-left: 0.75rem !important; }
  .sm\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important; }
  .sm\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .sm\:mt-n3 {
    margin-top: -0.75rem !important; }
  .sm\:mr-n3 {
    margin-right: -0.75rem !important; }
  .sm\:mb-n3 {
    margin-bottom: -0.75rem !important; }
  .sm\:ml-n3 {
    margin-left: -0.75rem !important; }
  .sm\:mx-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important; }
  .sm\:my-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .sm\:p3 {
    padding: 0.75rem !important; }
  .sm\:pt-3 {
    padding-top: 0.75rem !important; }
  .sm\:pr-3 {
    padding-right: 0.75rem !important; }
  .sm\:pb-3 {
    padding-bottom: 0.75rem !important; }
  .sm\:pl-3 {
    padding-left: 0.75rem !important; }
  .sm\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important; }
  .sm\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .sm\:m4 {
    margin: 1rem !important; }
  .sm\:mt-4 {
    margin-top: 1rem !important; }
  .sm\:mr-4 {
    margin-right: 1rem !important; }
  .sm\:mb-4 {
    margin-bottom: 1rem !important; }
  .sm\:ml-4 {
    margin-left: 1rem !important; }
  .sm\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
  .sm\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .sm\:mt-n4 {
    margin-top: -1rem !important; }
  .sm\:mr-n4 {
    margin-right: -1rem !important; }
  .sm\:mb-n4 {
    margin-bottom: -1rem !important; }
  .sm\:ml-n4 {
    margin-left: -1rem !important; }
  .sm\:mx-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important; }
  .sm\:my-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .sm\:p4 {
    padding: 1rem !important; }
  .sm\:pt-4 {
    padding-top: 1rem !important; }
  .sm\:pr-4 {
    padding-right: 1rem !important; }
  .sm\:pb-4 {
    padding-bottom: 1rem !important; }
  .sm\:pl-4 {
    padding-left: 1rem !important; }
  .sm\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .sm\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .sm\:m5 {
    margin: 1.25rem !important; }
  .sm\:mt-5 {
    margin-top: 1.25rem !important; }
  .sm\:mr-5 {
    margin-right: 1.25rem !important; }
  .sm\:mb-5 {
    margin-bottom: 1.25rem !important; }
  .sm\:ml-5 {
    margin-left: 1.25rem !important; }
  .sm\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important; }
  .sm\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .sm\:mt-n5 {
    margin-top: -1.25rem !important; }
  .sm\:mr-n5 {
    margin-right: -1.25rem !important; }
  .sm\:mb-n5 {
    margin-bottom: -1.25rem !important; }
  .sm\:ml-n5 {
    margin-left: -1.25rem !important; }
  .sm\:mx-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important; }
  .sm\:my-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .sm\:p5 {
    padding: 1.25rem !important; }
  .sm\:pt-5 {
    padding-top: 1.25rem !important; }
  .sm\:pr-5 {
    padding-right: 1.25rem !important; }
  .sm\:pb-5 {
    padding-bottom: 1.25rem !important; }
  .sm\:pl-5 {
    padding-left: 1.25rem !important; }
  .sm\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .sm\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .sm\:m6 {
    margin: 1.5rem !important; }
  .sm\:mt-6 {
    margin-top: 1.5rem !important; }
  .sm\:mr-6 {
    margin-right: 1.5rem !important; }
  .sm\:mb-6 {
    margin-bottom: 1.5rem !important; }
  .sm\:ml-6 {
    margin-left: 1.5rem !important; }
  .sm\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
  .sm\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .sm\:mt-n6 {
    margin-top: -1.5rem !important; }
  .sm\:mr-n6 {
    margin-right: -1.5rem !important; }
  .sm\:mb-n6 {
    margin-bottom: -1.5rem !important; }
  .sm\:ml-n6 {
    margin-left: -1.5rem !important; }
  .sm\:mx-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important; }
  .sm\:my-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .sm\:p6 {
    padding: 1.5rem !important; }
  .sm\:pt-6 {
    padding-top: 1.5rem !important; }
  .sm\:pr-6 {
    padding-right: 1.5rem !important; }
  .sm\:pb-6 {
    padding-bottom: 1.5rem !important; }
  .sm\:pl-6 {
    padding-left: 1.5rem !important; }
  .sm\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
  .sm\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .sm\:m8 {
    margin: 2rem !important; }
  .sm\:mt-8 {
    margin-top: 2rem !important; }
  .sm\:mr-8 {
    margin-right: 2rem !important; }
  .sm\:mb-8 {
    margin-bottom: 2rem !important; }
  .sm\:ml-8 {
    margin-left: 2rem !important; }
  .sm\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important; }
  .sm\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .sm\:mt-n8 {
    margin-top: -2rem !important; }
  .sm\:mr-n8 {
    margin-right: -2rem !important; }
  .sm\:mb-n8 {
    margin-bottom: -2rem !important; }
  .sm\:ml-n8 {
    margin-left: -2rem !important; }
  .sm\:mx-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important; }
  .sm\:my-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .sm\:p8 {
    padding: 2rem !important; }
  .sm\:pt-8 {
    padding-top: 2rem !important; }
  .sm\:pr-8 {
    padding-right: 2rem !important; }
  .sm\:pb-8 {
    padding-bottom: 2rem !important; }
  .sm\:pl-8 {
    padding-left: 2rem !important; }
  .sm\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  .sm\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .sm\:m10 {
    margin: 2.5rem !important; }
  .sm\:mt-10 {
    margin-top: 2.5rem !important; }
  .sm\:mr-10 {
    margin-right: 2.5rem !important; }
  .sm\:mb-10 {
    margin-bottom: 2.5rem !important; }
  .sm\:ml-10 {
    margin-left: 2.5rem !important; }
  .sm\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important; }
  .sm\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .sm\:mt-n10 {
    margin-top: -2.5rem !important; }
  .sm\:mr-n10 {
    margin-right: -2.5rem !important; }
  .sm\:mb-n10 {
    margin-bottom: -2.5rem !important; }
  .sm\:ml-n10 {
    margin-left: -2.5rem !important; }
  .sm\:mx-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important; }
  .sm\:my-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .sm\:p10 {
    padding: 2.5rem !important; }
  .sm\:pt-10 {
    padding-top: 2.5rem !important; }
  .sm\:pr-10 {
    padding-right: 2.5rem !important; }
  .sm\:pb-10 {
    padding-bottom: 2.5rem !important; }
  .sm\:pl-10 {
    padding-left: 2.5rem !important; }
  .sm\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important; }
  .sm\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .sm\:m12 {
    margin: 3rem !important; }
  .sm\:mt-12 {
    margin-top: 3rem !important; }
  .sm\:mr-12 {
    margin-right: 3rem !important; }
  .sm\:mb-12 {
    margin-bottom: 3rem !important; }
  .sm\:ml-12 {
    margin-left: 3rem !important; }
  .sm\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
  .sm\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .sm\:mt-n12 {
    margin-top: -3rem !important; }
  .sm\:mr-n12 {
    margin-right: -3rem !important; }
  .sm\:mb-n12 {
    margin-bottom: -3rem !important; }
  .sm\:ml-n12 {
    margin-left: -3rem !important; }
  .sm\:mx-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important; }
  .sm\:my-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .sm\:p12 {
    padding: 3rem !important; }
  .sm\:pt-12 {
    padding-top: 3rem !important; }
  .sm\:pr-12 {
    padding-right: 3rem !important; }
  .sm\:pb-12 {
    padding-bottom: 3rem !important; }
  .sm\:pl-12 {
    padding-left: 3rem !important; }
  .sm\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
  .sm\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .sm\:m16 {
    margin: 4rem !important; }
  .sm\:mt-16 {
    margin-top: 4rem !important; }
  .sm\:mr-16 {
    margin-right: 4rem !important; }
  .sm\:mb-16 {
    margin-bottom: 4rem !important; }
  .sm\:ml-16 {
    margin-left: 4rem !important; }
  .sm\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important; }
  .sm\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .sm\:mt-n16 {
    margin-top: -4rem !important; }
  .sm\:mr-n16 {
    margin-right: -4rem !important; }
  .sm\:mb-n16 {
    margin-bottom: -4rem !important; }
  .sm\:ml-n16 {
    margin-left: -4rem !important; }
  .sm\:mx-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important; }
  .sm\:my-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .sm\:p16 {
    padding: 4rem !important; }
  .sm\:pt-16 {
    padding-top: 4rem !important; }
  .sm\:pr-16 {
    padding-right: 4rem !important; }
  .sm\:pb-16 {
    padding-bottom: 4rem !important; }
  .sm\:pl-16 {
    padding-left: 4rem !important; }
  .sm\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important; }
  .sm\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .sm\:m20 {
    margin: 5rem !important; }
  .sm\:mt-20 {
    margin-top: 5rem !important; }
  .sm\:mr-20 {
    margin-right: 5rem !important; }
  .sm\:mb-20 {
    margin-bottom: 5rem !important; }
  .sm\:ml-20 {
    margin-left: 5rem !important; }
  .sm\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important; }
  .sm\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .sm\:mt-n20 {
    margin-top: -5rem !important; }
  .sm\:mr-n20 {
    margin-right: -5rem !important; }
  .sm\:mb-n20 {
    margin-bottom: -5rem !important; }
  .sm\:ml-n20 {
    margin-left: -5rem !important; }
  .sm\:mx-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important; }
  .sm\:my-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .sm\:p20 {
    padding: 5rem !important; }
  .sm\:pt-20 {
    padding-top: 5rem !important; }
  .sm\:pr-20 {
    padding-right: 5rem !important; }
  .sm\:pb-20 {
    padding-bottom: 5rem !important; }
  .sm\:pl-20 {
    padding-left: 5rem !important; }
  .sm\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important; }
  .sm\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .sm\:m24 {
    margin: 6rem !important; }
  .sm\:mt-24 {
    margin-top: 6rem !important; }
  .sm\:mr-24 {
    margin-right: 6rem !important; }
  .sm\:mb-24 {
    margin-bottom: 6rem !important; }
  .sm\:ml-24 {
    margin-left: 6rem !important; }
  .sm\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important; }
  .sm\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .sm\:mt-n24 {
    margin-top: -6rem !important; }
  .sm\:mr-n24 {
    margin-right: -6rem !important; }
  .sm\:mb-n24 {
    margin-bottom: -6rem !important; }
  .sm\:ml-n24 {
    margin-left: -6rem !important; }
  .sm\:mx-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important; }
  .sm\:my-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .sm\:p24 {
    padding: 6rem !important; }
  .sm\:pt-24 {
    padding-top: 6rem !important; }
  .sm\:pr-24 {
    padding-right: 6rem !important; }
  .sm\:pb-24 {
    padding-bottom: 6rem !important; }
  .sm\:pl-24 {
    padding-left: 6rem !important; }
  .sm\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important; }
  .sm\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .sm\:m32 {
    margin: 8rem !important; }
  .sm\:mt-32 {
    margin-top: 8rem !important; }
  .sm\:mr-32 {
    margin-right: 8rem !important; }
  .sm\:mb-32 {
    margin-bottom: 8rem !important; }
  .sm\:ml-32 {
    margin-left: 8rem !important; }
  .sm\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important; }
  .sm\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .sm\:mt-n32 {
    margin-top: -8rem !important; }
  .sm\:mr-n32 {
    margin-right: -8rem !important; }
  .sm\:mb-n32 {
    margin-bottom: -8rem !important; }
  .sm\:ml-n32 {
    margin-left: -8rem !important; }
  .sm\:mx-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important; }
  .sm\:my-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important; }
  .sm\:p32 {
    padding: 8rem !important; }
  .sm\:pt-32 {
    padding-top: 8rem !important; }
  .sm\:pr-32 {
    padding-right: 8rem !important; }
  .sm\:pb-32 {
    padding-bottom: 8rem !important; }
  .sm\:pl-32 {
    padding-left: 8rem !important; }
  .sm\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important; }
  .sm\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .sm\:m40 {
    margin: 10rem !important; }
  .sm\:mt-40 {
    margin-top: 10rem !important; }
  .sm\:mr-40 {
    margin-right: 10rem !important; }
  .sm\:mb-40 {
    margin-bottom: 10rem !important; }
  .sm\:ml-40 {
    margin-left: 10rem !important; }
  .sm\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important; }
  .sm\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .sm\:mt-n40 {
    margin-top: -10rem !important; }
  .sm\:mr-n40 {
    margin-right: -10rem !important; }
  .sm\:mb-n40 {
    margin-bottom: -10rem !important; }
  .sm\:ml-n40 {
    margin-left: -10rem !important; }
  .sm\:mx-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important; }
  .sm\:my-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .sm\:p40 {
    padding: 10rem !important; }
  .sm\:pt-40 {
    padding-top: 10rem !important; }
  .sm\:pr-40 {
    padding-right: 10rem !important; }
  .sm\:pb-40 {
    padding-bottom: 10rem !important; }
  .sm\:pl-40 {
    padding-left: 10rem !important; }
  .sm\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important; }
  .sm\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .sm\:m48 {
    margin: 12rem !important; }
  .sm\:mt-48 {
    margin-top: 12rem !important; }
  .sm\:mr-48 {
    margin-right: 12rem !important; }
  .sm\:mb-48 {
    margin-bottom: 12rem !important; }
  .sm\:ml-48 {
    margin-left: 12rem !important; }
  .sm\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important; }
  .sm\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .sm\:mt-n48 {
    margin-top: -12rem !important; }
  .sm\:mr-n48 {
    margin-right: -12rem !important; }
  .sm\:mb-n48 {
    margin-bottom: -12rem !important; }
  .sm\:ml-n48 {
    margin-left: -12rem !important; }
  .sm\:mx-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important; }
  .sm\:my-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important; }
  .sm\:p48 {
    padding: 12rem !important; }
  .sm\:pt-48 {
    padding-top: 12rem !important; }
  .sm\:pr-48 {
    padding-right: 12rem !important; }
  .sm\:pb-48 {
    padding-bottom: 12rem !important; }
  .sm\:pl-48 {
    padding-left: 12rem !important; }
  .sm\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important; }
  .sm\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .sm\:m56 {
    margin: 14rem !important; }
  .sm\:mt-56 {
    margin-top: 14rem !important; }
  .sm\:mr-56 {
    margin-right: 14rem !important; }
  .sm\:mb-56 {
    margin-bottom: 14rem !important; }
  .sm\:ml-56 {
    margin-left: 14rem !important; }
  .sm\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important; }
  .sm\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .sm\:mt-n56 {
    margin-top: -14rem !important; }
  .sm\:mr-n56 {
    margin-right: -14rem !important; }
  .sm\:mb-n56 {
    margin-bottom: -14rem !important; }
  .sm\:ml-n56 {
    margin-left: -14rem !important; }
  .sm\:mx-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important; }
  .sm\:my-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important; }
  .sm\:p56 {
    padding: 14rem !important; }
  .sm\:pt-56 {
    padding-top: 14rem !important; }
  .sm\:pr-56 {
    padding-right: 14rem !important; }
  .sm\:pb-56 {
    padding-bottom: 14rem !important; }
  .sm\:pl-56 {
    padding-left: 14rem !important; }
  .sm\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important; }
  .sm\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .sm\:m64 {
    margin: 16rem !important; }
  .sm\:mt-64 {
    margin-top: 16rem !important; }
  .sm\:mr-64 {
    margin-right: 16rem !important; }
  .sm\:mb-64 {
    margin-bottom: 16rem !important; }
  .sm\:ml-64 {
    margin-left: 16rem !important; }
  .sm\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important; }
  .sm\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .sm\:mt-n64 {
    margin-top: -16rem !important; }
  .sm\:mr-n64 {
    margin-right: -16rem !important; }
  .sm\:mb-n64 {
    margin-bottom: -16rem !important; }
  .sm\:ml-n64 {
    margin-left: -16rem !important; }
  .sm\:mx-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important; }
  .sm\:my-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important; }
  .sm\:p64 {
    padding: 16rem !important; }
  .sm\:pt-64 {
    padding-top: 16rem !important; }
  .sm\:pr-64 {
    padding-right: 16rem !important; }
  .sm\:pb-64 {
    padding-bottom: 16rem !important; }
  .sm\:pl-64 {
    padding-left: 16rem !important; }
  .sm\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important; }
  .sm\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .sm\:m85 {
    margin: 21.25rem !important; }
  .sm\:mt-85 {
    margin-top: 21.25rem !important; }
  .sm\:mr-85 {
    margin-right: 21.25rem !important; }
  .sm\:mb-85 {
    margin-bottom: 21.25rem !important; }
  .sm\:ml-85 {
    margin-left: 21.25rem !important; }
  .sm\:mx-85 {
    margin-left: 21.25rem !important;
    margin-right: 21.25rem !important; }
  .sm\:my-85 {
    margin-top: 21.25rem !important;
    margin-bottom: 21.25rem !important; }
  .sm\:mt-n85 {
    margin-top: -21.25rem !important; }
  .sm\:mr-n85 {
    margin-right: -21.25rem !important; }
  .sm\:mb-n85 {
    margin-bottom: -21.25rem !important; }
  .sm\:ml-n85 {
    margin-left: -21.25rem !important; }
  .sm\:mx-n85 {
    margin-left: -21.25rem !important;
    margin-right: -21.25rem !important; }
  .sm\:my-n85 {
    margin-top: -21.25rem !important;
    margin-bottom: -21.25rem !important; }
  .sm\:p85 {
    padding: 21.25rem !important; }
  .sm\:pt-85 {
    padding-top: 21.25rem !important; }
  .sm\:pr-85 {
    padding-right: 21.25rem !important; }
  .sm\:pb-85 {
    padding-bottom: 21.25rem !important; }
  .sm\:pl-85 {
    padding-left: 21.25rem !important; }
  .sm\:px-85 {
    padding-left: 21.25rem !important;
    padding-right: 21.25rem !important; }
  .sm\:py-85 {
    padding-top: 21.25rem !important;
    padding-bottom: 21.25rem !important; }
  .sm\:mauto {
    margin: auto !important; }
  .sm\:mt-auto {
    margin-top: auto !important; }
  .sm\:mr-auto {
    margin-right: auto !important; }
  .sm\:mb-auto {
    margin-bottom: auto !important; }
  .sm\:ml-auto {
    margin-left: auto !important; }
  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important; }
  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .sm\:mt-nauto {
    margin-top: -auto !important; }
  .sm\:mr-nauto {
    margin-right: -auto !important; }
  .sm\:mb-nauto {
    margin-bottom: -auto !important; }
  .sm\:ml-nauto {
    margin-left: -auto !important; }
  .sm\:mx-nauto {
    margin-left: -auto !important;
    margin-right: -auto !important; }
  .sm\:my-nauto {
    margin-top: -auto !important;
    margin-bottom: -auto !important; }
  .sm\:pauto {
    padding: auto !important; }
  .sm\:pt-auto {
    padding-top: auto !important; }
  .sm\:pr-auto {
    padding-right: auto !important; }
  .sm\:pb-auto {
    padding-bottom: auto !important; }
  .sm\:pl-auto {
    padding-left: auto !important; }
  .sm\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important; }
  .sm\:py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important; } }

@media (min-width: 600px) {
  .sm\+\:m0 {
    margin: 0 !important; }
  .sm\+\:mt-0 {
    margin-top: 0 !important; }
  .sm\+\:mr-0 {
    margin-right: 0 !important; }
  .sm\+\:mb-0 {
    margin-bottom: 0 !important; }
  .sm\+\:ml-0 {
    margin-left: 0 !important; }
  .sm\+\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm\+\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm\+\:mt-n0 {
    margin-top: 0 !important; }
  .sm\+\:mr-n0 {
    margin-right: 0 !important; }
  .sm\+\:mb-n0 {
    margin-bottom: 0 !important; }
  .sm\+\:ml-n0 {
    margin-left: 0 !important; }
  .sm\+\:mx-n0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm\+\:my-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm\+\:p0 {
    padding: 0 !important; }
  .sm\+\:pt-0 {
    padding-top: 0 !important; }
  .sm\+\:pr-0 {
    padding-right: 0 !important; }
  .sm\+\:pb-0 {
    padding-bottom: 0 !important; }
  .sm\+\:pl-0 {
    padding-left: 0 !important; }
  .sm\+\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm\+\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm\+\:m1 {
    margin: 0.25rem !important; }
  .sm\+\:mt-1 {
    margin-top: 0.25rem !important; }
  .sm\+\:mr-1 {
    margin-right: 0.25rem !important; }
  .sm\+\:mb-1 {
    margin-bottom: 0.25rem !important; }
  .sm\+\:ml-1 {
    margin-left: 0.25rem !important; }
  .sm\+\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
  .sm\+\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .sm\+\:mt-n1 {
    margin-top: -0.25rem !important; }
  .sm\+\:mr-n1 {
    margin-right: -0.25rem !important; }
  .sm\+\:mb-n1 {
    margin-bottom: -0.25rem !important; }
  .sm\+\:ml-n1 {
    margin-left: -0.25rem !important; }
  .sm\+\:mx-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important; }
  .sm\+\:my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .sm\+\:p1 {
    padding: 0.25rem !important; }
  .sm\+\:pt-1 {
    padding-top: 0.25rem !important; }
  .sm\+\:pr-1 {
    padding-right: 0.25rem !important; }
  .sm\+\:pb-1 {
    padding-bottom: 0.25rem !important; }
  .sm\+\:pl-1 {
    padding-left: 0.25rem !important; }
  .sm\+\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
  .sm\+\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .sm\+\:m2 {
    margin: 0.5rem !important; }
  .sm\+\:mt-2 {
    margin-top: 0.5rem !important; }
  .sm\+\:mr-2 {
    margin-right: 0.5rem !important; }
  .sm\+\:mb-2 {
    margin-bottom: 0.5rem !important; }
  .sm\+\:ml-2 {
    margin-left: 0.5rem !important; }
  .sm\+\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
  .sm\+\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .sm\+\:mt-n2 {
    margin-top: -0.5rem !important; }
  .sm\+\:mr-n2 {
    margin-right: -0.5rem !important; }
  .sm\+\:mb-n2 {
    margin-bottom: -0.5rem !important; }
  .sm\+\:ml-n2 {
    margin-left: -0.5rem !important; }
  .sm\+\:mx-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important; }
  .sm\+\:my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .sm\+\:p2 {
    padding: 0.5rem !important; }
  .sm\+\:pt-2 {
    padding-top: 0.5rem !important; }
  .sm\+\:pr-2 {
    padding-right: 0.5rem !important; }
  .sm\+\:pb-2 {
    padding-bottom: 0.5rem !important; }
  .sm\+\:pl-2 {
    padding-left: 0.5rem !important; }
  .sm\+\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
  .sm\+\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .sm\+\:m3 {
    margin: 0.75rem !important; }
  .sm\+\:mt-3 {
    margin-top: 0.75rem !important; }
  .sm\+\:mr-3 {
    margin-right: 0.75rem !important; }
  .sm\+\:mb-3 {
    margin-bottom: 0.75rem !important; }
  .sm\+\:ml-3 {
    margin-left: 0.75rem !important; }
  .sm\+\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important; }
  .sm\+\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .sm\+\:mt-n3 {
    margin-top: -0.75rem !important; }
  .sm\+\:mr-n3 {
    margin-right: -0.75rem !important; }
  .sm\+\:mb-n3 {
    margin-bottom: -0.75rem !important; }
  .sm\+\:ml-n3 {
    margin-left: -0.75rem !important; }
  .sm\+\:mx-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important; }
  .sm\+\:my-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .sm\+\:p3 {
    padding: 0.75rem !important; }
  .sm\+\:pt-3 {
    padding-top: 0.75rem !important; }
  .sm\+\:pr-3 {
    padding-right: 0.75rem !important; }
  .sm\+\:pb-3 {
    padding-bottom: 0.75rem !important; }
  .sm\+\:pl-3 {
    padding-left: 0.75rem !important; }
  .sm\+\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important; }
  .sm\+\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .sm\+\:m4 {
    margin: 1rem !important; }
  .sm\+\:mt-4 {
    margin-top: 1rem !important; }
  .sm\+\:mr-4 {
    margin-right: 1rem !important; }
  .sm\+\:mb-4 {
    margin-bottom: 1rem !important; }
  .sm\+\:ml-4 {
    margin-left: 1rem !important; }
  .sm\+\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
  .sm\+\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .sm\+\:mt-n4 {
    margin-top: -1rem !important; }
  .sm\+\:mr-n4 {
    margin-right: -1rem !important; }
  .sm\+\:mb-n4 {
    margin-bottom: -1rem !important; }
  .sm\+\:ml-n4 {
    margin-left: -1rem !important; }
  .sm\+\:mx-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important; }
  .sm\+\:my-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .sm\+\:p4 {
    padding: 1rem !important; }
  .sm\+\:pt-4 {
    padding-top: 1rem !important; }
  .sm\+\:pr-4 {
    padding-right: 1rem !important; }
  .sm\+\:pb-4 {
    padding-bottom: 1rem !important; }
  .sm\+\:pl-4 {
    padding-left: 1rem !important; }
  .sm\+\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .sm\+\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .sm\+\:m5 {
    margin: 1.25rem !important; }
  .sm\+\:mt-5 {
    margin-top: 1.25rem !important; }
  .sm\+\:mr-5 {
    margin-right: 1.25rem !important; }
  .sm\+\:mb-5 {
    margin-bottom: 1.25rem !important; }
  .sm\+\:ml-5 {
    margin-left: 1.25rem !important; }
  .sm\+\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important; }
  .sm\+\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .sm\+\:mt-n5 {
    margin-top: -1.25rem !important; }
  .sm\+\:mr-n5 {
    margin-right: -1.25rem !important; }
  .sm\+\:mb-n5 {
    margin-bottom: -1.25rem !important; }
  .sm\+\:ml-n5 {
    margin-left: -1.25rem !important; }
  .sm\+\:mx-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important; }
  .sm\+\:my-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .sm\+\:p5 {
    padding: 1.25rem !important; }
  .sm\+\:pt-5 {
    padding-top: 1.25rem !important; }
  .sm\+\:pr-5 {
    padding-right: 1.25rem !important; }
  .sm\+\:pb-5 {
    padding-bottom: 1.25rem !important; }
  .sm\+\:pl-5 {
    padding-left: 1.25rem !important; }
  .sm\+\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .sm\+\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .sm\+\:m6 {
    margin: 1.5rem !important; }
  .sm\+\:mt-6 {
    margin-top: 1.5rem !important; }
  .sm\+\:mr-6 {
    margin-right: 1.5rem !important; }
  .sm\+\:mb-6 {
    margin-bottom: 1.5rem !important; }
  .sm\+\:ml-6 {
    margin-left: 1.5rem !important; }
  .sm\+\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
  .sm\+\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .sm\+\:mt-n6 {
    margin-top: -1.5rem !important; }
  .sm\+\:mr-n6 {
    margin-right: -1.5rem !important; }
  .sm\+\:mb-n6 {
    margin-bottom: -1.5rem !important; }
  .sm\+\:ml-n6 {
    margin-left: -1.5rem !important; }
  .sm\+\:mx-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important; }
  .sm\+\:my-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .sm\+\:p6 {
    padding: 1.5rem !important; }
  .sm\+\:pt-6 {
    padding-top: 1.5rem !important; }
  .sm\+\:pr-6 {
    padding-right: 1.5rem !important; }
  .sm\+\:pb-6 {
    padding-bottom: 1.5rem !important; }
  .sm\+\:pl-6 {
    padding-left: 1.5rem !important; }
  .sm\+\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
  .sm\+\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .sm\+\:m8 {
    margin: 2rem !important; }
  .sm\+\:mt-8 {
    margin-top: 2rem !important; }
  .sm\+\:mr-8 {
    margin-right: 2rem !important; }
  .sm\+\:mb-8 {
    margin-bottom: 2rem !important; }
  .sm\+\:ml-8 {
    margin-left: 2rem !important; }
  .sm\+\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important; }
  .sm\+\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .sm\+\:mt-n8 {
    margin-top: -2rem !important; }
  .sm\+\:mr-n8 {
    margin-right: -2rem !important; }
  .sm\+\:mb-n8 {
    margin-bottom: -2rem !important; }
  .sm\+\:ml-n8 {
    margin-left: -2rem !important; }
  .sm\+\:mx-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important; }
  .sm\+\:my-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .sm\+\:p8 {
    padding: 2rem !important; }
  .sm\+\:pt-8 {
    padding-top: 2rem !important; }
  .sm\+\:pr-8 {
    padding-right: 2rem !important; }
  .sm\+\:pb-8 {
    padding-bottom: 2rem !important; }
  .sm\+\:pl-8 {
    padding-left: 2rem !important; }
  .sm\+\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  .sm\+\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .sm\+\:m10 {
    margin: 2.5rem !important; }
  .sm\+\:mt-10 {
    margin-top: 2.5rem !important; }
  .sm\+\:mr-10 {
    margin-right: 2.5rem !important; }
  .sm\+\:mb-10 {
    margin-bottom: 2.5rem !important; }
  .sm\+\:ml-10 {
    margin-left: 2.5rem !important; }
  .sm\+\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important; }
  .sm\+\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .sm\+\:mt-n10 {
    margin-top: -2.5rem !important; }
  .sm\+\:mr-n10 {
    margin-right: -2.5rem !important; }
  .sm\+\:mb-n10 {
    margin-bottom: -2.5rem !important; }
  .sm\+\:ml-n10 {
    margin-left: -2.5rem !important; }
  .sm\+\:mx-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important; }
  .sm\+\:my-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .sm\+\:p10 {
    padding: 2.5rem !important; }
  .sm\+\:pt-10 {
    padding-top: 2.5rem !important; }
  .sm\+\:pr-10 {
    padding-right: 2.5rem !important; }
  .sm\+\:pb-10 {
    padding-bottom: 2.5rem !important; }
  .sm\+\:pl-10 {
    padding-left: 2.5rem !important; }
  .sm\+\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important; }
  .sm\+\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .sm\+\:m12 {
    margin: 3rem !important; }
  .sm\+\:mt-12 {
    margin-top: 3rem !important; }
  .sm\+\:mr-12 {
    margin-right: 3rem !important; }
  .sm\+\:mb-12 {
    margin-bottom: 3rem !important; }
  .sm\+\:ml-12 {
    margin-left: 3rem !important; }
  .sm\+\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
  .sm\+\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .sm\+\:mt-n12 {
    margin-top: -3rem !important; }
  .sm\+\:mr-n12 {
    margin-right: -3rem !important; }
  .sm\+\:mb-n12 {
    margin-bottom: -3rem !important; }
  .sm\+\:ml-n12 {
    margin-left: -3rem !important; }
  .sm\+\:mx-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important; }
  .sm\+\:my-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .sm\+\:p12 {
    padding: 3rem !important; }
  .sm\+\:pt-12 {
    padding-top: 3rem !important; }
  .sm\+\:pr-12 {
    padding-right: 3rem !important; }
  .sm\+\:pb-12 {
    padding-bottom: 3rem !important; }
  .sm\+\:pl-12 {
    padding-left: 3rem !important; }
  .sm\+\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
  .sm\+\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .sm\+\:m16 {
    margin: 4rem !important; }
  .sm\+\:mt-16 {
    margin-top: 4rem !important; }
  .sm\+\:mr-16 {
    margin-right: 4rem !important; }
  .sm\+\:mb-16 {
    margin-bottom: 4rem !important; }
  .sm\+\:ml-16 {
    margin-left: 4rem !important; }
  .sm\+\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important; }
  .sm\+\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .sm\+\:mt-n16 {
    margin-top: -4rem !important; }
  .sm\+\:mr-n16 {
    margin-right: -4rem !important; }
  .sm\+\:mb-n16 {
    margin-bottom: -4rem !important; }
  .sm\+\:ml-n16 {
    margin-left: -4rem !important; }
  .sm\+\:mx-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important; }
  .sm\+\:my-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .sm\+\:p16 {
    padding: 4rem !important; }
  .sm\+\:pt-16 {
    padding-top: 4rem !important; }
  .sm\+\:pr-16 {
    padding-right: 4rem !important; }
  .sm\+\:pb-16 {
    padding-bottom: 4rem !important; }
  .sm\+\:pl-16 {
    padding-left: 4rem !important; }
  .sm\+\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important; }
  .sm\+\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .sm\+\:m20 {
    margin: 5rem !important; }
  .sm\+\:mt-20 {
    margin-top: 5rem !important; }
  .sm\+\:mr-20 {
    margin-right: 5rem !important; }
  .sm\+\:mb-20 {
    margin-bottom: 5rem !important; }
  .sm\+\:ml-20 {
    margin-left: 5rem !important; }
  .sm\+\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important; }
  .sm\+\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .sm\+\:mt-n20 {
    margin-top: -5rem !important; }
  .sm\+\:mr-n20 {
    margin-right: -5rem !important; }
  .sm\+\:mb-n20 {
    margin-bottom: -5rem !important; }
  .sm\+\:ml-n20 {
    margin-left: -5rem !important; }
  .sm\+\:mx-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important; }
  .sm\+\:my-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .sm\+\:p20 {
    padding: 5rem !important; }
  .sm\+\:pt-20 {
    padding-top: 5rem !important; }
  .sm\+\:pr-20 {
    padding-right: 5rem !important; }
  .sm\+\:pb-20 {
    padding-bottom: 5rem !important; }
  .sm\+\:pl-20 {
    padding-left: 5rem !important; }
  .sm\+\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important; }
  .sm\+\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .sm\+\:m24 {
    margin: 6rem !important; }
  .sm\+\:mt-24 {
    margin-top: 6rem !important; }
  .sm\+\:mr-24 {
    margin-right: 6rem !important; }
  .sm\+\:mb-24 {
    margin-bottom: 6rem !important; }
  .sm\+\:ml-24 {
    margin-left: 6rem !important; }
  .sm\+\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important; }
  .sm\+\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .sm\+\:mt-n24 {
    margin-top: -6rem !important; }
  .sm\+\:mr-n24 {
    margin-right: -6rem !important; }
  .sm\+\:mb-n24 {
    margin-bottom: -6rem !important; }
  .sm\+\:ml-n24 {
    margin-left: -6rem !important; }
  .sm\+\:mx-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important; }
  .sm\+\:my-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .sm\+\:p24 {
    padding: 6rem !important; }
  .sm\+\:pt-24 {
    padding-top: 6rem !important; }
  .sm\+\:pr-24 {
    padding-right: 6rem !important; }
  .sm\+\:pb-24 {
    padding-bottom: 6rem !important; }
  .sm\+\:pl-24 {
    padding-left: 6rem !important; }
  .sm\+\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important; }
  .sm\+\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .sm\+\:m32 {
    margin: 8rem !important; }
  .sm\+\:mt-32 {
    margin-top: 8rem !important; }
  .sm\+\:mr-32 {
    margin-right: 8rem !important; }
  .sm\+\:mb-32 {
    margin-bottom: 8rem !important; }
  .sm\+\:ml-32 {
    margin-left: 8rem !important; }
  .sm\+\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important; }
  .sm\+\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .sm\+\:mt-n32 {
    margin-top: -8rem !important; }
  .sm\+\:mr-n32 {
    margin-right: -8rem !important; }
  .sm\+\:mb-n32 {
    margin-bottom: -8rem !important; }
  .sm\+\:ml-n32 {
    margin-left: -8rem !important; }
  .sm\+\:mx-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important; }
  .sm\+\:my-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important; }
  .sm\+\:p32 {
    padding: 8rem !important; }
  .sm\+\:pt-32 {
    padding-top: 8rem !important; }
  .sm\+\:pr-32 {
    padding-right: 8rem !important; }
  .sm\+\:pb-32 {
    padding-bottom: 8rem !important; }
  .sm\+\:pl-32 {
    padding-left: 8rem !important; }
  .sm\+\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important; }
  .sm\+\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .sm\+\:m40 {
    margin: 10rem !important; }
  .sm\+\:mt-40 {
    margin-top: 10rem !important; }
  .sm\+\:mr-40 {
    margin-right: 10rem !important; }
  .sm\+\:mb-40 {
    margin-bottom: 10rem !important; }
  .sm\+\:ml-40 {
    margin-left: 10rem !important; }
  .sm\+\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important; }
  .sm\+\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .sm\+\:mt-n40 {
    margin-top: -10rem !important; }
  .sm\+\:mr-n40 {
    margin-right: -10rem !important; }
  .sm\+\:mb-n40 {
    margin-bottom: -10rem !important; }
  .sm\+\:ml-n40 {
    margin-left: -10rem !important; }
  .sm\+\:mx-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important; }
  .sm\+\:my-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .sm\+\:p40 {
    padding: 10rem !important; }
  .sm\+\:pt-40 {
    padding-top: 10rem !important; }
  .sm\+\:pr-40 {
    padding-right: 10rem !important; }
  .sm\+\:pb-40 {
    padding-bottom: 10rem !important; }
  .sm\+\:pl-40 {
    padding-left: 10rem !important; }
  .sm\+\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important; }
  .sm\+\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .sm\+\:m48 {
    margin: 12rem !important; }
  .sm\+\:mt-48 {
    margin-top: 12rem !important; }
  .sm\+\:mr-48 {
    margin-right: 12rem !important; }
  .sm\+\:mb-48 {
    margin-bottom: 12rem !important; }
  .sm\+\:ml-48 {
    margin-left: 12rem !important; }
  .sm\+\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important; }
  .sm\+\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .sm\+\:mt-n48 {
    margin-top: -12rem !important; }
  .sm\+\:mr-n48 {
    margin-right: -12rem !important; }
  .sm\+\:mb-n48 {
    margin-bottom: -12rem !important; }
  .sm\+\:ml-n48 {
    margin-left: -12rem !important; }
  .sm\+\:mx-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important; }
  .sm\+\:my-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important; }
  .sm\+\:p48 {
    padding: 12rem !important; }
  .sm\+\:pt-48 {
    padding-top: 12rem !important; }
  .sm\+\:pr-48 {
    padding-right: 12rem !important; }
  .sm\+\:pb-48 {
    padding-bottom: 12rem !important; }
  .sm\+\:pl-48 {
    padding-left: 12rem !important; }
  .sm\+\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important; }
  .sm\+\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .sm\+\:m56 {
    margin: 14rem !important; }
  .sm\+\:mt-56 {
    margin-top: 14rem !important; }
  .sm\+\:mr-56 {
    margin-right: 14rem !important; }
  .sm\+\:mb-56 {
    margin-bottom: 14rem !important; }
  .sm\+\:ml-56 {
    margin-left: 14rem !important; }
  .sm\+\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important; }
  .sm\+\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .sm\+\:mt-n56 {
    margin-top: -14rem !important; }
  .sm\+\:mr-n56 {
    margin-right: -14rem !important; }
  .sm\+\:mb-n56 {
    margin-bottom: -14rem !important; }
  .sm\+\:ml-n56 {
    margin-left: -14rem !important; }
  .sm\+\:mx-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important; }
  .sm\+\:my-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important; }
  .sm\+\:p56 {
    padding: 14rem !important; }
  .sm\+\:pt-56 {
    padding-top: 14rem !important; }
  .sm\+\:pr-56 {
    padding-right: 14rem !important; }
  .sm\+\:pb-56 {
    padding-bottom: 14rem !important; }
  .sm\+\:pl-56 {
    padding-left: 14rem !important; }
  .sm\+\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important; }
  .sm\+\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .sm\+\:m64 {
    margin: 16rem !important; }
  .sm\+\:mt-64 {
    margin-top: 16rem !important; }
  .sm\+\:mr-64 {
    margin-right: 16rem !important; }
  .sm\+\:mb-64 {
    margin-bottom: 16rem !important; }
  .sm\+\:ml-64 {
    margin-left: 16rem !important; }
  .sm\+\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important; }
  .sm\+\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .sm\+\:mt-n64 {
    margin-top: -16rem !important; }
  .sm\+\:mr-n64 {
    margin-right: -16rem !important; }
  .sm\+\:mb-n64 {
    margin-bottom: -16rem !important; }
  .sm\+\:ml-n64 {
    margin-left: -16rem !important; }
  .sm\+\:mx-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important; }
  .sm\+\:my-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important; }
  .sm\+\:p64 {
    padding: 16rem !important; }
  .sm\+\:pt-64 {
    padding-top: 16rem !important; }
  .sm\+\:pr-64 {
    padding-right: 16rem !important; }
  .sm\+\:pb-64 {
    padding-bottom: 16rem !important; }
  .sm\+\:pl-64 {
    padding-left: 16rem !important; }
  .sm\+\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important; }
  .sm\+\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .sm\+\:m85 {
    margin: 21.25rem !important; }
  .sm\+\:mt-85 {
    margin-top: 21.25rem !important; }
  .sm\+\:mr-85 {
    margin-right: 21.25rem !important; }
  .sm\+\:mb-85 {
    margin-bottom: 21.25rem !important; }
  .sm\+\:ml-85 {
    margin-left: 21.25rem !important; }
  .sm\+\:mx-85 {
    margin-left: 21.25rem !important;
    margin-right: 21.25rem !important; }
  .sm\+\:my-85 {
    margin-top: 21.25rem !important;
    margin-bottom: 21.25rem !important; }
  .sm\+\:mt-n85 {
    margin-top: -21.25rem !important; }
  .sm\+\:mr-n85 {
    margin-right: -21.25rem !important; }
  .sm\+\:mb-n85 {
    margin-bottom: -21.25rem !important; }
  .sm\+\:ml-n85 {
    margin-left: -21.25rem !important; }
  .sm\+\:mx-n85 {
    margin-left: -21.25rem !important;
    margin-right: -21.25rem !important; }
  .sm\+\:my-n85 {
    margin-top: -21.25rem !important;
    margin-bottom: -21.25rem !important; }
  .sm\+\:p85 {
    padding: 21.25rem !important; }
  .sm\+\:pt-85 {
    padding-top: 21.25rem !important; }
  .sm\+\:pr-85 {
    padding-right: 21.25rem !important; }
  .sm\+\:pb-85 {
    padding-bottom: 21.25rem !important; }
  .sm\+\:pl-85 {
    padding-left: 21.25rem !important; }
  .sm\+\:px-85 {
    padding-left: 21.25rem !important;
    padding-right: 21.25rem !important; }
  .sm\+\:py-85 {
    padding-top: 21.25rem !important;
    padding-bottom: 21.25rem !important; }
  .sm\+\:mauto {
    margin: auto !important; }
  .sm\+\:mt-auto {
    margin-top: auto !important; }
  .sm\+\:mr-auto {
    margin-right: auto !important; }
  .sm\+\:mb-auto {
    margin-bottom: auto !important; }
  .sm\+\:ml-auto {
    margin-left: auto !important; }
  .sm\+\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important; }
  .sm\+\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .sm\+\:mt-nauto {
    margin-top: -auto !important; }
  .sm\+\:mr-nauto {
    margin-right: -auto !important; }
  .sm\+\:mb-nauto {
    margin-bottom: -auto !important; }
  .sm\+\:ml-nauto {
    margin-left: -auto !important; }
  .sm\+\:mx-nauto {
    margin-left: -auto !important;
    margin-right: -auto !important; }
  .sm\+\:my-nauto {
    margin-top: -auto !important;
    margin-bottom: -auto !important; }
  .sm\+\:pauto {
    padding: auto !important; }
  .sm\+\:pt-auto {
    padding-top: auto !important; }
  .sm\+\:pr-auto {
    padding-right: auto !important; }
  .sm\+\:pb-auto {
    padding-bottom: auto !important; }
  .sm\+\:pl-auto {
    padding-left: auto !important; }
  .sm\+\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important; }
  .sm\+\:py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important; } }

@media (min-width: 768px) {
  .md\:m0 {
    margin: 0 !important; }
  .md\:mt-0 {
    margin-top: 0 !important; }
  .md\:mr-0 {
    margin-right: 0 !important; }
  .md\:mb-0 {
    margin-bottom: 0 !important; }
  .md\:ml-0 {
    margin-left: 0 !important; }
  .md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md\:mt-n0 {
    margin-top: 0 !important; }
  .md\:mr-n0 {
    margin-right: 0 !important; }
  .md\:mb-n0 {
    margin-bottom: 0 !important; }
  .md\:ml-n0 {
    margin-left: 0 !important; }
  .md\:mx-n0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md\:my-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md\:p0 {
    padding: 0 !important; }
  .md\:pt-0 {
    padding-top: 0 !important; }
  .md\:pr-0 {
    padding-right: 0 !important; }
  .md\:pb-0 {
    padding-bottom: 0 !important; }
  .md\:pl-0 {
    padding-left: 0 !important; }
  .md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md\:m1 {
    margin: 0.25rem !important; }
  .md\:mt-1 {
    margin-top: 0.25rem !important; }
  .md\:mr-1 {
    margin-right: 0.25rem !important; }
  .md\:mb-1 {
    margin-bottom: 0.25rem !important; }
  .md\:ml-1 {
    margin-left: 0.25rem !important; }
  .md\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
  .md\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .md\:mt-n1 {
    margin-top: -0.25rem !important; }
  .md\:mr-n1 {
    margin-right: -0.25rem !important; }
  .md\:mb-n1 {
    margin-bottom: -0.25rem !important; }
  .md\:ml-n1 {
    margin-left: -0.25rem !important; }
  .md\:mx-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important; }
  .md\:my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .md\:p1 {
    padding: 0.25rem !important; }
  .md\:pt-1 {
    padding-top: 0.25rem !important; }
  .md\:pr-1 {
    padding-right: 0.25rem !important; }
  .md\:pb-1 {
    padding-bottom: 0.25rem !important; }
  .md\:pl-1 {
    padding-left: 0.25rem !important; }
  .md\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
  .md\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .md\:m2 {
    margin: 0.5rem !important; }
  .md\:mt-2 {
    margin-top: 0.5rem !important; }
  .md\:mr-2 {
    margin-right: 0.5rem !important; }
  .md\:mb-2 {
    margin-bottom: 0.5rem !important; }
  .md\:ml-2 {
    margin-left: 0.5rem !important; }
  .md\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
  .md\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .md\:mt-n2 {
    margin-top: -0.5rem !important; }
  .md\:mr-n2 {
    margin-right: -0.5rem !important; }
  .md\:mb-n2 {
    margin-bottom: -0.5rem !important; }
  .md\:ml-n2 {
    margin-left: -0.5rem !important; }
  .md\:mx-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important; }
  .md\:my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .md\:p2 {
    padding: 0.5rem !important; }
  .md\:pt-2 {
    padding-top: 0.5rem !important; }
  .md\:pr-2 {
    padding-right: 0.5rem !important; }
  .md\:pb-2 {
    padding-bottom: 0.5rem !important; }
  .md\:pl-2 {
    padding-left: 0.5rem !important; }
  .md\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
  .md\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .md\:m3 {
    margin: 0.75rem !important; }
  .md\:mt-3 {
    margin-top: 0.75rem !important; }
  .md\:mr-3 {
    margin-right: 0.75rem !important; }
  .md\:mb-3 {
    margin-bottom: 0.75rem !important; }
  .md\:ml-3 {
    margin-left: 0.75rem !important; }
  .md\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important; }
  .md\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .md\:mt-n3 {
    margin-top: -0.75rem !important; }
  .md\:mr-n3 {
    margin-right: -0.75rem !important; }
  .md\:mb-n3 {
    margin-bottom: -0.75rem !important; }
  .md\:ml-n3 {
    margin-left: -0.75rem !important; }
  .md\:mx-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important; }
  .md\:my-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .md\:p3 {
    padding: 0.75rem !important; }
  .md\:pt-3 {
    padding-top: 0.75rem !important; }
  .md\:pr-3 {
    padding-right: 0.75rem !important; }
  .md\:pb-3 {
    padding-bottom: 0.75rem !important; }
  .md\:pl-3 {
    padding-left: 0.75rem !important; }
  .md\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important; }
  .md\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .md\:m4 {
    margin: 1rem !important; }
  .md\:mt-4 {
    margin-top: 1rem !important; }
  .md\:mr-4 {
    margin-right: 1rem !important; }
  .md\:mb-4 {
    margin-bottom: 1rem !important; }
  .md\:ml-4 {
    margin-left: 1rem !important; }
  .md\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
  .md\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .md\:mt-n4 {
    margin-top: -1rem !important; }
  .md\:mr-n4 {
    margin-right: -1rem !important; }
  .md\:mb-n4 {
    margin-bottom: -1rem !important; }
  .md\:ml-n4 {
    margin-left: -1rem !important; }
  .md\:mx-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important; }
  .md\:my-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .md\:p4 {
    padding: 1rem !important; }
  .md\:pt-4 {
    padding-top: 1rem !important; }
  .md\:pr-4 {
    padding-right: 1rem !important; }
  .md\:pb-4 {
    padding-bottom: 1rem !important; }
  .md\:pl-4 {
    padding-left: 1rem !important; }
  .md\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .md\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .md\:m5 {
    margin: 1.25rem !important; }
  .md\:mt-5 {
    margin-top: 1.25rem !important; }
  .md\:mr-5 {
    margin-right: 1.25rem !important; }
  .md\:mb-5 {
    margin-bottom: 1.25rem !important; }
  .md\:ml-5 {
    margin-left: 1.25rem !important; }
  .md\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important; }
  .md\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .md\:mt-n5 {
    margin-top: -1.25rem !important; }
  .md\:mr-n5 {
    margin-right: -1.25rem !important; }
  .md\:mb-n5 {
    margin-bottom: -1.25rem !important; }
  .md\:ml-n5 {
    margin-left: -1.25rem !important; }
  .md\:mx-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important; }
  .md\:my-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .md\:p5 {
    padding: 1.25rem !important; }
  .md\:pt-5 {
    padding-top: 1.25rem !important; }
  .md\:pr-5 {
    padding-right: 1.25rem !important; }
  .md\:pb-5 {
    padding-bottom: 1.25rem !important; }
  .md\:pl-5 {
    padding-left: 1.25rem !important; }
  .md\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .md\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .md\:m6 {
    margin: 1.5rem !important; }
  .md\:mt-6 {
    margin-top: 1.5rem !important; }
  .md\:mr-6 {
    margin-right: 1.5rem !important; }
  .md\:mb-6 {
    margin-bottom: 1.5rem !important; }
  .md\:ml-6 {
    margin-left: 1.5rem !important; }
  .md\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
  .md\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .md\:mt-n6 {
    margin-top: -1.5rem !important; }
  .md\:mr-n6 {
    margin-right: -1.5rem !important; }
  .md\:mb-n6 {
    margin-bottom: -1.5rem !important; }
  .md\:ml-n6 {
    margin-left: -1.5rem !important; }
  .md\:mx-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important; }
  .md\:my-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .md\:p6 {
    padding: 1.5rem !important; }
  .md\:pt-6 {
    padding-top: 1.5rem !important; }
  .md\:pr-6 {
    padding-right: 1.5rem !important; }
  .md\:pb-6 {
    padding-bottom: 1.5rem !important; }
  .md\:pl-6 {
    padding-left: 1.5rem !important; }
  .md\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
  .md\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .md\:m8 {
    margin: 2rem !important; }
  .md\:mt-8 {
    margin-top: 2rem !important; }
  .md\:mr-8 {
    margin-right: 2rem !important; }
  .md\:mb-8 {
    margin-bottom: 2rem !important; }
  .md\:ml-8 {
    margin-left: 2rem !important; }
  .md\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important; }
  .md\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .md\:mt-n8 {
    margin-top: -2rem !important; }
  .md\:mr-n8 {
    margin-right: -2rem !important; }
  .md\:mb-n8 {
    margin-bottom: -2rem !important; }
  .md\:ml-n8 {
    margin-left: -2rem !important; }
  .md\:mx-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important; }
  .md\:my-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .md\:p8 {
    padding: 2rem !important; }
  .md\:pt-8 {
    padding-top: 2rem !important; }
  .md\:pr-8 {
    padding-right: 2rem !important; }
  .md\:pb-8 {
    padding-bottom: 2rem !important; }
  .md\:pl-8 {
    padding-left: 2rem !important; }
  .md\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  .md\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .md\:m10 {
    margin: 2.5rem !important; }
  .md\:mt-10 {
    margin-top: 2.5rem !important; }
  .md\:mr-10 {
    margin-right: 2.5rem !important; }
  .md\:mb-10 {
    margin-bottom: 2.5rem !important; }
  .md\:ml-10 {
    margin-left: 2.5rem !important; }
  .md\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important; }
  .md\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .md\:mt-n10 {
    margin-top: -2.5rem !important; }
  .md\:mr-n10 {
    margin-right: -2.5rem !important; }
  .md\:mb-n10 {
    margin-bottom: -2.5rem !important; }
  .md\:ml-n10 {
    margin-left: -2.5rem !important; }
  .md\:mx-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important; }
  .md\:my-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .md\:p10 {
    padding: 2.5rem !important; }
  .md\:pt-10 {
    padding-top: 2.5rem !important; }
  .md\:pr-10 {
    padding-right: 2.5rem !important; }
  .md\:pb-10 {
    padding-bottom: 2.5rem !important; }
  .md\:pl-10 {
    padding-left: 2.5rem !important; }
  .md\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important; }
  .md\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .md\:m12 {
    margin: 3rem !important; }
  .md\:mt-12 {
    margin-top: 3rem !important; }
  .md\:mr-12 {
    margin-right: 3rem !important; }
  .md\:mb-12 {
    margin-bottom: 3rem !important; }
  .md\:ml-12 {
    margin-left: 3rem !important; }
  .md\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
  .md\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .md\:mt-n12 {
    margin-top: -3rem !important; }
  .md\:mr-n12 {
    margin-right: -3rem !important; }
  .md\:mb-n12 {
    margin-bottom: -3rem !important; }
  .md\:ml-n12 {
    margin-left: -3rem !important; }
  .md\:mx-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important; }
  .md\:my-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .md\:p12 {
    padding: 3rem !important; }
  .md\:pt-12 {
    padding-top: 3rem !important; }
  .md\:pr-12 {
    padding-right: 3rem !important; }
  .md\:pb-12 {
    padding-bottom: 3rem !important; }
  .md\:pl-12 {
    padding-left: 3rem !important; }
  .md\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
  .md\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .md\:m16 {
    margin: 4rem !important; }
  .md\:mt-16 {
    margin-top: 4rem !important; }
  .md\:mr-16 {
    margin-right: 4rem !important; }
  .md\:mb-16 {
    margin-bottom: 4rem !important; }
  .md\:ml-16 {
    margin-left: 4rem !important; }
  .md\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important; }
  .md\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .md\:mt-n16 {
    margin-top: -4rem !important; }
  .md\:mr-n16 {
    margin-right: -4rem !important; }
  .md\:mb-n16 {
    margin-bottom: -4rem !important; }
  .md\:ml-n16 {
    margin-left: -4rem !important; }
  .md\:mx-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important; }
  .md\:my-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .md\:p16 {
    padding: 4rem !important; }
  .md\:pt-16 {
    padding-top: 4rem !important; }
  .md\:pr-16 {
    padding-right: 4rem !important; }
  .md\:pb-16 {
    padding-bottom: 4rem !important; }
  .md\:pl-16 {
    padding-left: 4rem !important; }
  .md\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important; }
  .md\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .md\:m20 {
    margin: 5rem !important; }
  .md\:mt-20 {
    margin-top: 5rem !important; }
  .md\:mr-20 {
    margin-right: 5rem !important; }
  .md\:mb-20 {
    margin-bottom: 5rem !important; }
  .md\:ml-20 {
    margin-left: 5rem !important; }
  .md\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important; }
  .md\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .md\:mt-n20 {
    margin-top: -5rem !important; }
  .md\:mr-n20 {
    margin-right: -5rem !important; }
  .md\:mb-n20 {
    margin-bottom: -5rem !important; }
  .md\:ml-n20 {
    margin-left: -5rem !important; }
  .md\:mx-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important; }
  .md\:my-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .md\:p20 {
    padding: 5rem !important; }
  .md\:pt-20 {
    padding-top: 5rem !important; }
  .md\:pr-20 {
    padding-right: 5rem !important; }
  .md\:pb-20 {
    padding-bottom: 5rem !important; }
  .md\:pl-20 {
    padding-left: 5rem !important; }
  .md\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important; }
  .md\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .md\:m24 {
    margin: 6rem !important; }
  .md\:mt-24 {
    margin-top: 6rem !important; }
  .md\:mr-24 {
    margin-right: 6rem !important; }
  .md\:mb-24 {
    margin-bottom: 6rem !important; }
  .md\:ml-24 {
    margin-left: 6rem !important; }
  .md\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important; }
  .md\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .md\:mt-n24 {
    margin-top: -6rem !important; }
  .md\:mr-n24 {
    margin-right: -6rem !important; }
  .md\:mb-n24 {
    margin-bottom: -6rem !important; }
  .md\:ml-n24 {
    margin-left: -6rem !important; }
  .md\:mx-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important; }
  .md\:my-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .md\:p24 {
    padding: 6rem !important; }
  .md\:pt-24 {
    padding-top: 6rem !important; }
  .md\:pr-24 {
    padding-right: 6rem !important; }
  .md\:pb-24 {
    padding-bottom: 6rem !important; }
  .md\:pl-24 {
    padding-left: 6rem !important; }
  .md\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important; }
  .md\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .md\:m32 {
    margin: 8rem !important; }
  .md\:mt-32 {
    margin-top: 8rem !important; }
  .md\:mr-32 {
    margin-right: 8rem !important; }
  .md\:mb-32 {
    margin-bottom: 8rem !important; }
  .md\:ml-32 {
    margin-left: 8rem !important; }
  .md\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important; }
  .md\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .md\:mt-n32 {
    margin-top: -8rem !important; }
  .md\:mr-n32 {
    margin-right: -8rem !important; }
  .md\:mb-n32 {
    margin-bottom: -8rem !important; }
  .md\:ml-n32 {
    margin-left: -8rem !important; }
  .md\:mx-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important; }
  .md\:my-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important; }
  .md\:p32 {
    padding: 8rem !important; }
  .md\:pt-32 {
    padding-top: 8rem !important; }
  .md\:pr-32 {
    padding-right: 8rem !important; }
  .md\:pb-32 {
    padding-bottom: 8rem !important; }
  .md\:pl-32 {
    padding-left: 8rem !important; }
  .md\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important; }
  .md\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .md\:m40 {
    margin: 10rem !important; }
  .md\:mt-40 {
    margin-top: 10rem !important; }
  .md\:mr-40 {
    margin-right: 10rem !important; }
  .md\:mb-40 {
    margin-bottom: 10rem !important; }
  .md\:ml-40 {
    margin-left: 10rem !important; }
  .md\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important; }
  .md\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .md\:mt-n40 {
    margin-top: -10rem !important; }
  .md\:mr-n40 {
    margin-right: -10rem !important; }
  .md\:mb-n40 {
    margin-bottom: -10rem !important; }
  .md\:ml-n40 {
    margin-left: -10rem !important; }
  .md\:mx-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important; }
  .md\:my-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .md\:p40 {
    padding: 10rem !important; }
  .md\:pt-40 {
    padding-top: 10rem !important; }
  .md\:pr-40 {
    padding-right: 10rem !important; }
  .md\:pb-40 {
    padding-bottom: 10rem !important; }
  .md\:pl-40 {
    padding-left: 10rem !important; }
  .md\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important; }
  .md\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .md\:m48 {
    margin: 12rem !important; }
  .md\:mt-48 {
    margin-top: 12rem !important; }
  .md\:mr-48 {
    margin-right: 12rem !important; }
  .md\:mb-48 {
    margin-bottom: 12rem !important; }
  .md\:ml-48 {
    margin-left: 12rem !important; }
  .md\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important; }
  .md\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .md\:mt-n48 {
    margin-top: -12rem !important; }
  .md\:mr-n48 {
    margin-right: -12rem !important; }
  .md\:mb-n48 {
    margin-bottom: -12rem !important; }
  .md\:ml-n48 {
    margin-left: -12rem !important; }
  .md\:mx-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important; }
  .md\:my-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important; }
  .md\:p48 {
    padding: 12rem !important; }
  .md\:pt-48 {
    padding-top: 12rem !important; }
  .md\:pr-48 {
    padding-right: 12rem !important; }
  .md\:pb-48 {
    padding-bottom: 12rem !important; }
  .md\:pl-48 {
    padding-left: 12rem !important; }
  .md\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important; }
  .md\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .md\:m56 {
    margin: 14rem !important; }
  .md\:mt-56 {
    margin-top: 14rem !important; }
  .md\:mr-56 {
    margin-right: 14rem !important; }
  .md\:mb-56 {
    margin-bottom: 14rem !important; }
  .md\:ml-56 {
    margin-left: 14rem !important; }
  .md\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important; }
  .md\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .md\:mt-n56 {
    margin-top: -14rem !important; }
  .md\:mr-n56 {
    margin-right: -14rem !important; }
  .md\:mb-n56 {
    margin-bottom: -14rem !important; }
  .md\:ml-n56 {
    margin-left: -14rem !important; }
  .md\:mx-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important; }
  .md\:my-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important; }
  .md\:p56 {
    padding: 14rem !important; }
  .md\:pt-56 {
    padding-top: 14rem !important; }
  .md\:pr-56 {
    padding-right: 14rem !important; }
  .md\:pb-56 {
    padding-bottom: 14rem !important; }
  .md\:pl-56 {
    padding-left: 14rem !important; }
  .md\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important; }
  .md\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .md\:m64 {
    margin: 16rem !important; }
  .md\:mt-64 {
    margin-top: 16rem !important; }
  .md\:mr-64 {
    margin-right: 16rem !important; }
  .md\:mb-64 {
    margin-bottom: 16rem !important; }
  .md\:ml-64 {
    margin-left: 16rem !important; }
  .md\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important; }
  .md\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .md\:mt-n64 {
    margin-top: -16rem !important; }
  .md\:mr-n64 {
    margin-right: -16rem !important; }
  .md\:mb-n64 {
    margin-bottom: -16rem !important; }
  .md\:ml-n64 {
    margin-left: -16rem !important; }
  .md\:mx-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important; }
  .md\:my-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important; }
  .md\:p64 {
    padding: 16rem !important; }
  .md\:pt-64 {
    padding-top: 16rem !important; }
  .md\:pr-64 {
    padding-right: 16rem !important; }
  .md\:pb-64 {
    padding-bottom: 16rem !important; }
  .md\:pl-64 {
    padding-left: 16rem !important; }
  .md\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important; }
  .md\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .md\:m85 {
    margin: 21.25rem !important; }
  .md\:mt-85 {
    margin-top: 21.25rem !important; }
  .md\:mr-85 {
    margin-right: 21.25rem !important; }
  .md\:mb-85 {
    margin-bottom: 21.25rem !important; }
  .md\:ml-85 {
    margin-left: 21.25rem !important; }
  .md\:mx-85 {
    margin-left: 21.25rem !important;
    margin-right: 21.25rem !important; }
  .md\:my-85 {
    margin-top: 21.25rem !important;
    margin-bottom: 21.25rem !important; }
  .md\:mt-n85 {
    margin-top: -21.25rem !important; }
  .md\:mr-n85 {
    margin-right: -21.25rem !important; }
  .md\:mb-n85 {
    margin-bottom: -21.25rem !important; }
  .md\:ml-n85 {
    margin-left: -21.25rem !important; }
  .md\:mx-n85 {
    margin-left: -21.25rem !important;
    margin-right: -21.25rem !important; }
  .md\:my-n85 {
    margin-top: -21.25rem !important;
    margin-bottom: -21.25rem !important; }
  .md\:p85 {
    padding: 21.25rem !important; }
  .md\:pt-85 {
    padding-top: 21.25rem !important; }
  .md\:pr-85 {
    padding-right: 21.25rem !important; }
  .md\:pb-85 {
    padding-bottom: 21.25rem !important; }
  .md\:pl-85 {
    padding-left: 21.25rem !important; }
  .md\:px-85 {
    padding-left: 21.25rem !important;
    padding-right: 21.25rem !important; }
  .md\:py-85 {
    padding-top: 21.25rem !important;
    padding-bottom: 21.25rem !important; }
  .md\:mauto {
    margin: auto !important; }
  .md\:mt-auto {
    margin-top: auto !important; }
  .md\:mr-auto {
    margin-right: auto !important; }
  .md\:mb-auto {
    margin-bottom: auto !important; }
  .md\:ml-auto {
    margin-left: auto !important; }
  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important; }
  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .md\:mt-nauto {
    margin-top: -auto !important; }
  .md\:mr-nauto {
    margin-right: -auto !important; }
  .md\:mb-nauto {
    margin-bottom: -auto !important; }
  .md\:ml-nauto {
    margin-left: -auto !important; }
  .md\:mx-nauto {
    margin-left: -auto !important;
    margin-right: -auto !important; }
  .md\:my-nauto {
    margin-top: -auto !important;
    margin-bottom: -auto !important; }
  .md\:pauto {
    padding: auto !important; }
  .md\:pt-auto {
    padding-top: auto !important; }
  .md\:pr-auto {
    padding-right: auto !important; }
  .md\:pb-auto {
    padding-bottom: auto !important; }
  .md\:pl-auto {
    padding-left: auto !important; }
  .md\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important; }
  .md\:py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important; } }

@media (min-width: 1024px) {
  .md\+\:m0 {
    margin: 0 !important; }
  .md\+\:mt-0 {
    margin-top: 0 !important; }
  .md\+\:mr-0 {
    margin-right: 0 !important; }
  .md\+\:mb-0 {
    margin-bottom: 0 !important; }
  .md\+\:ml-0 {
    margin-left: 0 !important; }
  .md\+\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md\+\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md\+\:mt-n0 {
    margin-top: 0 !important; }
  .md\+\:mr-n0 {
    margin-right: 0 !important; }
  .md\+\:mb-n0 {
    margin-bottom: 0 !important; }
  .md\+\:ml-n0 {
    margin-left: 0 !important; }
  .md\+\:mx-n0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md\+\:my-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md\+\:p0 {
    padding: 0 !important; }
  .md\+\:pt-0 {
    padding-top: 0 !important; }
  .md\+\:pr-0 {
    padding-right: 0 !important; }
  .md\+\:pb-0 {
    padding-bottom: 0 !important; }
  .md\+\:pl-0 {
    padding-left: 0 !important; }
  .md\+\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md\+\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md\+\:m1 {
    margin: 0.25rem !important; }
  .md\+\:mt-1 {
    margin-top: 0.25rem !important; }
  .md\+\:mr-1 {
    margin-right: 0.25rem !important; }
  .md\+\:mb-1 {
    margin-bottom: 0.25rem !important; }
  .md\+\:ml-1 {
    margin-left: 0.25rem !important; }
  .md\+\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
  .md\+\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .md\+\:mt-n1 {
    margin-top: -0.25rem !important; }
  .md\+\:mr-n1 {
    margin-right: -0.25rem !important; }
  .md\+\:mb-n1 {
    margin-bottom: -0.25rem !important; }
  .md\+\:ml-n1 {
    margin-left: -0.25rem !important; }
  .md\+\:mx-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important; }
  .md\+\:my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .md\+\:p1 {
    padding: 0.25rem !important; }
  .md\+\:pt-1 {
    padding-top: 0.25rem !important; }
  .md\+\:pr-1 {
    padding-right: 0.25rem !important; }
  .md\+\:pb-1 {
    padding-bottom: 0.25rem !important; }
  .md\+\:pl-1 {
    padding-left: 0.25rem !important; }
  .md\+\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
  .md\+\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .md\+\:m2 {
    margin: 0.5rem !important; }
  .md\+\:mt-2 {
    margin-top: 0.5rem !important; }
  .md\+\:mr-2 {
    margin-right: 0.5rem !important; }
  .md\+\:mb-2 {
    margin-bottom: 0.5rem !important; }
  .md\+\:ml-2 {
    margin-left: 0.5rem !important; }
  .md\+\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
  .md\+\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .md\+\:mt-n2 {
    margin-top: -0.5rem !important; }
  .md\+\:mr-n2 {
    margin-right: -0.5rem !important; }
  .md\+\:mb-n2 {
    margin-bottom: -0.5rem !important; }
  .md\+\:ml-n2 {
    margin-left: -0.5rem !important; }
  .md\+\:mx-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important; }
  .md\+\:my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .md\+\:p2 {
    padding: 0.5rem !important; }
  .md\+\:pt-2 {
    padding-top: 0.5rem !important; }
  .md\+\:pr-2 {
    padding-right: 0.5rem !important; }
  .md\+\:pb-2 {
    padding-bottom: 0.5rem !important; }
  .md\+\:pl-2 {
    padding-left: 0.5rem !important; }
  .md\+\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
  .md\+\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .md\+\:m3 {
    margin: 0.75rem !important; }
  .md\+\:mt-3 {
    margin-top: 0.75rem !important; }
  .md\+\:mr-3 {
    margin-right: 0.75rem !important; }
  .md\+\:mb-3 {
    margin-bottom: 0.75rem !important; }
  .md\+\:ml-3 {
    margin-left: 0.75rem !important; }
  .md\+\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important; }
  .md\+\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .md\+\:mt-n3 {
    margin-top: -0.75rem !important; }
  .md\+\:mr-n3 {
    margin-right: -0.75rem !important; }
  .md\+\:mb-n3 {
    margin-bottom: -0.75rem !important; }
  .md\+\:ml-n3 {
    margin-left: -0.75rem !important; }
  .md\+\:mx-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important; }
  .md\+\:my-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .md\+\:p3 {
    padding: 0.75rem !important; }
  .md\+\:pt-3 {
    padding-top: 0.75rem !important; }
  .md\+\:pr-3 {
    padding-right: 0.75rem !important; }
  .md\+\:pb-3 {
    padding-bottom: 0.75rem !important; }
  .md\+\:pl-3 {
    padding-left: 0.75rem !important; }
  .md\+\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important; }
  .md\+\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .md\+\:m4 {
    margin: 1rem !important; }
  .md\+\:mt-4 {
    margin-top: 1rem !important; }
  .md\+\:mr-4 {
    margin-right: 1rem !important; }
  .md\+\:mb-4 {
    margin-bottom: 1rem !important; }
  .md\+\:ml-4 {
    margin-left: 1rem !important; }
  .md\+\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
  .md\+\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .md\+\:mt-n4 {
    margin-top: -1rem !important; }
  .md\+\:mr-n4 {
    margin-right: -1rem !important; }
  .md\+\:mb-n4 {
    margin-bottom: -1rem !important; }
  .md\+\:ml-n4 {
    margin-left: -1rem !important; }
  .md\+\:mx-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important; }
  .md\+\:my-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .md\+\:p4 {
    padding: 1rem !important; }
  .md\+\:pt-4 {
    padding-top: 1rem !important; }
  .md\+\:pr-4 {
    padding-right: 1rem !important; }
  .md\+\:pb-4 {
    padding-bottom: 1rem !important; }
  .md\+\:pl-4 {
    padding-left: 1rem !important; }
  .md\+\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .md\+\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .md\+\:m5 {
    margin: 1.25rem !important; }
  .md\+\:mt-5 {
    margin-top: 1.25rem !important; }
  .md\+\:mr-5 {
    margin-right: 1.25rem !important; }
  .md\+\:mb-5 {
    margin-bottom: 1.25rem !important; }
  .md\+\:ml-5 {
    margin-left: 1.25rem !important; }
  .md\+\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important; }
  .md\+\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .md\+\:mt-n5 {
    margin-top: -1.25rem !important; }
  .md\+\:mr-n5 {
    margin-right: -1.25rem !important; }
  .md\+\:mb-n5 {
    margin-bottom: -1.25rem !important; }
  .md\+\:ml-n5 {
    margin-left: -1.25rem !important; }
  .md\+\:mx-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important; }
  .md\+\:my-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .md\+\:p5 {
    padding: 1.25rem !important; }
  .md\+\:pt-5 {
    padding-top: 1.25rem !important; }
  .md\+\:pr-5 {
    padding-right: 1.25rem !important; }
  .md\+\:pb-5 {
    padding-bottom: 1.25rem !important; }
  .md\+\:pl-5 {
    padding-left: 1.25rem !important; }
  .md\+\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .md\+\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .md\+\:m6 {
    margin: 1.5rem !important; }
  .md\+\:mt-6 {
    margin-top: 1.5rem !important; }
  .md\+\:mr-6 {
    margin-right: 1.5rem !important; }
  .md\+\:mb-6 {
    margin-bottom: 1.5rem !important; }
  .md\+\:ml-6 {
    margin-left: 1.5rem !important; }
  .md\+\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
  .md\+\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .md\+\:mt-n6 {
    margin-top: -1.5rem !important; }
  .md\+\:mr-n6 {
    margin-right: -1.5rem !important; }
  .md\+\:mb-n6 {
    margin-bottom: -1.5rem !important; }
  .md\+\:ml-n6 {
    margin-left: -1.5rem !important; }
  .md\+\:mx-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important; }
  .md\+\:my-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .md\+\:p6 {
    padding: 1.5rem !important; }
  .md\+\:pt-6 {
    padding-top: 1.5rem !important; }
  .md\+\:pr-6 {
    padding-right: 1.5rem !important; }
  .md\+\:pb-6 {
    padding-bottom: 1.5rem !important; }
  .md\+\:pl-6 {
    padding-left: 1.5rem !important; }
  .md\+\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
  .md\+\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .md\+\:m8 {
    margin: 2rem !important; }
  .md\+\:mt-8 {
    margin-top: 2rem !important; }
  .md\+\:mr-8 {
    margin-right: 2rem !important; }
  .md\+\:mb-8 {
    margin-bottom: 2rem !important; }
  .md\+\:ml-8 {
    margin-left: 2rem !important; }
  .md\+\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important; }
  .md\+\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .md\+\:mt-n8 {
    margin-top: -2rem !important; }
  .md\+\:mr-n8 {
    margin-right: -2rem !important; }
  .md\+\:mb-n8 {
    margin-bottom: -2rem !important; }
  .md\+\:ml-n8 {
    margin-left: -2rem !important; }
  .md\+\:mx-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important; }
  .md\+\:my-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .md\+\:p8 {
    padding: 2rem !important; }
  .md\+\:pt-8 {
    padding-top: 2rem !important; }
  .md\+\:pr-8 {
    padding-right: 2rem !important; }
  .md\+\:pb-8 {
    padding-bottom: 2rem !important; }
  .md\+\:pl-8 {
    padding-left: 2rem !important; }
  .md\+\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  .md\+\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .md\+\:m10 {
    margin: 2.5rem !important; }
  .md\+\:mt-10 {
    margin-top: 2.5rem !important; }
  .md\+\:mr-10 {
    margin-right: 2.5rem !important; }
  .md\+\:mb-10 {
    margin-bottom: 2.5rem !important; }
  .md\+\:ml-10 {
    margin-left: 2.5rem !important; }
  .md\+\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important; }
  .md\+\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .md\+\:mt-n10 {
    margin-top: -2.5rem !important; }
  .md\+\:mr-n10 {
    margin-right: -2.5rem !important; }
  .md\+\:mb-n10 {
    margin-bottom: -2.5rem !important; }
  .md\+\:ml-n10 {
    margin-left: -2.5rem !important; }
  .md\+\:mx-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important; }
  .md\+\:my-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .md\+\:p10 {
    padding: 2.5rem !important; }
  .md\+\:pt-10 {
    padding-top: 2.5rem !important; }
  .md\+\:pr-10 {
    padding-right: 2.5rem !important; }
  .md\+\:pb-10 {
    padding-bottom: 2.5rem !important; }
  .md\+\:pl-10 {
    padding-left: 2.5rem !important; }
  .md\+\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important; }
  .md\+\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .md\+\:m12 {
    margin: 3rem !important; }
  .md\+\:mt-12 {
    margin-top: 3rem !important; }
  .md\+\:mr-12 {
    margin-right: 3rem !important; }
  .md\+\:mb-12 {
    margin-bottom: 3rem !important; }
  .md\+\:ml-12 {
    margin-left: 3rem !important; }
  .md\+\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
  .md\+\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .md\+\:mt-n12 {
    margin-top: -3rem !important; }
  .md\+\:mr-n12 {
    margin-right: -3rem !important; }
  .md\+\:mb-n12 {
    margin-bottom: -3rem !important; }
  .md\+\:ml-n12 {
    margin-left: -3rem !important; }
  .md\+\:mx-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important; }
  .md\+\:my-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .md\+\:p12 {
    padding: 3rem !important; }
  .md\+\:pt-12 {
    padding-top: 3rem !important; }
  .md\+\:pr-12 {
    padding-right: 3rem !important; }
  .md\+\:pb-12 {
    padding-bottom: 3rem !important; }
  .md\+\:pl-12 {
    padding-left: 3rem !important; }
  .md\+\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
  .md\+\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .md\+\:m16 {
    margin: 4rem !important; }
  .md\+\:mt-16 {
    margin-top: 4rem !important; }
  .md\+\:mr-16 {
    margin-right: 4rem !important; }
  .md\+\:mb-16 {
    margin-bottom: 4rem !important; }
  .md\+\:ml-16 {
    margin-left: 4rem !important; }
  .md\+\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important; }
  .md\+\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .md\+\:mt-n16 {
    margin-top: -4rem !important; }
  .md\+\:mr-n16 {
    margin-right: -4rem !important; }
  .md\+\:mb-n16 {
    margin-bottom: -4rem !important; }
  .md\+\:ml-n16 {
    margin-left: -4rem !important; }
  .md\+\:mx-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important; }
  .md\+\:my-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .md\+\:p16 {
    padding: 4rem !important; }
  .md\+\:pt-16 {
    padding-top: 4rem !important; }
  .md\+\:pr-16 {
    padding-right: 4rem !important; }
  .md\+\:pb-16 {
    padding-bottom: 4rem !important; }
  .md\+\:pl-16 {
    padding-left: 4rem !important; }
  .md\+\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important; }
  .md\+\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .md\+\:m20 {
    margin: 5rem !important; }
  .md\+\:mt-20 {
    margin-top: 5rem !important; }
  .md\+\:mr-20 {
    margin-right: 5rem !important; }
  .md\+\:mb-20 {
    margin-bottom: 5rem !important; }
  .md\+\:ml-20 {
    margin-left: 5rem !important; }
  .md\+\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important; }
  .md\+\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .md\+\:mt-n20 {
    margin-top: -5rem !important; }
  .md\+\:mr-n20 {
    margin-right: -5rem !important; }
  .md\+\:mb-n20 {
    margin-bottom: -5rem !important; }
  .md\+\:ml-n20 {
    margin-left: -5rem !important; }
  .md\+\:mx-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important; }
  .md\+\:my-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .md\+\:p20 {
    padding: 5rem !important; }
  .md\+\:pt-20 {
    padding-top: 5rem !important; }
  .md\+\:pr-20 {
    padding-right: 5rem !important; }
  .md\+\:pb-20 {
    padding-bottom: 5rem !important; }
  .md\+\:pl-20 {
    padding-left: 5rem !important; }
  .md\+\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important; }
  .md\+\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .md\+\:m24 {
    margin: 6rem !important; }
  .md\+\:mt-24 {
    margin-top: 6rem !important; }
  .md\+\:mr-24 {
    margin-right: 6rem !important; }
  .md\+\:mb-24 {
    margin-bottom: 6rem !important; }
  .md\+\:ml-24 {
    margin-left: 6rem !important; }
  .md\+\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important; }
  .md\+\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .md\+\:mt-n24 {
    margin-top: -6rem !important; }
  .md\+\:mr-n24 {
    margin-right: -6rem !important; }
  .md\+\:mb-n24 {
    margin-bottom: -6rem !important; }
  .md\+\:ml-n24 {
    margin-left: -6rem !important; }
  .md\+\:mx-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important; }
  .md\+\:my-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .md\+\:p24 {
    padding: 6rem !important; }
  .md\+\:pt-24 {
    padding-top: 6rem !important; }
  .md\+\:pr-24 {
    padding-right: 6rem !important; }
  .md\+\:pb-24 {
    padding-bottom: 6rem !important; }
  .md\+\:pl-24 {
    padding-left: 6rem !important; }
  .md\+\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important; }
  .md\+\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .md\+\:m32 {
    margin: 8rem !important; }
  .md\+\:mt-32 {
    margin-top: 8rem !important; }
  .md\+\:mr-32 {
    margin-right: 8rem !important; }
  .md\+\:mb-32 {
    margin-bottom: 8rem !important; }
  .md\+\:ml-32 {
    margin-left: 8rem !important; }
  .md\+\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important; }
  .md\+\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .md\+\:mt-n32 {
    margin-top: -8rem !important; }
  .md\+\:mr-n32 {
    margin-right: -8rem !important; }
  .md\+\:mb-n32 {
    margin-bottom: -8rem !important; }
  .md\+\:ml-n32 {
    margin-left: -8rem !important; }
  .md\+\:mx-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important; }
  .md\+\:my-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important; }
  .md\+\:p32 {
    padding: 8rem !important; }
  .md\+\:pt-32 {
    padding-top: 8rem !important; }
  .md\+\:pr-32 {
    padding-right: 8rem !important; }
  .md\+\:pb-32 {
    padding-bottom: 8rem !important; }
  .md\+\:pl-32 {
    padding-left: 8rem !important; }
  .md\+\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important; }
  .md\+\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .md\+\:m40 {
    margin: 10rem !important; }
  .md\+\:mt-40 {
    margin-top: 10rem !important; }
  .md\+\:mr-40 {
    margin-right: 10rem !important; }
  .md\+\:mb-40 {
    margin-bottom: 10rem !important; }
  .md\+\:ml-40 {
    margin-left: 10rem !important; }
  .md\+\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important; }
  .md\+\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .md\+\:mt-n40 {
    margin-top: -10rem !important; }
  .md\+\:mr-n40 {
    margin-right: -10rem !important; }
  .md\+\:mb-n40 {
    margin-bottom: -10rem !important; }
  .md\+\:ml-n40 {
    margin-left: -10rem !important; }
  .md\+\:mx-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important; }
  .md\+\:my-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .md\+\:p40 {
    padding: 10rem !important; }
  .md\+\:pt-40 {
    padding-top: 10rem !important; }
  .md\+\:pr-40 {
    padding-right: 10rem !important; }
  .md\+\:pb-40 {
    padding-bottom: 10rem !important; }
  .md\+\:pl-40 {
    padding-left: 10rem !important; }
  .md\+\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important; }
  .md\+\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .md\+\:m48 {
    margin: 12rem !important; }
  .md\+\:mt-48 {
    margin-top: 12rem !important; }
  .md\+\:mr-48 {
    margin-right: 12rem !important; }
  .md\+\:mb-48 {
    margin-bottom: 12rem !important; }
  .md\+\:ml-48 {
    margin-left: 12rem !important; }
  .md\+\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important; }
  .md\+\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .md\+\:mt-n48 {
    margin-top: -12rem !important; }
  .md\+\:mr-n48 {
    margin-right: -12rem !important; }
  .md\+\:mb-n48 {
    margin-bottom: -12rem !important; }
  .md\+\:ml-n48 {
    margin-left: -12rem !important; }
  .md\+\:mx-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important; }
  .md\+\:my-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important; }
  .md\+\:p48 {
    padding: 12rem !important; }
  .md\+\:pt-48 {
    padding-top: 12rem !important; }
  .md\+\:pr-48 {
    padding-right: 12rem !important; }
  .md\+\:pb-48 {
    padding-bottom: 12rem !important; }
  .md\+\:pl-48 {
    padding-left: 12rem !important; }
  .md\+\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important; }
  .md\+\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .md\+\:m56 {
    margin: 14rem !important; }
  .md\+\:mt-56 {
    margin-top: 14rem !important; }
  .md\+\:mr-56 {
    margin-right: 14rem !important; }
  .md\+\:mb-56 {
    margin-bottom: 14rem !important; }
  .md\+\:ml-56 {
    margin-left: 14rem !important; }
  .md\+\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important; }
  .md\+\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .md\+\:mt-n56 {
    margin-top: -14rem !important; }
  .md\+\:mr-n56 {
    margin-right: -14rem !important; }
  .md\+\:mb-n56 {
    margin-bottom: -14rem !important; }
  .md\+\:ml-n56 {
    margin-left: -14rem !important; }
  .md\+\:mx-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important; }
  .md\+\:my-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important; }
  .md\+\:p56 {
    padding: 14rem !important; }
  .md\+\:pt-56 {
    padding-top: 14rem !important; }
  .md\+\:pr-56 {
    padding-right: 14rem !important; }
  .md\+\:pb-56 {
    padding-bottom: 14rem !important; }
  .md\+\:pl-56 {
    padding-left: 14rem !important; }
  .md\+\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important; }
  .md\+\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .md\+\:m64 {
    margin: 16rem !important; }
  .md\+\:mt-64 {
    margin-top: 16rem !important; }
  .md\+\:mr-64 {
    margin-right: 16rem !important; }
  .md\+\:mb-64 {
    margin-bottom: 16rem !important; }
  .md\+\:ml-64 {
    margin-left: 16rem !important; }
  .md\+\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important; }
  .md\+\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .md\+\:mt-n64 {
    margin-top: -16rem !important; }
  .md\+\:mr-n64 {
    margin-right: -16rem !important; }
  .md\+\:mb-n64 {
    margin-bottom: -16rem !important; }
  .md\+\:ml-n64 {
    margin-left: -16rem !important; }
  .md\+\:mx-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important; }
  .md\+\:my-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important; }
  .md\+\:p64 {
    padding: 16rem !important; }
  .md\+\:pt-64 {
    padding-top: 16rem !important; }
  .md\+\:pr-64 {
    padding-right: 16rem !important; }
  .md\+\:pb-64 {
    padding-bottom: 16rem !important; }
  .md\+\:pl-64 {
    padding-left: 16rem !important; }
  .md\+\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important; }
  .md\+\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .md\+\:m85 {
    margin: 21.25rem !important; }
  .md\+\:mt-85 {
    margin-top: 21.25rem !important; }
  .md\+\:mr-85 {
    margin-right: 21.25rem !important; }
  .md\+\:mb-85 {
    margin-bottom: 21.25rem !important; }
  .md\+\:ml-85 {
    margin-left: 21.25rem !important; }
  .md\+\:mx-85 {
    margin-left: 21.25rem !important;
    margin-right: 21.25rem !important; }
  .md\+\:my-85 {
    margin-top: 21.25rem !important;
    margin-bottom: 21.25rem !important; }
  .md\+\:mt-n85 {
    margin-top: -21.25rem !important; }
  .md\+\:mr-n85 {
    margin-right: -21.25rem !important; }
  .md\+\:mb-n85 {
    margin-bottom: -21.25rem !important; }
  .md\+\:ml-n85 {
    margin-left: -21.25rem !important; }
  .md\+\:mx-n85 {
    margin-left: -21.25rem !important;
    margin-right: -21.25rem !important; }
  .md\+\:my-n85 {
    margin-top: -21.25rem !important;
    margin-bottom: -21.25rem !important; }
  .md\+\:p85 {
    padding: 21.25rem !important; }
  .md\+\:pt-85 {
    padding-top: 21.25rem !important; }
  .md\+\:pr-85 {
    padding-right: 21.25rem !important; }
  .md\+\:pb-85 {
    padding-bottom: 21.25rem !important; }
  .md\+\:pl-85 {
    padding-left: 21.25rem !important; }
  .md\+\:px-85 {
    padding-left: 21.25rem !important;
    padding-right: 21.25rem !important; }
  .md\+\:py-85 {
    padding-top: 21.25rem !important;
    padding-bottom: 21.25rem !important; }
  .md\+\:mauto {
    margin: auto !important; }
  .md\+\:mt-auto {
    margin-top: auto !important; }
  .md\+\:mr-auto {
    margin-right: auto !important; }
  .md\+\:mb-auto {
    margin-bottom: auto !important; }
  .md\+\:ml-auto {
    margin-left: auto !important; }
  .md\+\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important; }
  .md\+\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .md\+\:mt-nauto {
    margin-top: -auto !important; }
  .md\+\:mr-nauto {
    margin-right: -auto !important; }
  .md\+\:mb-nauto {
    margin-bottom: -auto !important; }
  .md\+\:ml-nauto {
    margin-left: -auto !important; }
  .md\+\:mx-nauto {
    margin-left: -auto !important;
    margin-right: -auto !important; }
  .md\+\:my-nauto {
    margin-top: -auto !important;
    margin-bottom: -auto !important; }
  .md\+\:pauto {
    padding: auto !important; }
  .md\+\:pt-auto {
    padding-top: auto !important; }
  .md\+\:pr-auto {
    padding-right: auto !important; }
  .md\+\:pb-auto {
    padding-bottom: auto !important; }
  .md\+\:pl-auto {
    padding-left: auto !important; }
  .md\+\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important; }
  .md\+\:py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important; } }

@media (min-width: 1440px) {
  .lg\:m0 {
    margin: 0 !important; }
  .lg\:mt-0 {
    margin-top: 0 !important; }
  .lg\:mr-0 {
    margin-right: 0 !important; }
  .lg\:mb-0 {
    margin-bottom: 0 !important; }
  .lg\:ml-0 {
    margin-left: 0 !important; }
  .lg\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg\:mt-n0 {
    margin-top: 0 !important; }
  .lg\:mr-n0 {
    margin-right: 0 !important; }
  .lg\:mb-n0 {
    margin-bottom: 0 !important; }
  .lg\:ml-n0 {
    margin-left: 0 !important; }
  .lg\:mx-n0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg\:my-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg\:p0 {
    padding: 0 !important; }
  .lg\:pt-0 {
    padding-top: 0 !important; }
  .lg\:pr-0 {
    padding-right: 0 !important; }
  .lg\:pb-0 {
    padding-bottom: 0 !important; }
  .lg\:pl-0 {
    padding-left: 0 !important; }
  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg\:m1 {
    margin: 0.25rem !important; }
  .lg\:mt-1 {
    margin-top: 0.25rem !important; }
  .lg\:mr-1 {
    margin-right: 0.25rem !important; }
  .lg\:mb-1 {
    margin-bottom: 0.25rem !important; }
  .lg\:ml-1 {
    margin-left: 0.25rem !important; }
  .lg\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
  .lg\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .lg\:mt-n1 {
    margin-top: -0.25rem !important; }
  .lg\:mr-n1 {
    margin-right: -0.25rem !important; }
  .lg\:mb-n1 {
    margin-bottom: -0.25rem !important; }
  .lg\:ml-n1 {
    margin-left: -0.25rem !important; }
  .lg\:mx-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important; }
  .lg\:my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .lg\:p1 {
    padding: 0.25rem !important; }
  .lg\:pt-1 {
    padding-top: 0.25rem !important; }
  .lg\:pr-1 {
    padding-right: 0.25rem !important; }
  .lg\:pb-1 {
    padding-bottom: 0.25rem !important; }
  .lg\:pl-1 {
    padding-left: 0.25rem !important; }
  .lg\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
  .lg\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .lg\:m2 {
    margin: 0.5rem !important; }
  .lg\:mt-2 {
    margin-top: 0.5rem !important; }
  .lg\:mr-2 {
    margin-right: 0.5rem !important; }
  .lg\:mb-2 {
    margin-bottom: 0.5rem !important; }
  .lg\:ml-2 {
    margin-left: 0.5rem !important; }
  .lg\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
  .lg\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .lg\:mt-n2 {
    margin-top: -0.5rem !important; }
  .lg\:mr-n2 {
    margin-right: -0.5rem !important; }
  .lg\:mb-n2 {
    margin-bottom: -0.5rem !important; }
  .lg\:ml-n2 {
    margin-left: -0.5rem !important; }
  .lg\:mx-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important; }
  .lg\:my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .lg\:p2 {
    padding: 0.5rem !important; }
  .lg\:pt-2 {
    padding-top: 0.5rem !important; }
  .lg\:pr-2 {
    padding-right: 0.5rem !important; }
  .lg\:pb-2 {
    padding-bottom: 0.5rem !important; }
  .lg\:pl-2 {
    padding-left: 0.5rem !important; }
  .lg\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
  .lg\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .lg\:m3 {
    margin: 0.75rem !important; }
  .lg\:mt-3 {
    margin-top: 0.75rem !important; }
  .lg\:mr-3 {
    margin-right: 0.75rem !important; }
  .lg\:mb-3 {
    margin-bottom: 0.75rem !important; }
  .lg\:ml-3 {
    margin-left: 0.75rem !important; }
  .lg\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important; }
  .lg\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .lg\:mt-n3 {
    margin-top: -0.75rem !important; }
  .lg\:mr-n3 {
    margin-right: -0.75rem !important; }
  .lg\:mb-n3 {
    margin-bottom: -0.75rem !important; }
  .lg\:ml-n3 {
    margin-left: -0.75rem !important; }
  .lg\:mx-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important; }
  .lg\:my-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .lg\:p3 {
    padding: 0.75rem !important; }
  .lg\:pt-3 {
    padding-top: 0.75rem !important; }
  .lg\:pr-3 {
    padding-right: 0.75rem !important; }
  .lg\:pb-3 {
    padding-bottom: 0.75rem !important; }
  .lg\:pl-3 {
    padding-left: 0.75rem !important; }
  .lg\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important; }
  .lg\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .lg\:m4 {
    margin: 1rem !important; }
  .lg\:mt-4 {
    margin-top: 1rem !important; }
  .lg\:mr-4 {
    margin-right: 1rem !important; }
  .lg\:mb-4 {
    margin-bottom: 1rem !important; }
  .lg\:ml-4 {
    margin-left: 1rem !important; }
  .lg\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
  .lg\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .lg\:mt-n4 {
    margin-top: -1rem !important; }
  .lg\:mr-n4 {
    margin-right: -1rem !important; }
  .lg\:mb-n4 {
    margin-bottom: -1rem !important; }
  .lg\:ml-n4 {
    margin-left: -1rem !important; }
  .lg\:mx-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important; }
  .lg\:my-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .lg\:p4 {
    padding: 1rem !important; }
  .lg\:pt-4 {
    padding-top: 1rem !important; }
  .lg\:pr-4 {
    padding-right: 1rem !important; }
  .lg\:pb-4 {
    padding-bottom: 1rem !important; }
  .lg\:pl-4 {
    padding-left: 1rem !important; }
  .lg\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .lg\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .lg\:m5 {
    margin: 1.25rem !important; }
  .lg\:mt-5 {
    margin-top: 1.25rem !important; }
  .lg\:mr-5 {
    margin-right: 1.25rem !important; }
  .lg\:mb-5 {
    margin-bottom: 1.25rem !important; }
  .lg\:ml-5 {
    margin-left: 1.25rem !important; }
  .lg\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important; }
  .lg\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .lg\:mt-n5 {
    margin-top: -1.25rem !important; }
  .lg\:mr-n5 {
    margin-right: -1.25rem !important; }
  .lg\:mb-n5 {
    margin-bottom: -1.25rem !important; }
  .lg\:ml-n5 {
    margin-left: -1.25rem !important; }
  .lg\:mx-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important; }
  .lg\:my-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .lg\:p5 {
    padding: 1.25rem !important; }
  .lg\:pt-5 {
    padding-top: 1.25rem !important; }
  .lg\:pr-5 {
    padding-right: 1.25rem !important; }
  .lg\:pb-5 {
    padding-bottom: 1.25rem !important; }
  .lg\:pl-5 {
    padding-left: 1.25rem !important; }
  .lg\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .lg\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .lg\:m6 {
    margin: 1.5rem !important; }
  .lg\:mt-6 {
    margin-top: 1.5rem !important; }
  .lg\:mr-6 {
    margin-right: 1.5rem !important; }
  .lg\:mb-6 {
    margin-bottom: 1.5rem !important; }
  .lg\:ml-6 {
    margin-left: 1.5rem !important; }
  .lg\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
  .lg\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .lg\:mt-n6 {
    margin-top: -1.5rem !important; }
  .lg\:mr-n6 {
    margin-right: -1.5rem !important; }
  .lg\:mb-n6 {
    margin-bottom: -1.5rem !important; }
  .lg\:ml-n6 {
    margin-left: -1.5rem !important; }
  .lg\:mx-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important; }
  .lg\:my-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .lg\:p6 {
    padding: 1.5rem !important; }
  .lg\:pt-6 {
    padding-top: 1.5rem !important; }
  .lg\:pr-6 {
    padding-right: 1.5rem !important; }
  .lg\:pb-6 {
    padding-bottom: 1.5rem !important; }
  .lg\:pl-6 {
    padding-left: 1.5rem !important; }
  .lg\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
  .lg\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .lg\:m8 {
    margin: 2rem !important; }
  .lg\:mt-8 {
    margin-top: 2rem !important; }
  .lg\:mr-8 {
    margin-right: 2rem !important; }
  .lg\:mb-8 {
    margin-bottom: 2rem !important; }
  .lg\:ml-8 {
    margin-left: 2rem !important; }
  .lg\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important; }
  .lg\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .lg\:mt-n8 {
    margin-top: -2rem !important; }
  .lg\:mr-n8 {
    margin-right: -2rem !important; }
  .lg\:mb-n8 {
    margin-bottom: -2rem !important; }
  .lg\:ml-n8 {
    margin-left: -2rem !important; }
  .lg\:mx-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important; }
  .lg\:my-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .lg\:p8 {
    padding: 2rem !important; }
  .lg\:pt-8 {
    padding-top: 2rem !important; }
  .lg\:pr-8 {
    padding-right: 2rem !important; }
  .lg\:pb-8 {
    padding-bottom: 2rem !important; }
  .lg\:pl-8 {
    padding-left: 2rem !important; }
  .lg\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  .lg\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .lg\:m10 {
    margin: 2.5rem !important; }
  .lg\:mt-10 {
    margin-top: 2.5rem !important; }
  .lg\:mr-10 {
    margin-right: 2.5rem !important; }
  .lg\:mb-10 {
    margin-bottom: 2.5rem !important; }
  .lg\:ml-10 {
    margin-left: 2.5rem !important; }
  .lg\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important; }
  .lg\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .lg\:mt-n10 {
    margin-top: -2.5rem !important; }
  .lg\:mr-n10 {
    margin-right: -2.5rem !important; }
  .lg\:mb-n10 {
    margin-bottom: -2.5rem !important; }
  .lg\:ml-n10 {
    margin-left: -2.5rem !important; }
  .lg\:mx-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important; }
  .lg\:my-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .lg\:p10 {
    padding: 2.5rem !important; }
  .lg\:pt-10 {
    padding-top: 2.5rem !important; }
  .lg\:pr-10 {
    padding-right: 2.5rem !important; }
  .lg\:pb-10 {
    padding-bottom: 2.5rem !important; }
  .lg\:pl-10 {
    padding-left: 2.5rem !important; }
  .lg\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important; }
  .lg\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .lg\:m12 {
    margin: 3rem !important; }
  .lg\:mt-12 {
    margin-top: 3rem !important; }
  .lg\:mr-12 {
    margin-right: 3rem !important; }
  .lg\:mb-12 {
    margin-bottom: 3rem !important; }
  .lg\:ml-12 {
    margin-left: 3rem !important; }
  .lg\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
  .lg\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .lg\:mt-n12 {
    margin-top: -3rem !important; }
  .lg\:mr-n12 {
    margin-right: -3rem !important; }
  .lg\:mb-n12 {
    margin-bottom: -3rem !important; }
  .lg\:ml-n12 {
    margin-left: -3rem !important; }
  .lg\:mx-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important; }
  .lg\:my-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .lg\:p12 {
    padding: 3rem !important; }
  .lg\:pt-12 {
    padding-top: 3rem !important; }
  .lg\:pr-12 {
    padding-right: 3rem !important; }
  .lg\:pb-12 {
    padding-bottom: 3rem !important; }
  .lg\:pl-12 {
    padding-left: 3rem !important; }
  .lg\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
  .lg\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .lg\:m16 {
    margin: 4rem !important; }
  .lg\:mt-16 {
    margin-top: 4rem !important; }
  .lg\:mr-16 {
    margin-right: 4rem !important; }
  .lg\:mb-16 {
    margin-bottom: 4rem !important; }
  .lg\:ml-16 {
    margin-left: 4rem !important; }
  .lg\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important; }
  .lg\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .lg\:mt-n16 {
    margin-top: -4rem !important; }
  .lg\:mr-n16 {
    margin-right: -4rem !important; }
  .lg\:mb-n16 {
    margin-bottom: -4rem !important; }
  .lg\:ml-n16 {
    margin-left: -4rem !important; }
  .lg\:mx-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important; }
  .lg\:my-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .lg\:p16 {
    padding: 4rem !important; }
  .lg\:pt-16 {
    padding-top: 4rem !important; }
  .lg\:pr-16 {
    padding-right: 4rem !important; }
  .lg\:pb-16 {
    padding-bottom: 4rem !important; }
  .lg\:pl-16 {
    padding-left: 4rem !important; }
  .lg\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important; }
  .lg\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .lg\:m20 {
    margin: 5rem !important; }
  .lg\:mt-20 {
    margin-top: 5rem !important; }
  .lg\:mr-20 {
    margin-right: 5rem !important; }
  .lg\:mb-20 {
    margin-bottom: 5rem !important; }
  .lg\:ml-20 {
    margin-left: 5rem !important; }
  .lg\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important; }
  .lg\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .lg\:mt-n20 {
    margin-top: -5rem !important; }
  .lg\:mr-n20 {
    margin-right: -5rem !important; }
  .lg\:mb-n20 {
    margin-bottom: -5rem !important; }
  .lg\:ml-n20 {
    margin-left: -5rem !important; }
  .lg\:mx-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important; }
  .lg\:my-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .lg\:p20 {
    padding: 5rem !important; }
  .lg\:pt-20 {
    padding-top: 5rem !important; }
  .lg\:pr-20 {
    padding-right: 5rem !important; }
  .lg\:pb-20 {
    padding-bottom: 5rem !important; }
  .lg\:pl-20 {
    padding-left: 5rem !important; }
  .lg\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important; }
  .lg\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .lg\:m24 {
    margin: 6rem !important; }
  .lg\:mt-24 {
    margin-top: 6rem !important; }
  .lg\:mr-24 {
    margin-right: 6rem !important; }
  .lg\:mb-24 {
    margin-bottom: 6rem !important; }
  .lg\:ml-24 {
    margin-left: 6rem !important; }
  .lg\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important; }
  .lg\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .lg\:mt-n24 {
    margin-top: -6rem !important; }
  .lg\:mr-n24 {
    margin-right: -6rem !important; }
  .lg\:mb-n24 {
    margin-bottom: -6rem !important; }
  .lg\:ml-n24 {
    margin-left: -6rem !important; }
  .lg\:mx-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important; }
  .lg\:my-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .lg\:p24 {
    padding: 6rem !important; }
  .lg\:pt-24 {
    padding-top: 6rem !important; }
  .lg\:pr-24 {
    padding-right: 6rem !important; }
  .lg\:pb-24 {
    padding-bottom: 6rem !important; }
  .lg\:pl-24 {
    padding-left: 6rem !important; }
  .lg\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important; }
  .lg\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .lg\:m32 {
    margin: 8rem !important; }
  .lg\:mt-32 {
    margin-top: 8rem !important; }
  .lg\:mr-32 {
    margin-right: 8rem !important; }
  .lg\:mb-32 {
    margin-bottom: 8rem !important; }
  .lg\:ml-32 {
    margin-left: 8rem !important; }
  .lg\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important; }
  .lg\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .lg\:mt-n32 {
    margin-top: -8rem !important; }
  .lg\:mr-n32 {
    margin-right: -8rem !important; }
  .lg\:mb-n32 {
    margin-bottom: -8rem !important; }
  .lg\:ml-n32 {
    margin-left: -8rem !important; }
  .lg\:mx-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important; }
  .lg\:my-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important; }
  .lg\:p32 {
    padding: 8rem !important; }
  .lg\:pt-32 {
    padding-top: 8rem !important; }
  .lg\:pr-32 {
    padding-right: 8rem !important; }
  .lg\:pb-32 {
    padding-bottom: 8rem !important; }
  .lg\:pl-32 {
    padding-left: 8rem !important; }
  .lg\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important; }
  .lg\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .lg\:m40 {
    margin: 10rem !important; }
  .lg\:mt-40 {
    margin-top: 10rem !important; }
  .lg\:mr-40 {
    margin-right: 10rem !important; }
  .lg\:mb-40 {
    margin-bottom: 10rem !important; }
  .lg\:ml-40 {
    margin-left: 10rem !important; }
  .lg\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important; }
  .lg\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .lg\:mt-n40 {
    margin-top: -10rem !important; }
  .lg\:mr-n40 {
    margin-right: -10rem !important; }
  .lg\:mb-n40 {
    margin-bottom: -10rem !important; }
  .lg\:ml-n40 {
    margin-left: -10rem !important; }
  .lg\:mx-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important; }
  .lg\:my-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .lg\:p40 {
    padding: 10rem !important; }
  .lg\:pt-40 {
    padding-top: 10rem !important; }
  .lg\:pr-40 {
    padding-right: 10rem !important; }
  .lg\:pb-40 {
    padding-bottom: 10rem !important; }
  .lg\:pl-40 {
    padding-left: 10rem !important; }
  .lg\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important; }
  .lg\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .lg\:m48 {
    margin: 12rem !important; }
  .lg\:mt-48 {
    margin-top: 12rem !important; }
  .lg\:mr-48 {
    margin-right: 12rem !important; }
  .lg\:mb-48 {
    margin-bottom: 12rem !important; }
  .lg\:ml-48 {
    margin-left: 12rem !important; }
  .lg\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important; }
  .lg\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .lg\:mt-n48 {
    margin-top: -12rem !important; }
  .lg\:mr-n48 {
    margin-right: -12rem !important; }
  .lg\:mb-n48 {
    margin-bottom: -12rem !important; }
  .lg\:ml-n48 {
    margin-left: -12rem !important; }
  .lg\:mx-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important; }
  .lg\:my-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important; }
  .lg\:p48 {
    padding: 12rem !important; }
  .lg\:pt-48 {
    padding-top: 12rem !important; }
  .lg\:pr-48 {
    padding-right: 12rem !important; }
  .lg\:pb-48 {
    padding-bottom: 12rem !important; }
  .lg\:pl-48 {
    padding-left: 12rem !important; }
  .lg\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important; }
  .lg\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .lg\:m56 {
    margin: 14rem !important; }
  .lg\:mt-56 {
    margin-top: 14rem !important; }
  .lg\:mr-56 {
    margin-right: 14rem !important; }
  .lg\:mb-56 {
    margin-bottom: 14rem !important; }
  .lg\:ml-56 {
    margin-left: 14rem !important; }
  .lg\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important; }
  .lg\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .lg\:mt-n56 {
    margin-top: -14rem !important; }
  .lg\:mr-n56 {
    margin-right: -14rem !important; }
  .lg\:mb-n56 {
    margin-bottom: -14rem !important; }
  .lg\:ml-n56 {
    margin-left: -14rem !important; }
  .lg\:mx-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important; }
  .lg\:my-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important; }
  .lg\:p56 {
    padding: 14rem !important; }
  .lg\:pt-56 {
    padding-top: 14rem !important; }
  .lg\:pr-56 {
    padding-right: 14rem !important; }
  .lg\:pb-56 {
    padding-bottom: 14rem !important; }
  .lg\:pl-56 {
    padding-left: 14rem !important; }
  .lg\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important; }
  .lg\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .lg\:m64 {
    margin: 16rem !important; }
  .lg\:mt-64 {
    margin-top: 16rem !important; }
  .lg\:mr-64 {
    margin-right: 16rem !important; }
  .lg\:mb-64 {
    margin-bottom: 16rem !important; }
  .lg\:ml-64 {
    margin-left: 16rem !important; }
  .lg\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important; }
  .lg\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .lg\:mt-n64 {
    margin-top: -16rem !important; }
  .lg\:mr-n64 {
    margin-right: -16rem !important; }
  .lg\:mb-n64 {
    margin-bottom: -16rem !important; }
  .lg\:ml-n64 {
    margin-left: -16rem !important; }
  .lg\:mx-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important; }
  .lg\:my-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important; }
  .lg\:p64 {
    padding: 16rem !important; }
  .lg\:pt-64 {
    padding-top: 16rem !important; }
  .lg\:pr-64 {
    padding-right: 16rem !important; }
  .lg\:pb-64 {
    padding-bottom: 16rem !important; }
  .lg\:pl-64 {
    padding-left: 16rem !important; }
  .lg\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important; }
  .lg\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .lg\:m85 {
    margin: 21.25rem !important; }
  .lg\:mt-85 {
    margin-top: 21.25rem !important; }
  .lg\:mr-85 {
    margin-right: 21.25rem !important; }
  .lg\:mb-85 {
    margin-bottom: 21.25rem !important; }
  .lg\:ml-85 {
    margin-left: 21.25rem !important; }
  .lg\:mx-85 {
    margin-left: 21.25rem !important;
    margin-right: 21.25rem !important; }
  .lg\:my-85 {
    margin-top: 21.25rem !important;
    margin-bottom: 21.25rem !important; }
  .lg\:mt-n85 {
    margin-top: -21.25rem !important; }
  .lg\:mr-n85 {
    margin-right: -21.25rem !important; }
  .lg\:mb-n85 {
    margin-bottom: -21.25rem !important; }
  .lg\:ml-n85 {
    margin-left: -21.25rem !important; }
  .lg\:mx-n85 {
    margin-left: -21.25rem !important;
    margin-right: -21.25rem !important; }
  .lg\:my-n85 {
    margin-top: -21.25rem !important;
    margin-bottom: -21.25rem !important; }
  .lg\:p85 {
    padding: 21.25rem !important; }
  .lg\:pt-85 {
    padding-top: 21.25rem !important; }
  .lg\:pr-85 {
    padding-right: 21.25rem !important; }
  .lg\:pb-85 {
    padding-bottom: 21.25rem !important; }
  .lg\:pl-85 {
    padding-left: 21.25rem !important; }
  .lg\:px-85 {
    padding-left: 21.25rem !important;
    padding-right: 21.25rem !important; }
  .lg\:py-85 {
    padding-top: 21.25rem !important;
    padding-bottom: 21.25rem !important; }
  .lg\:mauto {
    margin: auto !important; }
  .lg\:mt-auto {
    margin-top: auto !important; }
  .lg\:mr-auto {
    margin-right: auto !important; }
  .lg\:mb-auto {
    margin-bottom: auto !important; }
  .lg\:ml-auto {
    margin-left: auto !important; }
  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important; }
  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .lg\:mt-nauto {
    margin-top: -auto !important; }
  .lg\:mr-nauto {
    margin-right: -auto !important; }
  .lg\:mb-nauto {
    margin-bottom: -auto !important; }
  .lg\:ml-nauto {
    margin-left: -auto !important; }
  .lg\:mx-nauto {
    margin-left: -auto !important;
    margin-right: -auto !important; }
  .lg\:my-nauto {
    margin-top: -auto !important;
    margin-bottom: -auto !important; }
  .lg\:pauto {
    padding: auto !important; }
  .lg\:pt-auto {
    padding-top: auto !important; }
  .lg\:pr-auto {
    padding-right: auto !important; }
  .lg\:pb-auto {
    padding-bottom: auto !important; }
  .lg\:pl-auto {
    padding-left: auto !important; }
  .lg\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important; }
  .lg\:py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important; } }

@media (min-width: 1800px) {
  .xl\:m0 {
    margin: 0 !important; }
  .xl\:mt-0 {
    margin-top: 0 !important; }
  .xl\:mr-0 {
    margin-right: 0 !important; }
  .xl\:mb-0 {
    margin-bottom: 0 !important; }
  .xl\:ml-0 {
    margin-left: 0 !important; }
  .xl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl\:mt-n0 {
    margin-top: 0 !important; }
  .xl\:mr-n0 {
    margin-right: 0 !important; }
  .xl\:mb-n0 {
    margin-bottom: 0 !important; }
  .xl\:ml-n0 {
    margin-left: 0 !important; }
  .xl\:mx-n0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl\:my-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl\:p0 {
    padding: 0 !important; }
  .xl\:pt-0 {
    padding-top: 0 !important; }
  .xl\:pr-0 {
    padding-right: 0 !important; }
  .xl\:pb-0 {
    padding-bottom: 0 !important; }
  .xl\:pl-0 {
    padding-left: 0 !important; }
  .xl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl\:m1 {
    margin: 0.25rem !important; }
  .xl\:mt-1 {
    margin-top: 0.25rem !important; }
  .xl\:mr-1 {
    margin-right: 0.25rem !important; }
  .xl\:mb-1 {
    margin-bottom: 0.25rem !important; }
  .xl\:ml-1 {
    margin-left: 0.25rem !important; }
  .xl\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
  .xl\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .xl\:mt-n1 {
    margin-top: -0.25rem !important; }
  .xl\:mr-n1 {
    margin-right: -0.25rem !important; }
  .xl\:mb-n1 {
    margin-bottom: -0.25rem !important; }
  .xl\:ml-n1 {
    margin-left: -0.25rem !important; }
  .xl\:mx-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important; }
  .xl\:my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .xl\:p1 {
    padding: 0.25rem !important; }
  .xl\:pt-1 {
    padding-top: 0.25rem !important; }
  .xl\:pr-1 {
    padding-right: 0.25rem !important; }
  .xl\:pb-1 {
    padding-bottom: 0.25rem !important; }
  .xl\:pl-1 {
    padding-left: 0.25rem !important; }
  .xl\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
  .xl\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .xl\:m2 {
    margin: 0.5rem !important; }
  .xl\:mt-2 {
    margin-top: 0.5rem !important; }
  .xl\:mr-2 {
    margin-right: 0.5rem !important; }
  .xl\:mb-2 {
    margin-bottom: 0.5rem !important; }
  .xl\:ml-2 {
    margin-left: 0.5rem !important; }
  .xl\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
  .xl\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .xl\:mt-n2 {
    margin-top: -0.5rem !important; }
  .xl\:mr-n2 {
    margin-right: -0.5rem !important; }
  .xl\:mb-n2 {
    margin-bottom: -0.5rem !important; }
  .xl\:ml-n2 {
    margin-left: -0.5rem !important; }
  .xl\:mx-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important; }
  .xl\:my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .xl\:p2 {
    padding: 0.5rem !important; }
  .xl\:pt-2 {
    padding-top: 0.5rem !important; }
  .xl\:pr-2 {
    padding-right: 0.5rem !important; }
  .xl\:pb-2 {
    padding-bottom: 0.5rem !important; }
  .xl\:pl-2 {
    padding-left: 0.5rem !important; }
  .xl\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
  .xl\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .xl\:m3 {
    margin: 0.75rem !important; }
  .xl\:mt-3 {
    margin-top: 0.75rem !important; }
  .xl\:mr-3 {
    margin-right: 0.75rem !important; }
  .xl\:mb-3 {
    margin-bottom: 0.75rem !important; }
  .xl\:ml-3 {
    margin-left: 0.75rem !important; }
  .xl\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important; }
  .xl\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .xl\:mt-n3 {
    margin-top: -0.75rem !important; }
  .xl\:mr-n3 {
    margin-right: -0.75rem !important; }
  .xl\:mb-n3 {
    margin-bottom: -0.75rem !important; }
  .xl\:ml-n3 {
    margin-left: -0.75rem !important; }
  .xl\:mx-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important; }
  .xl\:my-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .xl\:p3 {
    padding: 0.75rem !important; }
  .xl\:pt-3 {
    padding-top: 0.75rem !important; }
  .xl\:pr-3 {
    padding-right: 0.75rem !important; }
  .xl\:pb-3 {
    padding-bottom: 0.75rem !important; }
  .xl\:pl-3 {
    padding-left: 0.75rem !important; }
  .xl\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important; }
  .xl\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .xl\:m4 {
    margin: 1rem !important; }
  .xl\:mt-4 {
    margin-top: 1rem !important; }
  .xl\:mr-4 {
    margin-right: 1rem !important; }
  .xl\:mb-4 {
    margin-bottom: 1rem !important; }
  .xl\:ml-4 {
    margin-left: 1rem !important; }
  .xl\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
  .xl\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .xl\:mt-n4 {
    margin-top: -1rem !important; }
  .xl\:mr-n4 {
    margin-right: -1rem !important; }
  .xl\:mb-n4 {
    margin-bottom: -1rem !important; }
  .xl\:ml-n4 {
    margin-left: -1rem !important; }
  .xl\:mx-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important; }
  .xl\:my-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .xl\:p4 {
    padding: 1rem !important; }
  .xl\:pt-4 {
    padding-top: 1rem !important; }
  .xl\:pr-4 {
    padding-right: 1rem !important; }
  .xl\:pb-4 {
    padding-bottom: 1rem !important; }
  .xl\:pl-4 {
    padding-left: 1rem !important; }
  .xl\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .xl\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .xl\:m5 {
    margin: 1.25rem !important; }
  .xl\:mt-5 {
    margin-top: 1.25rem !important; }
  .xl\:mr-5 {
    margin-right: 1.25rem !important; }
  .xl\:mb-5 {
    margin-bottom: 1.25rem !important; }
  .xl\:ml-5 {
    margin-left: 1.25rem !important; }
  .xl\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important; }
  .xl\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .xl\:mt-n5 {
    margin-top: -1.25rem !important; }
  .xl\:mr-n5 {
    margin-right: -1.25rem !important; }
  .xl\:mb-n5 {
    margin-bottom: -1.25rem !important; }
  .xl\:ml-n5 {
    margin-left: -1.25rem !important; }
  .xl\:mx-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important; }
  .xl\:my-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .xl\:p5 {
    padding: 1.25rem !important; }
  .xl\:pt-5 {
    padding-top: 1.25rem !important; }
  .xl\:pr-5 {
    padding-right: 1.25rem !important; }
  .xl\:pb-5 {
    padding-bottom: 1.25rem !important; }
  .xl\:pl-5 {
    padding-left: 1.25rem !important; }
  .xl\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .xl\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .xl\:m6 {
    margin: 1.5rem !important; }
  .xl\:mt-6 {
    margin-top: 1.5rem !important; }
  .xl\:mr-6 {
    margin-right: 1.5rem !important; }
  .xl\:mb-6 {
    margin-bottom: 1.5rem !important; }
  .xl\:ml-6 {
    margin-left: 1.5rem !important; }
  .xl\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
  .xl\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .xl\:mt-n6 {
    margin-top: -1.5rem !important; }
  .xl\:mr-n6 {
    margin-right: -1.5rem !important; }
  .xl\:mb-n6 {
    margin-bottom: -1.5rem !important; }
  .xl\:ml-n6 {
    margin-left: -1.5rem !important; }
  .xl\:mx-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important; }
  .xl\:my-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .xl\:p6 {
    padding: 1.5rem !important; }
  .xl\:pt-6 {
    padding-top: 1.5rem !important; }
  .xl\:pr-6 {
    padding-right: 1.5rem !important; }
  .xl\:pb-6 {
    padding-bottom: 1.5rem !important; }
  .xl\:pl-6 {
    padding-left: 1.5rem !important; }
  .xl\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
  .xl\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .xl\:m8 {
    margin: 2rem !important; }
  .xl\:mt-8 {
    margin-top: 2rem !important; }
  .xl\:mr-8 {
    margin-right: 2rem !important; }
  .xl\:mb-8 {
    margin-bottom: 2rem !important; }
  .xl\:ml-8 {
    margin-left: 2rem !important; }
  .xl\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important; }
  .xl\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .xl\:mt-n8 {
    margin-top: -2rem !important; }
  .xl\:mr-n8 {
    margin-right: -2rem !important; }
  .xl\:mb-n8 {
    margin-bottom: -2rem !important; }
  .xl\:ml-n8 {
    margin-left: -2rem !important; }
  .xl\:mx-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important; }
  .xl\:my-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .xl\:p8 {
    padding: 2rem !important; }
  .xl\:pt-8 {
    padding-top: 2rem !important; }
  .xl\:pr-8 {
    padding-right: 2rem !important; }
  .xl\:pb-8 {
    padding-bottom: 2rem !important; }
  .xl\:pl-8 {
    padding-left: 2rem !important; }
  .xl\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  .xl\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .xl\:m10 {
    margin: 2.5rem !important; }
  .xl\:mt-10 {
    margin-top: 2.5rem !important; }
  .xl\:mr-10 {
    margin-right: 2.5rem !important; }
  .xl\:mb-10 {
    margin-bottom: 2.5rem !important; }
  .xl\:ml-10 {
    margin-left: 2.5rem !important; }
  .xl\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important; }
  .xl\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .xl\:mt-n10 {
    margin-top: -2.5rem !important; }
  .xl\:mr-n10 {
    margin-right: -2.5rem !important; }
  .xl\:mb-n10 {
    margin-bottom: -2.5rem !important; }
  .xl\:ml-n10 {
    margin-left: -2.5rem !important; }
  .xl\:mx-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important; }
  .xl\:my-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .xl\:p10 {
    padding: 2.5rem !important; }
  .xl\:pt-10 {
    padding-top: 2.5rem !important; }
  .xl\:pr-10 {
    padding-right: 2.5rem !important; }
  .xl\:pb-10 {
    padding-bottom: 2.5rem !important; }
  .xl\:pl-10 {
    padding-left: 2.5rem !important; }
  .xl\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important; }
  .xl\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .xl\:m12 {
    margin: 3rem !important; }
  .xl\:mt-12 {
    margin-top: 3rem !important; }
  .xl\:mr-12 {
    margin-right: 3rem !important; }
  .xl\:mb-12 {
    margin-bottom: 3rem !important; }
  .xl\:ml-12 {
    margin-left: 3rem !important; }
  .xl\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
  .xl\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .xl\:mt-n12 {
    margin-top: -3rem !important; }
  .xl\:mr-n12 {
    margin-right: -3rem !important; }
  .xl\:mb-n12 {
    margin-bottom: -3rem !important; }
  .xl\:ml-n12 {
    margin-left: -3rem !important; }
  .xl\:mx-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important; }
  .xl\:my-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .xl\:p12 {
    padding: 3rem !important; }
  .xl\:pt-12 {
    padding-top: 3rem !important; }
  .xl\:pr-12 {
    padding-right: 3rem !important; }
  .xl\:pb-12 {
    padding-bottom: 3rem !important; }
  .xl\:pl-12 {
    padding-left: 3rem !important; }
  .xl\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
  .xl\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .xl\:m16 {
    margin: 4rem !important; }
  .xl\:mt-16 {
    margin-top: 4rem !important; }
  .xl\:mr-16 {
    margin-right: 4rem !important; }
  .xl\:mb-16 {
    margin-bottom: 4rem !important; }
  .xl\:ml-16 {
    margin-left: 4rem !important; }
  .xl\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important; }
  .xl\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .xl\:mt-n16 {
    margin-top: -4rem !important; }
  .xl\:mr-n16 {
    margin-right: -4rem !important; }
  .xl\:mb-n16 {
    margin-bottom: -4rem !important; }
  .xl\:ml-n16 {
    margin-left: -4rem !important; }
  .xl\:mx-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important; }
  .xl\:my-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .xl\:p16 {
    padding: 4rem !important; }
  .xl\:pt-16 {
    padding-top: 4rem !important; }
  .xl\:pr-16 {
    padding-right: 4rem !important; }
  .xl\:pb-16 {
    padding-bottom: 4rem !important; }
  .xl\:pl-16 {
    padding-left: 4rem !important; }
  .xl\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important; }
  .xl\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .xl\:m20 {
    margin: 5rem !important; }
  .xl\:mt-20 {
    margin-top: 5rem !important; }
  .xl\:mr-20 {
    margin-right: 5rem !important; }
  .xl\:mb-20 {
    margin-bottom: 5rem !important; }
  .xl\:ml-20 {
    margin-left: 5rem !important; }
  .xl\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important; }
  .xl\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .xl\:mt-n20 {
    margin-top: -5rem !important; }
  .xl\:mr-n20 {
    margin-right: -5rem !important; }
  .xl\:mb-n20 {
    margin-bottom: -5rem !important; }
  .xl\:ml-n20 {
    margin-left: -5rem !important; }
  .xl\:mx-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important; }
  .xl\:my-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .xl\:p20 {
    padding: 5rem !important; }
  .xl\:pt-20 {
    padding-top: 5rem !important; }
  .xl\:pr-20 {
    padding-right: 5rem !important; }
  .xl\:pb-20 {
    padding-bottom: 5rem !important; }
  .xl\:pl-20 {
    padding-left: 5rem !important; }
  .xl\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important; }
  .xl\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .xl\:m24 {
    margin: 6rem !important; }
  .xl\:mt-24 {
    margin-top: 6rem !important; }
  .xl\:mr-24 {
    margin-right: 6rem !important; }
  .xl\:mb-24 {
    margin-bottom: 6rem !important; }
  .xl\:ml-24 {
    margin-left: 6rem !important; }
  .xl\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important; }
  .xl\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .xl\:mt-n24 {
    margin-top: -6rem !important; }
  .xl\:mr-n24 {
    margin-right: -6rem !important; }
  .xl\:mb-n24 {
    margin-bottom: -6rem !important; }
  .xl\:ml-n24 {
    margin-left: -6rem !important; }
  .xl\:mx-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important; }
  .xl\:my-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .xl\:p24 {
    padding: 6rem !important; }
  .xl\:pt-24 {
    padding-top: 6rem !important; }
  .xl\:pr-24 {
    padding-right: 6rem !important; }
  .xl\:pb-24 {
    padding-bottom: 6rem !important; }
  .xl\:pl-24 {
    padding-left: 6rem !important; }
  .xl\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important; }
  .xl\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .xl\:m32 {
    margin: 8rem !important; }
  .xl\:mt-32 {
    margin-top: 8rem !important; }
  .xl\:mr-32 {
    margin-right: 8rem !important; }
  .xl\:mb-32 {
    margin-bottom: 8rem !important; }
  .xl\:ml-32 {
    margin-left: 8rem !important; }
  .xl\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important; }
  .xl\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .xl\:mt-n32 {
    margin-top: -8rem !important; }
  .xl\:mr-n32 {
    margin-right: -8rem !important; }
  .xl\:mb-n32 {
    margin-bottom: -8rem !important; }
  .xl\:ml-n32 {
    margin-left: -8rem !important; }
  .xl\:mx-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important; }
  .xl\:my-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important; }
  .xl\:p32 {
    padding: 8rem !important; }
  .xl\:pt-32 {
    padding-top: 8rem !important; }
  .xl\:pr-32 {
    padding-right: 8rem !important; }
  .xl\:pb-32 {
    padding-bottom: 8rem !important; }
  .xl\:pl-32 {
    padding-left: 8rem !important; }
  .xl\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important; }
  .xl\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .xl\:m40 {
    margin: 10rem !important; }
  .xl\:mt-40 {
    margin-top: 10rem !important; }
  .xl\:mr-40 {
    margin-right: 10rem !important; }
  .xl\:mb-40 {
    margin-bottom: 10rem !important; }
  .xl\:ml-40 {
    margin-left: 10rem !important; }
  .xl\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important; }
  .xl\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .xl\:mt-n40 {
    margin-top: -10rem !important; }
  .xl\:mr-n40 {
    margin-right: -10rem !important; }
  .xl\:mb-n40 {
    margin-bottom: -10rem !important; }
  .xl\:ml-n40 {
    margin-left: -10rem !important; }
  .xl\:mx-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important; }
  .xl\:my-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .xl\:p40 {
    padding: 10rem !important; }
  .xl\:pt-40 {
    padding-top: 10rem !important; }
  .xl\:pr-40 {
    padding-right: 10rem !important; }
  .xl\:pb-40 {
    padding-bottom: 10rem !important; }
  .xl\:pl-40 {
    padding-left: 10rem !important; }
  .xl\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important; }
  .xl\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .xl\:m48 {
    margin: 12rem !important; }
  .xl\:mt-48 {
    margin-top: 12rem !important; }
  .xl\:mr-48 {
    margin-right: 12rem !important; }
  .xl\:mb-48 {
    margin-bottom: 12rem !important; }
  .xl\:ml-48 {
    margin-left: 12rem !important; }
  .xl\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important; }
  .xl\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .xl\:mt-n48 {
    margin-top: -12rem !important; }
  .xl\:mr-n48 {
    margin-right: -12rem !important; }
  .xl\:mb-n48 {
    margin-bottom: -12rem !important; }
  .xl\:ml-n48 {
    margin-left: -12rem !important; }
  .xl\:mx-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important; }
  .xl\:my-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important; }
  .xl\:p48 {
    padding: 12rem !important; }
  .xl\:pt-48 {
    padding-top: 12rem !important; }
  .xl\:pr-48 {
    padding-right: 12rem !important; }
  .xl\:pb-48 {
    padding-bottom: 12rem !important; }
  .xl\:pl-48 {
    padding-left: 12rem !important; }
  .xl\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important; }
  .xl\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .xl\:m56 {
    margin: 14rem !important; }
  .xl\:mt-56 {
    margin-top: 14rem !important; }
  .xl\:mr-56 {
    margin-right: 14rem !important; }
  .xl\:mb-56 {
    margin-bottom: 14rem !important; }
  .xl\:ml-56 {
    margin-left: 14rem !important; }
  .xl\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important; }
  .xl\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .xl\:mt-n56 {
    margin-top: -14rem !important; }
  .xl\:mr-n56 {
    margin-right: -14rem !important; }
  .xl\:mb-n56 {
    margin-bottom: -14rem !important; }
  .xl\:ml-n56 {
    margin-left: -14rem !important; }
  .xl\:mx-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important; }
  .xl\:my-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important; }
  .xl\:p56 {
    padding: 14rem !important; }
  .xl\:pt-56 {
    padding-top: 14rem !important; }
  .xl\:pr-56 {
    padding-right: 14rem !important; }
  .xl\:pb-56 {
    padding-bottom: 14rem !important; }
  .xl\:pl-56 {
    padding-left: 14rem !important; }
  .xl\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important; }
  .xl\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .xl\:m64 {
    margin: 16rem !important; }
  .xl\:mt-64 {
    margin-top: 16rem !important; }
  .xl\:mr-64 {
    margin-right: 16rem !important; }
  .xl\:mb-64 {
    margin-bottom: 16rem !important; }
  .xl\:ml-64 {
    margin-left: 16rem !important; }
  .xl\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important; }
  .xl\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .xl\:mt-n64 {
    margin-top: -16rem !important; }
  .xl\:mr-n64 {
    margin-right: -16rem !important; }
  .xl\:mb-n64 {
    margin-bottom: -16rem !important; }
  .xl\:ml-n64 {
    margin-left: -16rem !important; }
  .xl\:mx-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important; }
  .xl\:my-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important; }
  .xl\:p64 {
    padding: 16rem !important; }
  .xl\:pt-64 {
    padding-top: 16rem !important; }
  .xl\:pr-64 {
    padding-right: 16rem !important; }
  .xl\:pb-64 {
    padding-bottom: 16rem !important; }
  .xl\:pl-64 {
    padding-left: 16rem !important; }
  .xl\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important; }
  .xl\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .xl\:m85 {
    margin: 21.25rem !important; }
  .xl\:mt-85 {
    margin-top: 21.25rem !important; }
  .xl\:mr-85 {
    margin-right: 21.25rem !important; }
  .xl\:mb-85 {
    margin-bottom: 21.25rem !important; }
  .xl\:ml-85 {
    margin-left: 21.25rem !important; }
  .xl\:mx-85 {
    margin-left: 21.25rem !important;
    margin-right: 21.25rem !important; }
  .xl\:my-85 {
    margin-top: 21.25rem !important;
    margin-bottom: 21.25rem !important; }
  .xl\:mt-n85 {
    margin-top: -21.25rem !important; }
  .xl\:mr-n85 {
    margin-right: -21.25rem !important; }
  .xl\:mb-n85 {
    margin-bottom: -21.25rem !important; }
  .xl\:ml-n85 {
    margin-left: -21.25rem !important; }
  .xl\:mx-n85 {
    margin-left: -21.25rem !important;
    margin-right: -21.25rem !important; }
  .xl\:my-n85 {
    margin-top: -21.25rem !important;
    margin-bottom: -21.25rem !important; }
  .xl\:p85 {
    padding: 21.25rem !important; }
  .xl\:pt-85 {
    padding-top: 21.25rem !important; }
  .xl\:pr-85 {
    padding-right: 21.25rem !important; }
  .xl\:pb-85 {
    padding-bottom: 21.25rem !important; }
  .xl\:pl-85 {
    padding-left: 21.25rem !important; }
  .xl\:px-85 {
    padding-left: 21.25rem !important;
    padding-right: 21.25rem !important; }
  .xl\:py-85 {
    padding-top: 21.25rem !important;
    padding-bottom: 21.25rem !important; }
  .xl\:mauto {
    margin: auto !important; }
  .xl\:mt-auto {
    margin-top: auto !important; }
  .xl\:mr-auto {
    margin-right: auto !important; }
  .xl\:mb-auto {
    margin-bottom: auto !important; }
  .xl\:ml-auto {
    margin-left: auto !important; }
  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important; }
  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .xl\:mt-nauto {
    margin-top: -auto !important; }
  .xl\:mr-nauto {
    margin-right: -auto !important; }
  .xl\:mb-nauto {
    margin-bottom: -auto !important; }
  .xl\:ml-nauto {
    margin-left: -auto !important; }
  .xl\:mx-nauto {
    margin-left: -auto !important;
    margin-right: -auto !important; }
  .xl\:my-nauto {
    margin-top: -auto !important;
    margin-bottom: -auto !important; }
  .xl\:pauto {
    padding: auto !important; }
  .xl\:pt-auto {
    padding-top: auto !important; }
  .xl\:pr-auto {
    padding-right: auto !important; }
  .xl\:pb-auto {
    padding-bottom: auto !important; }
  .xl\:pl-auto {
    padding-left: auto !important; }
  .xl\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important; }
  .xl\:py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important; } }

@media print {
  .print\:m0 {
    margin: 0 !important; }
  .print\:mt-0 {
    margin-top: 0 !important; }
  .print\:mr-0 {
    margin-right: 0 !important; }
  .print\:mb-0 {
    margin-bottom: 0 !important; }
  .print\:ml-0 {
    margin-left: 0 !important; }
  .print\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .print\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .print\:mt-n0 {
    margin-top: 0 !important; }
  .print\:mr-n0 {
    margin-right: 0 !important; }
  .print\:mb-n0 {
    margin-bottom: 0 !important; }
  .print\:ml-n0 {
    margin-left: 0 !important; }
  .print\:mx-n0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .print\:my-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .print\:p0 {
    padding: 0 !important; }
  .print\:pt-0 {
    padding-top: 0 !important; }
  .print\:pr-0 {
    padding-right: 0 !important; }
  .print\:pb-0 {
    padding-bottom: 0 !important; }
  .print\:pl-0 {
    padding-left: 0 !important; }
  .print\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .print\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .print\:m1 {
    margin: 0.25rem !important; }
  .print\:mt-1 {
    margin-top: 0.25rem !important; }
  .print\:mr-1 {
    margin-right: 0.25rem !important; }
  .print\:mb-1 {
    margin-bottom: 0.25rem !important; }
  .print\:ml-1 {
    margin-left: 0.25rem !important; }
  .print\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important; }
  .print\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .print\:mt-n1 {
    margin-top: -0.25rem !important; }
  .print\:mr-n1 {
    margin-right: -0.25rem !important; }
  .print\:mb-n1 {
    margin-bottom: -0.25rem !important; }
  .print\:ml-n1 {
    margin-left: -0.25rem !important; }
  .print\:mx-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important; }
  .print\:my-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .print\:p1 {
    padding: 0.25rem !important; }
  .print\:pt-1 {
    padding-top: 0.25rem !important; }
  .print\:pr-1 {
    padding-right: 0.25rem !important; }
  .print\:pb-1 {
    padding-bottom: 0.25rem !important; }
  .print\:pl-1 {
    padding-left: 0.25rem !important; }
  .print\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important; }
  .print\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .print\:m2 {
    margin: 0.5rem !important; }
  .print\:mt-2 {
    margin-top: 0.5rem !important; }
  .print\:mr-2 {
    margin-right: 0.5rem !important; }
  .print\:mb-2 {
    margin-bottom: 0.5rem !important; }
  .print\:ml-2 {
    margin-left: 0.5rem !important; }
  .print\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
  .print\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .print\:mt-n2 {
    margin-top: -0.5rem !important; }
  .print\:mr-n2 {
    margin-right: -0.5rem !important; }
  .print\:mb-n2 {
    margin-bottom: -0.5rem !important; }
  .print\:ml-n2 {
    margin-left: -0.5rem !important; }
  .print\:mx-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important; }
  .print\:my-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .print\:p2 {
    padding: 0.5rem !important; }
  .print\:pt-2 {
    padding-top: 0.5rem !important; }
  .print\:pr-2 {
    padding-right: 0.5rem !important; }
  .print\:pb-2 {
    padding-bottom: 0.5rem !important; }
  .print\:pl-2 {
    padding-left: 0.5rem !important; }
  .print\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
  .print\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .print\:m3 {
    margin: 0.75rem !important; }
  .print\:mt-3 {
    margin-top: 0.75rem !important; }
  .print\:mr-3 {
    margin-right: 0.75rem !important; }
  .print\:mb-3 {
    margin-bottom: 0.75rem !important; }
  .print\:ml-3 {
    margin-left: 0.75rem !important; }
  .print\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important; }
  .print\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .print\:mt-n3 {
    margin-top: -0.75rem !important; }
  .print\:mr-n3 {
    margin-right: -0.75rem !important; }
  .print\:mb-n3 {
    margin-bottom: -0.75rem !important; }
  .print\:ml-n3 {
    margin-left: -0.75rem !important; }
  .print\:mx-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important; }
  .print\:my-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .print\:p3 {
    padding: 0.75rem !important; }
  .print\:pt-3 {
    padding-top: 0.75rem !important; }
  .print\:pr-3 {
    padding-right: 0.75rem !important; }
  .print\:pb-3 {
    padding-bottom: 0.75rem !important; }
  .print\:pl-3 {
    padding-left: 0.75rem !important; }
  .print\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important; }
  .print\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .print\:m4 {
    margin: 1rem !important; }
  .print\:mt-4 {
    margin-top: 1rem !important; }
  .print\:mr-4 {
    margin-right: 1rem !important; }
  .print\:mb-4 {
    margin-bottom: 1rem !important; }
  .print\:ml-4 {
    margin-left: 1rem !important; }
  .print\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important; }
  .print\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .print\:mt-n4 {
    margin-top: -1rem !important; }
  .print\:mr-n4 {
    margin-right: -1rem !important; }
  .print\:mb-n4 {
    margin-bottom: -1rem !important; }
  .print\:ml-n4 {
    margin-left: -1rem !important; }
  .print\:mx-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important; }
  .print\:my-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .print\:p4 {
    padding: 1rem !important; }
  .print\:pt-4 {
    padding-top: 1rem !important; }
  .print\:pr-4 {
    padding-right: 1rem !important; }
  .print\:pb-4 {
    padding-bottom: 1rem !important; }
  .print\:pl-4 {
    padding-left: 1rem !important; }
  .print\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .print\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .print\:m5 {
    margin: 1.25rem !important; }
  .print\:mt-5 {
    margin-top: 1.25rem !important; }
  .print\:mr-5 {
    margin-right: 1.25rem !important; }
  .print\:mb-5 {
    margin-bottom: 1.25rem !important; }
  .print\:ml-5 {
    margin-left: 1.25rem !important; }
  .print\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important; }
  .print\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .print\:mt-n5 {
    margin-top: -1.25rem !important; }
  .print\:mr-n5 {
    margin-right: -1.25rem !important; }
  .print\:mb-n5 {
    margin-bottom: -1.25rem !important; }
  .print\:ml-n5 {
    margin-left: -1.25rem !important; }
  .print\:mx-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important; }
  .print\:my-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important; }
  .print\:p5 {
    padding: 1.25rem !important; }
  .print\:pt-5 {
    padding-top: 1.25rem !important; }
  .print\:pr-5 {
    padding-right: 1.25rem !important; }
  .print\:pb-5 {
    padding-bottom: 1.25rem !important; }
  .print\:pl-5 {
    padding-left: 1.25rem !important; }
  .print\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .print\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .print\:m6 {
    margin: 1.5rem !important; }
  .print\:mt-6 {
    margin-top: 1.5rem !important; }
  .print\:mr-6 {
    margin-right: 1.5rem !important; }
  .print\:mb-6 {
    margin-bottom: 1.5rem !important; }
  .print\:ml-6 {
    margin-left: 1.5rem !important; }
  .print\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important; }
  .print\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .print\:mt-n6 {
    margin-top: -1.5rem !important; }
  .print\:mr-n6 {
    margin-right: -1.5rem !important; }
  .print\:mb-n6 {
    margin-bottom: -1.5rem !important; }
  .print\:ml-n6 {
    margin-left: -1.5rem !important; }
  .print\:mx-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important; }
  .print\:my-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .print\:p6 {
    padding: 1.5rem !important; }
  .print\:pt-6 {
    padding-top: 1.5rem !important; }
  .print\:pr-6 {
    padding-right: 1.5rem !important; }
  .print\:pb-6 {
    padding-bottom: 1.5rem !important; }
  .print\:pl-6 {
    padding-left: 1.5rem !important; }
  .print\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important; }
  .print\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .print\:m8 {
    margin: 2rem !important; }
  .print\:mt-8 {
    margin-top: 2rem !important; }
  .print\:mr-8 {
    margin-right: 2rem !important; }
  .print\:mb-8 {
    margin-bottom: 2rem !important; }
  .print\:ml-8 {
    margin-left: 2rem !important; }
  .print\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important; }
  .print\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .print\:mt-n8 {
    margin-top: -2rem !important; }
  .print\:mr-n8 {
    margin-right: -2rem !important; }
  .print\:mb-n8 {
    margin-bottom: -2rem !important; }
  .print\:ml-n8 {
    margin-left: -2rem !important; }
  .print\:mx-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important; }
  .print\:my-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .print\:p8 {
    padding: 2rem !important; }
  .print\:pt-8 {
    padding-top: 2rem !important; }
  .print\:pr-8 {
    padding-right: 2rem !important; }
  .print\:pb-8 {
    padding-bottom: 2rem !important; }
  .print\:pl-8 {
    padding-left: 2rem !important; }
  .print\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important; }
  .print\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .print\:m10 {
    margin: 2.5rem !important; }
  .print\:mt-10 {
    margin-top: 2.5rem !important; }
  .print\:mr-10 {
    margin-right: 2.5rem !important; }
  .print\:mb-10 {
    margin-bottom: 2.5rem !important; }
  .print\:ml-10 {
    margin-left: 2.5rem !important; }
  .print\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important; }
  .print\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .print\:mt-n10 {
    margin-top: -2.5rem !important; }
  .print\:mr-n10 {
    margin-right: -2.5rem !important; }
  .print\:mb-n10 {
    margin-bottom: -2.5rem !important; }
  .print\:ml-n10 {
    margin-left: -2.5rem !important; }
  .print\:mx-n10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important; }
  .print\:my-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .print\:p10 {
    padding: 2.5rem !important; }
  .print\:pt-10 {
    padding-top: 2.5rem !important; }
  .print\:pr-10 {
    padding-right: 2.5rem !important; }
  .print\:pb-10 {
    padding-bottom: 2.5rem !important; }
  .print\:pl-10 {
    padding-left: 2.5rem !important; }
  .print\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important; }
  .print\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .print\:m12 {
    margin: 3rem !important; }
  .print\:mt-12 {
    margin-top: 3rem !important; }
  .print\:mr-12 {
    margin-right: 3rem !important; }
  .print\:mb-12 {
    margin-bottom: 3rem !important; }
  .print\:ml-12 {
    margin-left: 3rem !important; }
  .print\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important; }
  .print\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .print\:mt-n12 {
    margin-top: -3rem !important; }
  .print\:mr-n12 {
    margin-right: -3rem !important; }
  .print\:mb-n12 {
    margin-bottom: -3rem !important; }
  .print\:ml-n12 {
    margin-left: -3rem !important; }
  .print\:mx-n12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important; }
  .print\:my-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .print\:p12 {
    padding: 3rem !important; }
  .print\:pt-12 {
    padding-top: 3rem !important; }
  .print\:pr-12 {
    padding-right: 3rem !important; }
  .print\:pb-12 {
    padding-bottom: 3rem !important; }
  .print\:pl-12 {
    padding-left: 3rem !important; }
  .print\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important; }
  .print\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .print\:m16 {
    margin: 4rem !important; }
  .print\:mt-16 {
    margin-top: 4rem !important; }
  .print\:mr-16 {
    margin-right: 4rem !important; }
  .print\:mb-16 {
    margin-bottom: 4rem !important; }
  .print\:ml-16 {
    margin-left: 4rem !important; }
  .print\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important; }
  .print\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .print\:mt-n16 {
    margin-top: -4rem !important; }
  .print\:mr-n16 {
    margin-right: -4rem !important; }
  .print\:mb-n16 {
    margin-bottom: -4rem !important; }
  .print\:ml-n16 {
    margin-left: -4rem !important; }
  .print\:mx-n16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important; }
  .print\:my-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .print\:p16 {
    padding: 4rem !important; }
  .print\:pt-16 {
    padding-top: 4rem !important; }
  .print\:pr-16 {
    padding-right: 4rem !important; }
  .print\:pb-16 {
    padding-bottom: 4rem !important; }
  .print\:pl-16 {
    padding-left: 4rem !important; }
  .print\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important; }
  .print\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .print\:m20 {
    margin: 5rem !important; }
  .print\:mt-20 {
    margin-top: 5rem !important; }
  .print\:mr-20 {
    margin-right: 5rem !important; }
  .print\:mb-20 {
    margin-bottom: 5rem !important; }
  .print\:ml-20 {
    margin-left: 5rem !important; }
  .print\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important; }
  .print\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .print\:mt-n20 {
    margin-top: -5rem !important; }
  .print\:mr-n20 {
    margin-right: -5rem !important; }
  .print\:mb-n20 {
    margin-bottom: -5rem !important; }
  .print\:ml-n20 {
    margin-left: -5rem !important; }
  .print\:mx-n20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important; }
  .print\:my-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .print\:p20 {
    padding: 5rem !important; }
  .print\:pt-20 {
    padding-top: 5rem !important; }
  .print\:pr-20 {
    padding-right: 5rem !important; }
  .print\:pb-20 {
    padding-bottom: 5rem !important; }
  .print\:pl-20 {
    padding-left: 5rem !important; }
  .print\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important; }
  .print\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .print\:m24 {
    margin: 6rem !important; }
  .print\:mt-24 {
    margin-top: 6rem !important; }
  .print\:mr-24 {
    margin-right: 6rem !important; }
  .print\:mb-24 {
    margin-bottom: 6rem !important; }
  .print\:ml-24 {
    margin-left: 6rem !important; }
  .print\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important; }
  .print\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .print\:mt-n24 {
    margin-top: -6rem !important; }
  .print\:mr-n24 {
    margin-right: -6rem !important; }
  .print\:mb-n24 {
    margin-bottom: -6rem !important; }
  .print\:ml-n24 {
    margin-left: -6rem !important; }
  .print\:mx-n24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important; }
  .print\:my-n24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .print\:p24 {
    padding: 6rem !important; }
  .print\:pt-24 {
    padding-top: 6rem !important; }
  .print\:pr-24 {
    padding-right: 6rem !important; }
  .print\:pb-24 {
    padding-bottom: 6rem !important; }
  .print\:pl-24 {
    padding-left: 6rem !important; }
  .print\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important; }
  .print\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .print\:m32 {
    margin: 8rem !important; }
  .print\:mt-32 {
    margin-top: 8rem !important; }
  .print\:mr-32 {
    margin-right: 8rem !important; }
  .print\:mb-32 {
    margin-bottom: 8rem !important; }
  .print\:ml-32 {
    margin-left: 8rem !important; }
  .print\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important; }
  .print\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .print\:mt-n32 {
    margin-top: -8rem !important; }
  .print\:mr-n32 {
    margin-right: -8rem !important; }
  .print\:mb-n32 {
    margin-bottom: -8rem !important; }
  .print\:ml-n32 {
    margin-left: -8rem !important; }
  .print\:mx-n32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important; }
  .print\:my-n32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important; }
  .print\:p32 {
    padding: 8rem !important; }
  .print\:pt-32 {
    padding-top: 8rem !important; }
  .print\:pr-32 {
    padding-right: 8rem !important; }
  .print\:pb-32 {
    padding-bottom: 8rem !important; }
  .print\:pl-32 {
    padding-left: 8rem !important; }
  .print\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important; }
  .print\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .print\:m40 {
    margin: 10rem !important; }
  .print\:mt-40 {
    margin-top: 10rem !important; }
  .print\:mr-40 {
    margin-right: 10rem !important; }
  .print\:mb-40 {
    margin-bottom: 10rem !important; }
  .print\:ml-40 {
    margin-left: 10rem !important; }
  .print\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important; }
  .print\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .print\:mt-n40 {
    margin-top: -10rem !important; }
  .print\:mr-n40 {
    margin-right: -10rem !important; }
  .print\:mb-n40 {
    margin-bottom: -10rem !important; }
  .print\:ml-n40 {
    margin-left: -10rem !important; }
  .print\:mx-n40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important; }
  .print\:my-n40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important; }
  .print\:p40 {
    padding: 10rem !important; }
  .print\:pt-40 {
    padding-top: 10rem !important; }
  .print\:pr-40 {
    padding-right: 10rem !important; }
  .print\:pb-40 {
    padding-bottom: 10rem !important; }
  .print\:pl-40 {
    padding-left: 10rem !important; }
  .print\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important; }
  .print\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .print\:m48 {
    margin: 12rem !important; }
  .print\:mt-48 {
    margin-top: 12rem !important; }
  .print\:mr-48 {
    margin-right: 12rem !important; }
  .print\:mb-48 {
    margin-bottom: 12rem !important; }
  .print\:ml-48 {
    margin-left: 12rem !important; }
  .print\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important; }
  .print\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .print\:mt-n48 {
    margin-top: -12rem !important; }
  .print\:mr-n48 {
    margin-right: -12rem !important; }
  .print\:mb-n48 {
    margin-bottom: -12rem !important; }
  .print\:ml-n48 {
    margin-left: -12rem !important; }
  .print\:mx-n48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important; }
  .print\:my-n48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important; }
  .print\:p48 {
    padding: 12rem !important; }
  .print\:pt-48 {
    padding-top: 12rem !important; }
  .print\:pr-48 {
    padding-right: 12rem !important; }
  .print\:pb-48 {
    padding-bottom: 12rem !important; }
  .print\:pl-48 {
    padding-left: 12rem !important; }
  .print\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important; }
  .print\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .print\:m56 {
    margin: 14rem !important; }
  .print\:mt-56 {
    margin-top: 14rem !important; }
  .print\:mr-56 {
    margin-right: 14rem !important; }
  .print\:mb-56 {
    margin-bottom: 14rem !important; }
  .print\:ml-56 {
    margin-left: 14rem !important; }
  .print\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important; }
  .print\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .print\:mt-n56 {
    margin-top: -14rem !important; }
  .print\:mr-n56 {
    margin-right: -14rem !important; }
  .print\:mb-n56 {
    margin-bottom: -14rem !important; }
  .print\:ml-n56 {
    margin-left: -14rem !important; }
  .print\:mx-n56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important; }
  .print\:my-n56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important; }
  .print\:p56 {
    padding: 14rem !important; }
  .print\:pt-56 {
    padding-top: 14rem !important; }
  .print\:pr-56 {
    padding-right: 14rem !important; }
  .print\:pb-56 {
    padding-bottom: 14rem !important; }
  .print\:pl-56 {
    padding-left: 14rem !important; }
  .print\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important; }
  .print\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .print\:m64 {
    margin: 16rem !important; }
  .print\:mt-64 {
    margin-top: 16rem !important; }
  .print\:mr-64 {
    margin-right: 16rem !important; }
  .print\:mb-64 {
    margin-bottom: 16rem !important; }
  .print\:ml-64 {
    margin-left: 16rem !important; }
  .print\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important; }
  .print\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .print\:mt-n64 {
    margin-top: -16rem !important; }
  .print\:mr-n64 {
    margin-right: -16rem !important; }
  .print\:mb-n64 {
    margin-bottom: -16rem !important; }
  .print\:ml-n64 {
    margin-left: -16rem !important; }
  .print\:mx-n64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important; }
  .print\:my-n64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important; }
  .print\:p64 {
    padding: 16rem !important; }
  .print\:pt-64 {
    padding-top: 16rem !important; }
  .print\:pr-64 {
    padding-right: 16rem !important; }
  .print\:pb-64 {
    padding-bottom: 16rem !important; }
  .print\:pl-64 {
    padding-left: 16rem !important; }
  .print\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important; }
  .print\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .print\:m85 {
    margin: 21.25rem !important; }
  .print\:mt-85 {
    margin-top: 21.25rem !important; }
  .print\:mr-85 {
    margin-right: 21.25rem !important; }
  .print\:mb-85 {
    margin-bottom: 21.25rem !important; }
  .print\:ml-85 {
    margin-left: 21.25rem !important; }
  .print\:mx-85 {
    margin-left: 21.25rem !important;
    margin-right: 21.25rem !important; }
  .print\:my-85 {
    margin-top: 21.25rem !important;
    margin-bottom: 21.25rem !important; }
  .print\:mt-n85 {
    margin-top: -21.25rem !important; }
  .print\:mr-n85 {
    margin-right: -21.25rem !important; }
  .print\:mb-n85 {
    margin-bottom: -21.25rem !important; }
  .print\:ml-n85 {
    margin-left: -21.25rem !important; }
  .print\:mx-n85 {
    margin-left: -21.25rem !important;
    margin-right: -21.25rem !important; }
  .print\:my-n85 {
    margin-top: -21.25rem !important;
    margin-bottom: -21.25rem !important; }
  .print\:p85 {
    padding: 21.25rem !important; }
  .print\:pt-85 {
    padding-top: 21.25rem !important; }
  .print\:pr-85 {
    padding-right: 21.25rem !important; }
  .print\:pb-85 {
    padding-bottom: 21.25rem !important; }
  .print\:pl-85 {
    padding-left: 21.25rem !important; }
  .print\:px-85 {
    padding-left: 21.25rem !important;
    padding-right: 21.25rem !important; }
  .print\:py-85 {
    padding-top: 21.25rem !important;
    padding-bottom: 21.25rem !important; }
  .print\:mauto {
    margin: auto !important; }
  .print\:mt-auto {
    margin-top: auto !important; }
  .print\:mr-auto {
    margin-right: auto !important; }
  .print\:mb-auto {
    margin-bottom: auto !important; }
  .print\:ml-auto {
    margin-left: auto !important; }
  .print\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important; }
  .print\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .print\:mt-nauto {
    margin-top: -auto !important; }
  .print\:mr-nauto {
    margin-right: -auto !important; }
  .print\:mb-nauto {
    margin-bottom: -auto !important; }
  .print\:ml-nauto {
    margin-left: -auto !important; }
  .print\:mx-nauto {
    margin-left: -auto !important;
    margin-right: -auto !important; }
  .print\:my-nauto {
    margin-top: -auto !important;
    margin-bottom: -auto !important; }
  .print\:pauto {
    padding: auto !important; }
  .print\:pt-auto {
    padding-top: auto !important; }
  .print\:pr-auto {
    padding-right: auto !important; }
  .print\:pb-auto {
    padding-bottom: auto !important; }
  .print\:pl-auto {
    padding-left: auto !important; }
  .print\:px-auto {
    padding-left: auto !important;
    padding-right: auto !important; }
  .print\:py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important; } }

.list-none {
  list-style-type: none !important; }

.list-disc {
  list-style-type: disc !important; }

.list-decimal {
  list-style-type: decimal !important; }

.list-inside {
  list-style-position: inside !important; }

.list-outside {
  list-style-position: outside !important; }

@media (min-width: 410px) {
  .sm\:list-none {
    list-style-type: none !important; }
  .sm\:list-disc {
    list-style-type: disc !important; }
  .sm\:list-decimal {
    list-style-type: decimal !important; }
  .sm\:list-inside {
    list-style-position: inside !important; }
  .sm\:list-outside {
    list-style-position: outside !important; } }

@media (min-width: 600px) {
  .sm\+\:list-none {
    list-style-type: none !important; }
  .sm\+\:list-disc {
    list-style-type: disc !important; }
  .sm\+\:list-decimal {
    list-style-type: decimal !important; }
  .sm\+\:list-inside {
    list-style-position: inside !important; }
  .sm\+\:list-outside {
    list-style-position: outside !important; } }

@media (min-width: 768px) {
  .md\:list-none {
    list-style-type: none !important; }
  .md\:list-disc {
    list-style-type: disc !important; }
  .md\:list-decimal {
    list-style-type: decimal !important; }
  .md\:list-inside {
    list-style-position: inside !important; }
  .md\:list-outside {
    list-style-position: outside !important; } }

@media (min-width: 1024px) {
  .md\+\:list-none {
    list-style-type: none !important; }
  .md\+\:list-disc {
    list-style-type: disc !important; }
  .md\+\:list-decimal {
    list-style-type: decimal !important; }
  .md\+\:list-inside {
    list-style-position: inside !important; }
  .md\+\:list-outside {
    list-style-position: outside !important; } }

@media (min-width: 1440px) {
  .lg\:list-none {
    list-style-type: none !important; }
  .lg\:list-disc {
    list-style-type: disc !important; }
  .lg\:list-decimal {
    list-style-type: decimal !important; }
  .lg\:list-inside {
    list-style-position: inside !important; }
  .lg\:list-outside {
    list-style-position: outside !important; } }

@media (min-width: 1800px) {
  .xl\:list-none {
    list-style-type: none !important; }
  .xl\:list-disc {
    list-style-type: disc !important; }
  .xl\:list-decimal {
    list-style-type: decimal !important; }
  .xl\:list-inside {
    list-style-position: inside !important; }
  .xl\:list-outside {
    list-style-position: outside !important; } }

.font-hairline {
  font-weight: 100 !important; }

.font-thin {
  font-weight: 200 !important; }

.font-light {
  font-weight: 300 !important; }

.font-normal {
  font-weight: 400 !important; }

.font-medium {
  font-weight: 400 !important; }

.font-semibold {
  font-weight: 500 !important; }

.font-bold {
  font-weight: 600 !important; }

.font-extrabold {
  font-weight: 800 !important; }

.font-black {
  font-weight: 900 !important; }

.text-2xs {
  font-size: 0.65rem !important; }

.text-xs {
  font-size: 0.75rem !important; }

.text-sm {
  font-size: 0.875rem !important; }

.text-base {
  font-size: 1rem !important; }

.text-lg {
  font-size: 1.125rem !important; }

.text-xl {
  font-size: 1.25rem !important; }

.text-2xl {
  font-size: 1.5rem !important; }

.text-3xl {
  font-size: 1.875rem !important; }

.text-4xl {
  font-size: 2.25rem !important; }

.text-5xl {
  font-size: 3rem !important; }

.text-6xl {
  font-size: 4rem !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }

.uppercase {
  text-transform: uppercase !important; }

.lowercase {
  text-transform: lowercase !important; }

.capitalize {
  text-transform: capitalize !important; }

.normal-case {
  text-transform: none !important; }

.italic {
  font-style: italic !important; }

.not-italic {
  font-style: normal !important; }

.underline {
  text-decoration: underline !important; }

.line-through {
  text-decoration: line-through !important; }

.no-underline {
  text-decoration: none !important; }

.tracking-n2 {
  letter-spacing: -0.04em !important; }

.tracking-n1 {
  letter-spacing: -0.025em !important; }

.tracking-1 {
  letter-spacing: 0.025 !important; }

.tracking-2 {
  letter-spacing: 0.05em !important; }

.tracking-3 {
  letter-spacing: 0.2em !important; }

.tracking-4 {
  letter-spacing: 0.3em !important; }

.tracking-5 {
  letter-spacing: 0.5em !important; }

.whitespace-normal {
  white-space: normal !important; }

.whitespace-no-wrap {
  white-space: nowrap !important; }

.nowrap {
  white-space: nowrap !important; }

.whitespace-pre {
  white-space: pre !important; }

.whitespace-pre-line {
  white-space: pre-line !important; }

.whitespace-pre-wrap {
  white-space: pre-wrap !important; }

.break-normal {
  overflow-wrap: normal !important;
  word-break: normal !important; }

.break-words {
  overflow-wrap: break-word !important;
  word-break: break-word !important; }

.break-all {
  word-break: break-all !important; }

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important; }

.antialiased {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

.subpixel-antialiased {
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important; }

@media (min-width: 410px) {
  .sm\:font-hairline {
    font-weight: 100 !important; }
  .sm\:font-thin {
    font-weight: 200 !important; }
  .sm\:font-light {
    font-weight: 300 !important; }
  .sm\:font-normal {
    font-weight: 400 !important; }
  .sm\:font-medium {
    font-weight: 400 !important; }
  .sm\:font-semibold {
    font-weight: 500 !important; }
  .sm\:font-bold {
    font-weight: 600 !important; }
  .sm\:font-extrabold {
    font-weight: 800 !important; }
  .sm\:font-black {
    font-weight: 900 !important; }
  .sm\:text-2xs {
    font-size: 0.65rem !important; }
  .sm\:text-xs {
    font-size: 0.75rem !important; }
  .sm\:text-sm {
    font-size: 0.875rem !important; }
  .sm\:text-base {
    font-size: 1rem !important; }
  .sm\:text-lg {
    font-size: 1.125rem !important; }
  .sm\:text-xl {
    font-size: 1.25rem !important; }
  .sm\:text-2xl {
    font-size: 1.5rem !important; }
  .sm\:text-3xl {
    font-size: 1.875rem !important; }
  .sm\:text-4xl {
    font-size: 2.25rem !important; }
  .sm\:text-5xl {
    font-size: 3rem !important; }
  .sm\:text-6xl {
    font-size: 4rem !important; }
  .sm\:text-left {
    text-align: left !important; }
  .sm\:text-right {
    text-align: right !important; }
  .sm\:text-center {
    text-align: center !important; }
  .sm\:text-justify {
    text-align: justify !important; }
  .sm\:uppercase {
    text-transform: uppercase !important; }
  .sm\:lowercase {
    text-transform: lowercase !important; }
  .sm\:capitalize {
    text-transform: capitalize !important; }
  .sm\:normal-case {
    text-transform: none !important; }
  .sm\:italic {
    font-style: italic !important; }
  .sm\:not-italic {
    font-style: normal !important; }
  .sm\:underline {
    text-decoration: underline !important; }
  .sm\:line-through {
    text-decoration: line-through !important; }
  .sm\:no-underline {
    text-decoration: none !important; }
  .sm\:tracking-n2 {
    letter-spacing: -0.04em !important; }
  .sm\:tracking-n1 {
    letter-spacing: -0.025em !important; }
  .sm\:tracking-1 {
    letter-spacing: 0.025 !important; }
  .sm\:tracking-2 {
    letter-spacing: 0.05em !important; }
  .sm\:tracking-3 {
    letter-spacing: 0.2em !important; }
  .sm\:tracking-4 {
    letter-spacing: 0.3em !important; }
  .sm\:tracking-5 {
    letter-spacing: 0.5em !important; }
  .sm\:whitespace-normal {
    white-space: normal !important; }
  .sm\:whitespace-no-wrap {
    white-space: nowrap !important; }
  .sm\:nowrap {
    white-space: nowrap !important; }
  .sm\:whitespace-pre {
    white-space: pre !important; }
  .sm\:whitespace-pre-line {
    white-space: pre-line !important; }
  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap !important; }
  .sm\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important; }
  .sm\:break-words {
    overflow-wrap: break-word !important;
    word-break: break-word !important; }
  .sm\:break-all {
    word-break: break-all !important; }
  .sm\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important; }
  .sm\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important; } }

@media (min-width: 600px) {
  .sm\+\:font-hairline {
    font-weight: 100 !important; }
  .sm\+\:font-thin {
    font-weight: 200 !important; }
  .sm\+\:font-light {
    font-weight: 300 !important; }
  .sm\+\:font-normal {
    font-weight: 400 !important; }
  .sm\+\:font-medium {
    font-weight: 400 !important; }
  .sm\+\:font-semibold {
    font-weight: 500 !important; }
  .sm\+\:font-bold {
    font-weight: 600 !important; }
  .sm\+\:font-extrabold {
    font-weight: 800 !important; }
  .sm\+\:font-black {
    font-weight: 900 !important; }
  .sm\+\:text-2xs {
    font-size: 0.65rem !important; }
  .sm\+\:text-xs {
    font-size: 0.75rem !important; }
  .sm\+\:text-sm {
    font-size: 0.875rem !important; }
  .sm\+\:text-base {
    font-size: 1rem !important; }
  .sm\+\:text-lg {
    font-size: 1.125rem !important; }
  .sm\+\:text-xl {
    font-size: 1.25rem !important; }
  .sm\+\:text-2xl {
    font-size: 1.5rem !important; }
  .sm\+\:text-3xl {
    font-size: 1.875rem !important; }
  .sm\+\:text-4xl {
    font-size: 2.25rem !important; }
  .sm\+\:text-5xl {
    font-size: 3rem !important; }
  .sm\+\:text-6xl {
    font-size: 4rem !important; }
  .sm\+\:text-left {
    text-align: left !important; }
  .sm\+\:text-right {
    text-align: right !important; }
  .sm\+\:text-center {
    text-align: center !important; }
  .sm\+\:text-justify {
    text-align: justify !important; }
  .sm\+\:uppercase {
    text-transform: uppercase !important; }
  .sm\+\:lowercase {
    text-transform: lowercase !important; }
  .sm\+\:capitalize {
    text-transform: capitalize !important; }
  .sm\+\:normal-case {
    text-transform: none !important; }
  .sm\+\:italic {
    font-style: italic !important; }
  .sm\+\:not-italic {
    font-style: normal !important; }
  .sm\+\:underline {
    text-decoration: underline !important; }
  .sm\+\:line-through {
    text-decoration: line-through !important; }
  .sm\+\:no-underline {
    text-decoration: none !important; }
  .sm\+\:tracking-n2 {
    letter-spacing: -0.04em !important; }
  .sm\+\:tracking-n1 {
    letter-spacing: -0.025em !important; }
  .sm\+\:tracking-1 {
    letter-spacing: 0.025 !important; }
  .sm\+\:tracking-2 {
    letter-spacing: 0.05em !important; }
  .sm\+\:tracking-3 {
    letter-spacing: 0.2em !important; }
  .sm\+\:tracking-4 {
    letter-spacing: 0.3em !important; }
  .sm\+\:tracking-5 {
    letter-spacing: 0.5em !important; }
  .sm\+\:whitespace-normal {
    white-space: normal !important; }
  .sm\+\:whitespace-no-wrap {
    white-space: nowrap !important; }
  .sm\+\:nowrap {
    white-space: nowrap !important; }
  .sm\+\:whitespace-pre {
    white-space: pre !important; }
  .sm\+\:whitespace-pre-line {
    white-space: pre-line !important; }
  .sm\+\:whitespace-pre-wrap {
    white-space: pre-wrap !important; }
  .sm\+\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important; }
  .sm\+\:break-words {
    overflow-wrap: break-word !important;
    word-break: break-word !important; }
  .sm\+\:break-all {
    word-break: break-all !important; }
  .sm\+\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important; }
  .sm\+\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  .sm\+\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important; } }

@media (min-width: 768px) {
  .md\:font-hairline {
    font-weight: 100 !important; }
  .md\:font-thin {
    font-weight: 200 !important; }
  .md\:font-light {
    font-weight: 300 !important; }
  .md\:font-normal {
    font-weight: 400 !important; }
  .md\:font-medium {
    font-weight: 400 !important; }
  .md\:font-semibold {
    font-weight: 500 !important; }
  .md\:font-bold {
    font-weight: 600 !important; }
  .md\:font-extrabold {
    font-weight: 800 !important; }
  .md\:font-black {
    font-weight: 900 !important; }
  .md\:text-2xs {
    font-size: 0.65rem !important; }
  .md\:text-xs {
    font-size: 0.75rem !important; }
  .md\:text-sm {
    font-size: 0.875rem !important; }
  .md\:text-base {
    font-size: 1rem !important; }
  .md\:text-lg {
    font-size: 1.125rem !important; }
  .md\:text-xl {
    font-size: 1.25rem !important; }
  .md\:text-2xl {
    font-size: 1.5rem !important; }
  .md\:text-3xl {
    font-size: 1.875rem !important; }
  .md\:text-4xl {
    font-size: 2.25rem !important; }
  .md\:text-5xl {
    font-size: 3rem !important; }
  .md\:text-6xl {
    font-size: 4rem !important; }
  .md\:text-left {
    text-align: left !important; }
  .md\:text-right {
    text-align: right !important; }
  .md\:text-center {
    text-align: center !important; }
  .md\:text-justify {
    text-align: justify !important; }
  .md\:uppercase {
    text-transform: uppercase !important; }
  .md\:lowercase {
    text-transform: lowercase !important; }
  .md\:capitalize {
    text-transform: capitalize !important; }
  .md\:normal-case {
    text-transform: none !important; }
  .md\:italic {
    font-style: italic !important; }
  .md\:not-italic {
    font-style: normal !important; }
  .md\:underline {
    text-decoration: underline !important; }
  .md\:line-through {
    text-decoration: line-through !important; }
  .md\:no-underline {
    text-decoration: none !important; }
  .md\:tracking-n2 {
    letter-spacing: -0.04em !important; }
  .md\:tracking-n1 {
    letter-spacing: -0.025em !important; }
  .md\:tracking-1 {
    letter-spacing: 0.025 !important; }
  .md\:tracking-2 {
    letter-spacing: 0.05em !important; }
  .md\:tracking-3 {
    letter-spacing: 0.2em !important; }
  .md\:tracking-4 {
    letter-spacing: 0.3em !important; }
  .md\:tracking-5 {
    letter-spacing: 0.5em !important; }
  .md\:whitespace-normal {
    white-space: normal !important; }
  .md\:whitespace-no-wrap {
    white-space: nowrap !important; }
  .md\:nowrap {
    white-space: nowrap !important; }
  .md\:whitespace-pre {
    white-space: pre !important; }
  .md\:whitespace-pre-line {
    white-space: pre-line !important; }
  .md\:whitespace-pre-wrap {
    white-space: pre-wrap !important; }
  .md\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important; }
  .md\:break-words {
    overflow-wrap: break-word !important;
    word-break: break-word !important; }
  .md\:break-all {
    word-break: break-all !important; }
  .md\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important; }
  .md\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important; } }

@media (min-width: 1024px) {
  .md\+\:font-hairline {
    font-weight: 100 !important; }
  .md\+\:font-thin {
    font-weight: 200 !important; }
  .md\+\:font-light {
    font-weight: 300 !important; }
  .md\+\:font-normal {
    font-weight: 400 !important; }
  .md\+\:font-medium {
    font-weight: 400 !important; }
  .md\+\:font-semibold {
    font-weight: 500 !important; }
  .md\+\:font-bold {
    font-weight: 600 !important; }
  .md\+\:font-extrabold {
    font-weight: 800 !important; }
  .md\+\:font-black {
    font-weight: 900 !important; }
  .md\+\:text-2xs {
    font-size: 0.65rem !important; }
  .md\+\:text-xs {
    font-size: 0.75rem !important; }
  .md\+\:text-sm {
    font-size: 0.875rem !important; }
  .md\+\:text-base {
    font-size: 1rem !important; }
  .md\+\:text-lg {
    font-size: 1.125rem !important; }
  .md\+\:text-xl {
    font-size: 1.25rem !important; }
  .md\+\:text-2xl {
    font-size: 1.5rem !important; }
  .md\+\:text-3xl {
    font-size: 1.875rem !important; }
  .md\+\:text-4xl {
    font-size: 2.25rem !important; }
  .md\+\:text-5xl {
    font-size: 3rem !important; }
  .md\+\:text-6xl {
    font-size: 4rem !important; }
  .md\+\:text-left {
    text-align: left !important; }
  .md\+\:text-right {
    text-align: right !important; }
  .md\+\:text-center {
    text-align: center !important; }
  .md\+\:text-justify {
    text-align: justify !important; }
  .md\+\:uppercase {
    text-transform: uppercase !important; }
  .md\+\:lowercase {
    text-transform: lowercase !important; }
  .md\+\:capitalize {
    text-transform: capitalize !important; }
  .md\+\:normal-case {
    text-transform: none !important; }
  .md\+\:italic {
    font-style: italic !important; }
  .md\+\:not-italic {
    font-style: normal !important; }
  .md\+\:underline {
    text-decoration: underline !important; }
  .md\+\:line-through {
    text-decoration: line-through !important; }
  .md\+\:no-underline {
    text-decoration: none !important; }
  .md\+\:tracking-n2 {
    letter-spacing: -0.04em !important; }
  .md\+\:tracking-n1 {
    letter-spacing: -0.025em !important; }
  .md\+\:tracking-1 {
    letter-spacing: 0.025 !important; }
  .md\+\:tracking-2 {
    letter-spacing: 0.05em !important; }
  .md\+\:tracking-3 {
    letter-spacing: 0.2em !important; }
  .md\+\:tracking-4 {
    letter-spacing: 0.3em !important; }
  .md\+\:tracking-5 {
    letter-spacing: 0.5em !important; }
  .md\+\:whitespace-normal {
    white-space: normal !important; }
  .md\+\:whitespace-no-wrap {
    white-space: nowrap !important; }
  .md\+\:nowrap {
    white-space: nowrap !important; }
  .md\+\:whitespace-pre {
    white-space: pre !important; }
  .md\+\:whitespace-pre-line {
    white-space: pre-line !important; }
  .md\+\:whitespace-pre-wrap {
    white-space: pre-wrap !important; }
  .md\+\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important; }
  .md\+\:break-words {
    overflow-wrap: break-word !important;
    word-break: break-word !important; }
  .md\+\:break-all {
    word-break: break-all !important; }
  .md\+\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important; }
  .md\+\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  .md\+\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important; } }

@media (min-width: 1440px) {
  .lg\:font-hairline {
    font-weight: 100 !important; }
  .lg\:font-thin {
    font-weight: 200 !important; }
  .lg\:font-light {
    font-weight: 300 !important; }
  .lg\:font-normal {
    font-weight: 400 !important; }
  .lg\:font-medium {
    font-weight: 400 !important; }
  .lg\:font-semibold {
    font-weight: 500 !important; }
  .lg\:font-bold {
    font-weight: 600 !important; }
  .lg\:font-extrabold {
    font-weight: 800 !important; }
  .lg\:font-black {
    font-weight: 900 !important; }
  .lg\:text-2xs {
    font-size: 0.65rem !important; }
  .lg\:text-xs {
    font-size: 0.75rem !important; }
  .lg\:text-sm {
    font-size: 0.875rem !important; }
  .lg\:text-base {
    font-size: 1rem !important; }
  .lg\:text-lg {
    font-size: 1.125rem !important; }
  .lg\:text-xl {
    font-size: 1.25rem !important; }
  .lg\:text-2xl {
    font-size: 1.5rem !important; }
  .lg\:text-3xl {
    font-size: 1.875rem !important; }
  .lg\:text-4xl {
    font-size: 2.25rem !important; }
  .lg\:text-5xl {
    font-size: 3rem !important; }
  .lg\:text-6xl {
    font-size: 4rem !important; }
  .lg\:text-left {
    text-align: left !important; }
  .lg\:text-right {
    text-align: right !important; }
  .lg\:text-center {
    text-align: center !important; }
  .lg\:text-justify {
    text-align: justify !important; }
  .lg\:uppercase {
    text-transform: uppercase !important; }
  .lg\:lowercase {
    text-transform: lowercase !important; }
  .lg\:capitalize {
    text-transform: capitalize !important; }
  .lg\:normal-case {
    text-transform: none !important; }
  .lg\:italic {
    font-style: italic !important; }
  .lg\:not-italic {
    font-style: normal !important; }
  .lg\:underline {
    text-decoration: underline !important; }
  .lg\:line-through {
    text-decoration: line-through !important; }
  .lg\:no-underline {
    text-decoration: none !important; }
  .lg\:tracking-n2 {
    letter-spacing: -0.04em !important; }
  .lg\:tracking-n1 {
    letter-spacing: -0.025em !important; }
  .lg\:tracking-1 {
    letter-spacing: 0.025 !important; }
  .lg\:tracking-2 {
    letter-spacing: 0.05em !important; }
  .lg\:tracking-3 {
    letter-spacing: 0.2em !important; }
  .lg\:tracking-4 {
    letter-spacing: 0.3em !important; }
  .lg\:tracking-5 {
    letter-spacing: 0.5em !important; }
  .lg\:whitespace-normal {
    white-space: normal !important; }
  .lg\:whitespace-no-wrap {
    white-space: nowrap !important; }
  .lg\:nowrap {
    white-space: nowrap !important; }
  .lg\:whitespace-pre {
    white-space: pre !important; }
  .lg\:whitespace-pre-line {
    white-space: pre-line !important; }
  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap !important; }
  .lg\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important; }
  .lg\:break-words {
    overflow-wrap: break-word !important;
    word-break: break-word !important; }
  .lg\:break-all {
    word-break: break-all !important; }
  .lg\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important; }
  .lg\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important; } }

@media (min-width: 1800px) {
  .xl\:font-hairline {
    font-weight: 100 !important; }
  .xl\:font-thin {
    font-weight: 200 !important; }
  .xl\:font-light {
    font-weight: 300 !important; }
  .xl\:font-normal {
    font-weight: 400 !important; }
  .xl\:font-medium {
    font-weight: 400 !important; }
  .xl\:font-semibold {
    font-weight: 500 !important; }
  .xl\:font-bold {
    font-weight: 600 !important; }
  .xl\:font-extrabold {
    font-weight: 800 !important; }
  .xl\:font-black {
    font-weight: 900 !important; }
  .xl\:text-2xs {
    font-size: 0.65rem !important; }
  .xl\:text-xs {
    font-size: 0.75rem !important; }
  .xl\:text-sm {
    font-size: 0.875rem !important; }
  .xl\:text-base {
    font-size: 1rem !important; }
  .xl\:text-lg {
    font-size: 1.125rem !important; }
  .xl\:text-xl {
    font-size: 1.25rem !important; }
  .xl\:text-2xl {
    font-size: 1.5rem !important; }
  .xl\:text-3xl {
    font-size: 1.875rem !important; }
  .xl\:text-4xl {
    font-size: 2.25rem !important; }
  .xl\:text-5xl {
    font-size: 3rem !important; }
  .xl\:text-6xl {
    font-size: 4rem !important; }
  .xl\:text-left {
    text-align: left !important; }
  .xl\:text-right {
    text-align: right !important; }
  .xl\:text-center {
    text-align: center !important; }
  .xl\:text-justify {
    text-align: justify !important; }
  .xl\:uppercase {
    text-transform: uppercase !important; }
  .xl\:lowercase {
    text-transform: lowercase !important; }
  .xl\:capitalize {
    text-transform: capitalize !important; }
  .xl\:normal-case {
    text-transform: none !important; }
  .xl\:italic {
    font-style: italic !important; }
  .xl\:not-italic {
    font-style: normal !important; }
  .xl\:underline {
    text-decoration: underline !important; }
  .xl\:line-through {
    text-decoration: line-through !important; }
  .xl\:no-underline {
    text-decoration: none !important; }
  .xl\:tracking-n2 {
    letter-spacing: -0.04em !important; }
  .xl\:tracking-n1 {
    letter-spacing: -0.025em !important; }
  .xl\:tracking-1 {
    letter-spacing: 0.025 !important; }
  .xl\:tracking-2 {
    letter-spacing: 0.05em !important; }
  .xl\:tracking-3 {
    letter-spacing: 0.2em !important; }
  .xl\:tracking-4 {
    letter-spacing: 0.3em !important; }
  .xl\:tracking-5 {
    letter-spacing: 0.5em !important; }
  .xl\:whitespace-normal {
    white-space: normal !important; }
  .xl\:whitespace-no-wrap {
    white-space: nowrap !important; }
  .xl\:nowrap {
    white-space: nowrap !important; }
  .xl\:whitespace-pre {
    white-space: pre !important; }
  .xl\:whitespace-pre-line {
    white-space: pre-line !important; }
  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap !important; }
  .xl\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important; }
  .xl\:break-words {
    overflow-wrap: break-word !important;
    word-break: break-word !important; }
  .xl\:break-all {
    word-break: break-all !important; }
  .xl\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important; }
  .xl\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important; }
  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important; } }

.color-black {
  color: #000000 !important; }

.bg-black {
  background-color: #000000 !important; }

.border-black {
  border-color: #000000 !important; }

.color-off-black {
  color: #151617 !important; }

.bg-off-black {
  background-color: #151617 !important; }

.border-off-black {
  border-color: #151617 !important; }

.color-white {
  color: #FFFFFF !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.border-white {
  border-color: #FFFFFF !important; }

.color-theme-color {
  color: var(--theme-color) !important; }

.bg-theme-color {
  background-color: var(--theme-color) !important; }

.border-theme-color {
  border-color: var(--theme-color) !important; }

.color-theme-color-dark {
  color: var(--theme-color-dark) !important; }

.bg-theme-color-dark {
  background-color: var(--theme-color-dark) !important; }

.border-theme-color-dark {
  border-color: var(--theme-color-dark) !important; }

.color-grey {
  color: #6E6E6E !important; }

.bg-grey {
  background-color: #6E6E6E !important; }

.border-grey {
  border-color: #6E6E6E !important; }

.color-grey-90 {
  color: #2C2D2E !important; }

.bg-grey-90 {
  background-color: #2C2D2E !important; }

.border-grey-90 {
  border-color: #2C2D2E !important; }

.color-grey-80 {
  color: #444545 !important; }

.bg-grey-80 {
  background-color: #444545 !important; }

.border-grey-80 {
  border-color: #444545 !important; }

.color-grey-70 {
  color: #626363 !important; }

.bg-grey-70 {
  background-color: #626363 !important; }

.border-grey-70 {
  border-color: #626363 !important; }

.color-grey-60 {
  color: #7C7C7D !important; }

.bg-grey-60 {
  background-color: #7C7C7D !important; }

.border-grey-60 {
  border-color: #7C7C7D !important; }

.color-grey-50 {
  color: #8A8A8A !important; }

.bg-grey-50 {
  background-color: #8A8A8A !important; }

.border-grey-50 {
  border-color: #8A8A8A !important; }

.color-grey-40 {
  color: #AFB0B0 !important; }

.bg-grey-40 {
  background-color: #AFB0B0 !important; }

.border-grey-40 {
  border-color: #AFB0B0 !important; }

.color-grey-30 {
  color: #C9C9C9 !important; }

.bg-grey-30 {
  background-color: #C9C9C9 !important; }

.border-grey-30 {
  border-color: #C9C9C9 !important; }

.color-grey-20 {
  color: #D0D0D1 !important; }

.bg-grey-20 {
  background-color: #D0D0D1 !important; }

.border-grey-20 {
  border-color: #D0D0D1 !important; }

.color-grey-10 {
  color: #E7E7E7 !important; }

.bg-grey-10 {
  background-color: #E7E7E7 !important; }

.border-grey-10 {
  border-color: #E7E7E7 !important; }

.color-grey-5 {
  color: #F5F5F5 !important; }

.bg-grey-5 {
  background-color: #F5F5F5 !important; }

.border-grey-5 {
  border-color: #F5F5F5 !important; }

.color-red {
  color: #8A2C2C !important; }

.bg-red {
  background-color: #8A2C2C !important; }

.border-red {
  border-color: #8A2C2C !important; }

.color-red-5 {
  color: #F7D4D9 !important; }

.bg-red-5 {
  background-color: #F7D4D9 !important; }

.border-red-5 {
  border-color: #F7D4D9 !important; }

.color-gold {
  color: #A18455 !important; }

.bg-gold {
  background-color: #A18455 !important; }

.border-gold {
  border-color: #A18455 !important; }

.color-livid {
  color: #658389 !important; }

.bg-livid {
  background-color: #658389 !important; }

.border-livid {
  border-color: #658389 !important; }

.color-mint {
  color: #CADFC0 !important; }

.bg-mint {
  background-color: #CADFC0 !important; }

.border-mint {
  border-color: #CADFC0 !important; }

.color-mint-dark {
  color: #A9CA99 !important; }

.bg-mint-dark {
  background-color: #A9CA99 !important; }

.border-mint-dark {
  border-color: #A9CA99 !important; }

.color-coral {
  color: #FFCFDF !important; }

.bg-coral {
  background-color: #FFCFDF !important; }

.border-coral {
  border-color: #FFCFDF !important; }

.color-coral-dark {
  color: #FFCFDF !important; }

.bg-coral-dark {
  background-color: #FFCFDF !important; }

.border-coral-dark {
  border-color: #FFCFDF !important; }

.color-lilac {
  color: #ADA7D2 !important; }

.bg-lilac {
  background-color: #ADA7D2 !important; }

.border-lilac {
  border-color: #ADA7D2 !important; }

.color-lilac-dark {
  color: #857CB7 !important; }

.bg-lilac-dark {
  background-color: #857CB7 !important; }

.border-lilac-dark {
  border-color: #857CB7 !important; }

.color-peach {
  color: #F1BA9D !important; }

.bg-peach {
  background-color: #F1BA9D !important; }

.border-peach {
  border-color: #F1BA9D !important; }

.color-peach-dark {
  color: #E1A180 !important; }

.bg-peach-dark {
  background-color: #E1A180 !important; }

.border-peach-dark {
  border-color: #E1A180 !important; }

.color-bone {
  color: #FCF8F2 !important; }

.bg-bone {
  background-color: #FCF8F2 !important; }

.border-bone {
  border-color: #FCF8F2 !important; }

.color-bone-dark {
  color: #BEB9AB !important; }

.bg-bone-dark {
  background-color: #BEB9AB !important; }

.border-bone-dark {
  border-color: #BEB9AB !important; }

.color-teal {
  color: #CEEDED !important; }

.bg-teal {
  background-color: #CEEDED !important; }

.border-teal {
  border-color: #CEEDED !important; }

.color-teal-dark {
  color: #A6DDDD !important; }

.bg-teal-dark {
  background-color: #A6DDDD !important; }

.border-teal-dark {
  border-color: #A6DDDD !important; }

.color-lemon {
  color: #FBEDC7 !important; }

.bg-lemon {
  background-color: #FBEDC7 !important; }

.border-lemon {
  border-color: #FBEDC7 !important; }

.color-lemon-dark {
  color: #F3DA97 !important; }

.bg-lemon-dark {
  background-color: #F3DA97 !important; }

.border-lemon-dark {
  border-color: #F3DA97 !important; }

.color-culture-blue {
  color: #0D89AB !important; }

.bg-culture-blue {
  background-color: #0D89AB !important; }

.border-culture-blue {
  border-color: #0D89AB !important; }

.color-culture-blue-dark {
  color: #055C73 !important; }

.bg-culture-blue-dark {
  background-color: #055C73 !important; }

.border-culture-blue-dark {
  border-color: #055C73 !important; }

.color-gradient-blue {
  color: #070C5E !important; }

.bg-gradient-blue {
  background-color: #070C5E !important; }

.border-gradient-blue {
  border-color: #070C5E !important; }

.color-dark-blue {
  color: #003478 !important; }

.bg-dark-blue {
  background-color: #003478 !important; }

.border-dark-blue {
  border-color: #003478 !important; }

.color-bright-blue {
  color: #0F4DBC !important; }

.bg-bright-blue {
  background-color: #0F4DBC !important; }

.border-bright-blue {
  border-color: #0F4DBC !important; }

.color-notification-blue {
  color: #F6FAFF !important; }

.bg-notification-blue {
  background-color: #F6FAFF !important; }

.border-notification-blue {
  border-color: #F6FAFF !important; }

.color-notification-blue-border {
  color: #D4D6EE !important; }

.bg-notification-blue-border {
  background-color: #D4D6EE !important; }

.border-notification-blue-border {
  border-color: #D4D6EE !important; }

.color-light-green {
  color: #C1FC99 !important; }

.bg-light-green {
  background-color: #C1FC99 !important; }

.border-light-green {
  border-color: #C1FC99 !important; }

.color-light-green-tint {
  color: #CAF1B1 !important; }

.bg-light-green-tint {
  background-color: #CAF1B1 !important; }

.border-light-green-tint {
  border-color: #CAF1B1 !important; }

.color-dark-green {
  color: #00AE65 !important; }

.bg-dark-green {
  background-color: #00AE65 !important; }

.border-dark-green {
  border-color: #00AE65 !important; }

.color-notification-green {
  color: #F5FCF9 !important; }

.bg-notification-green {
  background-color: #F5FCF9 !important; }

.border-notification-green {
  border-color: #F5FCF9 !important; }

.color-notification-green-border {
  color: #B7F1D9 !important; }

.bg-notification-green-border {
  background-color: #B7F1D9 !important; }

.border-notification-green-border {
  border-color: #B7F1D9 !important; }

.color-yellow {
  color: #FFC312 !important; }

.bg-yellow {
  background-color: #FFC312 !important; }

.border-yellow {
  border-color: #FFC312 !important; }

.color-orange {
  color: #EE5A24 !important; }

.bg-orange {
  background-color: #EE5A24 !important; }

.border-orange {
  border-color: #EE5A24 !important; }

.color-transparent {
  color: rgba(0, 0, 0, 0) !important; }

.bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important; }

.border-transparent {
  border-color: rgba(0, 0, 0, 0) !important; }

/* * * * * * * * * * * * * * * * * * * * * * * *
* Miscellaneous - a few useful helper classes
* * * * * * * * * * * * * * * * * * * * * * */
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
  pointer-events: auto;
  background-color: transparent; }

.debug * {
  background: rgba(249, 202, 36, 0.05);
  box-shadow: 0 0 0 1px #F9CA24; }
  .debug *::after, .debug *::before {
    background: rgba(255, 121, 121, 0.05);
    box-shadow: 0 0 0 1px #FF7979; }

.clearfix::after {
  content: '';
  display: table;
  clear: both; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
  pointer-events: auto;
  background-color: transparent; }

.appearance-none {
  appearance: none !important; }
  .appearance-none::-webkit-inner-spin-button, .appearance-none::-webkit-outer-spin-button {
    appearance: none;
    margin: 0; }

.contains-icon {
  display: inline-flex;
  align-items: center; }

.invisible-scrollbars {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .invisible-scrollbars::-webkit-scrollbar {
    display: none; }

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.u-text-no-transform {
  text-transform: none;
  letter-spacing: unset; }

.u-accessible, .social-link span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  clip-path: inset(1px); }

.social-link {
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;
  background-color: #A18455;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .social-link:hover {
    opacity: 0.8; }
