body,
html {
    min-height: 100%;
    font-feature-settings: "liga" off;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
}

::selection {
    background-color: color('gold');
    color: color('white');
}

::-moz-selection {
    background-color: color('gold');
    color: color('white');
}
