//  This is a modified version of Normalize.css


*,
*::before,
*::after {
    box-sizing: border-box;
}


html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}


body {
    margin: 0;
    text-align: left;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(#000, 0);
}

main {
    display: block;
}


p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
}

h1 {
    font-size: 2em;
}



hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}


pre {
    font-family: monospace, monospace;
    font-size: 1em;
}


a {
    background-color: transparent;
}


abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}


b,
strong {
    font-weight: bolder;
}


code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}


small {
    font-size: 66%;
}


sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}


img {
    border-style: none;
    vertical-align: middle;
}


button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}


button,
input {
    overflow: visible;
}


button,
select {
    text-transform: none;
}


button,
[type='button'],
[type='reset'],
[type='submit'] {
    appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
}


button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}


textarea {
    overflow: auto;
}

[type='checkbox'],
[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    appearance: textfield;
    outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
    appearance: none;
}


::-webkit-file-upload-button {
    appearance: button;
    font: inherit;
}

::-ms-expand {
    background-color: transparent;
    border: 0;
}

::placeholder {
    opacity: 1;
}

:disabled,
[readonly] {
    opacity: 1;
}


details {
    display: block;
}

summary {
    display: list-item;
}

template {
    display: none;
}


[hidden] {
    display: none;
}


table {
    border-collapse: collapse; // Prevent double borders
}

caption {
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
    font-weight: normal;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}


label {
    display: inline-block;
}

textarea {
    overflow: auto;
    resize: vertical;
}

dt {
    font-weight: bold;
}

// reset margins
* {
    margin-top: 0;
    margin-bottom: 0;
}
