.clearfix {
    @include clearfix;
}

.stretched-link {
    &::after {
        @include abs(0, 0, 0, 0, 1);
        content: '';
        pointer-events: auto;
        background-color: transparent;
    }
}

.appearance-none {
    appearance: none !important;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
}

.contains-icon {
    display: inline-flex;
    align-items: center;
}

.invisible-scrollbars {
    @include invisible-scrollbars;
}

.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.u-text-no-transform {
    text-transform: none;
    letter-spacing: unset;
}

// An accessible label that takes up no visual space
.u-accessible {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0); // Deprecated but still needed to support Firefox & IE
    border: 0;
    clip-path: inset(1px); // New (experimental) CSS property
}

.social-link {
    width: rem(48);
    height: rem(48);
    margin-left: rem(8);
    background-color: color('gold');

    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 0.8;
    }


    span {
        @extend .u-accessible;
    }

}
