$site-width: 1900px;
$container-width: 1300px;
$container-spacing-sm: 35px;
$container-spacing-md: 48px;

.site-wrapper {
    max-width: $site-width;
    margin-left: auto;
    margin-right: auto;
}

%container-spacing {
    // @include spacing(px-4, mx-auto);
    @include spacing(mx-auto);
    padding-left: rem($container-spacing-sm);
    padding-right: rem($container-spacing-sm);

    @include breakpoint-up(md) {
        // @include spacing(px-12);
        padding-left: rem($container-spacing-md);
        padding-right: rem($container-spacing-md);
    }
}

.container {
    @extend %container-spacing;
    max-width: $container-width;
}

.container--responsive {
    /*
    * matches wrapper-medium from main site
    */
    @extend %container-spacing;
    max-width: 900px;
    @include breakpoint-up(md) { max-width: 1000px; }
    @include breakpoint-up(lg) { max-width: 1100px; }
    @include breakpoint-up(xl) { max-width: 1300px; }
}

// .container--sm {
//     @extend %container-spacing;

//     // TODO: Fix this magic number.. (trying to match 8/12 columns)
//     @include breakpoint-up(md) {
//         max-width: #{(($container-width - (96)) / 12 * 8) + 96};
//     }

// }

.container--full {
    @extend %container-spacing;
}


// MIKE
.--default-y-padding {
    @include spacing(pt-12, pb-24);

    @include breakpoint-up(md) {
        @include spacing(pt-24, pb-48);
    }
}


