ul,
.default-margins .list-zero {
    list-style: none;
    padding: 0;
    margin: 0;
}

ol,
ul.bullet-list {
    @include spacing(pl-8);
}

ul.bullet-list {
    list-style: initial;
}


dl {
    font-weight: bold;
}

.list-reset {
    @include list-reset;
}

.default-margins {
    ul {
        list-style-image: url("data:image/svg+xml;charset=UTF-8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath d='M8 8H0V0h8v8zM1 7h6V1H1v6z'/%3E%3C/svg%3E%0A");
        @include spacing(pl-4, mb-8);

        li {
            @include spacing(pl-5);
        }
    }
}
